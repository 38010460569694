{
  "lookup": [
    {
      "k": "Abra",
      "v": {
        "lookup": [
          {
            "k": "Bangued",
            "v": [
              "Agtangao",
              "Angad",
              "BaÃ±acao",
              "Bangbangar",
              "Cabuloan",
              "Calaba",
              "Cosili East (Proper)",
              "Cosili West (Buaya)",
              "Dangdangla",
              "Lingtan",
              "Lipcan",
              "Lubong",
              "Macarcarmay",
              "Macray",
              "Malita",
              "Maoay",
              "Palao",
              "Patucannay",
              "Sagap",
              "Santa Rosa",
              "Sao-atan",
              "Sappaac",
              "Tablac (Calot)",
              "Zone 1 Pob. (Nalasin)",
              "Zone 2 Pob. (Consiliman)",
              "Zone 3 Pob. (Lalaud)",
              "Zone 4 Pob. (Town Proper)",
              "Zone 5 Pob. (Bo. Barikir)",
              "Zone 6 Pob. (Sinapangan)",
              "Zone 7 Pob. (Baliling)"
            ]
          },
          {
            "k": "Bucay",
            "v": [
              "Abang",
              "Bangbangcag",
              "Bangcagan",
              "Banglolao",
              "Bugbog",
              "Calao",
              "Dugong",
              "Labon",
              "Layugan",
              "Madalipay",
              "North Poblacion",
              "Pagala",
              "Pakiling",
              "Palaquio",
              "Patoc",
              "Quimloong",
              "Salnec",
              "San Miguel",
              "Siblong",
              "South Poblacion",
              "Tabiog"
            ]
          },
          {
            "k": "Danglas",
            "v": [
              "Abaquid",
              "Cabaruan",
              "Caupasan (Pob.)",
              "Danglas",
              "Nagaparan",
              "Padangitan",
              "Pangal"
            ]
          },
          {
            "k": "Dolores",
            "v": [
              "Bayaan",
              "Cabaroan",
              "Calumbaya",
              "Cardona",
              "Isit",
              "Kimmalaba",
              "Libtec",
              "Lub-lubba",
              "Mudiit",
              "Namit-ingan",
              "Pacac",
              "Poblacion",
              "Salucag",
              "Talogtog",
              "Taping"
            ]
          },
          {
            "k": "La Paz",
            "v": [
              "Benben (Bonbon)",
              "Bulbulala",
              "Buli",
              "Canan (Gapan)",
              "Liguis",
              "Malabbaga",
              "Mudeng",
              "Pidipid",
              "Poblacion",
              "San Gregorio",
              "Toon",
              "Udangan"
            ]
          },
          {
            "k": "Lacub",
            "v": [
              "Bacag",
              "Buneg",
              "Guinguinabang",
              "Lan-ag",
              "Pacoc",
              "Poblacion (Talampac)"
            ]
          },
          {
            "k": "Lagangilang",
            "v": [
              "Aguet",
              "Bacooc",
              "Balais",
              "Cayapa",
              "Dalaguisen",
              "Laang",
              "Lagben",
              "Laguiben",
              "Nagtipulan",
              "Nagtupacan",
              "Paganao",
              "Pawa",
              "Poblacion",
              "Presentar",
              "San Isidro",
              "Tagodtod"
            ]
          },
          {
            "k": "Manabo",
            "v": [
              "Ayyeng (Pob.)",
              "Catacdegan Nuevo",
              "Catacdegan Viejo",
              "Luzong",
              "San Jose Norte",
              "San Jose Sur",
              "San Juan Norte",
              "San Juan Sur",
              "San Ramon East",
              "San Ramon West",
              "Santo Tomas"
            ]
          },
          {
            "k": "PeÃ±arrubia",
            "v": [
              "Dumayco",
              "Lusuac",
              "Malamsit (Pau-malamsit)",
              "Namarabar",
              "Patiao",
              "Poblacion",
              "Riang (Tiang)",
              "Santa Rosa",
              "Tattawa"
            ]
          },
          {
            "k": "Pidigan",
            "v": [
              "Alinaya",
              "Arab",
              "Garreta",
              "Immuli",
              "Laskig",
              "Monggoc",
              "Naguirayan",
              "Pamutic",
              "Pangtud",
              "Poblacion East",
              "Poblacion West",
              "San Diego",
              "Sulbec",
              "Suyo (Malidong)",
              "Yuyeng"
            ]
          },
          { "k": "Pilar", "v": ["Poblacion"] },
          {
            "k": "San Isidro",
            "v": [
              "Cabayogan",
              "Dalimag",
              "Langbaban",
              "Manayday",
              "Pantoc",
              "Poblacion",
              "Sabtan-olo",
              "Tangbao"
            ]
          },
          {
            "k": "San Quintin",
            "v": [
              "Labaan",
              "Palang",
              "Pantoc",
              "Poblacion",
              "Tangadan",
              "Villa Mercedes"
            ]
          },
          { "k": "Tayum", "v": ["Bagalay", "Deet", "Gaddani", "Velasco"] },
          {
            "k": "Villaviciosa",
            "v": [
              "Ap-apaya",
              "Bol-lilising",
              "Lap-lapog",
              "Lumaba",
              "Poblacion"
            ]
          }
        ],
        "names": [
          "Bangued",
          "Bucay",
          "Danglas",
          "Dolores",
          "La Paz",
          "Lacub",
          "Lagangilang",
          "Manabo",
          "PeÃ±arrubia",
          "Pidigan",
          "Pilar",
          "San Isidro",
          "San Quintin",
          "Tayum",
          "Villaviciosa"
        ]
      }
    },
    {
      "k": "Agusan Del Norte",
      "v": {
        "lookup": [
          {
            "k": "Buenavista",
            "v": [
              "Agong Ong",
              "Alubijid",
              "Malpoc",
              "Manapa",
              "Matabao",
              "Poblacion 1",
              "Poblacion 10",
              "Poblacion 2",
              "Poblacion 3",
              "Poblacion 4",
              "Poblacion 5",
              "Poblacion 6",
              "Poblacion 7",
              "Poblacion 8",
              "Poblacion 9",
              "Rizal",
              "Sacol",
              "Talo Ao"
            ]
          },
          {
            "k": "Butuan City",
            "v": [
              "Agao Pob Bgy 3",
              "Agusan Pequeno",
              "Ambago",
              "Amparo",
              "Ampayon",
              "Antongalon",
              "Baan Km 3",
              "Baan Riverside Pob Bgy 20",
              "Babag",
              "Bading Pob Bgy 22",
              "Bancasi",
              "Banza",
              "Basag",
              "Bayanihan Pob Bgy 27",
              "Bobon",
              "Bonbon",
              "Buhangin Pob Bgy 19",
              "Cabcabon",
              "Dagohoy Pob Bgy 7",
              "Diego Silang Pob Bgy 6",
              "Doongan",
              "Dumalagan",
              "Golden Ribbon Pob Bgy 2",
              "Holy Redeemer Pob Bgy 23",
              "Humabon Pob Bgy 11",
              "Imadejas Pob Bgy 24",
              "Jose Rizal Pob Bgy 25",
              "Kinamlutan",
              "Lapu Lapu Pob Bgy 8",
              "Lemon",
              "Leon Kilat Pob Bgy 13",
              "Libertad",
              "Limaha Pob Bgy 14",
              "Los Angeles",
              "Lumbocan",
              "Mahay",
              "Mahogany Pob Bgy 21",
              "Maon Pob Bgy 1",
              "Masao",
              "Maug",
              "New Society Village Pob Bgy 26",
              "Obrero Pob Bgy 18",
              "Ong Yiu Pob Bgy 16",
              "Pagatpatan",
              "Pangabugan",
              "Pigdaulan",
              "Pinamanculan",
              "Port Poyohon Pob Bgy 17 New Asia",
              "Rajah Soliman Pob Bgy 4",
              "Salvacion",
              "San Ignacio Pob Bgy 15",
              "San Mateo",
              "San Vicente",
              "Sikatuna Pob Bgy 10",
              "Silongan Pob Bgy 5",
              "Sumilihon",
              "Taguibo",
              "Tandang Sora Pob Bgy 12",
              "Tiniwisan",
              "Urduja Pob Bgy 9",
              "Villa Kananga"
            ]
          },
          {
            "k": "Cabadbaran City",
            "v": [
              "Bay Ang",
              "Bayabas",
              "Caasinan",
              "Cabinet",
              "Calamba",
              "Calibunan",
              "Comagascas",
              "Concepcion",
              "Katugasan",
              "Kauswagan",
              "La Union",
              "Mabini",
              "Mahaba",
              "Poblacion 1",
              "Poblacion 10",
              "Poblacion 11",
              "Poblacion 12",
              "Poblacion 2",
              "Poblacion 3",
              "Poblacion 4",
              "Poblacion 5",
              "Poblacion 6",
              "Poblacion 7",
              "Poblacion 8",
              "Poblacion 9",
              "Puting Bato",
              "Sanghan",
              "Soriano",
              "Tolosa"
            ]
          },
          {
            "k": "Carmen",
            "v": [
              "Cahayagan",
              "Gosoon",
              "Poblacion Carmen",
              "Rojales",
              "San Agustin",
              "Tagcatong",
              "Vinapor"
            ]
          },
          {
            "k": "Jabonga",
            "v": [
              "Baleguian",
              "Bangonay",
              "Cuyago",
              "Libas",
              "Poblacion Jabonga"
            ]
          },
          { "k": "Kitcharao", "v": ["Mahayahay", "Poblacion"] },
          {
            "k": "Magallanes",
            "v": [
              "Buhang",
              "Caloc An",
              "Marcos",
              "Poblacion",
              "Santo Nino",
              "Santo Rosario"
            ]
          },
          {
            "k": "Nasipit",
            "v": [
              "Aclan",
              "Amontay",
              "Ata Atahon",
              "Barangay 1 Pob",
              "Barangay 2 Pob",
              "Barangay 3 Pob",
              "Barangay 4 Pob",
              "Barangay 5 Pob",
              "Barangay 6 Pob",
              "Barangay 7 Pob",
              "Camagong",
              "Cubi Cubi",
              "Culit",
              "Kinabjangan",
              "Punta",
              "Santa Ana",
              "Talisay",
              "Triangulo"
            ]
          },
          {
            "k": "Remedios T Romualdez",
            "v": [
              "Balangbalang",
              "Basilisa",
              "Panaytayon",
              "Poblacion I Agay",
              "Poblacion Ii",
              "San Antonio",
              "Tagbongabong"
            ]
          },
          {
            "k": "Santiago",
            "v": [
              "Curva",
              "Jagupit",
              "La Paz",
              "Poblacion I",
              "Poblacion Ii",
              "San Isidro",
              "Tagbuyacan"
            ]
          },
          {
            "k": "Tubay",
            "v": [
              "Dona Rosario",
              "La Fraternidad",
              "Poblacion 1",
              "Poblacion 2",
              "Santa Ana",
              "Tagmamarkay",
              "Victory"
            ]
          }
        ],
        "names": [
          "Buenavista",
          "Butuan City",
          "Cabadbaran City",
          "Carmen",
          "Jabonga",
          "Kitcharao",
          "Magallanes",
          "Nasipit",
          "Remedios T Romualdez",
          "Santiago",
          "Tubay"
        ]
      }
    },
    {
      "k": "Agusan Del Sur",
      "v": {
        "lookup": [
          {
            "k": "Bayugan",
            "v": [
              "Bucac",
              "Cagbas",
              "Canayugan",
              "Claro Cortez",
              "Fili",
              "Hamogaway",
              "Mabuhay",
              "Mahayag",
              "Maygatasan",
              "Montivesta",
              "Mt Olive",
              "Noli",
              "Osmena",
              "Poblacion",
              "San Agustin",
              "Santa Teresita",
              "Taglatawan",
              "Tagubay",
              "Villa Undayon",
              "Wawa"
            ]
          },
          {
            "k": "Bunawan",
            "v": [
              "Bunawan Brook",
              "Consuelo",
              "Libertad",
              "Poblacion",
              "San Andres",
              "San Teodoro"
            ]
          },
          {
            "k": "Esperanza",
            "v": ["Crossing Luna", "Dakutan", "Piglawigan", "Poblacion"]
          },
          {
            "k": "Prosperidad",
            "v": [
              "Aurora",
              "Awa",
              "Azpetia",
              "La Caridad",
              "La Suerte",
              "Las Navas",
              "Lucena",
              "Magsaysay",
              "Mapaga",
              "Salvacion",
              "San Jose",
              "San Lorenzo",
              "San Martin",
              "San Pedro",
              "San Rafael",
              "San Salvador",
              "San Vicente",
              "Santa Irene",
              "Santa Maria"
            ]
          },
          {
            "k": "Rosario",
            "v": ["Bayugan 3", "Libuac", "Poblacion", "Santa Cruz", "Wasi An"]
          },
          {
            "k": "San Francisco",
            "v": [
              "Alegria",
              "Barangay 1",
              "Barangay 1 Pob",
              "Barangay 2",
              "Barangay 2 Pob",
              "Barangay 3",
              "Barangay 3 Pob",
              "Barangay 4",
              "Barangay 4 Pob",
              "Barangay 5",
              "Barangay 5 Pob",
              "Barrio Pikas",
              "Bayugan 1",
              "Bayugan 2",
              "Bitan Agan",
              "Buenasuerte",
              "Bunawan",
              "Bunawan Brooks",
              "Hubang",
              "Karaos",
              "Lapinigan",
              "Libertad",
              "Motor Pool",
              "Pasta",
              "Patin-ay",
              "Pisa-an",
              "Poblacion",
              "Purok Manga Lapinigan",
              "Rosario",
              "San Isidro",
              "Santa Ana",
              "Tagligid"
            ]
          },
          {
            "k": "Santa Josefa",
            "v": ["Angas", "Poblacion", "San Jose", "Santa Isabel"]
          },
          {
            "k": "Sibagat",
            "v": ["Afga", "Padiay", "Poblacion", "San Vicente", "Sinai"]
          },
          {
            "k": "Talacogon",
            "v": [
              "Batucan",
              "Causwagan",
              "Del Monte",
              "Labnig",
              "San Isidro Pob",
              "San Nicolas Pob",
              "Zillovia"
            ]
          },
          {
            "k": "Trento",
            "v": [
              "Basa",
              "Cuevas",
              "Kapatungan",
              "Langkila An",
              "Poblacion",
              "Pulang Lupa"
            ]
          },
          { "k": "Veruela", "v": ["Binongan", "Poblacion", "Sampaguita"] }
        ],
        "names": [
          "Bayugan",
          "Bunawan",
          "Esperanza",
          "Prosperidad",
          "Rosario",
          "San Francisco",
          "Santa Josefa",
          "Sibagat",
          "Talacogon",
          "Trento",
          "Veruela"
        ]
      }
    },
    {
      "k": "Aklan",
      "v": {
        "lookup": [
          { "k": "Altavas", "v": ["Poblacion"] },
          { "k": "Balete", "v": ["Poblacion"] },
          {
            "k": "Banga",
            "v": [
              "Agbanawan",
              "Bacan",
              "Badiangan",
              "Cerrudo",
              "Cupang",
              "Daja Norte",
              "Daja Sur",
              "Jumarap",
              "Lapnag",
              "Libas",
              "Linabuan Sur",
              "Mambog",
              "Mangan",
              "Muguing",
              "Pagsanghan",
              "Palale",
              "Poblacion",
              "Polo",
              "Sigcay",
              "Taba-ao",
              "Tabayon",
              "Torralba",
              "Ugsod",
              "Venturanza"
            ]
          },
          { "k": "Batan", "v": ["Poblacion"] },
          {
            "k": "Buruanga",
            "v": [
              "Alegria",
              "Bagongbayan",
              "Balusbos",
              "Bel-is",
              "Cabugan",
              "El Progreso",
              "Habana",
              "Katipunan",
              "Mayapay",
              "Nazareth",
              "Panilongan",
              "Poblacion",
              "Santander",
              "Tag-osip",
              "Tigum"
            ]
          },
          {
            "k": "Ibajay",
            "v": [
              "Agbago",
              "Aquino",
              "Aslum",
              "Colongcolong",
              "Laguinbanua",
              "Ondoy",
              "Poblacion",
              "Tagbaya"
            ]
          },
          {
            "k": "Kalibo",
            "v": [
              "Andagaw",
              "Bachaw Norte",
              "Bachaw Sur",
              "Briones",
              "Buswang New",
              "Buswang Old",
              "Caano",
              "Estancia",
              "Linabuan Norte",
              "Mabilo",
              "Mobo",
              "Nalook",
              "Poblacion",
              "Pook",
              "Tigayon",
              "Tinigaw"
            ]
          },
          {
            "k": "Lezo",
            "v": [
              "Agcawilan",
              "Bagto",
              "Bugasongan",
              "Carugdog",
              "Cogon",
              "Ibao",
              "Mina",
              "Poblacion",
              "Santa Cruz",
              "Santa Cruz Bigaa",
              "Silakat-nonok",
              "Tayhawan"
            ]
          },
          { "k": "Libacao", "v": ["Poblacion"] },
          { "k": "Madalag", "v": ["Poblacion"] },
          {
            "k": "Makato",
            "v": [
              "Agbalogo",
              "Aglucay",
              "Baybay",
              "Cabatanga",
              "Cajilo",
              "Calangcang",
              "Calimbajan",
              "Cayangwan",
              "Dumga",
              "Libang",
              "Mantiguib",
              "Poblacion",
              "Tibiawan",
              "Tina",
              "Tugas"
            ]
          },
          {
            "k": "Malay",
            "v": [
              "Argao",
              "Balabag",
              "Balusbus",
              "Cabulihan",
              "Caticlan",
              "Cogon",
              "Cubay Norte",
              "Cubay Sur",
              "Dumlog",
              "Manoc-manoc",
              "Motag",
              "Napaan",
              "Poblacion",
              "San Viray",
              "Yapak"
            ]
          },
          {
            "k": "Malinao",
            "v": [
              "Banaybanay",
              "Biga-a",
              "Cabayugan",
              "Cogon",
              "Kinalangay Nuevo",
              "Lilo-an",
              "Malandayon",
              "Manhanip",
              "Navitas",
              "Poblacion",
              "Rosario",
              "San Dimas",
              "San Ramon",
              "San Roque",
              "Tambuan"
            ]
          },
          {
            "k": "Nabas",
            "v": [
              "Alimbo-baybay",
              "Buenasuerte",
              "Buenavista",
              "Gibon",
              "Habana",
              "Libertad",
              "Nagustan",
              "Pinatuad",
              "Poblacion",
              "Rizal",
              "Solido",
              "Toledo",
              "Unidos",
              "Union"
            ]
          },
          {
            "k": "New Washington",
            "v": [
              "Candelaria",
              "Cawayan",
              "Dumaguit",
              "Fatima",
              "Guinbaliwan",
              "Jalas",
              "Jugas",
              "Lawa-an",
              "Mabilo",
              "Mataphao",
              "Ochando",
              "Poblacion",
              "Polo",
              "Puis",
              "Tambak"
            ]
          },
          {
            "k": "Numancia",
            "v": [
              "Albasan",
              "Aliputos",
              "Badio",
              "Bubog",
              "Bulwang",
              "Camanci Norte",
              "Camanci Sur",
              "Dongon East",
              "Dongon West",
              "Joyao-joyao",
              "Laguinbanua East",
              "Laguinbanua West",
              "Marianos",
              "Navitas",
              "Poblacion",
              "Pusiw",
              "Tabangka"
            ]
          },
          {
            "k": "Tangalan",
            "v": [
              "Afga",
              "Baybay",
              "Dapdap",
              "Dumatad",
              "Jawili",
              "Panayakan",
              "Poblacion",
              "Pudiot",
              "Tagas",
              "Tamalagon",
              "Tondog"
            ]
          }
        ],
        "names": [
          "Altavas",
          "Balete",
          "Banga",
          "Batan",
          "Buruanga",
          "Ibajay",
          "Kalibo",
          "Lezo",
          "Libacao",
          "Madalag",
          "Makato",
          "Malay",
          "Malinao",
          "Nabas",
          "New Washington",
          "Numancia",
          "Tangalan"
        ]
      }
    },
    {
      "k": "Albay",
      "v": {
        "lookup": [
          {
            "k": "Bacacay",
            "v": [
              "Baclayon",
              "Barangay 1 (Pob.)",
              "Barangay 10 (Pob.)",
              "Barangay 11 (Pob.)",
              "Barangay 12 (Pob.)",
              "Barangay 13 (Pob.)",
              "Barangay 14 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 4 (Pob.)",
              "Barangay 5 (Pob.)",
              "Barangay 6 (Pob.)",
              "Barangay 7 (Pob.)",
              "Barangay 8 (Pob.)",
              "Barangay 9 (Pob.)",
              "Bonga (Upper)",
              "Busdac (San Jose)",
              "Gubat Ilawod",
              "Hindi",
              "Igang",
              "Panarayon",
              "Pongco (Lower Bonga)",
              "Sogod"
            ]
          },
          {
            "k": "Camalig",
            "v": [
              "Baligang",
              "Barangay 1 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 4 (Pob.)",
              "Barangay 5 (Pob.)",
              "Barangay 6 (Pob.)",
              "Barangay 7 (Pob.)",
              "Bariw",
              "CabagÃ±an",
              "Comun",
              "Gapo",
              "Gotob",
              "Ilawod",
              "Libod",
              "Ligban",
              "Palanog",
              "Quirangay",
              "Salugan",
              "Sumlang",
              "Tagaytay",
              "Taladong",
              "Tinago"
            ]
          },
          {
            "k": "City Of Ligao",
            "v": [
              "Bagumbayan",
              "Bay",
              "Binatagan (Pob.)",
              "Calzada (Pob.)",
              "Cavasi",
              "Dunao",
              "Guilid",
              "Layon",
              "Paulog",
              "Santa Cruz (Pob.)",
              "Tagpo",
              "Tinago",
              "Tomolin",
              "Tuburan"
            ]
          },
          {
            "k": "Daraga (Locsin)",
            "v": [
              "Alcala",
              "Anislag",
              "Bagumbayan",
              "BaÃ±ag",
              "Bascaran",
              "Binitayan",
              "Bongalon",
              "Busay",
              "Cullat",
              "Ilawod Area Pob. (Dist. 2)",
              "Kilicao",
              "Kimantong",
              "Malabog",
              "Market Area Pob. (Dist. 1)",
              "Maroroy",
              "Mayon",
              "Namantao",
              "PeÃ±afrancia",
              "Sagpon",
              "Salvacion",
              "San Roque",
              "Sipi",
              "Tabon-tabon",
              "Tagas",
              "Villahermosa"
            ]
          },
          {
            "k": "Guinobatan",
            "v": [
              "Banao",
              "Binogsacan Lower",
              "Calzada",
              "Ilawod",
              "Inamnan Grande",
              "Inamnan PequeÃ±o",
              "Iraya",
              "Maguiron",
              "Maipon",
              "Morera",
              "Poblacion",
              "Quitago",
              "San Francisco",
              "San Rafael",
              "Travesia"
            ]
          },
          {
            "k": "Legazpi City",
            "v": [
              "Bgy. 1 - Em's Barrio (Pob.)",
              "Bgy. 10 - Cabugao",
              "Bgy. 11 - Maoyod Pob. (Bgy. 10 And 11)",
              "Bgy. 12 - Tula-tula (Pob.)",
              "Bgy. 13 - Ilawod West Pob. (Ilawod 1)",
              "Bgy. 14 - Ilawod Pob. (Ilawod 2)",
              "Bgy. 15 - Ilawod East Pob. (Ilawod 3)",
              "Bgy. 16 - Kawit-east Washington Drive (Pob.)",
              "Bgy. 17 - Rizal Sreet., Ilawod (Pob.)",
              "Bgy. 18 - CabagÃ±an West (Pob.)",
              "Bgy. 19 - CabagÃ±an",
              "Bgy. 2 - Em's Barrio South (Pob.)",
              "Bgy. 20 - CabagÃ±an East (Pob.)",
              "Bgy. 21 - Binanuahan West (Pob.)",
              "Bgy. 22 - Binanuahan East (Pob.)",
              "Bgy. 23 - Imperial Court Subd. (Pob.)",
              "Bgy. 24 - Rizal Street",
              "Bgy. 25 - Lapu-lapu (Pob.)",
              "Bgy. 26 - Dinagaan (Pob.)",
              "Bgy. 27 - Victory Village South (Pob.)",
              "Bgy. 28 - Victory Village North (Pob.)",
              "Bgy. 29 - Sabang (Pob.)",
              "Bgy. 3 - Em's Barrio East (Pob.)",
              "Bgy. 30 - Pigcale (Pob.)",
              "Bgy. 31 - Centro-baybay (Pob.)",
              "Bgy. 32 - San Roque (Bgy. 66)",
              "Bgy. 33 - Pnr-peÃ±aranda St.-iraya (Pob.)",
              "Bgy. 34 - Oro Site-magallanes St. (Pob.)",
              "Bgy. 35 - Tinago (Pob.)",
              "Bgy. 36 - Kapantawan (Pob.)",
              "Bgy. 37 - Bitano (Pob.)",
              "Bgy. 38 - Gogon (Bgy. 54)",
              "Bgy. 39 - Bonot (Pob.)",
              "Bgy. 4 - Sagpon Pob. (Sagpon 1)",
              "Bgy. 40 - Cruzada (Bgy. 52)",
              "Bgy. 41 - Bogtong (Bgy. 45)",
              "Bgy. 42 - Rawis (Bgy. 65)",
              "Bgy. 43 - Tamaoyan (Bgy. 67)",
              "Bgy. 44 - Pawa (Bgy. 61)",
              "Bgy. 45 - Dita (Bgy. 51)",
              "Bgy. 46 - San Joaquin (Bgy. 64)",
              "Bgy. 47 - Arimbay",
              "Bgy. 48 - Bagong Abre (Bgy. 42)",
              "Bgy. 49 - Bigaa (Bgy. 44)",
              "Bgy. 5 - Sagmin Pob. (Sagpon 2)",
              "Bgy. 50 - Padang (Bgy. 60)",
              "Bgy. 51 - Buyuan (Bgy. 49)",
              "Bgy. 52 - Matanag",
              "Bgy. 54 - Mabinit (Bgy. 59)",
              "Bgy. 55 - Estanza (Bgy. 53)",
              "Bgy. 56 - Taysan (Bgy. 68)",
              "Bgy. 57 - Dap-dap (Bgy. 69)",
              "Bgy. 58 - Buragwis",
              "Bgy. 59 - Puro (Bgy. 63)",
              "Bgy. 6 - BaÃ±adero Pob. (Sagpon 3)",
              "Bgy. 60 - Lamba",
              "Bgy. 61 - Maslog (Bgy. 58)",
              "Bgy. 62 - Homapon (Bgy. 55)",
              "Bgy. 63 - Mariawa (Bgy. 56)",
              "Bgy. 64 - Bagacay (Bgy. 41 Bagacay)",
              "Bgy. 65 - Imalnod (Bgy. 57)",
              "Bgy. 66 - Banquerohan (Bgy. 43)",
              "Bgy. 67 - Bariis (Bgy. 46)",
              "Bgy. 68 - San Francisco (Bgy. 62)",
              "Bgy. 69 - Buenavista (Bgy.47)",
              "Bgy. 7 - BaÃ±o (Pob.)",
              "Bgy. 70 - Cagbacong (Bgy. 50)",
              "Bgy. 8 - Bagumbayan (Pob.)",
              "Bgy. 9 - Pinaric (Pob.)"
            ]
          },
          {
            "k": "Libon",
            "v": [
              "Bacolod",
              "Marayag",
              "San Isidro",
              "Zone I (Pob.)",
              "Zone Ii (Pob.)",
              "Zone Iii (Pob.)",
              "Zone Iv (Pob.)",
              "Zone V (Pob.)",
              "Zone Vi (Pob.)",
              "Zone Vii (Pob.)"
            ]
          },
          {
            "k": "Malilipot",
            "v": [
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Barangay V (Pob.)",
              "Basud",
              "San Francisco",
              "San Isidro Ilawod",
              "San Jose",
              "Santa Teresa"
            ]
          },
          {
            "k": "Malinao",
            "v": [
              "Bagumbayan",
              "Balading",
              "Balza",
              "Bariw",
              "Burabod",
              "Cabunturan",
              "Pawa",
              "Payahan",
              "Poblacion"
            ]
          },
          {
            "k": "Oas",
            "v": [
              "Bagumbayan",
              "Balogo",
              "Busac",
              "Calzada",
              "Centro Poblacion",
              "Ilaor Norte",
              "Ilaor Sur",
              "Iraya Norte",
              "Iraya Sur",
              "Manga",
              "Mayao",
              "Obaliw-rinas",
              "Rizal",
              "Saban",
              "San Juan",
              "San Miguel",
              "San Pascual (Nale)",
              "San Vicente (Suca)",
              "Talongog"
            ]
          },
          {
            "k": "Polangui",
            "v": [
              "Alnay",
              "Balinad",
              "Basud",
              "Centro Occidental (Pob.)",
              "Centro Oriental (Pob.)",
              "Gabon",
              "Kinale",
              "Lanigay",
              "Magurang",
              "Mendez",
              "Napo",
              "Ponso",
              "Sugcad",
              "Ubaliw"
            ]
          },
          {
            "k": "Santo Domingo (Libog)",
            "v": [
              "Bagong San Roque",
              "Calayucay",
              "Del Rosario Pob. (Bgy. 3)",
              "Fidel Surtida",
              "Lidong",
              "Market Site Pob. (Bgy. 9)",
              "Nagsiya Pob. (Bgy. 8)",
              "Pandayan Pob. (Bgy. 10)",
              "San Andres",
              "San Fernando",
              "San Francisco Pob. (Bgy. 1)",
              "San Isidro",
              "San Juan Pob. (Bgy. 2)",
              "San Pedro Pob. (Bgy. 5)",
              "San Rafael Pob. (Bgy. 7)",
              "San Roque",
              "San Vicente Pob. (Bgy. 6.)",
              "Santa Misericordia",
              "Santo Domingo Pob. (Bgy. 4)",
              "Santo NiÃ±o"
            ]
          },
          {
            "k": "Tabaco City",
            "v": [
              "Bacolod",
              "Bangkilingan",
              "Baranghawon",
              "Basud (Pob.)",
              "BogÃ±abong",
              "Bombon (Pob.)",
              "CabagÃ±an",
              "Cobo",
              "Cormidal",
              "Divino Rostro (Pob.)",
              "Fatima",
              "Matagbac",
              "Panal",
              "Pawa",
              "Quinale Cabasan (Pob.)",
              "Salvacion",
              "San Carlos",
              "San Juan (Pob.)",
              "San Lorenzo",
              "San Ramon",
              "San Roque",
              "San Vicente",
              "Santo Cristo (Pob.)",
              "Tagas",
              "Tayhi (Pob.)"
            ]
          },
          {
            "k": "Tiwi",
            "v": [
              "Baybay",
              "Cararayan",
              "Coro-coro",
              "Gajo",
              "Libjo",
              "Libtong",
              "Nagas",
              "Oyama",
              "Tigbi (Pob.)"
            ]
          }
        ],
        "names": [
          "Bacacay",
          "Camalig",
          "City Of Ligao",
          "Daraga (Locsin)",
          "Guinobatan",
          "Legazpi City",
          "Libon",
          "Malilipot",
          "Malinao",
          "Oas",
          "Polangui",
          "Santo Domingo (Libog)",
          "Tabaco City",
          "Tiwi"
        ]
      }
    },
    {
      "k": "Antique",
      "v": {
        "lookup": [
          { "k": "Anini-y", "v": ["Poblacion"] },
          { "k": "Barbaza", "v": ["Poblacion"] },
          {
            "k": "Belison",
            "v": [
              "Concepcion",
              "Delima",
              "Ipil",
              "Maradiona",
              "Poblacion",
              "Rombang"
            ]
          },
          {
            "k": "Bugasong",
            "v": [
              "Anilawan",
              "Arangote",
              "Bagtason",
              "Camangahan",
              "Centro Ilawod (Pob.)",
              "Centro Ilaya (Pob.)",
              "Centro Pojo (Pob.)",
              "Cubay North",
              "Cubay South",
              "Guija",
              "Igbalangao",
              "Igsoro",
              "Ilaures",
              "Jinalinan",
              "Lacayon",
              "Maray",
              "Paliwan",
              "Pangalcagan",
              "Sabang East",
              "Sabang West",
              "Tagudtud North",
              "Tagudtud South",
              "Talisay",
              "Tica",
              "Tono-an",
              "Yapu",
              "Zaragoza"
            ]
          },
          {
            "k": "Culasi",
            "v": [
              "Bitadton Norte",
              "Bitadton Sur",
              "Caridad",
              "Centro Norte (Pob.)",
              "Centro Poblacion",
              "Centro Sur (Pob.)",
              "Esperanza",
              "Fe",
              "Lipata",
              "Naba"
            ]
          },
          {
            "k": "Hamtic",
            "v": [
              "Apdo",
              "Asluman",
              "Banawon",
              "Bia-an",
              "Budbudan",
              "Buhang",
              "Calacja I",
              "Calacja Ii",
              "Calala",
              "Caridad",
              "Dangcalan",
              "Fabrica",
              "Funda",
              "General Fullon (Tina)",
              "Gov. Evelio B. Javier (Lanag)",
              "Guintas",
              "Igbical",
              "Igbucagay",
              "Inabasan",
              "Ingwan-batangan",
              "La Paz",
              "Linaban",
              "Malandog",
              "Mapatag",
              "Masanag",
              "Piape I",
              "Piape Ii",
              "Piape Iii",
              "Poblacion 1",
              "Poblacion 2",
              "Poblacion 3",
              "Poblacion 4",
              "Poblacion 5",
              "Villavert-jimenez"
            ]
          },
          {
            "k": "Laua-an",
            "v": [
              "Casit-an",
              "Guinbanga-an",
              "Igtadiao",
              "Intao",
              "Loon",
              "Mauno",
              "Omlot",
              "Poblacion (Centro)"
            ]
          },
          {
            "k": "Libertad",
            "v": [
              "Barusbus",
              "Bulanao",
              "Centro Este (Pob.)",
              "Centro Weste (Pob.)",
              "Cubay",
              "Igcagay",
              "Lindero",
              "Maramig",
              "Pajo",
              "Panangkilon",
              "Paz",
              "San Roque",
              "Taboc",
              "Union"
            ]
          },
          {
            "k": "Pandan",
            "v": [
              "Bagumbayan",
              "Baybay",
              "Botbot",
              "Buang",
              "Cabugao",
              "Candari",
              "Carmen",
              "Centro Norte (Pob.)",
              "Centro Sur (Pob.)",
              "Dionela",
              "Dumrog",
              "Duyong",
              "Fragante",
              "Guia",
              "Idiacacan",
              "Jinalinan",
              "Mag-aba",
              "Nauring",
              "Patria",
              "San Andres",
              "Santa Cruz",
              "Santa Fe",
              "Santo Rosario",
              "Talisay",
              "Tingib",
              "Zaldivar"
            ]
          },
          {
            "k": "Patnongon",
            "v": [
              "AlvaÃ±iz",
              "Apgahan",
              "Aureliana",
              "Badiangan",
              "Igburi",
              "Macarina",
              "Magsaysay",
              "Padang",
              "Pandanan",
              "Poblacion",
              "Samalague",
              "San Rafael",
              "Tamayoc"
            ]
          },
          {
            "k": "San Jose",
            "v": [
              "Atabay",
              "Badiang",
              "Barangay 1 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 4 (Pob.)",
              "Barangay 5 (Pob.)",
              "Barangay 6 (Pob.)",
              "Barangay 7 (Pob.)",
              "Barangay 8 (Pob.)",
              "Bariri",
              "Bugarot (Catungan-bugarot)",
              "Cansadan (Cansadan-tubudan)",
              "Durog",
              "Funda-dalipe",
              "Igbonglo",
              "Inabasan",
              "Madrangca",
              "Magcalon",
              "Malaiba",
              "Maybato Norte",
              "Maybato Sur",
              "Mojon",
              "Pantao",
              "San Angel",
              "San Fernando",
              "San Pedro",
              "Supa"
            ]
          },
          {
            "k": "San Remigio",
            "v": [
              "Bagumbayan",
              "Baladjay",
              "Barangbang",
              "Bugo",
              "Cabiawan",
              "Cadolonan",
              "Carawisan I",
              "Carawisan Ii",
              "Iguirindon",
              "Magdalena",
              "Maragubdub",
              "Nasuli",
              "Poblacion (Calag-itan)",
              "Ramon Magsaysay",
              "San Rafael",
              "Trinidad",
              "Vilvar"
            ]
          },
          {
            "k": "Sebaste",
            "v": [
              "Abiera",
              "Aguila",
              "Aras-asan",
              "Bacalan",
              "Callan",
              "Idio",
              "Nauhon",
              "Poblacion"
            ]
          },
          {
            "k": "Sibalom",
            "v": [
              "Alangan",
              "Bari",
              "Biga-a",
              "Bongsod",
              "Bontol",
              "Bugnay",
              "Cabariuan",
              "Cadoldolan",
              "Catmon",
              "Catungan I",
              "Catungan Ii",
              "Catungan Iii",
              "Catungan Iv",
              "Cubay-napultan",
              "Cubay-sermon",
              "District I (Pob.)",
              "District Ii (Pob.)",
              "District Iii (Pob.)",
              "District Iv (Pob.)",
              "EgaÃ±a",
              "Esperanza I",
              "Esperanza Ii",
              "Esperanza Iii",
              "Igdalaquit",
              "Iglanot",
              "Igpanolong",
              "Ilabas",
              "Imparayan",
              "Inabasan",
              "Lacaron",
              "Luyang",
              "Mabini",
              "Millamena",
              "Mojon",
              "Nagdayao",
              "Nazareth",
              "Odiong",
              "Olaga",
              "Pangpang",
              "Panlagangan",
              "Pasong",
              "Pis-anan",
              "Rombang",
              "San Juan",
              "Sido",
              "Solong",
              "Tigbalua I",
              "Tigbalua Ii",
              "Villahermosa",
              "Villar"
            ]
          },
          { "k": "Tibiao", "v": ["Poblacion"] },
          {
            "k": "Tobias Fornier (Dao)",
            "v": [
              "Abaca",
              "Aras-asan",
              "Arobo",
              "Atabay",
              "Atiotes",
              "Bagumbayan",
              "Balud",
              "Barasanan A",
              "Barasanan B",
              "Barasanan C",
              "Cato-ogan",
              "Diclum",
              "Fatima",
              "Igbalogo",
              "Igbangcal-a",
              "Igbangcal-b",
              "Igbangcal-c",
              "Igcabuad",
              "Igcadac",
              "Igdalaguit",
              "Igdanlog",
              "Lindero",
              "Masayo",
              "Paciencia",
              "Poblacion Norte",
              "Poblacion Sur",
              "Santo Tomas",
              "Villaflor"
            ]
          },
          {
            "k": "Valderrama",
            "v": [
              "Binanogan",
              "Buluangan I",
              "Buluangan Ii",
              "Canipayan",
              "Iglinab",
              "Pandanan",
              "Takas (Pob.)",
              "Tigmamale",
              "Ubos (Pob.)"
            ]
          }
        ],
        "names": [
          "Anini-y",
          "Barbaza",
          "Belison",
          "Bugasong",
          "Culasi",
          "Hamtic",
          "Laua-an",
          "Libertad",
          "Pandan",
          "Patnongon",
          "San Jose",
          "San Remigio",
          "Sebaste",
          "Sibalom",
          "Tibiao",
          "Tobias Fornier (Dao)",
          "Valderrama"
        ]
      }
    },
    {
      "k": "Apayao",
      "v": {
        "lookup": [
          {
            "k": "Luna",
            "v": [
              "Dagupan",
              "Poblacion",
              "Quirino",
              "San Isidro Norte",
              "San Isidro Sur"
            ]
          }
        ],
        "names": ["Luna"]
      }
    },
    {
      "k": "Aurora",
      "v": {
        "lookup": [
          {
            "k": "Baler",
            "v": [
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Barangay V (Pob.)",
              "Buhangin",
              "Calabuanan",
              "Deguisit",
              "Obligacion",
              "Pingit",
              "Reserva",
              "Sabang",
              "Suclayin",
              "Zabali"
            ]
          },
          {
            "k": "Dipaculao",
            "v": [
              "Buenavista",
              "Ipil",
              "Laboy",
              "Lipit",
              "Lobbot",
              "Maligaya",
              "Mucdol",
              "North Poblacion",
              "Salay",
              "South Poblacion"
            ]
          },
          {
            "k": "Maria Aurora",
            "v": [
              "Alcala",
              "Bagtu",
              "Bannawag",
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Cabituculan East",
              "Cabituculan West",
              "Dikildit",
              "Dimanpudso",
              "Estonilo",
              "Florida",
              "Malasin",
              "Quirino",
              "Ramada",
              "San Joaquin",
              "San Jose",
              "San Juan",
              "Santa Lucia"
            ]
          },
          {
            "k": "San Luis",
            "v": [
              "Bacong",
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Ditumabo",
              "Nonong Senior",
              "Real",
              "San Isidro",
              "San Jose",
              "Zarah"
            ]
          }
        ],
        "names": ["Baler", "Dipaculao", "Maria Aurora", "San Luis"]
      }
    },
    {
      "k": "Basilan",
      "v": {
        "lookup": [
          {
            "k": "Isabela City",
            "v": [
              "Aguada",
              "Balatanay",
              "Baluno",
              "Begang",
              "Binuangan",
              "Busay",
              "Cabunbata",
              "Calvario",
              "Carbon",
              "Diki",
              "Dona Ramona T. Alano (Isabela Westsite)",
              "Isabela Eastside (Pob.)",
              "Isabela Proper (Pob.)",
              "Kapatagan Grande",
              "Kapayawan",
              "Kaumpurnah Zone I",
              "Kaumpurnah Zone Ii",
              "Kaumpurnah Zone Iii",
              "Kumalarang",
              "La Piedad (Pob.)",
              "Lampinigan",
              "Lanote",
              "Lukbuton",
              "Lumbang",
              "Makiri",
              "Maligue (Lunot)",
              "Marang-marang",
              "Marketsite (Pob.)",
              "Masula",
              "Menzi",
              "Panigayan",
              "Panunsulan",
              "Port Area (Pob.)",
              "Riverside",
              "San Rafael",
              "Santa Barbara",
              "Santa Cruz (Pob.)",
              "Seaside (Pob.)",
              "Small Kapatagan",
              "Sumagdang",
              "Sunrise Village (Pob.)",
              "Tabiawan",
              "Tabuk (Pob.)",
              "Tampalan",
              "Timpul"
            ]
          },
          {
            "k": "Lamitan City",
            "v": [
              "Balagtasan",
              "Bato",
              "Campo Uno",
              "Colonia",
              "Kulay Bato",
              "Lebbuh",
              "Limo-ok",
              "Malo-ong San Jose",
              "Santa Clara",
              "Sengal",
              "Ubit"
            ]
          },
          { "k": "Maluso", "v": ["Port Holland Zone I Pob. (Upper)", "Tamuk"] }
        ],
        "names": ["Isabela City", "Lamitan City", "Maluso"]
      }
    },
    {
      "k": "Bataan",
      "v": {
        "lookup": [
          {
            "k": "Abucay",
            "v": [
              "Calaylayan (Pob.)",
              "Capitangan",
              "Gabon",
              "Laon (Pob.)",
              "Mabatang",
              "Omboy",
              "Salian",
              "Wawa (Pob.)"
            ]
          },
          {
            "k": "Bagac",
            "v": [
              "Atilano L. Ricardo",
              "Bagumbayan (Pob.)",
              "Binukawan",
              "Ibaba",
              "Ibis",
              "Pag-asa (Wawa-sibacan)",
              "Parang",
              "San Antonio",
              "Tabing-ilog (Pob.)"
            ]
          },
          {
            "k": "Balanga City",
            "v": [
              "Bagong Silang",
              "Bagumbayan",
              "Cabog-cabog",
              "Camacho",
              "Cataning",
              "Central",
              "Cupang North",
              "Cupang Proper",
              "Cupang West",
              "DoÃ±a Francisca",
              "Ibayo",
              "Lote",
              "Malabia",
              "Munting Batangas (Cadre)",
              "Poblacion",
              "Pto. Rivas Ibaba",
              "Pto. Rivas Itaas",
              "San Jose",
              "Sibacan",
              "Talisay",
              "Tenejero",
              "Tortugas",
              "Tuyo"
            ]
          },
          {
            "k": "Dinalupihan",
            "v": [
              "Aquino",
              "Bangal",
              "Bayan-bayanan",
              "Bonifacio (Pob.)",
              "Burgos (Pob.)",
              "Colo",
              "Daang Bago",
              "Dalao",
              "Del Pilar (Pob.)",
              "Gen. Luna (Pob.)",
              "Gomez (Pob.)",
              "Happy Valley",
              "Jose C. Payumo, Jr.",
              "Kataasan",
              "Layac",
              "Luacan",
              "Mabini Ext. (Pob.)",
              "Mabini Proper (Pob.)",
              "Magsaysay",
              "Maligaya",
              "Naparing",
              "New San Jose",
              "Old San Jose",
              "Padre Dandan (Pob.)",
              "Pag-asa",
              "Pagalanggang",
              "Payangan",
              "Pentor",
              "Pinulot",
              "Rizal (Pob.)",
              "Roosevelt",
              "Roxas (Pob.)",
              "Saguing",
              "San Benito",
              "San Isidro (Pob.)",
              "San Pablo (Bulate)",
              "San Ramon",
              "San Simon",
              "Santa Isabel (Tabacan)",
              "Santo NiÃ±o",
              "Sapang Balas",
              "Torres Bugauen (Pob.)",
              "Tucop",
              "Zamora (Pob.)"
            ]
          },
          {
            "k": "Hermosa",
            "v": [
              "A. Rivera (Pob.)",
              "Almacen",
              "Bacong",
              "Balsic",
              "Bamban",
              "Burgos-soliman (Pob.)",
              "Cataning (Pob.)",
              "Culis",
              "Daungan (Pob.)",
              "Judge Roman Cruz Sr. (Mandama)",
              "Mabiga",
              "Mabuco",
              "Mambog - Mandama",
              "Palihan",
              "Pandatung",
              "Pulo",
              "Saba",
              "Sacrifice Valley",
              "San Pedro (Pob.)",
              "Santo Cristo (Pob.)",
              "Tipo"
            ]
          },
          {
            "k": "Limay",
            "v": [
              "Alangan",
              "Duale",
              "Kitang 2 And Luz",
              "Kitang I",
              "Lamao",
              "Landing",
              "Poblacion",
              "Reformista",
              "San Francisco De Asis",
              "St. Francis I",
              "St. Francis Ii",
              "Townsite",
              "Wawa"
            ]
          },
          {
            "k": "Mariveles",
            "v": [
              "Alas-asin",
              "Alion",
              "Balon-anito",
              "Batangas Ii",
              "Cabcaben",
              "Camaya",
              "Ipag",
              "Lucanin",
              "Malaya",
              "Maligaya",
              "Mt. View",
              "Poblacion",
              "San Carlos",
              "San Isidro",
              "Townsite"
            ]
          },
          {
            "k": "Morong",
            "v": ["Binaritan", "Nagbalayong", "Poblacion", "Sabang"]
          },
          {
            "k": "Orani",
            "v": [
              "Apollo",
              "Bagong Paraiso (Pob.)",
              "Balut (Pob.)",
              "Bayan (Pob.)",
              "Calero (Pob.)",
              "Centro I (Pob.)",
              "Centro Ii (Pob.)",
              "Dona",
              "Kabalutan",
              "Kaparangan",
              "Maria Fe",
              "Masantol",
              "Mulawin",
              "Pag Asa",
              "Paking-carbonero (Pob.)",
              "Palihan (Pob.)",
              "Pantalan Bago (Pob.)",
              "Pantalan Luma (Pob.)",
              "Parang Parang (Pob.)",
              "Puksuan",
              "Sibul",
              "Silahis",
              "Tagumpay",
              "Talimundoc",
              "Tapulao",
              "Tenejero (Pob.)",
              "Tugatog",
              "Wawa (Pob.)"
            ]
          },
          {
            "k": "Orion",
            "v": [
              "Arellano (Pob.)",
              "Bagumbayan (Pob.)",
              "Balagtas (Pob.)",
              "Balut (Pob.)",
              "Bantan",
              "Bilolo",
              "Calungusan",
              "Camachile",
              "Daang Bago (Pob.)",
              "Daang Bilolo (Pob.)",
              "Daang Pare",
              "General Lim (Kaput)",
              "Kapunitan",
              "Lati (Pob.)",
              "Lusungan (Pob.)",
              "Puting Buhangin",
              "Sabatan",
              "San Vicente (Pob.)",
              "Santa Elena",
              "Santo Domingo",
              "Villa Angeles (Pob.)",
              "Wakas (Pob.)",
              "Wawa (Pob.)"
            ]
          },
          {
            "k": "Pilar",
            "v": [
              "Ala-uli",
              "Bagumbayan",
              "Balut I",
              "Balut Ii",
              "Bantan Munti",
              "Burgos",
              "Del Rosario (Pob.)",
              "Diwa",
              "Landing",
              "Liyang",
              "Nagwaling",
              "Panilao",
              "Pantingan",
              "Poblacion",
              "Rizal (Pob.)",
              "Santa Rosa",
              "Wakas North",
              "Wakas South",
              "Wawa"
            ]
          },
          {
            "k": "Samal",
            "v": [
              "East Calaguiman (Pob.)",
              "East Daang Bago (Pob.)",
              "Gugo",
              "Ibaba (Pob.)",
              "Imelda",
              "Lalawigan",
              "San Juan (Pob.)",
              "San Roque (Pob.)",
              "Santa Lucia",
              "Sapa",
              "Tabing Ilog",
              "West Calaguiman (Pob.)",
              "West Daang Bago (Pob.)"
            ]
          }
        ],
        "names": [
          "Abucay",
          "Bagac",
          "Balanga City",
          "Dinalupihan",
          "Hermosa",
          "Limay",
          "Mariveles",
          "Morong",
          "Orani",
          "Orion",
          "Pilar",
          "Samal"
        ]
      }
    },
    {
      "k": "Batanes",
      "v": {
        "lookup": [
          {
            "k": "Ivana",
            "v": ["Radiwan", "Salagao", "San Vicente (Igang)", "Tuhel (Pob.)"]
          },
          {
            "k": "Mahatao",
            "v": ["Hanib", "Kaumbakan", "Panatayan", "Uvoy (Pob.)"]
          },
          {
            "k": "Uyugan",
            "v": ["Imnajbu", "Itbud", "Kayuganan (Pob.)", "Kayvaluganan (Pob.)"]
          }
        ],
        "names": ["Ivana", "Mahatao", "Uyugan"]
      }
    },
    {
      "k": "Batangas",
      "v": {
        "lookup": [
          {
            "k": "Agoncillo",
            "v": [
              "Adia",
              "Bagong Sikat",
              "Balangon",
              "Bangin",
              "Coral Na Munti",
              "Guitna",
              "Pamiga",
              "Panhulan",
              "Pansipit",
              "Poblacion",
              "Pook",
              "San Jacinto",
              "San Teodoro",
              "Santa Cruz",
              "Santo Tomas",
              "Subic Ibaba",
              "Subic Ilaya"
            ]
          },
          {
            "k": "Alitagtag",
            "v": [
              "Balagbag",
              "Concepcion",
              "Dalipit East",
              "Dalipit West",
              "Dominador East",
              "Dominador West",
              "Munlawin Norte",
              "Munlawin Sur",
              "Muzon Primero",
              "Muzon Segundo",
              "Pinagkurusan",
              "Poblacion East",
              "Poblacion West",
              "San Jose",
              "Santa Cruz"
            ]
          },
          {
            "k": "Balayan",
            "v": [
              "Baclaran",
              "Barangay 1 (Pob.)",
              "Barangay 10 (Pob.)",
              "Barangay 11 (Pob.)",
              "Barangay 12 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 4 (Pob.)",
              "Barangay 5 (Pob.)",
              "Barangay 6 (Pob.)",
              "Barangay 7 (Pob.)",
              "Barangay 8 (Pob.)",
              "Barangay 9 (Pob.)",
              "Caloocan",
              "Calzada",
              "Canda",
              "Carenahan",
              "Caybunga",
              "Cayponce",
              "Dao",
              "Dilao",
              "Duhatan",
              "Durungao",
              "Gimalas",
              "Gumamela",
              "Lagnas",
              "Lanatan",
              "Langgangan",
              "Lucban Pook",
              "Lucban Putol",
              "Malalay",
              "Munting Tubig",
              "Navotas",
              "Palikpikan",
              "Pooc",
              "Sambat",
              "Sampaga",
              "San Juan",
              "San Piro",
              "Santol",
              "Tactac",
              "Tanggoy"
            ]
          },
          {
            "k": "Balete",
            "v": [
              "Looc",
              "Magapi",
              "Makina",
              "Malabanan",
              "Palsara",
              "Poblacion",
              "Sala"
            ]
          },
          {
            "k": "Batangas",
            "v": [
              "Alangilan",
              "Balagtas",
              "Balete",
              "Banaba Center",
              "Banaba Ibaba",
              "Banaba Kanluran",
              "Banaba Silangan",
              "Barangay 1 (Pob.)",
              "Barangay 10 (Pob.)",
              "Barangay 11 (Pob.)",
              "Barangay 12 (Pob.)",
              "Barangay 13 (Pob.)",
              "Barangay 14 (Pob.)",
              "Barangay 15 (Pob.)",
              "Barangay 16 (Pob.)",
              "Barangay 17 (Pob.)",
              "Barangay 18 (Pob.)",
              "Barangay 19 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 20 (Pob.)",
              "Barangay 21 (Pob.)",
              "Barangay 22 (Pob.)",
              "Barangay 23 (Pob.)",
              "Barangay 24 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 4 (Pob.)",
              "Barangay 5 (Pob.)",
              "Barangay 6 (Pob.)",
              "Barangay 7 (Pob.)",
              "Barangay 8 (Pob.)",
              "Barangay 9 (Pob.)",
              "Bilogo",
              "Bolbok",
              "Bukal",
              "Calicanto",
              "Concepcion",
              "Conde Itaas",
              "Conde Labak",
              "Cuta",
              "Dalig",
              "Dela Paz",
              "Dela Paz Pulot Aplaya",
              "Domoclay",
              "Dumantay",
              "Gulod Itaas",
              "Gulod Labak",
              "Ilihan",
              "Kumintang Ibaba",
              "Kumintang Ilaya",
              "Libjo",
              "Maapas",
              "Mabacong (Matoco)",
              "Mahabang Dahilig",
              "Mahabang Parang",
              "Mahacot Kanluran",
              "Mahacot Silangan",
              "Malalim",
              "Malitam",
              "Pagkilatan",
              "Paharang Kanluran",
              "Paharang Silangan",
              "Pallocan Kanluran",
              "Pallocan Silangan",
              "Pinamucan",
              "Pinamucan Ibaba",
              "Pinamucan Silangan",
              "Sampaga",
              "San Isidro",
              "San Jose Sico",
              "San Miguel",
              "San Pedro",
              "Santa Clara",
              "Santa Rita Aplaya",
              "Santa Rita Karsada",
              "Santo Domingo",
              "Santo NiÃ±o",
              "Simlong",
              "Sirang Lupa",
              "Sorosoro Ibaba",
              "Sorosoro Ilaya",
              "Sorosoro Karsada",
              "Tabangao Ambulong",
              "Tabangao Aplaya (Tabangao Proper)",
              "Tabangao Dao",
              "Talahib Pandayan",
              "Talumpok Kanluran",
              "Talumpok Silangan",
              "Tinga Itaas",
              "Tinga Labak",
              "Tulo",
              "Wawa"
            ]
          },
          {
            "k": "Bauan",
            "v": [
              "Alagao",
              "Aplaya",
              "As-is",
              "Bagong Silang",
              "Baguilawa",
              "Balayong",
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Bolo",
              "Cupang",
              "Durungao",
              "Gulibay",
              "Inicbulan",
              "Malindig",
              "Manalupong",
              "Manghinao Proper",
              "Manghinao Uno",
              "New Danglayan",
              "Pitugo",
              "Rizal",
              "San Agustin",
              "San Andres Proper",
              "San Andres Uno",
              "San Miguel",
              "San Pedro",
              "San Roque",
              "San Teodoro",
              "San Vicente",
              "Santa Maria",
              "Santo Domingo",
              "Sinala"
            ]
          },
          {
            "k": "Calaca",
            "v": [
              "Bagong Tubig",
              "Barangay 1 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 4 (Pob.)",
              "Barangay 5 (Pob.)",
              "Barangay 6 (Pob.)",
              "Camastilisan",
              "Dacanlao",
              "Lumbang Calzada",
              "Makina",
              "Pantay",
              "Puting Bato East",
              "Puting Bato West",
              "Quisumbing",
              "Salong",
              "San Rafael",
              "Sinisian",
              "Talisay"
            ]
          },
          {
            "k": "Calatagan",
            "v": [
              "Bagong Silang",
              "Balibago",
              "Balitoc",
              "Barangay 1 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 4 (Pob.)",
              "Biga",
              "Carretunan",
              "Gulod",
              "Lucsuhin",
              "Paraiso",
              "Quilitisan",
              "Real",
              "Sambungan",
              "Santa Ana",
              "Talisay",
              "Tanagan"
            ]
          },
          {
            "k": "Cuenca",
            "v": [
              "Balagbag",
              "Barangay 1 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 4 (Pob.)",
              "Barangay 5 (Pob.)",
              "Barangay 6 (Pob.)",
              "Barangay 7 (Pob.)",
              "Barangay 8 (Pob.)",
              "Bungahan",
              "Dalipit East",
              "Dalipit West",
              "Dita",
              "Emmanuel",
              "Ibabao",
              "Labac",
              "San Felipe"
            ]
          },
          {
            "k": "Ibaan",
            "v": [
              "Bago",
              "Balanga",
              "Bungahan",
              "Calamias",
              "Catandala",
              "Coliat",
              "Dayapan",
              "Lapu-lapu",
              "Lucsuhin",
              "Mabalor",
              "Malainin",
              "Matala",
              "Palindan",
              "Pangao",
              "Panghayaan",
              "Poblacion",
              "Quilo",
              "Sabang",
              "San Agustin",
              "Sandalan",
              "Talaibon",
              "Tulay Na Patpat"
            ]
          },
          {
            "k": "Laurel",
            "v": [
              "As-is",
              "Balakilong",
              "Barangay 1 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 4 (Pob.)",
              "Barangay 5 (Pob.)",
              "Berinayan",
              "Bugaan East",
              "Bugaan West",
              "Buso-buso",
              "Gulod",
              "J. Leviste",
              "Molinete",
              "San Gabriel",
              "Santa Maria",
              "Ticub"
            ]
          },
          {
            "k": "Lemery",
            "v": [
              "Anak-dagat",
              "Arumahan",
              "Ayao-iyao",
              "Bagong Pook",
              "Bagong Sikat",
              "Balanga",
              "Bukal",
              "Dayapan",
              "District I (Pob.)",
              "District Ii (Pob.)",
              "District Iii (Pob.)",
              "District Iv (Pob.)",
              "Lucky",
              "Maguihan",
              "Mahayahay",
              "Maigsing Dahilig",
              "Maligaya",
              "Malinis",
              "Mataas Na Bayan",
              "Matingain I",
              "Matingain Ii",
              "Nonong Casto",
              "Palanas",
              "Payapa Ibaba",
              "Payapa Ilaya",
              "Rizal",
              "Sambal Ibaba",
              "Sambal Ilaya",
              "Sangalang",
              "Sinisian East",
              "Sinisian West",
              "Tubigan",
              "Wawa Ibaba",
              "Wawa Ilaya"
            ]
          },
          {
            "k": "Lian",
            "v": [
              "Bagong Pook",
              "Balibago",
              "Barangay 1 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 4 (Pob.)",
              "Barangay 5 (Pob.)",
              "Binubusan",
              "Bungahan",
              "Cumba",
              "Humayingan",
              "Kapito",
              "Luyahan",
              "Malaruhatan",
              "Matabungkay",
              "Prenza",
              "Puting-kahoy",
              "San Diego"
            ]
          },
          {
            "k": "Lipa",
            "v": [
              "Adya",
              "Anilao",
              "Anilao-labac",
              "Antipolo Del Norte",
              "Antipolo Del Sur",
              "Bagong Pook",
              "Balintawak",
              "Banaybanay",
              "Barangay 12 (Pob.)",
              "Bolbok",
              "Bugtong Na Pulo",
              "Bulacnin",
              "Bulaklakan",
              "Calamias",
              "Cumba",
              "Dagatan",
              "Duhatan",
              "Halang",
              "Inosloban",
              "Kayumanggi",
              "Latag",
              "Lodlod",
              "Lumbang",
              "Mabini",
              "Malagonlong",
              "Marauoy",
              "Mataas Na Lupa",
              "Munting Pulo",
              "Pangao",
              "Pinagkawitan",
              "Pinagtongulan",
              "Plaridel",
              "Poblacion Barangay 1",
              "Poblacion Barangay 10",
              "Poblacion Barangay 11",
              "Poblacion Barangay 2",
              "Poblacion Barangay 3",
              "Poblacion Barangay 4",
              "Poblacion Barangay 5",
              "Poblacion Barangay 6",
              "Poblacion Barangay 7",
              "Poblacion Barangay 8",
              "Poblacion Barangay 9",
              "Pusil",
              "Quezon",
              "Rizal",
              "Sabang",
              "Sampaguita",
              "San Benito",
              "San Carlos",
              "San Celestino",
              "San Francisco",
              "San Guillermo",
              "San Jose",
              "San Lucas",
              "San Salvador",
              "San Sebastian (Balagbag)",
              "Santo NiÃ±o",
              "Sico( If Fernando Airbase, Need Contact Number )",
              "Talisay",
              "Tambo",
              "Tangob",
              "Tanguay",
              "Tibig",
              "Tipacan"
            ]
          },
          {
            "k": "Lobo",
            "v": [
              "Banalo",
              "Fabrica",
              "Malapad Na Parang",
              "Masaguitsit",
              "Poblacion",
              "San Miguel"
            ]
          },
          {
            "k": "Mabini",
            "v": [
              "Anilao East",
              "Anilao Proper",
              "Bulacan",
              "Calamias",
              "Gasang",
              "Ligaya",
              "Mainaga",
              "Majuben",
              "Poblacion",
              "Pulang Lupa",
              "Pulong Anahao",
              "Pulong Balibaguhan",
              "Pulong Niogan",
              "Saguing",
              "Sampaguita",
              "San Francisco",
              "San Jose",
              "San Juan",
              "Santa Ana",
              "Santa Mesa",
              "Santo Tomas",
              "Solo",
              "Talaga East",
              "Talaga Proper"
            ]
          },
          {
            "k": "Malvar",
            "v": [
              "Bagong Pook",
              "Bilucao (San Isidro Western)",
              "Bulihan",
              "Luta Del Norte",
              "Luta Del Sur",
              "Poblacion",
              "San Andres",
              "San Fernando",
              "San Gregorio",
              "San Isidro East",
              "San Juan",
              "San Pedro I (Eastern)",
              "San Pedro Ii (Western)",
              "San Pioquinto",
              "Santiago"
            ]
          },
          {
            "k": "Mataas Na Kahoy",
            "v": [
              "Barangay Ii-a (Pob.)",
              "Bayorbor",
              "Bubuyan",
              "Calingatan",
              "District I (Pob.)",
              "District Ii (Pob.)",
              "District Iii (Pob.)",
              "District Iv (Pob.)",
              "Kinalaglagan",
              "Loob",
              "Manggahan",
              "Nangkaan",
              "San Sebastian",
              "Santol",
              "Upa"
            ]
          },
          {
            "k": "Nasugbu",
            "v": [
              "Balaytigui",
              "Banilad",
              "Barangay 1 (Pob.)",
              "Barangay 10 (Pob.)",
              "Barangay 11 (Pob.)",
              "Barangay 12 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 4 (Pob.)",
              "Barangay 5 (Pob.)",
              "Barangay 6 (Pob.)",
              "Barangay 7 (Pob.)",
              "Barangay 8 (Pob.)",
              "Barangay 9 (Pob.)",
              "Bilaran",
              "Bucana",
              "Catandaan",
              "Cogunan",
              "Dayap",
              "Lumbangan",
              "Malapad Na Bato",
              "Maugat",
              "Munting Indan",
              "Natipuan",
              "Pantalan",
              "Putat",
              "Reparo",
              "Talangan",
              "Tumalim",
              "Wawa"
            ]
          },
          {
            "k": "Padre Garcia",
            "v": [
              "Banaba",
              "Banaybanay",
              "Bawi",
              "Castillo",
              "Cawongan",
              "Pansol",
              "Payapa",
              "Poblacion",
              "Quilo-quilo North",
              "Quilo-quilo South",
              "San Felipe",
              "San Miguel",
              "Tamak",
              "Tangob"
            ]
          },
          {
            "k": "Rosario",
            "v": [
              "Alupay",
              "Bagong Pook",
              "Balibago",
              "Barangay A (Pob.)",
              "Barangay B (Pob.)",
              "Barangay C (Pob.)",
              "Barangay D (Pob.)",
              "Barangay E (Pob.)",
              "Baybayin",
              "Bulihan",
              "Cahigam",
              "Colongan",
              "Itlugan",
              "Lumbangan",
              "Maalas-as",
              "Mabato",
              "Mabunga",
              "Macalamcam A",
              "Macalamcam B",
              "Malaya",
              "Maligaya",
              "Marilag",
              "Masaya",
              "Mavalor",
              "Mayuro",
              "Namuco",
              "Namunga",
              "Natu",
              "Pinagsibaan",
              "Putingkahoy",
              "Quilib",
              "San Carlos",
              "San Ignacio",
              "San Jose",
              "San Roque",
              "Santa Cruz",
              "Timbugan",
              "Tiquiwan"
            ]
          },
          {
            "k": "San Jose",
            "v": [
              "Aguila",
              "Aya",
              "Banaybanay I",
              "Banaybanay Ii",
              "Calansayan",
              "Don Luis",
              "Galamay-amo",
              "Palanca",
              "Pinagtung-ulan",
              "Poblacion Barangay I",
              "Poblacion Barangay Ii",
              "Poblacion Barangay Iii",
              "Poblacion Barangay Iv",
              "Santo Cristo",
              "Taysan"
            ]
          },
          {
            "k": "San Juan",
            "v": [
              "Abung",
              "Bataan",
              "Buhay Na Sapa",
              "Calicanto",
              "Calitcalit",
              "Calubcub I",
              "Janaojanao",
              "Laiya-aplaya",
              "Laiya-ibabao",
              "Libato",
              "Mabalanoy",
              "Maraykit",
              "Muzon",
              "Nagsaulay",
              "Palahanan I",
              "Palahanan Ii",
              "Palingowak",
              "Pinagbayanan",
              "Poblacion",
              "Putingbuhangin",
              "Sampiro",
              "Sico I",
              "Sico Ii",
              "Subukin",
              "Talahiban I",
              "Talahiban Ii",
              "Ticalan",
              "Tipaz"
            ]
          },
          {
            "k": "San Luis",
            "v": [
              "Bagong Tubig",
              "Calumpang East",
              "Calumpang West",
              "Dulangan",
              "Manggahan",
              "Muzon",
              "Poblacion",
              "San Antonio",
              "San Jose",
              "San Martin",
              "Santa Monica",
              "Taliba",
              "Talon",
              "Tejero",
              "Tungal"
            ]
          },
          {
            "k": "San Nicolas",
            "v": [
              "Abelo",
              "Balete",
              "Baluk-baluk",
              "Bancoro",
              "Bangin",
              "Calangay",
              "Hipit",
              "Maabud North",
              "Maabud South",
              "Munlawin",
              "Pansipit",
              "Poblacion",
              "Santo NiÃ±o",
              "Sinturisan",
              "Tagudtod"
            ]
          },
          {
            "k": "San Pascual",
            "v": [
              "Alalum",
              "Antipolo",
              "Balimbing",
              "Banaba",
              "Bayanan",
              "Danglayan",
              "Del Pilar",
              "Gelerang Kawayan",
              "Ilat North",
              "Ilat South",
              "Kaingin",
              "Laurel",
              "Malaking Pook",
              "Mataas Na Lupa",
              "Natunuan North",
              "Natunuan South",
              "Padre Castillo",
              "Palsahingin",
              "Pila",
              "Poblacion",
              "Pook Ni Banal",
              "Sambat",
              "San Antonio",
              "San Mateo",
              "Santo NiÃ±o"
            ]
          },
          {
            "k": "Santa Teresita",
            "v": [
              "Antipolo",
              "Bihis",
              "Calumala",
              "Pacifico",
              "Poblacion I",
              "Poblacion Ii",
              "Poblacion Iii",
              "Sampa",
              "Sinipian"
            ]
          },
          {
            "k": "Santo Tomas",
            "v": [
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "San Agustin",
              "San Antonio",
              "San Bartolome",
              "San Felix",
              "San Fernando",
              "San Francisco",
              "San Isidro Norte",
              "San Isidro Sur",
              "San Joaquin",
              "San Jose",
              "San Juan",
              "San Luis",
              "San Miguel",
              "San Pablo",
              "San Pedro",
              "San Rafael",
              "San Roque",
              "San Vicente",
              "Santa Ana",
              "Santa Anastacia",
              "Santa Clara",
              "Santa Cruz",
              "Santa Elena",
              "Santa Maria",
              "Santa Teresita",
              "Santiago"
            ]
          },
          {
            "k": "Taal",
            "v": [
              "Apacay",
              "Balisong",
              "Bihis",
              "Bolbok",
              "Buli",
              "Butong",
              "Carasuche",
              "Cawit",
              "Caysasay",
              "Cubamba",
              "Cultihan",
              "Gahol",
              "Halang",
              "Iba",
              "Imamawo",
              "Ipil",
              "Laguile",
              "Latag",
              "Luntal",
              "Mahabang Lodlod",
              "Niogan",
              "Pansol",
              "Poblacion 1",
              "Poblacion 10",
              "Poblacion 11",
              "Poblacion 12",
              "Poblacion 13",
              "Poblacion 14",
              "Poblacion 2",
              "Poblacion 3",
              "Poblacion 4",
              "Poblacion 5",
              "Poblacion 6",
              "Poblacion 7",
              "Poblacion 8",
              "Poblacion 9",
              "Pook",
              "Seiran",
              "Tatlong Maria",
              "Tierra Alta",
              "Tulo"
            ]
          },
          {
            "k": "Talisay",
            "v": [
              "Aya",
              "Balas",
              "Banga",
              "Buco",
              "Caloocan",
              "Leynes",
              "Poblacion Barangay 1",
              "Poblacion Barangay 2",
              "Poblacion Barangay 3",
              "Poblacion Barangay 4",
              "Poblacion Barangay 5",
              "Poblacion Barangay 6",
              "Poblacion Barangay 7",
              "Poblacion Barangay 8",
              "Quiling",
              "Sampaloc",
              "San Guillermo",
              "Santa Maria",
              "Tranca",
              "Tumaway"
            ]
          },
          {
            "k": "Tanauan",
            "v": [
              "Ambulong",
              "Bagbag",
              "Bagumbayan",
              "Balele",
              "Banadero",
              "Banjo East",
              "Banjo Laurel (Banjo West)",
              "Bilog-bilog",
              "Boot",
              "Cale",
              "Darasa",
              "Gonzales",
              "Hidalgo",
              "Janopol",
              "Janopol Oriental",
              "Luyos",
              "Mabini",
              "Maria Paz",
              "Maugat",
              "MontaÃ±a (Ik-ik)",
              "Natatas",
              "Pagaspas",
              "Poblacion Barangay 1",
              "Poblacion Barangay 2",
              "Poblacion Barangay 3",
              "Poblacion Barangay 4",
              "Poblacion Barangay 5",
              "Poblacion Barangay 6",
              "Poblacion Barangay 7",
              "Sala",
              "Sambat",
              "San Jose",
              "Santor",
              "Sulpoc",
              "Talaga",
              "Tinurik",
              "Trapiche",
              "Wawa"
            ]
          },
          {
            "k": "Taysan",
            "v": [
              "Bacao",
              "Dagatan",
              "Laurel",
              "Mabayabas",
              "Mahanadiong",
              "Mataas Na Lupa",
              "PiÃ±a",
              "Poblacion East",
              "Poblacion West"
            ]
          },
          {
            "k": "Tuy",
            "v": [
              "Acle",
              "Bolboc",
              "Burgos (Pob.)",
              "Dao",
              "Guinhawa",
              "Lumbangan",
              "Luna (Pob.)",
              "Luntal",
              "Magahis",
              "Malibu",
              "Mataywanac-kabitols",
              "Putol",
              "Rillo (Pob.)",
              "Rizal (Pob.)",
              "Sabang",
              "Talon",
              "Tuyon-tuyon"
            ]
          }
        ],
        "names": [
          "Agoncillo",
          "Alitagtag",
          "Balayan",
          "Balete",
          "Batangas",
          "Bauan",
          "Calaca",
          "Calatagan",
          "Cuenca",
          "Ibaan",
          "Laurel",
          "Lemery",
          "Lian",
          "Lipa",
          "Lobo",
          "Mabini",
          "Malvar",
          "Mataas Na Kahoy",
          "Nasugbu",
          "Padre Garcia",
          "Rosario",
          "San Jose",
          "San Juan",
          "San Luis",
          "San Nicolas",
          "San Pascual",
          "Santa Teresita",
          "Santo Tomas",
          "Taal",
          "Talisay",
          "Tanauan",
          "Taysan",
          "Tuy"
        ]
      }
    },
    {
      "k": "Benguet",
      "v": {
        "lookup": [
          {
            "k": "Baguio City",
            "v": [
              "A. Bonifacio-caguioa-rimando (Abcr)",
              "Abanao-zandueta-kayong-chugum-otek (Azkco)",
              "Alfonso Tabora",
              "Andres Bonifacio (Lower Bokawkan)",
              "Aurora Hill Proper (Malvar-sgt. Floresca)",
              "Aurora Hill, North Central",
              "Aurora Hill, South Central",
              "Bagong Lipunan Market+d616",
              "Bakakeng Central",
              "Bakakeng North",
              "Bal-marcoville (Marcoville)",
              "Bal-marcoville(marcoville)",
              "Balsigan",
              "Bayan Park East",
              "Bayan Park Village",
              "Bayan Park West (Bayan Park)",
              "Bgh Compound",
              "Brookside",
              "Brookspoint",
              "Cabinet Hill-teacher's Camp",
              "Camdas Subdivision",
              "Camp 7",
              "Camp 8",
              "Camp Allen",
              "Campo Filipino",
              "City Camp Central",
              "City Camp Proper",
              "Country Club Village",
              "Cresencia Village",
              "Dagsian Lower",
              "Dagsian, Lower",
              "Dagsian, Upper",
              "Dizon Subdivision",
              "Dominican Hill-mirador",
              "Dontogan",
              "Dps Area",
              "Engineers' Hill",
              "Fairview Village",
              "Ferdinand (Happy Homes-campo Sioco)",
              "Fort Del Pilar",
              "Gabriela Silang",
              "General Emilio F. Aguinaldo (Quirino-magsaysay Lower)",
              "General Emilio F. Aguinaldo (Quirino-magsaysay, Lower)",
              "General Luna Lower",
              "General Luna, Lower",
              "General Luna, Upper",
              "Gibraltar",
              "Greenwater Village",
              "Guisad Central",
              "Guisad Sorong",
              "Happy Hollow",
              "Happy Homes (Happy Homes-lucban)",
              "Harrison-claudio Carantes",
              "Hillside",
              "Holy Ghost Extension",
              "Holy Ghost Proper",
              "Honeymoon (Honeymoon-holy Ghost)",
              "Imelda R. Marcos (La Salle)",
              "Imelda Village",
              "Irisan",
              "Kabayanihan",
              "Kagitingan",
              "Kayang Extension",
              "Kayang-hilltop",
              "Kias",
              "Legarda-burnham-kisad",
              "Liwanag-loakan",
              "Loakan Proper",
              "Lopez Jaena",
              "Lourdes Subdivision Extension",
              "Lourdes Subdivision Proper",
              "Lourdes Subdivision, Lower",
              "Lourdes Subdivision, Proper",
              "Lower Lourdes Subdivision",
              "Lower Magsaysay",
              "Lower Quirino Hill",
              "Lower Rock Quarry",
              "Lualhati",
              "Lucnab",
              "Magsaysay Private Road",
              "Magsaysay, Lower",
              "Magsaysay, Upper",
              "Malcolm Square-perfecto (Jose Abad Santos)",
              "Manuel A. Roxas",
              "Market Subdivision, Upper",
              "Middle Quezon Hill Subdivision (Quezon Hill Middle)",
              "Middle Quezon Hill Subdivision(quezon Hill Middle)",
              "Middle Quirino Hill",
              "Middle Rock Quarry",
              "Military Cut-off",
              "Mines View Park",
              "Modern Site, East",
              "Modern Site, West",
              "Mrr-queen Of Peace",
              "New Lucban",
              "North Sanitary Camp",
              "Outlook Drive",
              "Pacdal",
              "Padre Burgos",
              "Padre Zamora",
              "Palma-urbano (CariÃ±o-palma)",
              "Phil-am",
              "Phil. Military Academy",
              "Pinget",
              "Pinsao Pilot Project",
              "Pinsao Proper",
              "Poliwes",
              "Pucsusan",
              "Quezon Hill Proper",
              "Quezon Hill, Upper",
              "Quirino Hill, East",
              "Quirino Hill, Lower",
              "Quirino Hill, Middle",
              "Quirino Hill, West",
              "Quirino-magasaysay (Upper Qm)",
              "Quirino-magsaysay, Upper (Upper Qm)",
              "Rizal Monument Area",
              "Rock Quarry, Lower",
              "Rock Quarry, Middle",
              "Rock Quarry, Upper",
              "Saint Joseph Village",
              "Salud Mitra",
              "San Antonio Village",
              "San Luis Village",
              "San Roque Village",
              "San Vicente",
              "Sanitary Camp, North",
              "Sanitary Camp, South",
              "Santa Escolastica",
              "Santo Rosario",
              "Santo Tomas Proper",
              "Santo Tomas School Area",
              "Scout Barrio",
              "Session Road Area",
              "Slaughter House Area (Santo NiÃ±o Slaughter)",
              "Slaughter House Area (Santo NiÃ±o Slaughter)",
              "Slu-svp Housing Village",
              "South Drive",
              "South Sanitary Camp",
              "Teodora Alonzo",
              "Trancoville",
              "Upper Dagsian",
              "Upper General Luna",
              "Upper Magsaysay",
              "Upper Market Subdivision",
              "Upper Quezon Hill",
              "Upper Rock Quarry",
              "Victoria Village",
              "West Bayan Park (Bayan Park)",
              "West Modern Site",
              "West Quirino Hill"
            ]
          },
          {
            "k": "La Trinidad",
            "v": [
              "Alapang",
              "Alno",
              "Ambiong",
              "Bahong",
              "Balili",
              "Beckel",
              "Betag",
              "Bineng",
              "Cruz",
              "Lubas",
              "Pico",
              "Poblacion",
              "Puguis",
              "Shilan",
              "Tawang",
              "Wangal"
            ]
          },
          {
            "k": "Sablan",
            "v": [
              "Bagong",
              "Balluay",
              "Banangan",
              "Banengbeng",
              "Bayabas",
              "Kamog",
              "Pappa",
              "Poblacion"
            ]
          },
          {
            "k": "Tuba",
            "v": [
              "Ansagan",
              "Camp 3",
              "Camp 4",
              "Camp One",
              "Nangalisan",
              "Poblacion",
              "San Pascual",
              "Tabaan Norte",
              "Tabaan Sur",
              "Tadiangan",
              "Taloy Norte",
              "Taloy Sur",
              "Twin Peaks"
            ]
          },
          {
            "k": "Tublay",
            "v": [
              "Ambassador",
              "Ambongdolan",
              "Ba-ayan",
              "Basil",
              "Caponga (Pob.)",
              "Daclan",
              "Tublay Central",
              "Tuel"
            ]
          }
        ],
        "names": ["Baguio City", "La Trinidad", "Sablan", "Tuba", "Tublay"]
      }
    },
    {
      "k": "Biliran",
      "v": {
        "lookup": [
          {
            "k": "Almeria",
            "v": [
              "Caucab",
              "Jamorawon",
              "Lo Ok",
              "Matanga",
              "Pili",
              "Poblacion",
              "Pulang Bato",
              "Tabunan",
              "Talahid",
              "Tamarindo"
            ]
          },
          {
            "k": "Biliran",
            "v": [
              "Bato",
              "Burabod",
              "Busali",
              "Julita",
              "San Isidro Pob",
              "San Roque Pob",
              "Sanggalang",
              "Villa Enage Baras"
            ]
          },
          {
            "k": "Cabucgayan",
            "v": [
              "Balaquid",
              "Baso",
              "Bunga",
              "Casiawan",
              "Langgao",
              "Libertad",
              "Looc",
              "Magbangon Pob",
              "Pawikan",
              "Salawad",
              "Talibong"
            ]
          },
          {
            "k": "Caibiran",
            "v": [
              "Alegria",
              "Binohangan",
              "Cabibihan",
              "Looc",
              "Manlabang",
              "Maurang",
              "Tomalistis",
              "Union",
              "Uson"
            ]
          },
          {
            "k": "Culaba",
            "v": [
              "Acaban",
              "Bacolod",
              "Binongtoan",
              "Bool Central Pob",
              "Bool East Pob",
              "Bool West Pob",
              "Culaba Central Pob",
              "Guindapunan",
              "Habuhab",
              "Looc",
              "Marvel Pob",
              "Patag",
              "Pinamihagan",
              "Salvacion",
              "San Roque",
              "Virginia Pob"
            ]
          },
          {
            "k": "Kawayan",
            "v": [
              "Baganito",
              "Balacson",
              "Balite",
              "Bilwang",
              "Bulalacao",
              "Burabod",
              "Buyo",
              "Inasuyan",
              "Kansanok",
              "Mada O",
              "Mapuyo",
              "Masagaosao",
              "Masagongsong",
              "Poblacion",
              "San Lorenzo",
              "Tabunan North",
              "Tucdao",
              "Ungale",
              "Villa Cornejo Looc"
            ]
          },
          {
            "k": "Naval",
            "v": [
              "Agpangi",
              "Anislagan",
              "Atipolo",
              "Borac",
              "Cabungaan",
              "Calumpang",
              "Capinahan",
              "Caraycaray",
              "Catmon",
              "Imelda",
              "Larrazabal",
              "Lico",
              "Lucsoon",
              "Padre Inocentes Garcia Pob",
              "Padre Sergio Eamiguel",
              "Poblacion",
              "Sabang",
              "San Pablo",
              "Santissimo Rosario Pob Santo Rosa",
              "Santo Nino",
              "Talustusan",
              "Villa Consuelo"
            ]
          }
        ],
        "names": [
          "Almeria",
          "Biliran",
          "Cabucgayan",
          "Caibiran",
          "Culaba",
          "Kawayan",
          "Naval"
        ]
      }
    },
    {
      "k": "Bohol",
      "v": {
        "lookup": [
          {
            "k": "Alburquerque",
            "v": [
              "Bahi",
              "East Poblacion",
              "San Agustin",
              "Toril",
              "West Poblacion"
            ]
          },
          {
            "k": "Alicia",
            "v": [
              "Katipunan",
              "La Hacienda",
              "Napo",
              "Poblacion Calingganay",
              "Progreso"
            ]
          },
          {
            "k": "Antequera",
            "v": [
              "Bungahan",
              "Can Omay",
              "Cansibuan",
              "Celing",
              "Danao",
              "Danicop",
              "Mag Aso",
              "Poblacion",
              "Tagubaas",
              "Tupas",
              "Ubojan",
              "Villa Aurora Canoc Oc"
            ]
          },
          {
            "k": "Baclayon",
            "v": [
              "Guiwanon",
              "Landican",
              "Laya",
              "Libertad",
              "Montana",
              "Poblacion",
              "San Isidro",
              "San Roque",
              "San Vicente",
              "Santa Cruz",
              "Taguihon"
            ]
          },
          {
            "k": "Balilihan",
            "v": [
              "Baucan Norte",
              "Baucan Sur",
              "Boctol",
              "Cabad",
              "Candasig",
              "Cantalid",
              "Cantomimbo",
              "Datag Norte",
              "Datag Sur",
              "Del Carmen Este Pob",
              "Del Carmen Norte Pob",
              "Del Carmen Sur Pob",
              "Del Carmen Weste Pob",
              "Magsija",
              "Maslog",
              "Sagasa",
              "Sal Ing",
              "San Isidro",
              "San Roque"
            ]
          },
          {
            "k": "Batuan",
            "v": [
              "Cantigdas",
              "Poblacion Norte",
              "Poblacion Sur",
              "Poblacion Vieja Longsudaan"
            ]
          },
          { "k": "Bien Unido", "v": ["Tuboran"] },
          {
            "k": "Bilar",
            "v": [
              "Bugang Sur",
              "Cabacnitan Magsaysay",
              "Poblacion",
              "Quezon",
              "Riverside",
              "Subayon",
              "Villa Aurora",
              "Villa Suerte",
              "Yanaya",
              "Zamora"
            ]
          },
          {
            "k": "Buenavista",
            "v": [
              "Bato",
              "Bonotbonot",
              "Bugaong",
              "Cambuhat",
              "Cambus Oc",
              "Cangawa",
              "Cruz",
              "Dait",
              "Lapacan Norte",
              "Lapacan Sur",
              "Sweetland"
            ]
          },
          {
            "k": "Calape",
            "v": [
              "Abucayan Norte",
              "Abucayan Sur",
              "Bentig",
              "Binogawan",
              "Bonbon",
              "Calunasan",
              "Catmonan",
              "Desamparados Pob",
              "Labuon",
              "Liboron",
              "Mandaug",
              "Sampoangon",
              "San Isidro",
              "Santa Cruz Pob",
              "Tultugan",
              "Ulbujan"
            ]
          },
          {
            "k": "Candijay",
            "v": [
              "Boyo An",
              "Cambane",
              "La Union",
              "Luan",
              "Lungsoda An",
              "Pagahat",
              "Poblacion",
              "Tambongan"
            ]
          },
          {
            "k": "Carmen",
            "v": ["Alegria", "Bicao", "Buenavista", "Buenos Aires"]
          },
          {
            "k": "Catigbian",
            "v": [
              "Alegria",
              "Baang",
              "Haguilanan",
              "Poblacion",
              "Poblacion Weste",
              "Sinakayanan",
              "Triple Union"
            ]
          },
          {
            "k": "Clarin",
            "v": [
              "Bacani",
              "Bogtongbod",
              "Bonbon",
              "Buacao",
              "Caboy",
              "Mataub",
              "Nahawan",
              "Poblacion Centro",
              "Poblacion Norte",
              "Poblacion Sur",
              "Tangaran",
              "Tontunan"
            ]
          },
          {
            "k": "Corella",
            "v": [
              "Anislag",
              "Canangca An",
              "Canapnapan",
              "Cancatac",
              "Pandol",
              "Poblacion",
              "Sambog",
              "Tanday"
            ]
          },
          {
            "k": "Cortes",
            "v": [
              "De La Paz",
              "Loreto",
              "Lourdes",
              "Malayo Norte",
              "Malayo Sur",
              "Poblacion",
              "Rosario",
              "Salvador",
              "San Roque",
              "Upper De La Paz"
            ]
          },
          { "k": "Dagohoy", "v": ["Poblacion"] },
          {
            "k": "Dauis",
            "v": [
              "Biking",
              "Bingag",
              "Catarman",
              "Dao",
              "Mariveles",
              "Mayacabac",
              "Poblacion",
              "San Isidro Canlongon",
              "Songculan",
              "Tabalong",
              "Tinago",
              "Totolan"
            ]
          },
          {
            "k": "Dimiao",
            "v": [
              "Abihid",
              "Balbalan",
              "Banban",
              "Luyo",
              "Malijao",
              "Sawang",
              "Tangohay",
              "Taongon Cabatuan",
              "Taongon Can Andam",
              "Tawid Bitaog"
            ]
          },
          {
            "k": "Duero",
            "v": [
              "Guinsularan",
              "Itum",
              "Langkis",
              "Lobogon",
              "Madua Norte",
              "Madua Sur",
              "Mambool",
              "Mawi",
              "San Antonio Pob",
              "San Isidro",
              "San Pedro"
            ]
          },
          {
            "k": "Garcia Hernandez",
            "v": [
              "Calma",
              "Canayaon East",
              "Canayaon West",
              "Libertad",
              "Lungsodaan East",
              "Lungsodaan West",
              "Malinao",
              "Manaba",
              "Poblacion East",
              "Poblacion West",
              "Ulbujan East",
              "Ulbujan West",
              "Victoria"
            ]
          },
          {
            "k": "Guindulman",
            "v": [
              "Basdio",
              "Bulawan",
              "Cabantian",
              "Canhaway",
              "Guinacot",
              "Guio Ang",
              "Sawang Pob",
              "Tabajan Pob",
              "Tabunok",
              "Trinidad"
            ]
          },
          {
            "k": "Inabanga",
            "v": [
              "Badiang",
              "Cagawasan",
              "Cagayan",
              "Cawayan",
              "Cogon",
              "Dagohoy",
              "Dait Sur",
              "Fatima",
              "Ilaud Pob",
              "Ilaya",
              "Ilihan",
              "Lapacan Norte",
              "Lapacan Sur",
              "Lawis",
              "Liloan Norte",
              "Liloan Sur",
              "Lutao",
              "Luyo",
              "Mabuhay",
              "Nabuad",
              "Ondol",
              "Poblacion",
              "Saa",
              "San Isidro",
              "Santo Rosario",
              "Tambook",
              "Tungod",
              "U Og",
              "Ubujan"
            ]
          },
          {
            "k": "Jagna",
            "v": [
              "Alejawan",
              "Bunga Ilaya",
              "Bunga Mar",
              "Can Uba",
              "Can Upao",
              "Canjulao",
              "Cantagay",
              "Faraon",
              "Ipil",
              "Larapan",
              "Looc",
              "Naatang",
              "Nausok",
              "Pagina",
              "Pangdan",
              "Poblacion Pondol",
              "Tejero",
              "Tubod Mar",
              "Tubod Monte"
            ]
          },
          {
            "k": "Jetafe",
            "v": [
              "Campao Occidental",
              "Campao Oriental",
              "Carlos P Garcia",
              "Corte Baud",
              "Poblacion",
              "Saguise",
              "Salog",
              "San Jose",
              "Santo Nino",
              "Taytay",
              "Tugas",
              "Tulang"
            ]
          },
          {
            "k": "Lila",
            "v": [
              "Banban",
              "La Fortuna",
              "Malinao East",
              "Nagsulay",
              "Poblacion",
              "Taug",
              "Tiguis"
            ]
          },
          {
            "k": "Loay",
            "v": [
              "Agape",
              "Alegria Sur",
              "Bonbon",
              "Botoc Occidental",
              "Botoc Oriental",
              "Calvario",
              "Concepcion",
              "Hinawanan",
              "Las Salinas Sur",
              "Palo",
              "Poblacion Ibabao",
              "Poblacion Ubos",
              "Sagnap",
              "Tambangan",
              "Tayong Oriental",
              "Villalimpia",
              "Yanangan"
            ]
          },
          {
            "k": "Loboc",
            "v": [
              "Agape",
              "Alegria",
              "Gotozon",
              "Poblacion Ondol",
              "Poblacion Sawang",
              "Valladolid",
              "Villaflor"
            ]
          },
          {
            "k": "Loon",
            "v": [
              "Basac",
              "Basdacu",
              "Calayugan Norte",
              "Calayugan Sur",
              "Canhangdon Occidental",
              "Canhangdon Oriental",
              "Catagbacan Handig",
              "Catagbacan Norte",
              "Catagbacan Sur",
              "Cogon Norte Pob",
              "Cogon Sur",
              "Cuasi",
              "Moto Norte Pob",
              "Moto Sur Pob",
              "Pig Ot",
              "Pondol",
              "Song On",
              "Tangnan",
              "Tontonan",
              "Ubayon"
            ]
          },
          {
            "k": "Maribojoc",
            "v": [
              "Aliguay",
              "Anislag",
              "Bayacabac",
              "Bood",
              "Dipatlong",
              "Guiwanon",
              "Jandig",
              "Lagtangon",
              "Lincod",
              "Poblacion",
              "Punsod",
              "Punta Cruz",
              "San Roque Aghao",
              "San Vicente"
            ]
          },
          {
            "k": "Panglao",
            "v": [
              "Bil Isan",
              "Bolod",
              "Danao",
              "Doljo",
              "Libaong",
              "Looc",
              "Lourdes",
              "Poblacion",
              "Tangnan",
              "Tawala"
            ]
          },
          {
            "k": "Sagbayan Borja",
            "v": [
              "Canmano",
              "Canmaya Centro",
              "Canmaya Diot",
              "Dagnawan",
              "Libertad Norte",
              "Libertad Sur",
              "Mantalongon",
              "Poblacion",
              "Sagbayan Sur",
              "San Agustin",
              "San Vicente Norte",
              "San Vicente Sur",
              "Santa Cruz",
              "Ubojan"
            ]
          },
          { "k": "San Miguel", "v": ["Poblacion"] },
          { "k": "Sevilla", "v": ["Ewon", "Poblacion"] },
          {
            "k": "Sikatuna",
            "v": [
              "Abucay Norte",
              "Abucay Sur",
              "Badiang",
              "Bahaybahay",
              "Cambuac Norte",
              "Cambuac Sur",
              "Canagong",
              "Libjo",
              "Poblacion I",
              "Poblacion Ii"
            ]
          },
          {
            "k": "Tagbilaran City",
            "v": [
              "Bool",
              "Booy",
              "Cabawan",
              "Cogon",
              "Dampas",
              "Dao",
              "Manga",
              "Mansasa",
              "Poblacion I",
              "Poblacion Ii",
              "Poblacion Iii",
              "San Isidro",
              "Taloto",
              "Tiptip",
              "Ubujan"
            ]
          },
          {
            "k": "Talibon",
            "v": [
              "Bagacay",
              "Balintawak",
              "Burgos",
              "Poblacion",
              "San Agustin",
              "San Francisco",
              "San Isidro",
              "San Jose",
              "San Pedro",
              "San Roque",
              "Santo Nino",
              "Tanghaligue"
            ]
          },
          { "k": "Trinidad", "v": ["Poblacion"] },
          {
            "k": "Tubigon",
            "v": [
              "Cabulihan",
              "Cahayag",
              "Centro Pob",
              "Guiwanon",
              "Ilihan Norte",
              "Macaas",
              "Panaytayon",
              "Pandan",
              "Pinayagan Norte",
              "Pinayagan Sur",
              "Pooc Occidental Pob",
              "Pooc Oriental Pob",
              "Potohan",
              "Tinangnan"
            ]
          },
          { "k": "Ubay", "v": ["Fatima", "Poblacion", "Tapon"] },
          {
            "k": "Valencia",
            "v": [
              "Adlawan",
              "Anas",
              "Canduao Occidental",
              "Canduao Oriental",
              "Canlusong",
              "Canmanico",
              "Cansibao",
              "Catug A",
              "Cutcutan",
              "Magsaysay",
              "Marawis",
              "Omjon",
              "Poblacion Occidental",
              "Poblacion Oriental",
              "Simang",
              "Taug",
              "Tausion"
            ]
          }
        ],
        "names": [
          "Alburquerque",
          "Alicia",
          "Antequera",
          "Baclayon",
          "Balilihan",
          "Batuan",
          "Bien Unido",
          "Bilar",
          "Buenavista",
          "Calape",
          "Candijay",
          "Carmen",
          "Catigbian",
          "Clarin",
          "Corella",
          "Cortes",
          "Dagohoy",
          "Dauis",
          "Dimiao",
          "Duero",
          "Garcia Hernandez",
          "Guindulman",
          "Inabanga",
          "Jagna",
          "Jetafe",
          "Lila",
          "Loay",
          "Loboc",
          "Loon",
          "Maribojoc",
          "Panglao",
          "Sagbayan Borja",
          "San Miguel",
          "Sevilla",
          "Sikatuna",
          "Tagbilaran City",
          "Talibon",
          "Trinidad",
          "Tubigon",
          "Ubay",
          "Valencia"
        ]
      }
    },
    {
      "k": "Bukidnon",
      "v": {
        "lookup": [
          {
            "k": "Baungon",
            "v": [
              "Buenavista",
              "Imbatug Pob",
              "Lacolac",
              "Liboran",
              "Lingating",
              "Maasin",
              "Mabuhay",
              "Nicdao",
              "Philagro",
              "San Miguel",
              "San Vicente"
            ]
          },
          {
            "k": "City Of Malaybalay",
            "v": [
              "Aglayan",
              "Apo Macote",
              "Bangcud",
              "Barangay 1 Pob",
              "Barangay 10 Pob",
              "Barangay 11 Pob",
              "Barangay 2 Pob",
              "Barangay 3 Pob",
              "Barangay 4 Pob",
              "Barangay 5 Pob",
              "Barangay 6 Pob",
              "Barangay 7 Pob",
              "Barangay 8 Pob",
              "Barangay 9 Pob",
              "Cabangahan",
              "Casisang",
              "City Proper",
              "Dalwangan",
              "Kalasungay",
              "Laguitas",
              "Linabo",
              "Managok",
              "Patpat Lapu Lapu",
              "San Jose",
              "San Martin",
              "Santo Nino",
              "Simaya",
              "Sumpong",
              "Violeta"
            ]
          },
          {
            "k": "Damulog",
            "v": ["Old Damulog", "Poblacion New Damulog", "Sampagar"]
          },
          { "k": "Dangcagan", "v": ["Barongcot", "Poblacion"] },
          {
            "k": "Don Carlos",
            "v": [
              "Don Carlos Norte",
              "Don Carlos Sur Pob",
              "Lake Pinamaloy",
              "Pinamaloy",
              "Poblacion"
            ]
          },
          {
            "k": "Impasug Ong",
            "v": [
              "Impalutao",
              "Kaanib",
              "La Fortuna",
              "Poblacion",
              "San Antonio",
              "Santa Ana"
            ]
          },
          {
            "k": "Kalilangan",
            "v": ["Bangbang", "Kinura", "Poblacion", "West Poblacion"]
          },
          {
            "k": "Kibawe",
            "v": [
              "Labuagon",
              "Natulongan",
              "Palma",
              "Poblacion",
              "Spring",
              "Talahiron",
              "West Kibawe Pob"
            ]
          },
          {
            "k": "Kitaotao",
            "v": [
              "Bagong Silang",
              "Buda",
              "Magsaysay",
              "Poblacion",
              "Sinuda Simod",
              "Tawas"
            ]
          },
          {
            "k": "Lantapan",
            "v": ["Alanib", "Balila", "Bantuanon", "Poblacion"]
          },
          { "k": "Libona", "v": ["Poblacion", "San Jose"] },
          {
            "k": "Malaybalay City",
            "v": [
              "Aglayan",
              "Apo Macote",
              "Bangcud",
              "Barangay 1 Poblacion",
              "Barangay 10 Impalambong",
              "Barangay 11 Impalambong",
              "Barangay 2 Poblacion",
              "Barangay 3 Poblacion",
              "Barangay 4 Poblacion",
              "Barangay 5 Poblacion",
              "Barangay 6 Poblacion",
              "Barangay 7 Poblacion",
              "Barangay 8 Poblacion",
              "Barangay 9 Poblacion",
              "Cabangahan",
              "Casisang",
              "Cmp Asito Bahian Impalam",
              "Dalwangan",
              "Kalasungay",
              "Laguitas",
              "Linabo",
              "Managok",
              "Nasuli",
              "Patpat",
              "San Jose",
              "San Martin",
              "Santo Nino",
              "Simaya",
              "Sumpong",
              "Violeta"
            ]
          },
          {
            "k": "Manolo Fortich",
            "v": [
              "Agusan Canyon",
              "Alae",
              "Damilag",
              "Diclum",
              "Lingion",
              "Lunocan",
              "Maluko",
              "Mambatangan",
              "Mantibugao",
              "Miranda",
              "Poblacion",
              "San Miguel",
              "Tankulan Pob"
            ]
          },
          {
            "k": "Maramag",
            "v": [
              "Anahawan",
              "Base Camp",
              "Dologon",
              "Kuya",
              "Panadtalan",
              "San Miguel",
              "South Poblacion",
              "Tubigon"
            ]
          },
          {
            "k": "Pangantucan",
            "v": ["Adtuyon", "Barandias", "Kinura", "Lantay", "Poblacion"]
          },
          {
            "k": "Quezon",
            "v": [
              "Busco",
              "Butong",
              "Cebole",
              "Kiburiao",
              "Libertad",
              "Merangerang",
              "Mibantang",
              "Palacapao",
              "Poblacion Kiokong",
              "Puntian",
              "Salawagan",
              "San Jose",
              "Santa Cruz"
            ]
          },
          { "k": "Sumilao", "v": ["Kisolon", "San Vicente"] },
          {
            "k": "Talakag",
            "v": [
              "Barangay 1 Pob",
              "Barangay 2 Pob",
              "Barangay 3 Pob",
              "Barangay 4 Pob",
              "Barangay 5 Pob",
              "Poblacion",
              "San Isidro",
              "Santo Nino Lumbayawa"
            ]
          },
          {
            "k": "Valencia City",
            "v": [
              "Bagontaas",
              "Batangan",
              "Colonia",
              "Dabong-dabong",
              "Hagkol",
              "Lilingayon",
              "Lumbo",
              "Lurogan",
              "Mailag",
              "Pinatilan",
              "Poblacion",
              "San Carlos",
              "Sugod"
            ]
          }
        ],
        "names": [
          "Baungon",
          "City Of Malaybalay",
          "Damulog",
          "Dangcagan",
          "Don Carlos",
          "Impasug Ong",
          "Kalilangan",
          "Kibawe",
          "Kitaotao",
          "Lantapan",
          "Libona",
          "Malaybalay City",
          "Manolo Fortich",
          "Maramag",
          "Pangantucan",
          "Quezon",
          "Sumilao",
          "Talakag",
          "Valencia City"
        ]
      }
    },
    {
      "k": "Bulacan",
      "v": {
        "lookup": [
          {
            "k": "Angat",
            "v": [
              "Banaban",
              "Baybay",
              "Binagbag",
              "Donacion",
              "Encanto",
              "Marungko",
              "Niugan",
              "Paltok",
              "Pulong Yantok",
              "San Roque (Pob.)",
              "Santa Cruz (Pob.)",
              "Santa Lucia",
              "Santo Cristo (Pob.)",
              "Sulucan",
              "Taboc"
            ]
          },
          {
            "k": "Balagtas (Bigaa)",
            "v": [
              "Borol 1st",
              "Borol 2nd",
              "Dalig",
              "Longos",
              "Panginay",
              "Pulong Gubat",
              "San Juan",
              "Santol",
              "Wawa (Pob.)"
            ]
          },
          {
            "k": "Baliuag",
            "v": [
              "Bagong Nayon",
              "Barangca",
              "Calantipay",
              "Catulinan",
              "Concepcion",
              "Hinukay",
              "Makinabang",
              "Matangtubig",
              "Pagala",
              "Paitan",
              "Piel",
              "Pinagbarilan",
              "Poblacion",
              "Sabang",
              "San Jose",
              "San Roque",
              "Santa Barbara",
              "Santo Cristo",
              "Santo NiÃ±o",
              "Subic",
              "Sulivan",
              "Tangos",
              "Tarcan",
              "Tiaong",
              "Tibag",
              "Tilapayong",
              "Virgen Delas Flores"
            ]
          },
          {
            "k": "Bocaue",
            "v": [
              "Antipona",
              "Bagumbayan",
              "Bambang",
              "Batia",
              "BiÃ±ang 1st",
              "BiÃ±ang 2nd",
              "Bolacan",
              "Bundukan",
              "Bunlo",
              "Caingin",
              "Duhat",
              "Igulot",
              "Lolomboy",
              "Poblacion",
              "Sulucan",
              "Taal",
              "Tambobong",
              "Turo",
              "Wakas"
            ]
          },
          {
            "k": "Bulacan",
            "v": [
              "Bagumbayan",
              "Balubad",
              "Bambang",
              "Matungao",
              "Maysantol",
              "Perez",
              "Pitpitan",
              "San Francisco",
              "San Jose (Pob.)",
              "San Nicolas",
              "Santa Ana",
              "Santa Ines",
              "Taliptip",
              "Tibig"
            ]
          },
          {
            "k": "Bustos",
            "v": [
              "Bonga Mayor",
              "Bonga Menor",
              "Buisan",
              "Camachilihan",
              "Cambaog",
              "Catacte",
              "Liciada",
              "Malamig",
              "Malawak",
              "Poblacion",
              "San Pedro",
              "Talampas",
              "Tanawan",
              "Tibagan"
            ]
          },
          {
            "k": "Calumpit",
            "v": [
              "Balite",
              "Balungao",
              "Buguion",
              "Bulusan",
              "Calizon",
              "Calumpang",
              "Caniogan",
              "Corazon",
              "Frances",
              "Gatbuca",
              "Gugo",
              "Iba Este",
              "Iba O'este",
              "Longos",
              "Meysulao",
              "Meyto",
              "Palimbang",
              "Panducot",
              "Pio Cruzcosa",
              "Poblacion",
              "Pungo",
              "San Jose",
              "San Marcos",
              "San Miguel",
              "Santa Lucia",
              "Santo NiÃ±o",
              "Sapang Bayan",
              "Sergio Bayan",
              "Sucol"
            ]
          },
          {
            "k": "Guiguinto",
            "v": [
              "Cutcut",
              "Daungan",
              "Ilang-ilang",
              "Malis",
              "Panginay",
              "Poblacion",
              "Pritil",
              "Pulong Gubat",
              "Santa Cruz",
              "Santa Rita",
              "Tabang",
              "Tabe",
              "Tiaong",
              "Tuktukan"
            ]
          },
          {
            "k": "Hagonoy",
            "v": [
              "Abulalas",
              "Carillo",
              "Iba",
              "Iba-ibayo",
              "Mercado",
              "Palapat",
              "Sagrada Familia",
              "San Agustin",
              "San Isidro",
              "San Jose",
              "San Juan",
              "San Miguel",
              "San Nicolas",
              "San Pablo",
              "San Pascual",
              "San Pedro",
              "San Roque",
              "San Sebastian",
              "Santa Cruz",
              "Santa Elena",
              "Santa Monica",
              "Santo NiÃ±o (Pob.)",
              "Santo Rosario",
              "Tampok"
            ]
          },
          {
            "k": "Malolos City",
            "v": [
              "Anilao",
              "Atlag",
              "Bagna",
              "Bagong Bayan",
              "Balayong",
              "Balite",
              "Bangkal",
              "Barihan",
              "Bulihan",
              "Bungahan",
              "Caingin",
              "Calero",
              "Canalate",
              "Caniogan",
              "Catmon",
              "Cofradia",
              "Dakila",
              "Guinhawa",
              "Ligas",
              "Liyang",
              "Longos",
              "Look 1st",
              "Look 2nd",
              "Lugam",
              "Mabolo",
              "Mambog",
              "Matimbo",
              "Mojon",
              "Niugan",
              "Panasahan",
              "Pinagbakahan",
              "San Agustin",
              "San Gabriel",
              "San Juan",
              "San Pablo",
              "San Vicente (Pob.)",
              "Santiago",
              "Santisima Trinidad",
              "Santo Cristo",
              "Santo NiÃ±o (Pob.)",
              "Santo Rosario (Pob.)",
              "Santol",
              "Sumapang Bata",
              "Sumapang Matanda",
              "Taal",
              "Tikay"
            ]
          },
          {
            "k": "Marilao",
            "v": [
              "Abangan Norte",
              "Abangan Sur",
              "Ibayo",
              "Lambakin",
              "Lias",
              "Loma De Gato",
              "Nagbalon",
              "Patubig",
              "Poblacion I",
              "Poblacion Ii",
              "Prenza I",
              "Prenza Ii",
              "Santa Rosa I",
              "Santa Rosa Ii",
              "Saog",
              "Tabing Ilog"
            ]
          },
          {
            "k": "Meycauayan City",
            "v": [
              "Bagbaguin",
              "Bahay Pare",
              "Bancal",
              "Banga",
              "Bayugo",
              "Caingin",
              "Calvario",
              "Camalig",
              "Gasak (Saint Francis)",
              "Hulo",
              "Iba",
              "Langka",
              "Lawa",
              "Libtong",
              "Longos",
              "Malhacan",
              "Pajo",
              "Pandayan",
              "Pantoc",
              "Perez",
              "Poblacion",
              "Saluysoy",
              "Tugatog",
              "Zamora"
            ]
          },
          {
            "k": "Norzagaray",
            "v": [
              "Bangkal",
              "Baraka",
              "Bigte",
              "Bitungol",
              "Friendship Village Resources",
              "Matictic",
              "Minuyan",
              "Partida",
              "Pinagtulayan",
              "Poblacion",
              "Tigbe"
            ]
          },
          {
            "k": "Obando",
            "v": [
              "Catanghalan",
              "Hulo",
              "Lawa",
              "Paco",
              "Pag-asa (Pob.)",
              "Paliwas",
              "Panghulo",
              "Salambao",
              "San Pascual",
              "Tawiran"
            ]
          },
          {
            "k": "Pandi",
            "v": [
              "Bagbaguin",
              "Bagong Barrio",
              "Baka-bakahan",
              "Bunsuran I",
              "Bunsuran Ii",
              "Bunsuran Iii",
              "Cacarong Bata",
              "Cacarong Matanda",
              "Cupang",
              "Malibong Bata",
              "Malibong Matanda",
              "Manatal",
              "Mapulang Lupa",
              "Masagana",
              "Masuso",
              "Pinagkuartelan",
              "Poblacion",
              "Real De Cacarong",
              "San Roque",
              "Santo NiÃ±o",
              "Siling Bata",
              "Siling Matanda"
            ]
          },
          {
            "k": "Paombong",
            "v": [
              "Kapitangan",
              "Malumot",
              "Pinalagdan",
              "Poblacion",
              "San Isidro I",
              "San Isidro Ii",
              "San Jose",
              "San Roque",
              "San Vicente",
              "Santo NiÃ±o",
              "Santo Rosario"
            ]
          },
          {
            "k": "Plaridel",
            "v": [
              "Agnaya",
              "Bagong Silang",
              "Banga I",
              "Banga Ii",
              "Bintog",
              "Bulihan",
              "Culianin",
              "Dampol",
              "Lagundi",
              "Lalangan",
              "Lumang Bayan",
              "Parulan",
              "Poblacion",
              "Rueda",
              "San Jose",
              "Santa Ines",
              "Santo NiÃ±o",
              "Sipat",
              "Tabang"
            ]
          },
          {
            "k": "Pulilan",
            "v": [
              "Balatong A",
              "Balatong B",
              "Cutcot",
              "Dampol I",
              "Dampol Ii-a",
              "Dampol Ii-b",
              "Dulong Malabon",
              "Inaon",
              "Longos",
              "Lumbac",
              "Paltao",
              "Penabatan",
              "Poblacion",
              "Santa Peregrina",
              "Santo Cristo",
              "Taal",
              "Tabon",
              "Tibag",
              "Tinejero"
            ]
          },
          {
            "k": "San Ildefonso",
            "v": [
              "Akle",
              "Anyatam",
              "Bagong Barrio",
              "Basuit",
              "Bubulong Malaki",
              "Bubulong Munti",
              "Bulusukan",
              "Calasag",
              "Calawitan",
              "Gabihan",
              "Garlang",
              "Lapnit",
              "Maasim",
              "Makapilapil",
              "Malipampang",
              "Mataas Na Parang",
              "Matimbubong",
              "Nabaong Garlang",
              "Palapala",
              "Pinaod",
              "Poblacion",
              "Pulong Tamo",
              "San Juan",
              "Santa Catalina Bata",
              "Santa Catalina Matanda",
              "Sapang Putik",
              "Sapang Putol",
              "Sumandig",
              "Telepatio",
              "Umpucan",
              "Upig"
            ]
          },
          {
            "k": "San Jose Del Monte City",
            "v": [
              "Assumption",
              "Bagong Buhay",
              "Bagong Buhay Ii",
              "Bagong Buhay Iii",
              "Citrus",
              "Ciudad Real",
              "Dulong Bayan",
              "Fatima",
              "Fatima Ii",
              "Fatima Iii",
              "Fatima Iv",
              "Fatima V",
              "Francisco Homes-guijo",
              "Francisco Homes-mulawin",
              "Francisco Homes-narra",
              "Francisco Homes-yakal",
              "Gaya-gaya",
              "Graceville",
              "Gumaoc Central",
              "Gumaoc East",
              "Gumaoc West",
              "Kaybanban",
              "Kaypian",
              "Lawang Pari",
              "Maharlika",
              "Minuyan",
              "Minuyan Ii",
              "Minuyan Iii",
              "Minuyan Iv",
              "Minuyan Proper",
              "Minuyan V",
              "Muzon",
              "Paradise Iii",
              "Poblacion",
              "Poblacion I",
              "San Isidro",
              "San Manuel",
              "San Martin",
              "San Martin Ii",
              "San Martin Iii",
              "San Martin Iv",
              "San Pedro",
              "San Rafael",
              "San Rafael I",
              "San Rafael Iii",
              "San Rafael Iv",
              "San Rafael V",
              "San Roque",
              "Santa Cruz",
              "Santa Cruz Ii",
              "Santa Cruz Iii",
              "Santa Cruz Iv",
              "Santa Cruz V",
              "Santo Cristo",
              "Santo NiÃ±o",
              "Santo NiÃ±o Ii",
              "Sapang Palay",
              "St. Martin De Porres",
              "Tungkong Mangga"
            ]
          },
          {
            "k": "San Miguel",
            "v": [
              "Bagong Pag-asa",
              "Bagong Silang",
              "Balite",
              "Bantog",
              "Bardias",
              "Baritan",
              "Batasan Bata",
              "Batasan Matanda",
              "Biclat",
              "Buga",
              "Buliran",
              "Bulualto",
              "Calumpang",
              "Cambio",
              "Camias",
              "Ilog-bulo",
              "King Kabayo",
              "Labne",
              "Lambakin",
              "Magmarale",
              "Mandile",
              "Pacalag",
              "Paliwasan",
              "Partida",
              "Pinambaran",
              "Poblacion",
              "Pulong Bayabas",
              "Pulong Duhat",
              "Sacdalan",
              "Salacot",
              "Salangan",
              "San Agustin",
              "San Jose",
              "San Juan",
              "San Vicente",
              "Santa Ines",
              "Santa Lucia",
              "Santa Rita Bata",
              "Santa Rita Matanda",
              "Sapang",
              "Sibul",
              "Tartaro",
              "Tibagan",
              "Tigpalas"
            ]
          },
          {
            "k": "San Rafael",
            "v": [
              "Banca-banca",
              "Bma-balagtas",
              "Caingin",
              "Capihan",
              "Coral Na Bato",
              "Cruz Na Daan",
              "Dagat-dagatan",
              "Diliman I",
              "Diliman Ii",
              "Libis",
              "Lico",
              "Maasim",
              "Mabalas-balas",
              "Maguinao",
              "Maronguillo",
              "Paco",
              "Pansumaloc",
              "Pantubig",
              "Pasong Intsik",
              "Pinacpinacan",
              "Poblacion",
              "Pulo",
              "Pulong Bayabas",
              "Salapungan",
              "Sampaloc",
              "San Agustin",
              "San Roque",
              "Talacsan",
              "Tambubong",
              "Ulingao"
            ]
          },
          {
            "k": "Santa Maria",
            "v": [
              "Bagbaguin",
              "Balasing",
              "Buenavista",
              "Bulac",
              "Camangyanan",
              "Catmon",
              "Cay Pombo",
              "Caysio",
              "Guyong",
              "Lalakhan",
              "Mag-asawang Sapa",
              "Mahabang Parang",
              "Manggahan",
              "Parada",
              "Poblacion",
              "Pulong Buhangin",
              "San Gabriel",
              "San Jose Patag",
              "San Vicente",
              "Santa Clara",
              "Santa Cruz",
              "Silangan",
              "Tabing Bakod",
              "Tumana"
            ]
          }
        ],
        "names": [
          "Angat",
          "Balagtas (Bigaa)",
          "Baliuag",
          "Bocaue",
          "Bulacan",
          "Bustos",
          "Calumpit",
          "Guiguinto",
          "Hagonoy",
          "Malolos City",
          "Marilao",
          "Meycauayan City",
          "Norzagaray",
          "Obando",
          "Pandi",
          "Paombong",
          "Plaridel",
          "Pulilan",
          "San Ildefonso",
          "San Jose Del Monte City",
          "San Miguel",
          "San Rafael",
          "Santa Maria"
        ]
      }
    },
    {
      "k": "Cagayan",
      "v": {
        "lookup": [
          { "k": "Abulug", "v": ["Calog Norte", "Libertad", "Lucban"] },
          {
            "k": "Alcala",
            "v": [
              "Baculod",
              "Baybayog",
              "Calantac",
              "Centro Norte (Pob.)",
              "Centro Sur (Pob.)",
              "Jurisdiction",
              "Maraburab",
              "Pared",
              "Piggatan",
              "Pinopoc",
              "Tupang"
            ]
          },
          {
            "k": "Allacapan",
            "v": [
              "Bessang",
              "Bulo",
              "Burot",
              "Capagaran (Brigida)",
              "Capalutan",
              "Capanickian Norte",
              "Capanickian Sur",
              "Cataratan",
              "Centro East (Pob.)",
              "Centro West (Pob.)",
              "Daan-ili",
              "Dagupan",
              "Dalayap",
              "Iringan",
              "Labben",
              "Maluyo",
              "Matucay",
              "Nagattatan"
            ]
          },
          {
            "k": "Amulung",
            "v": [
              "Anquiray",
              "Babayuan",
              "Baculud",
              "Calamagui",
              "Centro",
              "Concepcion",
              "Estefania",
              "Jurisdiccion",
              "Monte Alegre"
            ]
          },
          {
            "k": "Aparri",
            "v": [
              "Backiling",
              "Bangag",
              "Binalan",
              "Bisagu",
              "Bukig",
              "Bulala Norte",
              "Bulala Sur",
              "Caagaman",
              "Centro 1 (Pob.)",
              "Centro 10 (Pob.)",
              "Centro 11 (Pob.)",
              "Centro 12 (Pob.)",
              "Centro 13 (Pob.)",
              "Centro 14 (Pob.)",
              "Centro 15 (Pob.)",
              "Centro 2 (Pob.)",
              "Centro 3 (Pob.)",
              "Centro 4 (Pob.)",
              "Centro 5 (Pob.)",
              "Centro 6 (Pob.)",
              "Centro 7 (Pob.)",
              "Centro 8 (Pob.)",
              "Centro 9 (Pob.)",
              "Dodan",
              "Gaddang",
              "Macanaya (Pescaria)",
              "Maura",
              "Minanga",
              "Navagan",
              "Paddaya",
              "Punta",
              "San Antonio",
              "Sanja",
              "Tallungan",
              "Toran"
            ]
          },
          {
            "k": "Ballesteros",
            "v": [
              "Ammubuan",
              "Baran",
              "Cabaritan East",
              "Cabaritan West",
              "Cabayu",
              "Cabuluan East",
              "Cabuluan West",
              "Centro East (Pob.)",
              "Centro West (Pob.)",
              "Fugu",
              "Mabuttal East",
              "Mabuttal West",
              "Palloc",
              "Payagan East",
              "Payagan West",
              "San Juan",
              "Santa Cruz",
              "Zitanga"
            ]
          },
          {
            "k": "Buguey",
            "v": [
              "Antiporda",
              "Ballang",
              "Cabaritan",
              "Calamegatan",
              "Centro (Pob.)",
              "Centro West",
              "Dalaya",
              "Fula",
              "Leron",
              "Maddalero",
              "Paddaya Este",
              "Paddaya Weste",
              "Pattao",
              "Quinawegan",
              "San Vicente",
              "Santa Maria",
              "Tabbac",
              "Villa Gracia",
              "Villa Leonora"
            ]
          },
          {
            "k": "Camalaniugan",
            "v": [
              "Abagao",
              "Afunan Cabayu",
              "Agusi",
              "Alilinu",
              "Baggao",
              "Bantay",
              "Bulala",
              "Casili Norte",
              "Casili Sur",
              "Catotoran Norte",
              "Catotoran Sur",
              "Centro Norte (Pob.)",
              "Centro Sur (Pob.)",
              "Cullit",
              "Dacal-lafugu",
              "Dugo",
              "Jurisdiction",
              "Luec",
              "Minanga",
              "Sapping",
              "Tagum",
              "Ziminila"
            ]
          },
          {
            "k": "Claveria",
            "v": [
              "Cadcadir West",
              "Camalaggoan/d LeaÃ±o",
              "Centro I (Pob.)",
              "Centro Ii (Pob.)",
              "Centro Iii",
              "Centro Iv (Nangasangan)",
              "Centro V (Mina)",
              "Centro Vi (Minanga)",
              "Centro Vii (Malasin East)",
              "Centro Viii (Malasin West)",
              "Culao",
              "Dibalio",
              "Kilkiling",
              "Mabnang",
              "Pata East",
              "Pata West",
              "Taggat Norte",
              "Taggat Sur",
              "Union"
            ]
          },
          {
            "k": "Enrile",
            "v": [
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (San Roque)",
              "Barangay Iii-a",
              "Barangay Iv (Pob.)",
              "Lanna",
              "Maddarulug Norte",
              "Maddarulug Sur",
              "Roma Norte"
            ]
          },
          {
            "k": "Gattaran",
            "v": [
              "Aguiguican",
              "Baracaoit",
              "Baraoidan",
              "Barbarit",
              "Calaoagan Bassit",
              "Calaoagan Dackel",
              "Capissayan Norte",
              "Capissayan Sur",
              "Casicallan Norte",
              "Casicallan Sur",
              "Centro Norte (Pob.)",
              "Centro Sur (Pob.)",
              "Cullit",
              "Cumao",
              "Dummun",
              "Fugu",
              "Guising",
              "L. Adviento",
              "Lapogan",
              "Nabaccayan",
              "Nassiping",
              "Newagac",
              "Palagao Norte",
              "Palagao Sur",
              "San Vicente",
              "Santa Maria",
              "Takiki"
            ]
          },
          {
            "k": "Gonzaga",
            "v": [
              "Amunitan",
              "Batangan",
              "Baua",
              "Cabanbanan Norte",
              "Cabanbanan Sur",
              "Calayan",
              "Callao",
              "Caroan",
              "Casitan",
              "Flourishing (Pob.)",
              "Ipil",
              "Isca",
              "Minanga",
              "Paradise (Pob.)",
              "Pateng",
              "Progressive (Pob.)",
              "Rebecca (Nagbabalacan*)",
              "San Jose",
              "Santa Cruz",
              "Santa Maria",
              "Smart (Pob.)",
              "Tapel"
            ]
          },
          {
            "k": "Iguig",
            "v": [
              "Ajat (Pob.)",
              "Atulu",
              "Baculud",
              "Bayo",
              "Campo",
              "Dumpao",
              "Gammad",
              "Malabbac",
              "Manaoag (Aquiliquilao)",
              "Minanga Norte",
              "Minanga Sur",
              "Nattanzan (Pob.)",
              "Redondo",
              "Salamague",
              "San Esteban (Capitan)",
              "San Isidro (Ugac West)",
              "San Lorenzo",
              "San Vicente (Ugac East)",
              "Santa Barbara",
              "Santa Rosa",
              "Santa Teresa (Gammad Sur)",
              "Santiago"
            ]
          },
          {
            "k": "Lal-lo",
            "v": [
              "Abagao",
              "Alaguia",
              "Bagumbayan",
              "Bangag",
              "Bical",
              "Bicud",
              "Cabayabasan (Capacuan)",
              "Cagoran",
              "Catayauan",
              "Centro (Pob.)",
              "Cullit",
              "Fusina",
              "Jurisdiction",
              "Lalafugan",
              "Logac",
              "Magapit",
              "Maxingal",
              "San Jose",
              "Santa Maria",
              "Tucalana"
            ]
          },
          {
            "k": "Lasam",
            "v": [
              "Alannay",
              "Callao Norte",
              "Callao Sur",
              "Centro I (Pob.)",
              "Centro Ii (Pob.)",
              "Centro Iii (Pob.)",
              "Finugo Norte",
              "Gabun",
              "Ignacio Jurado (Finugu Sur)",
              "Magsaysay",
              "Malinta",
              "Minanga Norte",
              "Minanga Sur",
              "Nabannagan East",
              "Nabannagan West",
              "Nicolas Agatep",
              "San Pedro"
            ]
          },
          {
            "k": "Pamplona",
            "v": [
              "Allasitan",
              "Bagu",
              "Balingit",
              "Bidduang",
              "Centro (Pob.)",
              "Curva",
              "Masi",
              "San Juan",
              "Santa Cruz"
            ]
          },
          {
            "k": "PeÃ±ablanca",
            "v": [
              "Aggugaddan",
              "Alimanao",
              "Callao",
              "Camasi",
              "Centro (Pob.)",
              "Dodan",
              "Malibabag",
              "Nannarian",
              "Parabba",
              "Patagueleg",
              "San Roque (Litto)",
              "Sisim"
            ]
          },
          {
            "k": "Piat",
            "v": [
              "Baung",
              "Maguilling",
              "Poblacion I",
              "Poblacion Ii",
              "Santa Barbara",
              "Santo Domingo",
              "Villa Rey (San Gaspar*)"
            ]
          },
          {
            "k": "Sanchez-mira",
            "v": [
              "Bangan",
              "Callungan",
              "Centro I (Pob.)",
              "Centro Ii (Pob.)",
              "Dacal",
              "Dagueray",
              "Langagan",
              "Magacan",
              "Marzan",
              "Masisit",
              "Nagrangtayan",
              "Namuac",
              "San Andres",
              "Santor",
              "Tokitok"
            ]
          },
          {
            "k": "Santa Teresita",
            "v": [
              "Buyun",
              "Caniugan",
              "Centro East (Pob.)",
              "Centro West",
              "Dungeg",
              "Luga",
              "Masi",
              "Mission",
              "Simpatuyo",
              "Villa"
            ]
          },
          {
            "k": "Solana",
            "v": [
              "Andarayan North",
              "Andarayan South",
              "Bangag",
              "Basi East",
              "Basi West",
              "Bauan East",
              "Bauan West",
              "Cadaanan",
              "Calamagui",
              "Centro Northeast (Pob.)",
              "Centro Northwest (Pob.)",
              "Centro Southeast (Pob.)",
              "Centro Southwest (Pob.)",
              "Lanna",
              "Lingu",
              "Maddarulug (Santo Domingo)",
              "Nangalisan",
              "Natappian East",
              "Natappian West",
              "Sampaguita"
            ]
          },
          {
            "k": "Tuao",
            "v": [
              "Accusilian",
              "Alabug",
              "Bagumbayan",
              "Balagao",
              "Cagumitan",
              "Fugu",
              "Lakambini",
              "Naruangan",
              "Poblacion I (Ward I Centro)",
              "Poblacion Ii (Ward Ii Centro)"
            ]
          },
          {
            "k": "Tuguegarao City",
            "v": [
              "Annafunan East",
              "Annafunan West",
              "Atulayan Norte",
              "Atulayan Sur",
              "Bagay",
              "Buntun",
              "Caggay",
              "Capatan",
              "Carig",
              "Caritan Centro",
              "Caritan Norte",
              "Caritan Sur",
              "Cataggaman Nuevo",
              "Cataggaman Pardo",
              "Cataggaman Viejo",
              "Centro 1 (Pob.)",
              "Centro 10 (Pob.)",
              "Centro 11 (Pob.)",
              "Centro 12 (Pob.)",
              "Centro 2 (Pob.)",
              "Centro 3 (Pob.)",
              "Centro 4 (Pob.)",
              "Centro 5 (Pob.)",
              "Centro 6 (Pob.)",
              "Centro 7 (Pob.)",
              "Centro 8 (Pob.)",
              "Centro 9 (Pob.)",
              "Dadda",
              "Gosi Norte",
              "Gosi Sur",
              "Larion Alto",
              "Larion Bajo",
              "Leonarda",
              "Libag Norte",
              "Libag Sur",
              "Linao East",
              "Linao Norte",
              "Linao West",
              "Nambbalan Norte",
              "Nambbalan Sur",
              "Pallua Norte",
              "Pallua Sur",
              "Pengue (Pengue-ruyu)",
              "Reyes",
              "San Gabriel",
              "Tagga",
              "Tanza",
              "Ugac Norte",
              "Ugac Sur"
            ]
          }
        ],
        "names": [
          "Abulug",
          "Alcala",
          "Allacapan",
          "Amulung",
          "Aparri",
          "Ballesteros",
          "Buguey",
          "Camalaniugan",
          "Claveria",
          "Enrile",
          "Gattaran",
          "Gonzaga",
          "Iguig",
          "Lal-lo",
          "Lasam",
          "Pamplona",
          "PeÃ±ablanca",
          "Piat",
          "Sanchez-mira",
          "Santa Teresita",
          "Solana",
          "Tuao",
          "Tuguegarao City"
        ]
      }
    },
    {
      "k": "Camarines Norte",
      "v": {
        "lookup": [
          {
            "k": "Basud",
            "v": [
              "Bactas",
              "Langa",
              "Laniton",
              "Mampili",
              "Mantugawe",
              "Matnog",
              "Mocong",
              "Pagsangahan",
              "Pinagwarasan",
              "Poblacion 1",
              "Poblacion 2",
              "San Felipe"
            ]
          },
          {
            "k": "Daet",
            "v": [
              "Alawihao",
              "Awitan",
              "Bagasbas",
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Barangay V (Pob.)",
              "Barangay Vi (Pob.)",
              "Barangay Vii (Pob.)",
              "Barangay Viii (Pob.)",
              "Bibirao",
              "Borabod",
              "Calasgasan",
              "Camambugan",
              "Cobangbang (Carumpit)",
              "Dogongan",
              "Gahonon",
              "Gubat",
              "Lag-on",
              "Magang",
              "Mambalite",
              "Mancruz (Mangcruz)",
              "Pamorangon",
              "San Isidro"
            ]
          },
          {
            "k": "Jose Panganiban",
            "v": [
              "Motherlode",
              "North Poblacion",
              "Parang",
              "Plaridel",
              "Santa Rosa Norte",
              "Santa Rosa Sur",
              "South Poblacion"
            ]
          },
          {
            "k": "Labo",
            "v": [
              "Anahaw (Pob.)",
              "Bautista",
              "Bulhao",
              "Cabusay",
              "Fundado",
              "Guinacutan",
              "Gumamela (Pob.)",
              "Iberica",
              "Kalamunding (Pob.)",
              "Lugui",
              "Mabilo I",
              "Mabilo Ii",
              "Malasugui",
              "Masalong",
              "Pinya (Pob.)",
              "San Antonio",
              "San Francisco (Pob.)",
              "Talobatib",
              "Tulay Na Lupa"
            ]
          },
          {
            "k": "Mercedes",
            "v": [
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Barangay V (Pob.)",
              "Barangay Vi (Pob.)",
              "Barangay Vii (Pob.)",
              "Del Rosario (Tagongtong)",
              "San Roque"
            ]
          },
          {
            "k": "Paracale",
            "v": [
              "Bagumbayan",
              "Batobalani",
              "Capacuan",
              "Dalnac",
              "Maybato",
              "Poblacion Sur",
              "Tawig"
            ]
          },
          {
            "k": "San Vicente",
            "v": [
              "Man-ogob",
              "Poblacion District I (Silangan/bgy. 1)",
              "Poblacion District Ii (Kanluran/bgy. 2)",
              "San Jose (Iraya Norte)"
            ]
          },
          {
            "k": "Talisay",
            "v": [
              "Binanuaan",
              "Cahabaan",
              "Calintaan",
              "Gabon",
              "Itomang",
              "Poblacion",
              "San Francisco",
              "San Isidro",
              "San Nicolas",
              "Santa Cruz",
              "Santo NiÃ±o"
            ]
          },
          {
            "k": "Vinzons",
            "v": [
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Calangcawan Norte",
              "Calangcawan Sur",
              "Guinacutan",
              "Mangcawayan",
              "Santo Domingo"
            ]
          }
        ],
        "names": [
          "Basud",
          "Daet",
          "Jose Panganiban",
          "Labo",
          "Mercedes",
          "Paracale",
          "San Vicente",
          "Talisay",
          "Vinzons"
        ]
      }
    },
    {
      "k": "Camarines Sur",
      "v": {
        "lookup": [
          {
            "k": "Baao",
            "v": [
              "Agdangan Pob. (San Cayetano)",
              "Bagumbayan",
              "Buluang (San Antonio)",
              "Del Rosario (Pob.)",
              "La Medalla",
              "Sagrada",
              "Salvacion",
              "San Francisco (Pob.)",
              "San Isidro",
              "San Jose (Pob.)",
              "San Juan",
              "San Nicolas (Pob.)",
              "San Ramon (Pob.)",
              "San Roque (Pob.)",
              "San Vicente",
              "Santa Cruz (Pob.)",
              "Santa Teresita"
            ]
          },
          {
            "k": "Bato",
            "v": [
              "Agos",
              "Bacolod",
              "Divina Pastora (Pob.)",
              "Masoli",
              "Sagrada (Sagrada Familia)",
              "San Isidro (Pob.)",
              "San Juan",
              "San Miguel",
              "San Rafael (Pob.)",
              "San Vicente",
              "Santa Cruz (Pob.)",
              "Santiago (Pob.)",
              "Tres Reyes (Pob.)"
            ]
          },
          {
            "k": "Bombon",
            "v": [
              "San Antonio",
              "San Francisco",
              "San Isidro (Pob.)",
              "San Jose (Pob.)",
              "San Roque (Pob.)",
              "Santo Domingo"
            ]
          },
          {
            "k": "Buhi",
            "v": [
              "Cabatuan",
              "Lourdes (Santa Lourdes)",
              "Monte Calvario",
              "Sagrada Familia",
              "Salvacion",
              "San Buenaventura (Pob.)",
              "San Jose Baybayon",
              "San Pascual (Pob.)",
              "San Pedro (Pob.)",
              "San Roque (Pob.)",
              "Santa Elena (Pob.)",
              "Santa Justina"
            ]
          },
          {
            "k": "Bula",
            "v": [
              "Bagoladio",
              "Bagumbayan",
              "Causip",
              "Pawili",
              "Sagrada (Sagrada Familia)",
              "Salvacion (Pob.)",
              "San Francisco",
              "San Isidro",
              "San Jose",
              "San Roque (Pob.)",
              "Santo Domingo"
            ]
          },
          {
            "k": "Cabusao",
            "v": [
              "Barcelonita",
              "Camagong",
              "Castillo",
              "New Poblacion",
              "Pandan",
              "San Pedro",
              "Santa Cruz",
              "Santa Lutgarda (Pob.)"
            ]
          },
          {
            "k": "Calabanga",
            "v": [
              "Balongay",
              "Belen",
              "Binaliw",
              "Cabanbanan",
              "Cagsao",
              "Del Carmen (Pob.)",
              "La Purisima",
              "Manguiring",
              "Pagatpat (San Jose)",
              "Paolbo",
              "Sabang",
              "Salvacion-baybay",
              "San Antonio (Quipayo)",
              "San Antonio Poblacion",
              "San Bernardino",
              "San Francisco (Pob.)",
              "San Isidro",
              "San Lucas",
              "San Miguel (Pob.)",
              "San Pablo (Pob.)",
              "San Roque",
              "San Vicente (Pob.)",
              "Santa Cruz Poblacion",
              "Santa Cruz Ratay",
              "Santa Isabel (Pob.)",
              "Santa Salud (Pob.)",
              "Santo Domingo",
              "Santo NiÃ±o (Quipayo)",
              "Sibobo",
              "Sogod"
            ]
          },
          {
            "k": "Camaligan",
            "v": [
              "Dugcal",
              "Marupit",
              "San Jose-san Pablo (Pob.)",
              "San Juan-san Ramon (Pob.)",
              "San Lucas (Pob.)",
              "San Marcos (Pob.)",
              "San Mateo (Pob.)",
              "San Roque",
              "Santo Domingo (Pob.)",
              "Santo Tomas (Pob.)",
              "Sua"
            ]
          },
          {
            "k": "Canaman",
            "v": [
              "Baras (Pob.)",
              "Del Rosario",
              "Dinaga (Pob.)",
              "Haring",
              "Pangpang (Pob.)",
              "Poro",
              "San Agustin",
              "San Francisco",
              "San Juan",
              "San Nicolas",
              "San Roque",
              "San Vicente",
              "Santa Cruz (Pob.)",
              "Santa Teresita",
              "Sua",
              "Tibgao (Pob.)"
            ]
          },
          {
            "k": "Del Gallego",
            "v": ["Zone I Fatima (Pob.)", "Zone Ii San Antonio (Pob.)"]
          },
          {
            "k": "Gainza",
            "v": [
              "Cagbunga",
              "Dahilig",
              "District I (Pob.)",
              "District Ii (Pob.)",
              "Namuat",
              "Sampaloc"
            ]
          },
          {
            "k": "Goa",
            "v": [
              "Bagumbayan Grande (Pob.)",
              "Bagumbayan PequeÃ±o (Pob.)",
              "Belen (Pob.)",
              "Buyo",
              "Catagbacan",
              "Gimaga",
              "Halawig-gogon",
              "La Purisima (Pob.)",
              "Matacla",
              "Panday (Pob.)",
              "San Benito (Pob.)",
              "San Isidro (Pob.)",
              "San Jose (Pob.)",
              "San Juan Bautista (Pob.)",
              "San Juan Evangelista (Pob.)",
              "Tagongtong"
            ]
          },
          {
            "k": "Iriga City",
            "v": [
              "Francia",
              "La Anunciacion",
              "La Medalla",
              "La Purisima",
              "La Trinidad",
              "Perpetual Help",
              "Salvacion",
              "San Agustin",
              "San Antonio",
              "San Francisco (Pob.)",
              "San Isidro",
              "San Jose",
              "San Juan",
              "San Miguel",
              "San Nicolas",
              "San Roque (Pob.)",
              "San Vicente Sur",
              "Santa Cruz Norte",
              "Santa Cruz Sur",
              "Santa Elena",
              "Santa Teresita",
              "Santiago",
              "Santo Domingo"
            ]
          },
          {
            "k": "Lagonoy",
            "v": [
              "Del Carmen",
              "San Francisco (Pob.)",
              "San Isidro Norte (Pob.)",
              "San Isidro Sur (Pob.)",
              "San Rafael",
              "San Sebastian",
              "San Vicente (Pob.)",
              "Santa Cruz (Pob.)",
              "Santa Maria (Pob.)",
              "Saripongpong (Pob.)"
            ]
          },
          {
            "k": "Libmanan",
            "v": [
              "Bagacay",
              "Bagumbayan",
              "Bahao",
              "Bahay",
              "Begajo Norte",
              "Begajo Sur",
              "Beguito Nuevo",
              "Beguito Viejo",
              "Bikal",
              "Busak",
              "Concepcion",
              "Duang Niog",
              "Handong",
              "Ibid",
              "Labao",
              "Libod I",
              "Libod Ii",
              "Loba-loba",
              "Mambulo Nievo",
              "Palong",
              "Poblacion",
              "Potot",
              "Puro-batia",
              "San Isidro",
              "San Juan",
              "Sibujo",
              "Sigamot",
              "Station-church Site",
              "Taban-fundado",
              "Tinangkihan"
            ]
          },
          {
            "k": "Lupi",
            "v": [
              "Bel-cruz",
              "Bulawan Jr.",
              "Colacling (Del Rosario)",
              "Poblacion",
              "Sooc"
            ]
          },
          {
            "k": "Magarao",
            "v": [
              "Bell (Pob.)",
              "Casuray",
              "Monserrat (Pob.)",
              "Ponong",
              "San Francisco (Pob.)",
              "San Isidro (Pob.)",
              "San Juan (Pob.)",
              "San Miguel",
              "San Pantaleon (Pob.)",
              "Santa Lucia (Pob.)",
              "Santa Rosa",
              "Santo Tomas (Pob.)"
            ]
          },
          {
            "k": "Milaor",
            "v": [
              "Alimbuyog",
              "Amparado (Pob.)",
              "Balagbag",
              "Borongborongan",
              "Capucnasan",
              "Dalipay",
              "Del Rosario (Pob.)",
              "Flordeliz",
              "Lipot",
              "Mayaopayawan",
              "Maycatmon",
              "Maydaso",
              "San Antonio",
              "San Jose (Pob.)",
              "San Miguel (Pob.)",
              "San Roque (Pob.)",
              "San Vicente (Pob.)",
              "Santo Domingo (Pob.)",
              "Tarusanan"
            ]
          },
          {
            "k": "Minalabac",
            "v": [
              "Antipolo",
              "Baliuag Nuevo",
              "Baliuag Viejo",
              "Del Carmen-del Rosario (Pob.)",
              "Irayang Solong",
              "Malitbog",
              "San Antonio",
              "San Felipe-santiago (Pob.)",
              "San Francisco (Pob.)",
              "San Jose",
              "San Juan-san Lorenzo (Pob.)"
            ]
          },
          {
            "k": "Nabua",
            "v": [
              "Antipolo Old",
              "Antipolo Young",
              "Bustrac",
              "La Opinion",
              "Lourdes Old",
              "Lourdes Young",
              "Paloyon Oriental",
              "Paloyon Proper (Sagrada Paloyon)",
              "San Antonio Ogbon",
              "San Antonio(pob.)",
              "San Esteban (Pob.)",
              "San Francisco (Pob.)",
              "San Isidro (Pob.)",
              "San Juan (Pob.)",
              "San Luis (Pob.)",
              "San Miguel (Pob.)",
              "San Nicolas (Pob.)",
              "San Roque (Pob.)",
              "San Roque Madawon",
              "San Roque Sagumay",
              "Santa Cruz",
              "Santa Elena Baras",
              "Santiago Old",
              "Santiago Young",
              "Santo Domingo"
            ]
          },
          {
            "k": "Naga City",
            "v": [
              "Abella",
              "Bagumbayan Norte",
              "Bagumbayan Sur",
              "Balatas",
              "Calauag",
              "Cararayan",
              "Carolina",
              "Concepcion Grande",
              "Concepcion PequeÃ±o",
              "Dayangdang",
              "Del Rosario",
              "Dinaga",
              "Igualdad Interior",
              "Lerma",
              "Liboton",
              "Mabolo",
              "Pacol",
              "Panicuason",
              "PeÃ±afrancia",
              "Sabang",
              "San Felipe",
              "San Francisco (Pob.)",
              "San Isidro",
              "Santa Cruz",
              "Tabuco",
              "Tinago",
              "Triangulo"
            ]
          },
          {
            "k": "Ocampo",
            "v": [
              "Ayugan",
              "Cagmanaba",
              "Hanawan",
              "Hibago",
              "La Purisima Nuevo",
              "New Moriones",
              "Old Moriones",
              "Pinit",
              "Poblacion Central",
              "Poblacion East",
              "Poblacion West",
              "Salvacion",
              "San Antonio",
              "San Francisco",
              "San Roque Commonal"
            ]
          },
          {
            "k": "Pamplona",
            "v": [
              "Batang",
              "Cagbunga",
              "Del Rosario",
              "Poblacion",
              "Salvacion",
              "San Gabriel",
              "San Isidro",
              "San Rafael",
              "San Ramon",
              "San Vicente",
              "Tambo",
              "Veneracion (Taguilid)"
            ]
          },
          {
            "k": "Pasacao",
            "v": [
              "Balogo",
              "Caranan",
              "Odicon",
              "Quitang",
              "San Antonio",
              "San Cirilo (Pob.)",
              "Santa Rosa Del Norte (Pob.)",
              "Santa Rosa Del Sur (Pob.)"
            ]
          },
          {
            "k": "Pili",
            "v": [
              "Anayan",
              "Binanwaanan",
              "Cadlan",
              "Caroyroyan",
              "Del Rosario",
              "Himaao",
              "La Purisima",
              "New San Roque",
              "Old San Roque (Pob.)",
              "Palestina",
              "Pawili",
              "Sagrada",
              "Sagurong",
              "San Agustin",
              "San Antonio (Pob.)",
              "San Isidro (Pob.)",
              "San Jose",
              "San Juan (Pob.)",
              "San Vicente (Pob.)",
              "Santiago (Pob.)",
              "Santo NiÃ±o",
              "Tagbong",
              "Tinangis"
            ]
          },
          {
            "k": "Ragay",
            "v": [
              "Agrupacion",
              "Amomokpok",
              "Apad",
              "Banga Caves",
              "Binahan Proper",
              "F. Simeon (Pugod)",
              "Godofredo Reyes Sr. (Catabangan Crossing)",
              "Liboro",
              "Lower Santa Cruz",
              "Panaytayan",
              "Poblacion Ilaod",
              "Poblacion Iraya",
              "Port Junction Norte",
              "Port Junction Sur",
              "San Rafael",
              "Upper Omon",
              "Upper Santa Cruz"
            ]
          },
          {
            "k": "SagÃ±ay",
            "v": [
              "Del Carmen (Pob.)",
              "Mabca",
              "Nato",
              "San Antonio (Pob.)",
              "San Isidro (Pob.)",
              "San Roque (Pob.)"
            ]
          },
          {
            "k": "San Fernando",
            "v": [
              "Beberon",
              "Bonifacio (Pob.)",
              "Buenavista (Pob.)",
              "Del Pilar (Pob.)",
              "Grijalvo",
              "Lupi",
              "Pamukid",
              "Planza",
              "Rizal (Pob.)",
              "Santa Cruz"
            ]
          },
          {
            "k": "San Jose",
            "v": [
              "Boclod",
              "Camagong",
              "Danlog",
              "Del Carmen (Pob.)",
              "Dolo",
              "Kinalansan",
              "Sabang",
              "San Antonio (Pob.)",
              "San Juan (Pob.)",
              "San Vicente (Pob.)",
              "Santa Cruz (Pob.)",
              "Soledad (Pob.)"
            ]
          },
          {
            "k": "Sipocot",
            "v": [
              "Alteza",
              "Azucena",
              "Bolo Norte",
              "Bolo Sur",
              "Calagbangan",
              "Gaongan",
              "Impig",
              "Malubago",
              "Manangle",
              "North Centro (Pob.)",
              "North Villazar",
              "Serranzana",
              "South Centro (Pob.)",
              "South Villazar",
              "Taisan",
              "Tara"
            ]
          },
          { "k": "Siruma", "v": ["Poblacion"] },
          {
            "k": "Tigaon",
            "v": [
              "Caraycayon",
              "Gingaroy",
              "Gubat",
              "Mabalodbalod",
              "May-anao",
              "Panagan",
              "Poblacion",
              "Salvacion",
              "San Antonio",
              "San Francisco",
              "San Miguel",
              "San Rafael",
              "Talojongon",
              "Vinagre"
            ]
          },
          {
            "k": "Tinambac",
            "v": [
              "Bagacay",
              "Binalay",
              "Bolaobalite",
              "Filarca (Pob.)",
              "La Purisima (Pob.)",
              "Mananao",
              "Salvacion",
              "Salvacion Poblacion",
              "San Isidro (Pob.)",
              "San Pascual (Pob.)",
              "Santa Cruz (Pob.)",
              "Sogod"
            ]
          }
        ],
        "names": [
          "Baao",
          "Bato",
          "Bombon",
          "Buhi",
          "Bula",
          "Cabusao",
          "Calabanga",
          "Camaligan",
          "Canaman",
          "Del Gallego",
          "Gainza",
          "Goa",
          "Iriga City",
          "Lagonoy",
          "Libmanan",
          "Lupi",
          "Magarao",
          "Milaor",
          "Minalabac",
          "Nabua",
          "Naga City",
          "Ocampo",
          "Pamplona",
          "Pasacao",
          "Pili",
          "Ragay",
          "SagÃ±ay",
          "San Fernando",
          "San Jose",
          "Sipocot",
          "Siruma",
          "Tigaon",
          "Tinambac"
        ]
      }
    },
    {
      "k": "Camiguin",
      "v": {
        "lookup": [
          {
            "k": "Catarman",
            "v": [
              "Alga",
              "Catibac",
              "Compol",
              "Kilambon",
              "Lawigan",
              "Panghiawan",
              "Poblacion Catarman",
              "Tangaro"
            ]
          },
          {
            "k": "Guinsiliban",
            "v": ["Cantaan", "Liong", "Maac", "Poblacion Guinsiliban"]
          },
          {
            "k": "Mahinog",
            "v": [
              "Benoni",
              "Binaliwan",
              "Catuhugan",
              "Hubangon Proper",
              "Litican",
              "Poblacion Mahinog",
              "San Jose",
              "San Roque",
              "Tupsan Pequeno"
            ]
          },
          {
            "k": "Mambajao",
            "v": [
              "Agoho",
              "Anito",
              "Balbagon",
              "Baylao",
              "Bug Ong",
              "Cabua-an",
              "Common Poblacion",
              "Kuguita",
              "Magting",
              "Naasag",
              "Pandan",
              "Soro Soro",
              "Tangub",
              "Tupsan",
              "Yumbing"
            ]
          },
          {
            "k": "Sagay",
            "v": [
              "Aguran",
              "Alangilan",
              "Balite",
              "Bonbon",
              "Bugang",
              "Poblacion"
            ]
          }
        ],
        "names": ["Catarman", "Guinsiliban", "Mahinog", "Mambajao", "Sagay"]
      }
    },
    {
      "k": "Capiz",
      "v": {
        "lookup": [
          {
            "k": "Cuartero",
            "v": [
              "Angub",
              "Bito-on Ilawod",
              "Bito-on Ilaya",
              "Poblacion Ilawod",
              "Poblacion Ilaya",
              "Poblacion Takas"
            ]
          },
          {
            "k": "Dao",
            "v": [
              "Balucuan",
              "Manhoy",
              "Nasunogan",
              "Poblacion Ilawod",
              "Poblacion Ilaya"
            ]
          },
          {
            "k": "Dumalag",
            "v": [
              "Poblacion",
              "San Jose",
              "Santa Cruz",
              "Santo Angel",
              "Santo NiÃ±o"
            ]
          },
          {
            "k": "Dumarao",
            "v": [
              "Codingle",
              "Dangula",
              "Ongol Ilawod",
              "Ongol Ilaya",
              "Poblacion Ilawod",
              "Poblacion Ilaya",
              "Salcedo",
              "Tinaytayan"
            ]
          },
          {
            "k": "Ivisan",
            "v": [
              "Agmalobo",
              "Cudian",
              "Ilaya-ivisan",
              "Malocloc Norte",
              "Malocloc Sur",
              "Matnog",
              "Mianay",
              "Ondoy",
              "Poblacion Norte",
              "Poblacion Sur",
              "Santa Cruz"
            ]
          },
          {
            "k": "Jamindan",
            "v": ["Agambulong", "Bayebaye", "Esperanza", "Poblacion"]
          },
          {
            "k": "Ma-ayon",
            "v": ["Poblacion Ilawod", "Poblacion Ilaya", "Poblacion Tabuc"]
          },
          {
            "k": "Mambusao",
            "v": [
              "Atiplo",
              "Bula",
              "Bunga",
              "Caidquid",
              "Manibad",
              "Poblacion Proper",
              "Poblacion Tabuc",
              "Tugas",
              "Tumalalud"
            ]
          },
          {
            "k": "Panay",
            "v": [
              "Agbalo",
              "Agbanban",
              "Anhawon",
              "Bagacay",
              "Bago Chiquito",
              "Bago Grande",
              "Bato",
              "Bonga",
              "Cabugao Este",
              "Cabugao Oeste",
              "Calapawan",
              "Calitan",
              "Candual",
              "Cogon",
              "Libon",
              "Linateran",
              "Lomboy",
              "Lus-onan",
              "Pawa",
              "Poblacion Ilawod",
              "Poblacion Ilaya",
              "Poblacion Tabuc",
              "Tanza Norte",
              "Tanza Sur",
              "Tico"
            ]
          },
          {
            "k": "Panitan",
            "v": [
              "Agkilo",
              "Agloway",
              "Cogon",
              "Conciencia",
              "Ensenagan",
              "Poblacion Ilawod",
              "Poblacion Ilaya",
              "Salocon",
              "Tabuc Norte",
              "Tabuc Sur",
              "Timpas"
            ]
          },
          {
            "k": "Pilar",
            "v": [
              "Cayus",
              "Dulangan",
              "Natividad",
              "Poblacion",
              "Rosario",
              "San Blas",
              "San Esteban",
              "San Fernando",
              "San Nicolas",
              "San Pedro",
              "Santa Fe"
            ]
          },
          {
            "k": "Pontevedra",
            "v": [
              "Bailan",
              "Binuntucan",
              "Hipona",
              "Ilawod (Pob.)",
              "Ilaya (Pob.)",
              "Intungcan",
              "Jolongajog",
              "Lantangan",
              "Malag-it",
              "Rizal",
              "Sublangon",
              "Tabuc",
              "Tacas"
            ]
          },
          {
            "k": "President Roxas",
            "v": [
              "Aranguel",
              "Cabugcabug",
              "Culilang",
              "Hanglid",
              "Ibaca",
              "Marita",
              "Pantalan Cabugcabug",
              "Poblacion (Elizalde)",
              "Pondol",
              "Sangkal",
              "Vizcaya"
            ]
          },
          {
            "k": "Roxas City",
            "v": [
              "Adlawan",
              "Bago",
              "Balijuagan",
              "Banica",
              "Barra",
              "Bato",
              "Baybay",
              "Bolo",
              "Cabugao",
              "Cagay",
              "Cogon",
              "Culajao",
              "Culasi",
              "Dayao",
              "Dinginan",
              "Dumolog",
              "Gabu-an",
              "Inzo Arnaldo Village (Cadimahan)",
              "Jumaguicjic",
              "Lanot",
              "Lawa-an",
              "Libas",
              "Liong",
              "Loctugan",
              "Lonoy",
              "Milibili",
              "Mongpong",
              "Olotayan",
              "Poblacion I (Barangay I)",
              "Poblacion Ii (Barangay Ii)",
              "Poblacion Iii (Barangay Iii)",
              "Poblacion Iv (Barangay Iv)",
              "Poblacion Ix (Barangay Ix)",
              "Poblacion V (Barangay V)",
              "Poblacion Vi (Barangay Vi)",
              "Poblacion Vii (Barangay Vii)",
              "Poblacion Viii (Barangay Viii)",
              "Poblacion X (Barangay X)",
              "Poblacion Xi (Barangay Xi)",
              "Punta Cogon",
              "Punta Tabuc",
              "San Jose",
              "Sibaguan",
              "Talon",
              "Tanque",
              "Tanza",
              "Tiza"
            ]
          },
          {
            "k": "Sapi-an",
            "v": ["Dapdapan", "Majanlud", "Maninang", "Poblacion"]
          },
          {
            "k": "Sigma",
            "v": [
              "Amaga",
              "Dayhagon",
              "Guintas",
              "Malapad Cogon",
              "Mianay",
              "Parian",
              "Poblacion Norte",
              "Poblacion Sur",
              "Tawog"
            ]
          }
        ],
        "names": [
          "Cuartero",
          "Dao",
          "Dumalag",
          "Dumarao",
          "Ivisan",
          "Jamindan",
          "Ma-ayon",
          "Mambusao",
          "Panay",
          "Panitan",
          "Pilar",
          "Pontevedra",
          "President Roxas",
          "Roxas City",
          "Sapi-an",
          "Sigma"
        ]
      }
    },
    {
      "k": "Catanduanes",
      "v": {
        "lookup": [
          {
            "k": "Bato",
            "v": [
              "Bagumbayan",
              "Banawang",
              "Binanuahan",
              "Cabugao",
              "Guinobatan",
              "Ilawod (Pob.)",
              "Libod Poblacion",
              "Marinawa",
              "Sipi",
              "Tamburan"
            ]
          },
          {
            "k": "San Andres (Calolbon)",
            "v": [
              "Barihay",
              "Batong Paloway",
              "Belmonte (Pob.)",
              "Bislig",
              "Cabcab",
              "Cabungahan",
              "Carangag",
              "Catagbacan",
              "Codon",
              "Comagaycay",
              "Datag",
              "Divino Rostro (Pob.)",
              "Esperanza (Pob.)",
              "Hilawan",
              "Lictin",
              "Lubas",
              "Manambrag",
              "Mayngaway",
              "Palawig",
              "Puting Baybay",
              "Rizal",
              "Salvacion (Pob.)",
              "San Jose",
              "San Roque (Pob.)",
              "San Vicente",
              "Santa Cruz (Pob.)",
              "Sapang Palay (Pob.)",
              "Tibang",
              "Timbaan",
              "Tominawog",
              "Wagdas (Pob.)",
              "Yocti"
            ]
          },
          {
            "k": "San Miguel",
            "v": [
              "Atsan (District I)",
              "Boton",
              "Poblacion District Ii",
              "Poblacion District Iii"
            ]
          },
          {
            "k": "Virac",
            "v": [
              "Antipolo Del Norte",
              "Antipolo Del Sur",
              "Balite",
              "Batag",
              "Bigaa",
              "Buenavista",
              "Buyo",
              "Cabihian",
              "Calabnigan",
              "Calampong",
              "Calatagan Proper",
              "Calatagan Tibang",
              "Capilihan",
              "Casoocan",
              "Cavinitan",
              "Concepcion (Pob.)",
              "Constantino (Pob.)",
              "Danicop",
              "F. Tacorda Village",
              "Francia (Pob.)",
              "Gogon Centro",
              "Gogon Sirangan",
              "Hawan Grande",
              "Hawan Ilaya",
              "Hicming",
              "Ibong Sapa (San Vicente Sur)",
              "Igang",
              "Juan M. Alberto (Poniton)",
              "Lanao (Pob.)",
              "Magnesia Del Norte",
              "Magnesia Del Sur",
              "Marcelo Alberto (Pob.)",
              "Marilima",
              "Pajo Baguio",
              "Pajo San Isidro",
              "Palnab Del Norte",
              "Palnab Del Sur",
              "Palta Big",
              "Palta Salvacion",
              "Palta Small",
              "Rawis (Pob.)",
              "Salvacion",
              "San Isidro Village",
              "San Jose (Pob.)",
              "San Juan (Pob.)",
              "San Pablo (Pob.)",
              "San Pedro (Pob.)",
              "San Roque (Pob.)",
              "San Vicente",
              "Santa Cruz",
              "Santa Elena (Pob.)",
              "Santo Cristo",
              "Santo Domingo",
              "Santo NiÃ±o",
              "Simamla",
              "Sogod-simamla",
              "Sogod-tibgao",
              "Talisoy",
              "Tubaon",
              "Valencia"
            ]
          }
        ],
        "names": ["Bato", "San Andres (Calolbon)", "San Miguel", "Virac"]
      }
    },
    {
      "k": "Cavite",
      "v": {
        "lookup": [
          {
            "k": "Alfonso",
            "v": [
              "Amuyong",
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Barangay V (Pob.)",
              "Bilog",
              "Buck Estate",
              "Esperanza Ibaba",
              "Esperanza Ilaya",
              "Kaysuyo",
              "Kaytitinga I",
              "Kaytitinga Ii",
              "Kaytitinga Iii",
              "Luksuhin Ibaba",
              "Luksuhin Ilaya",
              "Mangas I",
              "Mangas Ii",
              "Marahan I",
              "Marahan Ii",
              "Matagbak I",
              "Matagbak Ii",
              "Pajo",
              "Palumlum",
              "Santa Teresa",
              "Sikat",
              "Sinaliw Malaki",
              "Sinaliw Na Munti",
              "Sulsugin",
              "Taywanak Ibaba",
              "Taywanak Ilaya",
              "Upli"
            ]
          },
          {
            "k": "Amadeo",
            "v": [
              "Banaybanay",
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Barangay Ix (Pob.)",
              "Barangay V (Pob.)",
              "Barangay Vi (Pob.)",
              "Barangay Vii (Pob.)",
              "Barangay Viii (Pob.)",
              "Barangay X (Pob.)",
              "Barangay Xi (Pob.)",
              "Barangay Xii (Pob.)",
              "Bucal",
              "Buho",
              "Dagatan",
              "Halang",
              "Loma",
              "Maitim I",
              "Maymangga",
              "Minantok Kanluran",
              "Minantok Silangan",
              "Pangil",
              "Salaban",
              "Talon",
              "Tamacan"
            ]
          },
          {
            "k": "Bacoor",
            "v": [
              "Alima",
              "Aniban I",
              "Aniban Ii",
              "Aniban Iii",
              "Aniban Iv",
              "Aniban V",
              "Banalo",
              "Bayanan",
              "Campo Santo",
              "Daang Bukid",
              "Digman",
              "Dulong Bayan",
              "Habay I",
              "Habay Ii",
              "Kaingin (Pob.)",
              "Ligas I",
              "Ligas Ii",
              "Ligas Iii",
              "Mabolo I",
              "Mabolo Ii",
              "Mabolo Iii",
              "Maliksi I",
              "Maliksi Ii",
              "Maliksi Iii",
              "Mambog I",
              "Mambog Ii",
              "Mambog Iii",
              "Mambog Iv",
              "Mambog V",
              "Molino I",
              "Molino Ii",
              "Molino Iii",
              "Molino Iv",
              "Molino V",
              "Molino Vi",
              "Molino Vii",
              "Niog I",
              "Niog Ii",
              "Niog Iii",
              "P.f. Espiritu I (Panapaan)",
              "P.f. Espiritu Ii",
              "P.f. Espiritu Iii",
              "P.f. Espiritu Iv",
              "P.f. Espiritu V",
              "P.f. Espiritu Vi",
              "P.f. Espiritu Vii",
              "P.f. Espiritu Viii",
              "Queens Row Central",
              "Queens Row East",
              "Queens Row West",
              "Real I",
              "Real Ii",
              "Salinas I",
              "Salinas Ii",
              "Salinas Iii",
              "Salinas Iv",
              "San Nicolas I",
              "San Nicolas Ii",
              "San Nicolas Iii",
              "Sineguelasan",
              "Tabing Dagat",
              "Talaba I",
              "Talaba Ii",
              "Talaba Iii",
              "Talaba Iv",
              "Talaba V",
              "Talaba Vi",
              "Talaba Vii",
              "Zapote I",
              "Zapote Ii",
              "Zapote Iii",
              "Zapote Iv"
            ]
          },
          {
            "k": "Carmona",
            "v": [
              "Bancal",
              "Barangay 1 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 4 (Pob.)",
              "Barangay 5 (Pob.)",
              "Barangay 6 (Pob.)",
              "Barangay 7 (Pob.)",
              "Barangay 8 (Pob.)",
              "Cabilang Baybay",
              "Lantic (Critical Area For Sitio Pinaligawan Matanda)",
              "Mabuhay",
              "Maduya",
              "Milagrosa"
            ]
          },
          {
            "k": "Cavite",
            "v": [
              "Barangay 1 (Hen. M. Alvarez)",
              "Barangay 10 (Kingfisher)",
              "Barangay 10-a (Kingfisher-a)",
              "Barangay 10-b (Kingfisher-b)",
              "Barangay 11 (Lawin)",
              "Barangay 12 (Love Bird)",
              "Barangay 13 (Aguila)",
              "Barangay 14 (Loro)",
              "Barangay 15 (Kilyawan)",
              "Barangay 16 (Martines)",
              "Barangay 17 (Kalapati)",
              "Barangay 18 (Maya)",
              "Barangay 19 (Gemini)",
              "Barangay 2 (C. Tirona)",
              "Barangay 20 (Virgo)",
              "Barangay 21 (Scorpio)",
              "Barangay 22 (Leo)",
              "Barangay 22-a (Leo A)",
              "Barangay 23 (Aquarius)",
              "Barangay 24 (Libra)",
              "Barangay 25 (Capricorn)",
              "Barangay 26 (Cancer)",
              "Barangay 27 (Sagitarius)",
              "Barangay 28 (Taurus)",
              "Barangay 29 (Lao-lao)",
              "Barangay 29-a (Lao-lao A)",
              "Barangay 3 (Hen. E. Aguinaldo)",
              "Barangay 30 (Bid-bid)",
              "Barangay 31 (Maya-maya)",
              "Barangay 32 (Salay-salay)",
              "Barangay 33 (Buwan-buwan)",
              "Barangay 34 (Lapu-lapu)",
              "Barangay 35 (Hasa-hasa)",
              "Barangay 36 (Sap-sap)",
              "Barangay 36-a (Sap-sap A)",
              "Barangay 37 (Cadena De Amor)",
              "Barangay 37-a (Cadena De Amor A)",
              "Barangay 38 (Sampaguita)",
              "Barangay 38-a (Sampaguita A)",
              "Barangay 39 (Jasmin)",
              "Barangay 4 (Hen. M. Trias)",
              "Barangay 40 (Gumamela)",
              "Barangay 41 (Rosal)",
              "Barangay 42 (Pinagbuklod)",
              "Barangay 42-a (Pinagbuklod A)",
              "Barangay 42-b (Pinagbuklod B)",
              "Barangay 42-c (Pinagbuklod C)",
              "Barangay 43 (Pinagpala)",
              "Barangay 44 (Maligaya)",
              "Barangay 45 (Kaunlaran)",
              "Barangay 45-a (Kaunlaran A)",
              "Barangay 46 (Sinagtala)",
              "Barangay 47 (Pagkakaisa)",
              "Barangay 47-a (Pagkakaisa A)",
              "Barangay 47-b (Pagkakaisa B)",
              "Barangay 48 (Narra)",
              "Barangay 48-a (Narra A)",
              "Barangay 49 (Akasya)",
              "Barangay 49-a (Akasya A)",
              "Barangay 5 (Hen. E. Evangelista)",
              "Barangay 50 (Kabalyero)",
              "Barangay 51 (Kamagong)",
              "Barangay 52 (Ipil)",
              "Barangay 53 (Yakal)",
              "Barangay 53-a (Yakal A)",
              "Barangay 53-b (Yakal B)",
              "Barangay 54 (Pechay)",
              "Barangay 54-a (Pechay A)",
              "Barangay 55 (Ampalaya)",
              "Barangay 56 (Labanos)",
              "Barangay 57 (Repolyo)",
              "Barangay 58 (Patola)",
              "Barangay 58-a (Patola A)",
              "Barangay 59 (Sitaw)",
              "Barangay 6 (Diego Silang)",
              "Barangay 60 (Letsugas)",
              "Barangay 61 (Talong)",
              "Barangay 61-a (Talong A)",
              "Barangay 62 (Kangkong)",
              "Barangay 62-a (Kangkong A)",
              "Barangay 62-b (Kangkong B)",
              "Barangay 7 (Kapitan Kong)",
              "Barangay 8 (Manuel S. Rojas)",
              "Barangay 9 (Kanaway)"
            ]
          },
          {
            "k": "DasmariÃ±as",
            "v": [
              "Burol",
              "Burol I",
              "Burol Ii",
              "Burol Iii",
              "Emmanuel Bergado I",
              "Emmanuel Bergado Ii",
              "Fatima I",
              "Fatima Ii",
              "Fatima Iii",
              "Langkaan I",
              "Langkaan Ii",
              "Luzviminda I",
              "Luzviminda Ii",
              "Paliparan I",
              "Paliparan Ii",
              "Paliparan Iii",
              "Sabang",
              "Saint Peter I",
              "Saint Peter Ii",
              "Salawag",
              "Salitran I",
              "Salitran Ii",
              "Salitran Iii",
              "Salitran Iv",
              "Sampaloc I",
              "Sampaloc Ii",
              "Sampaloc Iii",
              "Sampaloc Iv",
              "Sampaloc V",
              "San Agustin I",
              "San Agustin Ii",
              "San Agustin Iii",
              "San Andres I",
              "San Andres Ii",
              "San Antonio De Padua I",
              "San Antonio De Padua Ii",
              "San Dionisio (Barangay 1)",
              "San Esteban (Barangay 4)",
              "San Francisco I",
              "San Francisco Ii",
              "San Isidro Labrador I",
              "San Isidro Labrador Ii",
              "San Jose",
              "San Juan (San Juan I)",
              "San Lorenzo Ruiz I",
              "San Lorenzo Ruiz Ii",
              "San Luis I",
              "San Luis Ii",
              "San Manuel I",
              "San Manuel Ii",
              "San Mateo",
              "San Miguel",
              "San Miguel Ii",
              "San Nicolas I",
              "San Nicolas Ii",
              "San Roque (Sta. Cristina Ii)",
              "San Simon (Barangay 7)",
              "Santa Cristina I",
              "Santa Cristina Ii",
              "Santa Cruz I",
              "Santa Cruz Ii",
              "Santa Fe",
              "Santa Lucia (San Juan Ii)",
              "Santa Maria (Barangay 20)",
              "Santo Cristo (Barangay 3)",
              "Santo NiÃ±o I",
              "Santo NiÃ±o Ii",
              "Victoria Reyes",
              "Zone I (Pob.)",
              "Zone I-b",
              "Zone Ii (Pob.)",
              "Zone Iii (Pob.)",
              "Zone Iv (Pob.)"
            ]
          },
          {
            "k": "Gen. Mariano Alvarez",
            "v": [
              "Aldiano Olales",
              "Barangay 1 Poblacion (Area I)",
              "Barangay 2 Poblacion",
              "Barangay 3 Poblacion",
              "Barangay 4 Poblacion",
              "Barangay 5 Poblacion",
              "Benjamin Tirona (Area D)",
              "Bernardo Pulido (Area H)",
              "Epifanio Malia",
              "Fiorello Carimag (Area C)",
              "Francisco De Castro (Sunshine Vill.)",
              "Francisco Reyes",
              "Gavino Maderan",
              "Gregoria De Jesus",
              "Inocencio Salud",
              "Jacinto Lumbreras",
              "Kapitan Kua (Area F)",
              "Koronel Jose P. Elises (Area E)",
              "Macario Dacon",
              "Marcelino Memije",
              "Nicolasa Virata (San Jose)",
              "Pantaleon Granados (Area G)",
              "Ramon Cruz (Area J)",
              "San Gabriel (Area K)",
              "San Jose",
              "Severino De Las Alas",
              "Tiniente Tiago"
            ]
          },
          {
            "k": "General Emilio Aguinaldo",
            "v": [
              "A. Dalusag",
              "Batas Dao",
              "CastaÃ±os Cerca",
              "CastaÃ±os Lejos",
              "Kabulusan",
              "Kaypaaba",
              "Lumipa",
              "Poblacion I",
              "Poblacion Ii",
              "Poblacion Iii",
              "Poblacion Iv",
              "Tabora"
            ]
          },
          {
            "k": "General Trias",
            "v": [
              "Arnaldo Pob. (Bgy. 7)",
              "Bacao I",
              "Bacao Ii",
              "Bagumbayan Pob. (Bgy. 5)",
              "Biclatan",
              "Buenavista I",
              "Buenavista Ii",
              "Buenavista Iii",
              "Corregidor Pob. (Bgy. 10)",
              "Dulong Bayan Pob. (Bgy. 3)",
              "Gov. Ferrer Pob. (Bgy. 1)",
              "Javalera",
              "Manggahan",
              "Navarro",
              "Ninety Sixth Pob. (Bgy. 8)",
              "Panungyanan",
              "Pasong Camachile I",
              "Pasong Camachile Ii",
              "Pasong Kawayan I",
              "Pasong Kawayan Ii",
              "Pinagtipunan",
              "Prinza Pob. (Bgy. 9)",
              "Sampalucan Pob. (Bgy. 2)",
              "San Francisco",
              "San Gabriel Pob. (Bgy. 4)",
              "San Juan I",
              "San Juan Ii",
              "Santa Clara",
              "Santiago",
              "Tapia",
              "Tejero",
              "Vibora Pob. (Bgy. 6)"
            ]
          },
          {
            "k": "Imus",
            "v": [
              "Alapan I-a",
              "Alapan I-b",
              "Alapan I-c",
              "Alapan Ii-a",
              "Alapan Ii-b",
              "Anabu I-a",
              "Anabu I-b",
              "Anabu I-c",
              "Anabu I-d",
              "Anabu I-e",
              "Anabu I-f",
              "Anabu I-g",
              "Anabu Ii-a",
              "Anabu Ii-b",
              "Anabu Ii-c",
              "Anabu Ii-d",
              "Anabu Ii-e",
              "Anabu Ii-f",
              "Bagong Silang (Bahayang Pag-asa)",
              "Bayan Luma I",
              "Bayan Luma Ii",
              "Bayan Luma Iii",
              "Bayan Luma Iv",
              "Bayan Luma Ix",
              "Bayan Luma V",
              "Bayan Luma Vi",
              "Bayan Luma Vii",
              "Bayan Luma Viii",
              "Bucandala I",
              "Bucandala Ii",
              "Bucandala Iii",
              "Bucandala Iv",
              "Bucandala V",
              "Buhay Na Tubig",
              "Carsadang Bago I",
              "Carsadang Bago Ii",
              "Magdalo",
              "Maharlika",
              "Malagasang I-a",
              "Malagasang I-b",
              "Malagasang I-c",
              "Malagasang I-d",
              "Malagasang I-e",
              "Malagasang I-f",
              "Malagasang I-g",
              "Malagasang Ii-a",
              "Malagasang Ii-b",
              "Malagasang Ii-c",
              "Malagasang Ii-d",
              "Malagasang Ii-e",
              "Malagasang Ii-f",
              "Malagasang Ii-g",
              "Mariano Espeleta I",
              "Mariano Espeleta Ii",
              "Mariano Espeleta Iii",
              "Medicion I-a",
              "Medicion I-b",
              "Medicion I-c",
              "Medicion I-d",
              "Medicion Ii-a",
              "Medicion Ii-b",
              "Medicion Ii-c",
              "Medicion Ii-d",
              "Medicion Ii-e",
              "Medicion Ii-f",
              "Pag-asa I",
              "Pag-asa Ii",
              "Pag-asa Iii",
              "Palico I",
              "Palico Ii",
              "Palico Iii",
              "Palico Iv",
              "Pasong Buaya I",
              "Pasong Buaya Ii",
              "Pinagbuklod",
              "Poblacion I-a (Pob.)",
              "Poblacion I-b",
              "Poblacion I-c",
              "Poblacion Ii-a (Pob.)",
              "Poblacion Ii-b",
              "Poblacion Iii-a (Pob.)",
              "Poblacion Iii-b",
              "Poblacion Iv-a (Pob.)",
              "Poblacion Iv-b",
              "Poblacion Iv-c",
              "Poblacion Iv-d",
              "Tanzang Luma I",
              "Tanzang Luma Ii",
              "Tanzang Luma Iii",
              "Tanzang Luma Iv (Southern City)",
              "Tanzang Luma V",
              "Tanzang Luma Vi",
              "Toclong I-a",
              "Toclong I-b",
              "Toclong I-c",
              "Toclong Ii-a",
              "Toclong Ii-b"
            ]
          },
          {
            "k": "Indang",
            "v": [
              "Agus-us",
              "Alulod",
              "Banaba Cerca",
              "Banaba Lejos",
              "Bancod",
              "Barangay 1 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 4 (Pob.)",
              "Buna Cerca",
              "Buna Lejos I",
              "Buna Lejos Ii",
              "Calumpang Cerca",
              "Calumpang Lejos I",
              "Carasuchi",
              "Daine I",
              "Daine Ii",
              "Guyam Malaki",
              "Guyam Munti",
              "Harasan",
              "Kayquit I",
              "Kayquit Ii",
              "Kayquit Iii",
              "Kaytambog",
              "Kaytapos",
              "Limbon",
              "Lumampong Balagbag",
              "Lumampong Halayhay",
              "Mahabangkahoy Cerca",
              "Mahabangkahoy Lejos",
              "Mataas Na Lupa (Checkpoint)",
              "Pulo",
              "Tambo Balagbag",
              "Tambo Ilaya",
              "Tambo Kulit",
              "Tambo Malaki"
            ]
          },
          {
            "k": "Kawit",
            "v": [
              "Balsahan-bisita",
              "Batong Dalig",
              "Binakayan-aplaya",
              "Binakayan-kanluran",
              "Congbalay-legaspi",
              "Gahak",
              "Kaingen",
              "Magdalo (Putol)",
              "Manggahan-lawin",
              "Marulas",
              "Panamitan",
              "Poblacion",
              "Pulvorista",
              "Samala-marquez",
              "San Sebastian",
              "Santa Isabel",
              "Tabon I",
              "Tabon Ii",
              "Tabon Iii",
              "Toclong",
              "Tramo-bantayan",
              "Wakas I",
              "Wakas Ii"
            ]
          },
          {
            "k": "Magallanes",
            "v": [
              "Barangay 1 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 4 (Pob.)",
              "Barangay 5 (Pob.)"
            ]
          },
          {
            "k": "Maragondon",
            "v": [
              "Bucal I",
              "Bucal Ii",
              "Bucal Iii A",
              "Bucal Iii B",
              "Bucal Iv A",
              "Bucal Iv B",
              "Caingin Pob. (Barangay 3)",
              "Garita I A",
              "Garita I B",
              "Layong Mabilog",
              "Pinagsanhan I A",
              "Poblacion I A",
              "Poblacion I B",
              "Poblacion Ii A",
              "Poblacion Ii B",
              "San Miguel I A (Caputatan)",
              "San Miguel I B",
              "Talipusngo"
            ]
          },
          {
            "k": "Mendez (Mendez-nuÃ±ez)",
            "v": [
              "Anuling Cerca I",
              "Anuling Cerca Ii",
              "Anuling Lejos I (Anuling)",
              "Anuling Lejos Ii",
              "Asis I",
              "Asis Ii",
              "Asis Iii",
              "Banayad",
              "Bukal",
              "Galicia I",
              "Galicia Ii",
              "Galicia Iii",
              "Miguel Mojica",
              "Palocpoc I",
              "Palocpoc Ii",
              "Panungyan I",
              "Panungyan Ii",
              "Poblacion I (Barangay I)",
              "Poblacion Ii (Barangay Ii)",
              "Poblacion Iii (Barangay Iii)",
              "Poblacion Iv (Barangay Iv)",
              "Poblacion V (Barangay V)",
              "Poblacion Vi (Barangay Vi)",
              "Poblacion Vii (Barangay Vii)"
            ]
          },
          {
            "k": "Naic",
            "v": [
              "Bagong Karsada",
              "Balsahan",
              "Bancaan",
              "Bucana Malaki",
              "Bucana Sasahan",
              "Calubcob",
              "Capt. C. Nazareno (Pob.)",
              "Gomez-zamora (Pob.)",
              "Halang",
              "Humbac",
              "Ibayo Estacion",
              "Ibayo Silangan",
              "Kanluran",
              "Labac",
              "Latoria",
              "Mabolo",
              "Makina",
              "Malainen Bago",
              "Malainen Luma",
              "Molino",
              "Munting Mapino",
              "Muzon",
              "Palangue 1",
              "Palangue 2 And 3",
              "Sabang",
              "San Roque",
              "Santulan",
              "Sapa",
              "Timalan Balsahan",
              "Timalan Concepcion"
            ]
          },
          {
            "k": "Noveleta",
            "v": [
              "Magdiwang",
              "Poblacion",
              "Salcedo I",
              "Salcedo Ii",
              "San Antonio I",
              "San Antonio Ii",
              "San Jose I",
              "San Jose Ii",
              "San Juan I",
              "San Juan Ii",
              "San Rafael I",
              "San Rafael Ii",
              "San Rafael Iii",
              "San Rafael Iv",
              "Santa Rosa I",
              "Santa Rosa Ii"
            ]
          },
          {
            "k": "Rosario",
            "v": [
              "Bagbag I",
              "Bagbag Ii",
              "Kanluran",
              "Ligtong I",
              "Ligtong Ii",
              "Ligtong Iii",
              "Ligtong Iv",
              "Muzon I",
              "Muzon Ii",
              "Poblacion",
              "Sapa I",
              "Sapa Ii",
              "Sapa Iii",
              "Sapa Iv",
              "Silangan I",
              "Silangan Ii",
              "Tejeros Convention",
              "Wawa I",
              "Wawa Ii",
              "Wawa Iii"
            ]
          },
          {
            "k": "Silang",
            "v": [
              "Acacia",
              "Adlas",
              "Anahaw I",
              "Anahaw Ii",
              "Balite I",
              "Balite Ii",
              "Balubad",
              "Banaba",
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Barangay V (Pob.)",
              "Batas",
              "Biga I",
              "Biga Ii",
              "Biluso",
              "Bucal",
              "Buho",
              "Bulihan",
              "Cabangaan",
              "Carmen",
              "Hoyo",
              "Hukay",
              "Iba",
              "Inchican",
              "Ipil I",
              "Ipil Ii",
              "Kalubkob",
              "Kaong",
              "Lalaan I",
              "Lalaan Ii",
              "Litlit",
              "Lucsuhin",
              "Lumil",
              "Maguyam",
              "Malabag",
              "Malaking Tatyao",
              "Mataas Na Burol",
              "Munting Ilog",
              "Narra I",
              "Narra Ii",
              "Narra Iii",
              "Paligawan",
              "Pasong Langka",
              "Pooc I",
              "Pooc Ii",
              "Pulong Bunga",
              "Pulong Saging",
              "Puting Kahoy",
              "Sabutan",
              "San Miguel I",
              "San Miguel Ii",
              "San Vicente I",
              "Santol",
              "Silang",
              "Tartaria",
              "Tibig",
              "Toledo",
              "Tubuan I",
              "Tubuan Ii",
              "Tubuan Iii",
              "Ulat",
              "Yakal"
            ]
          },
          {
            "k": "Tagaytay",
            "v": [
              "Asisan",
              "Bagong Tubig",
              "Francisco (San Francisco)",
              "Guinhawa North",
              "Guinhawa South",
              "Iruhin East",
              "Iruhin South",
              "Iruhin West",
              "Kaybagal East",
              "Kaybagal North",
              "Kaybagal South (Pob.)",
              "Mag-asawang Ilat",
              "Maharlika East",
              "Maharlika West",
              "Maitim 2nd Central",
              "Maitim 2nd East",
              "Maitim 2nd West",
              "Mendez Crossing East",
              "Mendez Crossing West",
              "Neogan",
              "Patutong Malaki North",
              "Patutong Malaki South",
              "Sambong",
              "San Jose",
              "Silang Junction North",
              "Silang Junction South",
              "Sungay North",
              "Sungay South",
              "Tolentino East",
              "Tolentino West",
              "Zambal"
            ]
          },
          {
            "k": "Tanza",
            "v": [
              "Amaya I",
              "Amaya Ii",
              "Amaya Iii",
              "Amaya Iv",
              "Amaya V",
              "Amaya Vi",
              "Amaya Vii",
              "Bagtas",
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Biga",
              "Biwas",
              "Bucal",
              "Bunga",
              "Calibuyo",
              "Capipisa",
              "Daang Amaya I",
              "Daang Amaya Ii",
              "Daang Amaya Iii",
              "Halayhay",
              "Julugan I",
              "Julugan Ii",
              "Julugan Iii",
              "Julugan Iv",
              "Julugan V",
              "Julugan Vi",
              "Julugan Vii",
              "Julugan Viii",
              "Lambingan",
              "Mulawin",
              "Paradahan I",
              "Paradahan Ii",
              "Punta I",
              "Punta Ii",
              "Sahud Ulan",
              "Sanja Mayor",
              "Santol",
              "Tanauan",
              "Tres Cruses"
            ]
          },
          {
            "k": "Ternate",
            "v": [
              "Bucana",
              "Poblacion I (Barangay I)",
              "Poblacion I A",
              "Poblacion Ii (Barangay Ii)",
              "Poblacion Iii (Barangay Iii)",
              "San Jose",
              "San Juan I",
              "San Juan Ii",
              "Sapang I",
              "Sapang Ii"
            ]
          },
          {
            "k": "Trece Martires",
            "v": [
              "Aguado (Piscal Mundo)",
              "Cabezas",
              "Cabuco",
              "Conchu (Lagundian)",
              "De Ocampo",
              "Gregorio (Aliang)",
              "Inocencio (B. Pook)",
              "Lallana",
              "Lapidario (Bayog)",
              "Luciano (Bitangan)",
              "Osorio",
              "Perez (Lucbanan)",
              "San Agustin (Pob.)"
            ]
          }
        ],
        "names": [
          "Alfonso",
          "Amadeo",
          "Bacoor",
          "Carmona",
          "Cavite",
          "DasmariÃ±as",
          "Gen. Mariano Alvarez",
          "General Emilio Aguinaldo",
          "General Trias",
          "Imus",
          "Indang",
          "Kawit",
          "Magallanes",
          "Maragondon",
          "Mendez (Mendez-nuÃ±ez)",
          "Naic",
          "Noveleta",
          "Rosario",
          "Silang",
          "Tagaytay",
          "Tanza",
          "Ternate",
          "Trece Martires"
        ]
      }
    },
    {
      "k": "Cebu",
      "v": {
        "lookup": [
          { "k": "Alcantara", "v": ["Palanas", "Poblacion"] },
          {
            "k": "Alegria",
            "v": [
              "Compostela",
              "Guadalupe",
              "Legaspi",
              "Lepanto",
              "Madridejos",
              "Montpeller",
              "Poblacion",
              "Santa Filomena",
              "Valencia"
            ]
          },
          {
            "k": "Argao",
            "v": [
              "Alambijud",
              "Anajao",
              "Apo",
              "Balaas",
              "Balisong",
              "Binlod",
              "Bogo",
              "Bug-ot",
              "Bulasa",
              "Butong",
              "Calagasan",
              "Canbantug",
              "Canbanua",
              "Cansuje",
              "Capio-an",
              "Casay",
              "Catang",
              "Colawin",
              "Conalum",
              "Guiwanon",
              "Gutlang",
              "Jampang",
              "Jomgao",
              "Lamacan",
              "Langtad",
              "Langub",
              "Lapay",
              "Lengigon",
              "Mabasa",
              "Mandilikit",
              "Mompeller",
              "Panadtaran",
              "Poblacion",
              "Sua",
              "Sumaguan",
              "Tabayag",
              "Talaga",
              "Talaytay",
              "Talo-ot",
              "Tiguib",
              "Tulang",
              "Tulic",
              "Ubaub",
              "Usmad"
            ]
          },
          {
            "k": "Asturias",
            "v": [
              "Bago",
              "Langub",
              "Lunas",
              "New Bago",
              "Owak",
              "Poblacion",
              "San Roque",
              "Santa Lucia",
              "Tagbubonga"
            ]
          },
          {
            "k": "Badian",
            "v": [
              "Balhaan",
              "Banhigan",
              "Basak",
              "Bugas",
              "Lambug",
              "Malhiao",
              "Manduyong",
              "Poblacion",
              "Taytay",
              "Zaragosa"
            ]
          },
          {
            "k": "Balamban",
            "v": [
              "Abucayan",
              "Aliwanay",
              "Arpili",
              "Baliwagan (Pob.)",
              "Buanoy",
              "Cambuhawe",
              "Cantuod",
              "Nangka",
              "Pondol",
              "Prenza",
              "Santa Cruz-santo Nindno (Pob.)"
            ]
          },
          {
            "k": "Bantayan",
            "v": [
              "Atop-atop",
              "Baigad",
              "Bantigue (Pob.)",
              "Baod",
              "Guiwanon",
              "Kabac",
              "Kabangbang",
              "Kampingganon",
              "Kangkaibe",
              "Mojon",
              "Obo-ob",
              "Patao",
              "Putian",
              "Sillon",
              "Suba (Pob.)",
              "Sulangan",
              "Sungko",
              "Tamiao",
              "Ticad (Pob.)"
            ]
          },
          {
            "k": "Barili",
            "v": [
              "Azucena",
              "Bagakay",
              "Balao",
              "Bolocboloc",
              "Cabcaban",
              "Cagay",
              "Campangga",
              "Candugay",
              "Dakit",
              "Giloctog",
              "Giwanon",
              "Guibuangan",
              "Gunting",
              "Japitan",
              "Kalubihan",
              "Maghanoy",
              "Maigang",
              "Malolos",
              "Mantalongon",
              "Mantayupan",
              "Minolos",
              "Paril",
              "Patupat",
              "Poblacion",
              "San Rafael",
              "Santa Ana",
              "Sayaw",
              "Tal-ot",
              "Tubod",
              "Vito"
            ]
          },
          {
            "k": "Bogo City",
            "v": [
              "Anonang Norte",
              "Anonang Sur",
              "Banban",
              "Binabag",
              "Bungtod (Pob.)",
              "Carbon (Pob.)",
              "Cayang",
              "Cogon (Pob.)",
              "Dakit",
              "Don Pedro Rodriguez",
              "Gairan",
              "Guadalupe",
              "La Paz",
              "La Purisima Concepcion (Pob.)",
              "Libertad",
              "Lourdes (Pob.)",
              "Malingin",
              "Marangog",
              "Nailon",
              "Odlot",
              "Pandan (Pandan Heights)",
              "Polambato",
              "Sambag (Pob.)",
              "San Vicente (Pob.)",
              "Santo Nindno",
              "Santo Rosario (Pob.)",
              "Siocon",
              "Sudlonon",
              "Taytayan"
            ]
          },
          {
            "k": "Boljoon",
            "v": ["El Pardo", "Poblacion", "San Antonio", "Upper Becerril"]
          },
          {
            "k": "Borbon",
            "v": [
              "Bingay",
              "Cadaruhan",
              "Campusong",
              "Clavera",
              "Don Gregorio Antigua (Taytayan)",
              "Laaw",
              "Lugo",
              "Poblacion",
              "Sagay",
              "San Jose",
              "Tabunan",
              "Tagnucan"
            ]
          },
          {
            "k": "Carcar City",
            "v": [
              "Bolinawan",
              "Guadalupe",
              "Liburon",
              "Napo",
              "Ocana",
              "Perrelos",
              "Poblacion I",
              "Poblacion Ii",
              "Poblacion Iii",
              "Tuyom",
              "Valladolid"
            ]
          },
          {
            "k": "Carmen",
            "v": [
              "Baring",
              "Cantipay",
              "Cantumog",
              "Cogon East",
              "Cogon West",
              "Dawis Norte",
              "Dawis Sur",
              "Ipil",
              "Luyang",
              "Poblacion",
              "Puente",
              "Triumfo"
            ]
          },
          {
            "k": "Catmon",
            "v": [
              "Binongkalan",
              "Catmondaan",
              "Corazon (Pob.)",
              "Flores (Pob.)",
              "Macaas",
              "Panalipan",
              "San Jose Pob. (Catadman)"
            ]
          },
          {
            "k": "Cebu City",
            "v": [
              "Apas",
              "Babag",
              "Bacayan",
              "Banilad",
              "Basak Pardo",
              "Basak San Nicolas",
              "Binaliw",
              "Brgy Bario Luz",
              "Buhisan",
              "Bulacao",
              "Busay (Pob.)",
              "Calamba",
              "Camputhaw (Pob.)",
              "Capitol Site (Pob.)",
              "Carreta",
              "Central (Pob.)",
              "Cogon Pardo",
              "Cogon Ramos (Pob.)",
              "Day-as",
              "Duljo (Pob.)",
              "Ermita (Pob.)",
              "Guadalupe",
              "Hippodromo",
              "Inayawan",
              "Kalubihan (Pob.)",
              "Kalunasan",
              "Kamagayan (Pob.)",
              "Kasambagan",
              "Kinasang-an Pardo",
              "Labangon",
              "Lahug (Pob.)",
              "Lorega (Lorega San Miguel)",
              "Lusaran",
              "Mabini",
              "Mabolo",
              "Malubog",
              "Mambaling",
              "Pahina Central (Pob.)",
              "Pahina San Nicolas",
              "Pamutan",
              "Pardo (Pob.)",
              "Pari-an",
              "Pasil",
              "Pit-os",
              "Pulangbato",
              "Punta Princesa",
              "Quiot Pardo",
              "Sambag I (Pob.)",
              "Sambag Ii (Pob.)",
              "San Antonio (Pob.)",
              "San Jose",
              "San Nicolas Central",
              "San Roque (Ciudad)",
              "Santa Cruz (Pob.)",
              "Sapangdaku",
              "Sawang Calero (Pob.)",
              "Suba Pob. (Suba San Nicolas)",
              "T. Padilla",
              "Talamban",
              "Tejero (Villa Gonzalo)",
              "Tinago",
              "Tisa",
              "To-ong Pardo",
              "Zapatera"
            ]
          },
          {
            "k": "Compostela",
            "v": [
              "Buluang",
              "Cabadiangan",
              "Cambayog",
              "Canamucan",
              "Cogon",
              "Estaca",
              "Magay",
              "Poblacion",
              "Tamiao",
              "Tubigan"
            ]
          },
          {
            "k": "Consolacion",
            "v": [
              "Cansaga",
              "Casili",
              "Danglag",
              "Garing",
              "Jugan",
              "Lamac",
              "Nangka",
              "Panas",
              "Pitogo",
              "Poblacion Occidental",
              "Poblacion Oriental",
              "Pulpogan Purok 1 & 2",
              "Pulpogan Purok 3 & 4",
              "Tayud",
              "Tilhaong",
              "Tolotolo",
              "Tugbongan"
            ]
          },
          {
            "k": "Cordova",
            "v": [
              "Alegria",
              "Bangbang",
              "Buagsong",
              "Catarman",
              "Cogon",
              "Dapitan",
              "Day-as",
              "Gabi",
              "Ibabao",
              "Pilipog",
              "Poblacion",
              "San Miguel"
            ]
          },
          {
            "k": "Daanbantayan",
            "v": [
              "Aguho",
              "Bagay",
              "Bakhawan",
              "Bateria",
              "Bitoon",
              "Calape",
              "Lanao",
              "Malbago",
              "Malingin",
              "Maya",
              "Pajo",
              "Paypay",
              "Poblacion",
              "Talisay",
              "Tinubdan",
              "Tominjao"
            ]
          },
          {
            "k": "Dalaguete",
            "v": [
              "Balud",
              "Banhigan",
              "Caleriohan",
              "Caliongan",
              "Casay",
              "Catolohan",
              "Cawayan",
              "Consolacion",
              "Coro",
              "Dumalan",
              "Jolomaynon",
              "Lanao",
              "Langkas",
              "Malones",
              "Mananggal",
              "Manlapay",
              "Mantalongon",
              "Nalhub",
              "Obo",
              "Obong",
              "Poblacion",
              "Sacsac",
              "Tabon",
              "Tapun",
              "Tuba"
            ]
          },
          {
            "k": "Danao City",
            "v": [
              "Baliang",
              "Binaliw",
              "Cabungahan",
              "Cagat-lamac",
              "Cambanay",
              "Cogon-cruz",
              "Dunggoan",
              "Guinacot",
              "Guinsay",
              "Looc",
              "Manlayag",
              "Maslog",
              "Poblacion",
              "Quisol",
              "Sabang",
              "Sandayong Norte",
              "Sandayong Sur",
              "Santa Rosa",
              "Suba",
              "Taboc",
              "Taytay",
              "Tuburan Sur"
            ]
          },
          {
            "k": "Dumanjug",
            "v": [
              "Balaygtiki",
              "Bitoon",
              "Bulak",
              "Calaboon",
              "Camboang",
              "Candabong",
              "Cogon",
              "Cotcoton",
              "Ilaya (Pob.)",
              "Kanghumaod",
              "Kanyuko",
              "Lawaan",
              "Liong",
              "Paculob",
              "Panlaan",
              "Poblacion Central",
              "Poblacion Looc",
              "Poblacion Sima",
              "Tangil",
              "Tapon",
              "Tubod-bitoon",
              "Tubod-dugoan"
            ]
          },
          {
            "k": "Ginatilan",
            "v": [
              "Cagsing",
              "Guiwanon",
              "Mangaco",
              "Palanas",
              "Poblacion",
              "San Roque"
            ]
          },
          {
            "k": "Lapu-lapu City (Opon)",
            "v": [
              "Agus",
              "Babag",
              "Bankal",
              "Basak",
              "Buaya",
              "Calawisan",
              "Canjulao",
              "Gun-ob",
              "Ibo",
              "Looc",
              "Mactan",
              "Maribago",
              "Marigondon",
              "Pajac",
              "Pajo",
              "Poblacion",
              "Punta Engandno",
              "Pusok",
              "Subabasbas"
            ]
          },
          {
            "k": "Liloan",
            "v": [
              "Cabadiangan",
              "Calero",
              "Catarman",
              "Cotcot",
              "Jubay",
              "Poblacion",
              "San Roque",
              "San Vicente",
              "Tabla",
              "Tayud",
              "Yati"
            ]
          },
          {
            "k": "Madridejos",
            "v": [
              "Bunakan",
              "Kangwayan",
              "Kaongkod",
              "Kodia",
              "Maalat",
              "Malbago",
              "Mancilang",
              "Pili",
              "Poblacion",
              "San Agustin",
              "Tabagak",
              "Talangnan",
              "Tarong",
              "Tugas"
            ]
          },
          {
            "k": "Malabuyoc",
            "v": [
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Cerdendna (Ansan)",
              "Labrador (Bulod)",
              "Lombo",
              "Looc",
              "Montandneza (Inamlang)",
              "Santo Nino",
              "Sorsogon (Balimaya)",
              "Tolosa (Calatagan)"
            ]
          },
          {
            "k": "Mandaue City",
            "v": [
              "Alang-alang",
              "Bakilid",
              "Banilad",
              "Basak",
              "Cabancalan",
              "Cambaro",
              "Canduman",
              "Casili",
              "Casuntingan",
              "Centro (Pob.)",
              "Cubacub",
              "Guizo",
              "Ibabao-estancia",
              "Jagobiao",
              "Labogon",
              "Looc",
              "Maguikay",
              "Mantuyong",
              "Opao",
              "Pagsabungan",
              "Pakna-an",
              "Subangdaku",
              "Tabok",
              "Tawason",
              "Tingub",
              "Tipolo",
              "Umapad"
            ]
          },
          {
            "k": "Medellin",
            "v": [
              "Canhabagat",
              "Curva",
              "Daanlungsod",
              "Dayhagon",
              "Don Virgilio Gonzales",
              "Kawit",
              "Lamintak Norte",
              "Lamintak Sur",
              "Luy-a",
              "Mahawak",
              "Panugnawan",
              "Poblacion",
              "Tindog"
            ]
          },
          {
            "k": "Minglanilla",
            "v": [
              "Cadulawan",
              "Calajo-an",
              "Linao",
              "Pakigne",
              "Poblacion Ward I",
              "Poblacion Ward Ii",
              "Poblacion Ward Iii",
              "Poblacion Ward Iv",
              "Tubod",
              "Tulay",
              "Tunghaan",
              "Tungkil",
              "Tungkop",
              "Vito"
            ]
          },
          {
            "k": "Moalboal",
            "v": [
              "Balabagon",
              "Basdiot",
              "Batadbatad",
              "Bugho",
              "Busay",
              "Lanao",
              "Poblacion East",
              "Poblacion West",
              "Saavedra",
              "Tomonoy",
              "Tuble",
              "Tunga"
            ]
          },
          {
            "k": "Naga City",
            "v": [
              "Balirong",
              "Cantao-an",
              "Central Poblacion",
              "Colon",
              "East Poblacion",
              "Inayagan",
              "Inoburan",
              "Langtad",
              "Lutac",
              "Mainit Lower",
              "Naalad",
              "North Poblacion",
              "Pangdan",
              "South Poblacion",
              "Tangke",
              "Tinaan",
              "Tuyan",
              "Uling",
              "West Poblacion"
            ]
          },
          {
            "k": "Oslob",
            "v": [
              "Alo",
              "Bonbon",
              "Daanlungsod",
              "Gawi",
              "Lagunde",
              "Mainit",
              "Pungtod",
              "Tan-awan"
            ]
          },
          {
            "k": "Pinamungahan",
            "v": [
              "Cabiangon",
              "Mangoto",
              "Pandacan",
              "Poblacion",
              "Tajao",
              "Tutay"
            ]
          },
          {
            "k": "Ronda",
            "v": [
              "Butong",
              "Canduling",
              "Cansalonoy",
              "Cansayahon",
              "Ilaya",
              "Langin",
              "Libo-o",
              "Palanas",
              "Poblacion",
              "Santa Cruz"
            ]
          },
          {
            "k": "Samboan",
            "v": [
              "Calatagan",
              "Canorong",
              "Dalahikan",
              "Jumangpas",
              "Poblacion",
              "San Sebastian",
              "Suba",
              "Tangbo"
            ]
          },
          {
            "k": "San Fernando",
            "v": [
              "Balud",
              "Panadtaran",
              "Pitalo",
              "Poblacion North",
              "Poblacion South",
              "San Isidro",
              "Sangat"
            ]
          },
          {
            "k": "San Remigio",
            "v": [
              "Anapog",
              "Argawanon",
              "Bancasan",
              "Batad",
              "Busogon",
              "Calambua",
              "Dapdap",
              "Hagnaya",
              "Kayam",
              "Kinawahan",
              "Lambusan",
              "Lawis",
              "Looc",
              "Luyang",
              "Mano",
              "Poblacion",
              "Punta",
              "San Miguel",
              "Tacup",
              "Tambongon",
              "To-ong",
              "Victoria"
            ]
          },
          {
            "k": "Sibonga",
            "v": [
              "Abugon",
              "Bagacay",
              "Bahay",
              "Candaguit",
              "Poblacion",
              "Sabang",
              "Simala"
            ]
          },
          {
            "k": "Sogod",
            "v": ["Bagatayam", "Bawo", "Damolog", "Ibabao", "Liki", "Poblacion"]
          },
          {
            "k": "Tabogon",
            "v": [
              "Alang-alang",
              "Caduawan",
              "Daantabogon",
              "Ilihan",
              "Kal-anan",
              "Libjo",
              "Manlagtang",
              "Maslog",
              "Muabog",
              "Pio",
              "Poblacion",
              "Somosa",
              "Tapul"
            ]
          },
          {
            "k": "Talisay City",
            "v": [
              "Biasong",
              "Bulacao",
              "Cadulawan",
              "Cansojong",
              "Dumlog",
              "Lagtang",
              "Lawaan I",
              "Lawaan Ii",
              "Lawaan Iii",
              "Linao",
              "Maghaway",
              "Mohon",
              "Poblacion",
              "Pooc",
              "San Isidro",
              "San Roque",
              "Tabunoc",
              "Tangke"
            ]
          },
          {
            "k": "Toledo City",
            "v": [
              "Bato",
              "Cabitoonan",
              "Calongcalong",
              "Cantabaco",
              "Carmen",
              "Daanglungsod",
              "Don Andres Soriano (Lutopan)",
              "Dumlog",
              "Ilihan",
              "Juan Climaco, Sr. (Magdugo)",
              "Luray Ii",
              "Matab-ang",
              "Media Once",
              "Poblacion",
              "Poog",
              "Sangi",
              "Talavera",
              "Tubod"
            ]
          }
        ],
        "names": [
          "Alcantara",
          "Alegria",
          "Argao",
          "Asturias",
          "Badian",
          "Balamban",
          "Bantayan",
          "Barili",
          "Bogo City",
          "Boljoon",
          "Borbon",
          "Carcar City",
          "Carmen",
          "Catmon",
          "Cebu City",
          "Compostela",
          "Consolacion",
          "Cordova",
          "Daanbantayan",
          "Dalaguete",
          "Danao City",
          "Dumanjug",
          "Ginatilan",
          "Lapu-lapu City (Opon)",
          "Liloan",
          "Madridejos",
          "Malabuyoc",
          "Mandaue City",
          "Medellin",
          "Minglanilla",
          "Moalboal",
          "Naga City",
          "Oslob",
          "Pinamungahan",
          "Ronda",
          "Samboan",
          "San Fernando",
          "San Remigio",
          "Sibonga",
          "Sogod",
          "Tabogon",
          "Talisay City",
          "Toledo City"
        ]
      }
    },
    {
      "k": "Cotabato",
      "v": {
        "lookup": [
          { "k": "Aleosan", "v": ["Dualing", "Pagangan", "San Mateo"] },
          { "k": "Amas", "v": ["Poblacion"] },
          {
            "k": "Antipas",
            "v": [
              "B. Cadungon",
              "Camutan",
              "Canaan",
              "Datu Agod",
              "Dolores",
              "Kiyaab",
              "Luhong",
              "Magsaysay",
              "Malangag",
              "Malatad",
              "Malire",
              "New Pontevedra",
              "Poblacion"
            ]
          },
          {
            "k": "Arakan",
            "v": [
              "Allab",
              "Anapolon",
              "Badiangon",
              "Binoongan",
              "Dallag",
              "Datu Ladayon",
              "Datu Matangkil",
              "Doroluman",
              "Gambodes",
              "Ganatan",
              "Greenfield",
              "Kabalantian",
              "Katipunan",
              "Kinawayan",
              "Kulaman Valley",
              "Lanao Kuran",
              "Libertad",
              "Makalangot",
              "Malibatuan",
              "Maria Caridad",
              "Meocan",
              "Naje",
              "Napalico",
              "Salasang",
              "San Miguel",
              "Santo NiÃ±o",
              "Sumalili",
              "Tumanding"
            ]
          },
          {
            "k": "Carmen",
            "v": [
              "Aroman",
              "Bentangan",
              "Cadiis",
              "General Luna",
              "Katanayanan",
              "Kib-ayao",
              "Kibenes",
              "Kibugtongan",
              "Kilala",
              "Kimadzil",
              "Kitulaan",
              "Langogan",
              "Lanoon",
              "Liliongan",
              "Macabenban",
              "Malapag",
              "Manarapan",
              "Manili",
              "Nasapian",
              "Palanggalan",
              "Pebpoloan",
              "Poblacion",
              "Ranzo",
              "Tacupan",
              "Tambad",
              "Tonganon",
              "Tupig",
              "Ugalingan (Lumayong)"
            ]
          },
          {
            "k": "City Of Kidapawan",
            "v": [
              "Amas",
              "Amazion",
              "Balabag",
              "Balindog",
              "Benoligan",
              "Berada",
              "Gayola",
              "Ginatilan",
              "Ilomavis",
              "Indangan",
              "Junction",
              "Kalaisan",
              "Kalasuyan",
              "Katipunan",
              "Lanao",
              "Linangcob",
              "Luvimin",
              "Macabolig",
              "Magsaysay",
              "Malinan",
              "Manongol",
              "Marbel (Embac)",
              "Mateo",
              "Meochao",
              "Mua-an",
              "New Bohol",
              "Nuangan",
              "Onica",
              "Paco",
              "Patadon (Patadon East)",
              "Perez",
              "Poblacion",
              "San Isidro",
              "San Roque",
              "Santo NiÃ±o",
              "Sibawan",
              "Sikitan",
              "Singao",
              "Sudapin",
              "Sumbao"
            ]
          },
          {
            "k": "Kabacan",
            "v": [
              "Aringay",
              "Bangilan",
              "Bannawag",
              "Buluan",
              "Cuyapon",
              "Dagupan",
              "Katidtuan",
              "Kayaga",
              "Kilagasan",
              "Magatos",
              "Malamote",
              "Malanduague",
              "Nanga-an",
              "Osias",
              "Paatan Lower",
              "Paatan Upper",
              "Pedtad",
              "Pisan",
              "Poblacion",
              "Salapungan",
              "Sanggadong",
              "Simbuhay",
              "Simone",
              "Tamped"
            ]
          },
          {
            "k": "Libungan",
            "v": ["Abaga", "Baguer", "Batiocan", "Poblacion", "Sinawingan"]
          },
          {
            "k": "Magpet",
            "v": [
              "Alibayon",
              "Amabel",
              "Bagumbayan",
              "Balete",
              "Bangkal",
              "Bantac",
              "Basak",
              "Binay",
              "Bongolanon",
              "Datu Celo",
              "Del Pilar",
              "Doles",
              "Don Panaca",
              "Gubatan",
              "Ilian",
              "Imamaling",
              "Inac",
              "Kamada",
              "Kauswagan",
              "Kinarum",
              "Kisandal",
              "Magcaalam",
              "Mahongcog",
              "Manobisa",
              "Manobo",
              "Noa",
              "Owas",
              "Pangao-an",
              "Poblacion",
              "Sallab",
              "Tagbac",
              "Temporan"
            ]
          },
          {
            "k": "Makilala",
            "v": [
              "Batasan",
              "Bato",
              "Biangan",
              "Buena Vida",
              "Buhay",
              "Bulacanon",
              "Bulakanon",
              "Cabilao",
              "Concepcion",
              "Dagupan",
              "Garsika",
              "Guangan",
              "Indangan",
              "Jose Rizal",
              "Katipunan Ii",
              "Kawayanon",
              "Kisante",
              "Leboce",
              "Libertad",
              "Luayon",
              "Luna Norte",
              "Luna Sur",
              "Malabuan",
              "Malasila",
              "Malungon",
              "New Baguio",
              "New Bulatukan",
              "New Cebu",
              "New Israel",
              "Old Bulatukan",
              "Poblacion",
              "Rodero",
              "Saguing",
              "San Vicente",
              "Santa Felomina",
              "Santo NiÃ±o",
              "Sinkatulan",
              "Taluntalunan",
              "Villaflores"
            ]
          },
          {
            "k": "Matalam",
            "v": [
              "Arakan",
              "Bangbang",
              "Bato",
              "Central Malamote",
              "Dalapitan",
              "Estado",
              "Ilian",
              "Kabulacan",
              "Kibia",
              "Kibudoc",
              "Kidama",
              "Kilada",
              "Lampayan",
              "Latagan",
              "Linao",
              "Lower Malamote",
              "Manubuan",
              "Manupal",
              "Marbel",
              "Minamaing",
              "Natutungan",
              "New Abra",
              "New Alimodian",
              "New Bugasong",
              "New Pandan",
              "Patadon West",
              "Pinamaton",
              "Poblacion",
              "Salvacion",
              "Santa Maria",
              "Sarayan",
              "Taculen",
              "Taguranao",
              "Tamped (Tampad)"
            ]
          },
          {
            "k": "Midsayap",
            "v": [
              "Agriculture",
              "Anonang",
              "Arizona",
              "Bagumba",
              "Baliki",
              "Barangay Poblacion 1",
              "Barangay Poblacion 2",
              "Barangay Poblacion 3",
              "Barangay Poblacion 4",
              "Barangay Poblacion 5",
              "Barangay Poblacion 6",
              "Barangay Poblacion 7",
              "Barangay Poblacion 8",
              "Bitoka",
              "Bual Norte",
              "Bual Sur",
              "Bulanan Upper",
              "Central Bulanan",
              "Central Glad",
              "Central Katingawan",
              "Central Labas",
              "Damatulan",
              "Ilbocean",
              "Kadigasan",
              "Kadingilan",
              "Kapinpilan",
              "Kimagango",
              "Kiwanan",
              "Kudarangan",
              "Lagumbingan",
              "Lomopog",
              "Lower Glad",
              "Lower Katingawan",
              "Macasendeg",
              "Malamote",
              "Malingao",
              "Milaya",
              "Mudseng",
              "Nabalawag",
              "Nalin",
              "Nes",
              "Olandang",
              "Palongoguen",
              "Patindeguen",
              "Rangaban",
              "Sadaan",
              "Salunayan",
              "Sambulawan",
              "San Isidro",
              "San Pedro",
              "Santa Cruz",
              "Tugal",
              "Tumbras",
              "Upper Glad I",
              "Upper Glad Ii",
              "Upper Labas",
              "Villarica"
            ]
          },
          {
            "k": "Mlang",
            "v": [
              "Bagontapay",
              "Bialong",
              "Buayan",
              "Calunasan",
              "Dagong",
              "Dalipe",
              "Dungo-an",
              "Gaunan",
              "Inas",
              "Katipunan",
              "La Fortuna",
              "La Suerte",
              "Langkong",
              "Lepaga",
              "Liboo",
              "Lika",
              "Luz Village",
              "Magallon",
              "Malayan",
              "New Antique",
              "New Barbaza",
              "New Consolacion",
              "New Esperanza",
              "New Janiuay",
              "New Kalibo",
              "New Lawa-an",
              "New Rizal",
              "Nueva Vida",
              "Pag-asa",
              "Palma-perez",
              "Poblacion",
              "Poblacion B",
              "Pulang-lupa",
              "Sangat",
              "Tawantawan",
              "Tibao",
              "Ugpay"
            ]
          },
          {
            "k": "Pigcawayan",
            "v": [
              "Balogo",
              "Capayuran",
              "North Manuangan",
              "Poblacion I",
              "Poblacion Ii",
              "Poblacion Iii",
              "Presbitero",
              "South Manuangan",
              "Tubon",
              "Upper Baguer (Baguer)"
            ]
          },
          {
            "k": "President Roxas",
            "v": [
              "Alegria",
              "Bato-bato",
              "Cabangbangan",
              "Camasi",
              "Datu Indang",
              "Datu Sandongan",
              "Del Carmen",
              "F. Cajelo (New Maasin)",
              "Greenhill",
              "Idaoman",
              "Ilustre",
              "Kamarahan",
              "Kimaruhing",
              "Kisupaan",
              "La Esperanza",
              "Labu-o",
              "Lamalama",
              "Lomonay",
              "Mabuhay",
              "New Cebu",
              "Poblacion",
              "Sagcungan",
              "Salat",
              "Sarayan",
              "Tuael"
            ]
          },
          {
            "k": "Tulunan",
            "v": [
              "Bacong",
              "Bagumbayan",
              "Banayal",
              "Batang",
              "Bituan",
              "Bual",
              "Bunawan",
              "Daig",
              "Damawato",
              "Dungos",
              "F. Cajelo",
              "Galidan",
              "Genoveva Baynosa",
              "Kanibong",
              "La Esperanza",
              "Lampagang",
              "Magbok",
              "Maybula",
              "Minapan",
              "Nabundasan",
              "New Caridad",
              "New Culasi",
              "New Panay",
              "Paraiso",
              "Poblacion",
              "Popoyon",
              "Sibsib",
              "Tambac",
              "Tuburan"
            ]
          }
        ],
        "names": [
          "Aleosan",
          "Amas",
          "Antipas",
          "Arakan",
          "Carmen",
          "City Of Kidapawan",
          "Kabacan",
          "Libungan",
          "Magpet",
          "Makilala",
          "Matalam",
          "Midsayap",
          "Mlang",
          "Pigcawayan",
          "President Roxas",
          "Tulunan"
        ]
      }
    },
    {
      "k": "Davao De Oro",
      "v": {
        "lookup": [
          {
            "k": "Compostela",
            "v": [
              "Aurora",
              "Bagongon",
              "Gabi",
              "Lagab",
              "Mangayon",
              "Mapaca",
              "Maparat",
              "New Alegria",
              "Ngan",
              "OsmeÃ±a",
              "Panansalan",
              "Poblacion",
              "San Jose",
              "San Miguel",
              "Siocon",
              "Tamia"
            ]
          },
          {
            "k": "Laak (San Vicente)",
            "v": [
              "Banbanon",
              "Kaligutan",
              "Kapatagan",
              "Kilagding",
              "Laac (Pob.)"
            ]
          },
          {
            "k": "Mabini (DoÃ±a Alicia)",
            "v": [
              "Anitapan",
              "Cabuyuan",
              "Cadunan",
              "Cuambog (Pob.)",
              "Del Pilar",
              "Golden Valley (Maraut)",
              "Libodon",
              "Pangibiran",
              "Pindasan",
              "San Antonio",
              "Tagnanan (Mampising)"
            ]
          },
          {
            "k": "Maco",
            "v": [
              "Anibongan",
              "Anislagan",
              "Binuangan",
              "Bucana",
              "Calabcab",
              "Concepcion",
              "Dumlan",
              "Elizalde (Somil)",
              "Gubatan",
              "Hijo",
              "Kinuban",
              "Langgam",
              "Lapu-lapu",
              "Libay-libay",
              "Limbo",
              "Lumatab",
              "Magangit",
              "Mainit",
              "Malamodao",
              "Manipongol",
              "Mapaang",
              "Masara",
              "New Asturias",
              "New Barili",
              "New Leyte",
              "New Visayas",
              "Panangan",
              "Pangi (Gaudencio Antonio)",
              "Panibasan",
              "Panoraon",
              "Poblacion",
              "San Juan",
              "San Roque",
              "Sangab",
              "Tagbaros",
              "Taglawig",
              "Teresa"
            ]
          },
          {
            "k": "Maragusan (San Mariano)",
            "v": [
              "Bagong Silang",
              "Bahi",
              "Cambagang",
              "Coronobe",
              "Katipunan",
              "Lahi",
              "Langgawisan",
              "Mabugnao",
              "Magcagong",
              "Mahayahay",
              "Mapawa",
              "Maragusan (Pob.)",
              "Mauswagon",
              "New Albay",
              "New Katipunan",
              "New Man-ay",
              "New Panay",
              "Paloc",
              "Pamintaran",
              "Parasanon",
              "Talian",
              "Tandik",
              "Tigbao",
              "Tupaz"
            ]
          },
          {
            "k": "Mawab",
            "v": [
              "Andili",
              "Bawani",
              "Concepcion",
              "Malinawon",
              "Nueva Visayas",
              "Nuevo Iloco",
              "Poblacion",
              "Salvacion",
              "Saosao",
              "Sawangan",
              "Tuboran"
            ]
          },
          {
            "k": "Monkayo",
            "v": [
              "Awao",
              "Babag",
              "Banlag",
              "Baylo",
              "Casoon",
              "Haguimitan",
              "Inambatan",
              "Macopa",
              "Mamunga",
              "Mount Diwata",
              "Naboc",
              "Olaycon",
              "Pasian (Santa Filomena)",
              "Poblacion",
              "Rizal",
              "Salvacion",
              "San Isidro",
              "San Jose",
              "Tubo-tubo (New Del Monte)",
              "Union",
              "Upper Ulip"
            ]
          },
          {
            "k": "Montevista",
            "v": [
              "Banagbanag",
              "Banglasan",
              "Bankerohan Norte",
              "Bankerohan Sur",
              "Camansi",
              "Camantangan",
              "Canidkid",
              "Concepcion",
              "Dauman",
              "Lebanon",
              "Linoan",
              "Mayaon",
              "New Calape",
              "New Cebulan (Sambayon)",
              "New Dalaguete",
              "New Visayas",
              "Prosperidad",
              "San Jose (Pob.)",
              "San Vicente",
              "Tapia"
            ]
          },
          {
            "k": "Nabunturan",
            "v": [
              "Anislagan",
              "Antiquera",
              "Basak",
              "Bayabas",
              "Bukal",
              "Cabacungan",
              "Cabidianan",
              "Katipunan",
              "Libasan",
              "Linda",
              "Magading",
              "Magsaysay",
              "Mainit",
              "Manat",
              "Matilo",
              "Mipangi",
              "New Dauis",
              "New Sibonga",
              "Ogao",
              "Pangutosan",
              "Poblacion",
              "San Isidro",
              "San Roque",
              "San Vicente",
              "Santa Maria",
              "Santo NiÃ±o (Kao)",
              "Sasa",
              "Tagnocon"
            ]
          },
          {
            "k": "New Bataan",
            "v": [
              "Andap",
              "Bantacan",
              "Batinao",
              "Cabinuangan (Pob.)",
              "Camanlangan",
              "Cogonon",
              "Fatima",
              "Kahayag",
              "Katipunan",
              "Magangit",
              "Magsaysay",
              "Manurigao",
              "Pagsabangan",
              "Panag",
              "San Roque",
              "Tandawan"
            ]
          },
          {
            "k": "Pantukan",
            "v": [
              "Araibo",
              "Bongabong",
              "Bongbong",
              "Kingking (Pob.)",
              "Las Arenas",
              "Magnaga",
              "Matiao",
              "Napnapan",
              "P. Fuentes",
              "Tag-ugpo",
              "Tagdangua",
              "Tambongon",
              "Tibagon"
            ]
          }
        ],
        "names": [
          "Compostela",
          "Laak (San Vicente)",
          "Mabini (DoÃ±a Alicia)",
          "Maco",
          "Maragusan (San Mariano)",
          "Mawab",
          "Monkayo",
          "Montevista",
          "Nabunturan",
          "New Bataan",
          "Pantukan"
        ]
      }
    },
    {
      "k": "Davao Del Norte",
      "v": {
        "lookup": [
          {
            "k": "Asuncion (Saug)",
            "v": [
              "Binancian",
              "Buan",
              "Buclad",
              "Cabaywa",
              "Camansa",
              "Cambanogoy (Pob.)",
              "Camoning",
              "Canatan",
              "Concepcion",
              "DoÃ±a Andrea",
              "Magatos",
              "Napungas",
              "New Bantayan",
              "New Loon",
              "New Santiago",
              "Pamacaun",
              "Poblacion",
              "Sagayen",
              "San Vicente",
              "Santa Filomena",
              "Sonlon"
            ]
          },
          {
            "k": "Braulio E. Dujali",
            "v": ["Cabay-angan", "Dujali", "Magupising", "New Casay", "Tanglaw"]
          },
          {
            "k": "Carmen",
            "v": [
              "Alejal",
              "Anibongan",
              "Asuncion (Cuatro-cuatro)",
              "Cebulano",
              "Guadalupe",
              "Ising (Pob.)",
              "La Paz",
              "Mabaus",
              "Mabuhay",
              "Magsaysay",
              "Mangalcal",
              "Minda",
              "New Camiling",
              "Poblacion",
              "Salvacion",
              "San Isidro",
              "Santo NiÃ±o",
              "Taba",
              "Tibulao",
              "Tubod",
              "Tuganay"
            ]
          },
          {
            "k": "City Of Tagum",
            "v": [
              "Apokon",
              "Bincungan",
              "Busaon",
              "Canocotan",
              "Cuambogan",
              "La Filipina",
              "Liboganon",
              "Madaum",
              "Magdum",
              "Magugpo East",
              "Magugpo North",
              "Magugpo Poblacion",
              "Magugpo South",
              "Magugpo West",
              "Mankilam",
              "New Balamban",
              "Nueva Fuerza",
              "Pagsabangan",
              "Pandapan",
              "San Agustin",
              "San Isidro",
              "San Miguel (Camp 4)",
              "Visayan Village"
            ]
          },
          {
            "k": "Island Garden City Of Samal",
            "v": [
              "Adecor",
              "Anonang",
              "Aumbay",
              "Aundanao",
              "Balet",
              "Bandera",
              "Caliclic (Dangca-an)",
              "Camudmud",
              "Catagman",
              "Cawag",
              "Cogon",
              "Cogon (Talicod)",
              "Dadatan",
              "Del Monte",
              "Guilon",
              "Kanaan",
              "Kinawitnon",
              "Libertad",
              "Libuak",
              "Licup",
              "Limao",
              "Linosutan",
              "Mambago-a",
              "Mambago-b",
              "Miranda (Pob.)",
              "Moncado (Pob.)",
              "Pangubatan (Talicod I)",
              "PeÃ±aplata (Pob.)",
              "Poblacion (Kaputian)",
              "San Agustin",
              "San Antonio",
              "San Isidro (Babak)",
              "San Isidro (Kaputian)",
              "San Jose (San Lapuz)",
              "San Miguel (Magamono)",
              "San Remigio",
              "Santa Cruz (Talicod Ii)",
              "Santo NiÃ±o",
              "Sion (Zion)",
              "Tagbaobo",
              "Tagbay",
              "Tagbitan-ag",
              "Tagdaliao",
              "Tagpopongan",
              "Tambo",
              "Toril"
            ]
          },
          {
            "k": "Kapalong",
            "v": [
              "Capungagan",
              "Florida",
              "Gabuyan",
              "Gupitan",
              "Katipunan",
              "Luna",
              "Mabantao",
              "Mamacao",
              "Maniki (Poblacion)",
              "Pag-asa",
              "Sampao",
              "Semong",
              "Sua-on",
              "Tiburcia"
            ]
          },
          {
            "k": "New Corella",
            "v": [
              "Cabidianan",
              "Carcor",
              "Del Monte",
              "Del Pilar",
              "El Salvador",
              "Limba-an",
              "Macgum",
              "Mambing",
              "Mesaoy",
              "New Bohol",
              "New Cortez",
              "New Sambog",
              "Patrocenio",
              "Poblacion",
              "San Jose",
              "San Roque",
              "Santa Cruz",
              "Santa Fe",
              "Santo NiÃ±o",
              "Suawon"
            ]
          },
          {
            "k": "Panabo City",
            "v": [
              "A. O. Floirendo",
              "Buenavista",
              "Cacao",
              "Cagangohan",
              "Consolacion",
              "Dapco",
              "Datu Abdul Dadia",
              "Gredu (Pob.)",
              "J.p. Laurel",
              "Kasilak",
              "Katipunan",
              "Katualan",
              "Kauswagan",
              "Kiotoy",
              "Little Panay",
              "Lower Panaga (Roxas)",
              "Mabunao",
              "Maduao",
              "Malativas",
              "Manay",
              "Nanyo",
              "New Malaga (Dalisay)",
              "New Malitbog",
              "New Pandan (Pob.)",
              "New Visayas",
              "Quezon",
              "Salvacion",
              "San Francisco (Pob.)",
              "San Nicolas",
              "San Pedro",
              "San Roque",
              "San Vicente",
              "Santa Cruz",
              "Santo NiÃ±o (Pob.)",
              "Sindaton",
              "Southern Davao",
              "Tagpore",
              "Tibungol",
              "Upper Licanan",
              "Waterfall"
            ]
          },
          {
            "k": "San Isidro",
            "v": [
              "Dacudao",
              "Datu Balong",
              "Igangon",
              "Kipalili",
              "Libuton",
              "Linao",
              "Mamangan",
              "Monte Dujali",
              "Pinamuno",
              "Sabangan",
              "San Miguel",
              "Santo NiÃ±o",
              "Sawata"
            ]
          },
          {
            "k": "Santo Tomas",
            "v": [
              "Balagunan",
              "Bobongon",
              "Casig-ang",
              "Esperanza",
              "Kimamon",
              "Kinamayan",
              "La Libertad",
              "Lungaog",
              "Magwawa",
              "New Katipunan",
              "New Visayas",
              "Pantaron",
              "Salvacion",
              "San Jose",
              "San Miguel",
              "San Vicente",
              "Talomo",
              "Tibal-og (Pob.)",
              "Tulalian"
            ]
          }
        ],
        "names": [
          "Asuncion (Saug)",
          "Braulio E. Dujali",
          "Carmen",
          "City Of Tagum",
          "Island Garden City Of Samal",
          "Kapalong",
          "New Corella",
          "Panabo City",
          "San Isidro",
          "Santo Tomas"
        ]
      }
    },
    {
      "k": "Davao Del Sur",
      "v": {
        "lookup": [
          {
            "k": "Bansalan",
            "v": [
              "Alegre",
              "Alta Vista",
              "Anonang",
              "Bitaug",
              "Bonifacio",
              "Buenavista",
              "Darapuay",
              "Dolo",
              "Eman",
              "Kinuskusan",
              "Libertad",
              "Linawan",
              "Mabuhay",
              "Mabunga",
              "Managa",
              "Marber",
              "New Clarin (Miral)",
              "Poblacion",
              "Poblacion Dos",
              "Rizal",
              "Santo NiÃ±o",
              "Sibayan",
              "Tinongtongan",
              "Tubod",
              "Union"
            ]
          },
          {
            "k": "Davao City",
            "v": [
              "Acacia",
              "Agdao",
              "Alambre",
              "Alejandra Navarro (Lasang)",
              "Alfonso Angliongto Sr.",
              "Angalan",
              "Atan-awe",
              "Baganihan",
              "Bago Aplaya",
              "Bago Gallera",
              "Bago Oshiro",
              "Baguio (Pob.)",
              "Balengaeng",
              "Baliok",
              "Bangkal Heights",
              "Bantol",
              "Baracatan",
              "Barangay 1-a (Pob.)",
              "Barangay 10-a (Pob.)",
              "Barangay 11-b (Pob.)",
              "Barangay 12-b (Pob.)",
              "Barangay 13-b (Pob.)",
              "Barangay 14-b (Pob.)",
              "Barangay 15-b (Pob.)",
              "Barangay 16-b (Pob.)",
              "Barangay 17-b (Pob.)",
              "Barangay 18-b (Pob.)",
              "Barangay 19-b (Pob.)",
              "Barangay 2-a (Pob.)",
              "Barangay 20-b (Pob.)",
              "Barangay 21-c (Pob.)",
              "Barangay 22-c (Pob.)",
              "Barangay 23-c (Pob.)",
              "Barangay 24-c (Pob.)",
              "Barangay 25-c (Pob.)",
              "Barangay 26-c (Pob.)",
              "Barangay 27-c (Pob.)",
              "Barangay 28-c (Pob.)",
              "Barangay 29-c (Pob.)",
              "Barangay 3-a (Pob.)",
              "Barangay 30-c (Pob.)",
              "Barangay 31-d (Pob.)",
              "Barangay 32-d (Pob.)",
              "Barangay 33-d (Pob.)",
              "Barangay 34-d (Pob.)",
              "Barangay 35-d (Pob.)",
              "Barangay 36-d (Pob.)",
              "Barangay 37-d (Pob.)",
              "Barangay 38-d (Pob.)",
              "Barangay 39-d (Pob.)",
              "Barangay 4-a (Pob.)",
              "Barangay 40-d (Pob.)",
              "Barangay 5-a (Pob.)",
              "Barangay 6-a (Pob.)",
              "Barangay 7-a (Pob.)",
              "Barangay 8-a (Pob.)",
              "Barangay 9-a (Pob.)",
              "Bato",
              "Bayabas",
              "Biao Escuela",
              "Biao Guianga",
              "Biao Joaquin",
              "Binugao",
              "Bucana",
              "Buda",
              "Buhangin (Pob.)",
              "Bunawan (Pob.)",
              "Cabantian",
              "Cadalian",
              "Calinan (Pob.)",
              "Callawa",
              "Camansi",
              "Carmen",
              "Catalunan Grande",
              "Catalunan PequeÃ±o",
              "Catigan",
              "Cawayan",
              "Centro (San Juan)",
              "Colosas",
              "Communal",
              "Crossing Bayabas",
              "Dacudao",
              "Dalag",
              "Dalagdag",
              "Daliao",
              "Daliaon Plantation",
              "Datu Salumay",
              "Dominga",
              "Dumoy",
              "Eden",
              "Fatima (Benowang)",
              "Gatungan",
              "Gov. Paciano Bangoy",
              "Gov. Vicente Duterte",
              "Gumalang",
              "Gumitan",
              "Ilang",
              "Inayangan",
              "Indangan",
              "Kap. Tomas Monteverde, Sr.",
              "Kilate",
              "Lacson",
              "Lamanan",
              "Lampianao",
              "Langub",
              "Lapu-lapu",
              "Leon Garcia, Sr.",
              "Lizada",
              "Los Amigos",
              "Lubogan",
              "Lumiad",
              "Ma-a",
              "Mabuhay",
              "Magsaysay",
              "Magtuod",
              "Mahayag",
              "Malabog",
              "Malagos",
              "Malamba",
              "Manambulan",
              "Mandug",
              "Manuel Guianga",
              "Mapula",
              "Marapangi",
              "Marilog",
              "Matina Aplaya",
              "Matina Biao",
              "Matina Crossing",
              "Matina Pangi",
              "Megkawayan",
              "Mintal",
              "Mudiang",
              "Mulig",
              "New Carmen",
              "New Valencia",
              "Pampanga",
              "Panacan",
              "Panalum",
              "Pandaitan",
              "Pangyan",
              "Paquibato (Pob.)",
              "Paradise Embak",
              "Rafael Castillo",
              "Riverside",
              "Salapawan",
              "Salaysay",
              "Saloy",
              "San Antonio",
              "San Isidro (Licanan)",
              "Santo NiÃ±o",
              "Sasa",
              "Sibulan",
              "Sirawan",
              "Sirib",
              "Suawan (Tuli)",
              "Subasta",
              "Sumimao",
              "Tacunan",
              "Tagakpan",
              "Tagluno",
              "Tagurano",
              "Talandang",
              "Talomo (Pob.)",
              "Talomo River",
              "Tamayong",
              "Tambobong",
              "Tamugan",
              "Tapak",
              "Tawan-tawan",
              "Tibuloy",
              "Tibungco",
              "Tigatto",
              "Toril (Pob.)",
              "Tugbok (Pob.)",
              "Tungakalan",
              "Ubalde",
              "Ula",
              "Vicente Hizon Sr.",
              "Waan",
              "Wangan",
              "Wilfredo Aquino",
              "Wines"
            ]
          },
          {
            "k": "Digos City",
            "v": [
              "Aplaya",
              "Balabag",
              "Binaton",
              "Cogon",
              "Colorado",
              "Dawis",
              "Dulangan",
              "Goma",
              "Igpit",
              "Kapatagan (Rizal)",
              "Kiagot",
              "Lungag",
              "Mahayahay",
              "Matti",
              "Poblacion",
              "Ruparan",
              "San Agustin",
              "San Jose (Balutakay)",
              "San Miguel (Odaca)",
              "San Roque",
              "Sinawilan",
              "Soong",
              "Tiguman",
              "Tres De Mayo",
              "Zone 1 (Pob.)",
              "Zone 2 (Pob.)",
              "Zone 3 (Pob.)"
            ]
          },
          {
            "k": "Hagonoy",
            "v": [
              "Balutakay",
              "Clib",
              "Guihing",
              "Guihing Aplaya",
              "Hagonoy Crossing",
              "Kibuaya",
              "La Union",
              "Lanuro",
              "Lapulabao",
              "Leling",
              "Mahayahay",
              "Malabang Damsite",
              "Maliit Digos",
              "New Quezon",
              "Paligue",
              "Poblacion",
              "Sacub",
              "San Guillermo",
              "San Isidro",
              "Sinayawan",
              "Tologan"
            ]
          },
          {
            "k": "Kiblawan",
            "v": [
              "Abnate",
              "Bagong Negros",
              "Bagong Silang",
              "Bagumbayan",
              "Balasiao",
              "Bonifacio",
              "Bulol-salo",
              "Bunot",
              "Cogon-bacaca",
              "Dapok",
              "Ihan",
              "Kibongbong",
              "Kimlawis",
              "Kisulan",
              "Lati-an",
              "Manual",
              "Maraga-a",
              "Molopolo",
              "New Sibonga",
              "Panaglib",
              "Pasig",
              "Poblacion",
              "Pocaleel",
              "San Isidro",
              "San Jose",
              "San Pedro",
              "Santo NiÃ±o",
              "Tacub",
              "Tacul",
              "Waterfall"
            ]
          },
          {
            "k": "Magsaysay",
            "v": [
              "Bacungan",
              "Balnate",
              "Barayong",
              "Blocon",
              "Dalawinon",
              "Dalumay",
              "Glamang",
              "Kanapulo",
              "Kasuga",
              "Lower Bala",
              "Mabini",
              "Maibo",
              "Malawanit",
              "Malongon",
              "New Ilocos",
              "New Opon",
              "Poblacion",
              "San Isidro",
              "San Miguel",
              "Tacul",
              "Tagaytay",
              "Upper Bala"
            ]
          },
          {
            "k": "Malalag",
            "v": [
              "Bagumbayan",
              "Baybay",
              "Bolton",
              "Bulacan",
              "Caputian",
              "Ibo",
              "Kiblagon",
              "Lapla",
              "Mabini",
              "New Baclayon",
              "Pitu",
              "Poblacion",
              "Rizal (Parame)",
              "San Isidro",
              "Tagansule"
            ]
          },
          {
            "k": "Malita",
            "v": [
              "Aquacor Compound",
              "Barangay Bagsak (Highway Only)",
              "Barangay Buhangin (Highway Only)",
              "Barangay Lacaron (Proper Only)",
              "Barangay Tubalan (Proper Only)",
              "Bolita Proper",
              "Culaman",
              "Dpwh - Buhangin",
              "Fishing Village",
              "Kidalapong Proper",
              "New Argao",
              "Pilis",
              "Poblacion",
              "Spama Street",
              "Talisay Talucanga",
              "Talucanga Proper"
            ]
          },
          {
            "k": "Matanao",
            "v": [
              "Asbang",
              "Asinan",
              "Bagumbayan",
              "Bangkal",
              "Buas",
              "Buri",
              "Cabligan (Managa)",
              "Camanchiles",
              "Ceboza",
              "Colonsabak",
              "Dongan-pekong",
              "Kabasagan",
              "Kapok",
              "Kauswagan",
              "Kibao",
              "La Suerte",
              "Langa-an",
              "Lower Marber",
              "Manga",
              "New Katipunan",
              "New Murcia",
              "New Visayas",
              "Poblacion",
              "Saboy",
              "San Jose",
              "San Miguel",
              "San Vicente",
              "Saub",
              "Sinaragan",
              "Sinawilan",
              "Tamlangon",
              "Tibongbong",
              "Towak"
            ]
          },
          {
            "k": "Padada",
            "v": [
              "Almendras (Pob.)",
              "Don Sergio OsmeÃ±a, Sr.",
              "Harada Butai",
              "Lower Katipunan",
              "Lower Limonzo",
              "Lower Malinao",
              "N C Ordaneza District (Pob.)",
              "Northern Paligue",
              "Palili",
              "Piape",
              "Punta Piape",
              "Quirino District (Pob.)",
              "San Isidro",
              "Southern Paligue",
              "Tulogan",
              "Upper Limonzo",
              "Upper Malinao"
            ]
          },
          {
            "k": "Santa Cruz",
            "v": [
              "Astorga",
              "Bato",
              "Coronon",
              "Darong",
              "Inawayan",
              "Jose Rizal",
              "Matutungan",
              "Melilia",
              "Saliducon",
              "Sibulan",
              "Sinoron",
              "Tagabuli",
              "Tibolo",
              "Tuban",
              "Zone I (Pob.)",
              "Zone Ii (Pob.)",
              "Zone Iii (Pob.)",
              "Zone Iv (Pob.)"
            ]
          },
          {
            "k": "Santa Maria",
            "v": [
              "Barangay Basiawan",
              "Barangay San Agustin",
              "Colibao (Highway Only)",
              "Comase (Highway Only)",
              "Lower Santa Maria",
              "Poblacion Proper"
            ]
          },
          {
            "k": "Sulop",
            "v": [
              "Balasinon",
              "Buguis",
              "Carre",
              "Clib",
              "Harada Butai",
              "Katipunan",
              "Kiblagon",
              "Labon",
              "Laperas",
              "Lapla",
              "Litos",
              "Luparan",
              "Mckinley",
              "New Cebu",
              "OsmeÃ±a",
              "Palili",
              "Parame",
              "Roxas",
              "Solongvale",
              "Tagolilong",
              "Tala-o",
              "Talas",
              "Tanwalang",
              "Waterfall"
            ]
          }
        ],
        "names": [
          "Bansalan",
          "Davao City",
          "Digos City",
          "Hagonoy",
          "Kiblawan",
          "Magsaysay",
          "Malalag",
          "Malita",
          "Matanao",
          "Padada",
          "Santa Cruz",
          "Santa Maria",
          "Sulop"
        ]
      }
    },
    {
      "k": "Davao Occidental",
      "v": {
        "lookup": [
          {
            "k": "Malita",
            "v": [
              "Bito",
              "Bolila",
              "Buhangin",
              "Culaman",
              "Datu Danwata",
              "Demoloc",
              "Felis",
              "Fishing Village (Fisherman's Village)",
              "Kibalatong",
              "Kidalapong",
              "Kilalag",
              "Kinangan",
              "Lacaron",
              "Lagumit",
              "Lais",
              "Little Baguio",
              "Macol",
              "Mana",
              "Manuel Peralta",
              "New Argao",
              "Pangaleon",
              "Pangian",
              "Pinalpalan",
              "Poblacion",
              "Sangay",
              "Talogoy",
              "Tical",
              "Ticulon",
              "Tingolo",
              "Tubalan"
            ]
          },
          {
            "k": "Santa Maria",
            "v": [
              "Basiawan",
              "Buca",
              "Cadaatan",
              "Datu Daligasao",
              "Datu Intan",
              "Kidadan",
              "Kinilidan",
              "Kisulad",
              "Malalag Tubig",
              "Mamacao",
              "Ogpao",
              "Pongpong",
              "San Agustin",
              "San Antonio",
              "San Isidro",
              "San Juan",
              "San Pedro",
              "San Roque",
              "Santo NiÃ±o",
              "Santo Rosario",
              "Tanglad"
            ]
          }
        ],
        "names": ["Malita", "Santa Maria"]
      }
    },
    {
      "k": "Davao Oriental",
      "v": {
        "lookup": [
          {
            "k": "Baganga",
            "v": ["Banao", "Dapnan", "Kinablangan", "Lucod", "Saoquegue"]
          },
          {
            "k": "Banaybanay",
            "v": [
              "Cabangcalan",
              "Caganganan",
              "Calubihan",
              "Causwagan",
              "Mahayag",
              "Maputi",
              "Mogbongcogon",
              "Panikian",
              "Pintatagan",
              "Piso Proper",
              "Poblacion",
              "Punta Linao",
              "Rang-ay",
              "San Vicente"
            ]
          },
          {
            "k": "Boston",
            "v": ["Carmen", "Poblacion", "San Jose", "Sibajay"]
          },
          {
            "k": "Cateel",
            "v": [
              "San Alfonso",
              "San Antonio",
              "San Rafael",
              "Santa Filomena",
              "Taytayan"
            ]
          },
          {
            "k": "Governor Generoso",
            "v": [
              "Anitap",
              "Crispin Dela Cruz",
              "Don Aurelio Chicote",
              "Lavigan",
              "Luzon",
              "Magdug",
              "Manuel Roxas",
              "Monserrat",
              "Nangan",
              "Oregon",
              "Poblacion",
              "Pundaguitan",
              "Sergio OsmeÃ±a",
              "Surop",
              "Tagabebe",
              "Tamban",
              "Tandang Sora",
              "Tibanban",
              "Tiblawan",
              "Upper Tibanban"
            ]
          },
          {
            "k": "Lupon",
            "v": [
              "Bagumbayan",
              "Cabadiangan",
              "Calapagan",
              "Cocornon",
              "Corporacion",
              "Don Mariano Marcos",
              "Ilangay",
              "Langka",
              "Lantawan",
              "Limbahan",
              "Macangao",
              "Magsaysay",
              "Mahayahay",
              "Maragatas",
              "Marayag",
              "New Visayas",
              "Poblacion",
              "San Isidro",
              "San Jose",
              "Tagboa",
              "Tagugpo"
            ]
          },
          { "k": "Manay", "v": ["Central (Pob.)", "Holy Cross", "Zaragosa"] },
          {
            "k": "Mati City",
            "v": [
              "Badas",
              "Bobon",
              "Buso",
              "Cabuaya",
              "Central (Pob.)",
              "Culian",
              "Dahican",
              "Danao",
              "Dawan",
              "Don Enrique Lopez",
              "Don Martin Marundan",
              "Don Salvador Lopez, Sr.",
              "Langka",
              "Lawigan",
              "Libudon",
              "Luban",
              "Macambol",
              "Mamali",
              "Matiao",
              "Mayo",
              "Sainz",
              "Sanghay",
              "Tagabakid",
              "Tagbinonga",
              "Taguibo",
              "Tamisan"
            ]
          },
          {
            "k": "San Isidro",
            "v": [
              "Baon",
              "Batobato (Pob.)",
              "Bitaogan",
              "Cambaleon",
              "Dugmanon",
              "Iba",
              "La Union",
              "Lapu-lapu",
              "Maag",
              "Manikling",
              "Maputi",
              "San Miguel",
              "San Roque",
              "Santo Rosario",
              "Sudlon",
              "Talisay"
            ]
          },
          {
            "k": "Tarragona",
            "v": ["Cabagayan", "Central (Pob.)", "Jovellar", "Tomoaong"]
          }
        ],
        "names": [
          "Baganga",
          "Banaybanay",
          "Boston",
          "Cateel",
          "Governor Generoso",
          "Lupon",
          "Manay",
          "Mati City",
          "San Isidro",
          "Tarragona"
        ]
      }
    },
    {
      "k": "Dinagat Islands",
      "v": {
        "lookup": [
          {
            "k": "Basilisa Rizal",
            "v": [
              "Diegas",
              "Dona Helene",
              "Ferdinand",
              "Santo Nino",
              "Villa Ecleo",
              "Villa Pantinople"
            ]
          },
          {
            "k": "Cagdianao",
            "v": [
              "Cabunga An",
              "Ma Atas",
              "Poblacion",
              "R Ecleo, Sr",
              "San Jose"
            ]
          },
          {
            "k": "Dinagat",
            "v": [
              "Bagumbayan",
              "Cayetano",
              "Escolta Pob",
              "Justiniana Edera",
              "Magsaysay",
              "Mauswagon Pob",
              "New Mabuhay",
              "Wadas",
              "White Beach Pob"
            ]
          },
          {
            "k": "San Jose",
            "v": [
              "Aurelio",
              "Cuarinta",
              "Don Ruben Ecleo Baltazar",
              "Jacquez",
              "Luna",
              "Mahayahay",
              "Matingbe",
              "San Juan",
              "Santa Cruz",
              "Wilson"
            ]
          }
        ],
        "names": ["Basilisa Rizal", "Cagdianao", "Dinagat", "San Jose"]
      }
    },
    {
      "k": "Eastern Samar",
      "v": {
        "lookup": [
          {
            "k": "Balangkayan",
            "v": [
              "Balogo",
              "Bangon",
              "Cabay",
              "Caisawan",
              "Cantubi",
              "General Malvar",
              "Guinpoliran",
              "Julag",
              "Magsaysay",
              "Maramag",
              "Poblacion I",
              "Poblacion Ii",
              "Poblacion Iii",
              "Poblacion Iv",
              "Poblacion V",
              "Proper"
            ]
          },
          {
            "k": "Borongan City",
            "v": [
              "Alang Alang",
              "Amantacop",
              "Ando",
              "Balacdas",
              "Balud",
              "Banuyo",
              "Baras",
              "Bato",
              "Bayobay",
              "Benowangan",
              "Bugas",
              "Cabalagnan",
              "Cabong",
              "Cagbonga",
              "Calico An",
              "Calingatngan",
              "Camada",
              "Campesao",
              "Can Abong",
              "Can Aga",
              "Canjaway",
              "Canlaray",
              "Canyopay",
              "Divinubo",
              "Hebacong",
              "Hindang",
              "Lalawigan",
              "Libuton",
              "Locso On",
              "Maybacong",
              "Maypangdan",
              "Pepelitan",
              "Pinanag An",
              "Proper",
              "Punta Maria",
              "Purok A Pob",
              "Purok B Pob",
              "Purok C Pob",
              "Purok D1 Pob",
              "Purok D2 Pob",
              "Purok E Pob",
              "Purok F Pob",
              "Purok G Pob",
              "Purok H Pob",
              "Sabang North",
              "Sabang South",
              "San Gregorio",
              "San Jose",
              "San Pablo",
              "San Saturnino",
              "Santa Fe",
              "Siha",
              "Sohutan",
              "Songco",
              "Suribao",
              "Surok",
              "Taboc",
              "Tabunan",
              "Tamoso"
            ]
          },
          {
            "k": "Can Avid",
            "v": [
              "Balagon",
              "Barangay 1 Poblacion",
              "Barangay 10 Poblacion",
              "Barangay 2 Poblacion",
              "Barangay 3 Poblacion",
              "Barangay 4 Poblacion",
              "Barangay 5 Poblacion",
              "Barangay 6 Poblacion",
              "Barangay 7 Poblacion",
              "Barangay 8 Poblacion",
              "Barangay 9 Poblacion",
              "Baruk",
              "Boco",
              "Caghalong",
              "Camantang",
              "Can Ilay",
              "Cansangaya",
              "Canteros",
              "Carolina",
              "Guibuangan",
              "Jepaco",
              "Mabuhay",
              "Malogo",
              "Obong",
              "Pandol",
              "Proper",
              "Rawis",
              "Salvacion",
              "Solong"
            ]
          },
          {
            "k": "Dolores",
            "v": [
              "Aroganga",
              "Barangay 1 Pob",
              "Barangay 10 Pob",
              "Barangay 11 Pob",
              "Barangay 12 Pob",
              "Barangay 13 Pob",
              "Barangay 14 Pob",
              "Barangay 15 Pob",
              "Barangay 2 Pob",
              "Barangay 3 Pob",
              "Barangay 4 Pob",
              "Barangay 5 Pob",
              "Barangay 6 Pob",
              "Barangay 7 Pob",
              "Barangay 8 Pob",
              "Barangay 9 Pob",
              "Bonghon",
              "Buenavista",
              "Cabago An",
              "Caglao An",
              "Cagtabon",
              "Dampigan",
              "Dapdap",
              "Del Pilar",
              "Denigpian",
              "Gap Ang",
              "Hilabaan",
              "Hinolaso",
              "Japitan",
              "Jicontol",
              "Libertad",
              "Magongbong",
              "Magsaysay",
              "Malaintos",
              "Malobago",
              "Osmena",
              "Proper",
              "Rizal",
              "San Isidro Malabag",
              "San Pascual",
              "San Roque",
              "San Vicente",
              "Santa Cruz",
              "Santo Nino",
              "Tanauan",
              "Tikling",
              "Villahermosa"
            ]
          },
          {
            "k": "Guiuan",
            "v": [
              "Alingarog",
              "Bagua",
              "Banahao",
              "Baras",
              "Barbo",
              "Bucao",
              "Bungtod",
              "Campoyong",
              "Cantahay",
              "Cogon",
              "Gahoy",
              "Hollywood",
              "Lupok Pob",
              "Ngolos",
              "Pagnamitan",
              "Poblacion Ward 1",
              "Poblacion Ward 10",
              "Poblacion Ward 11",
              "Poblacion Ward 12",
              "Poblacion Ward 2",
              "Poblacion Ward 3",
              "Poblacion Ward 4",
              "Poblacion Ward 4 A",
              "Poblacion Ward 5",
              "Poblacion Ward 6",
              "Poblacion Ward 7",
              "Poblacion Ward 8",
              "Poblacion Ward 9",
              "Poblacion Ward 9 A",
              "Salug",
              "Sapao",
              "Sulangan",
              "Surok",
              "Taytay",
              "Timala"
            ]
          },
          {
            "k": "Llorente",
            "v": [
              "Antipolo",
              "Babanikhon",
              "Bacayawan",
              "Barangay 1 Pob",
              "Barangay 10 Pob",
              "Barangay 11 Pob",
              "Barangay 12 Pob",
              "Barangay 2 Pob",
              "Barangay 3 Pob",
              "Barangay 4 Pob",
              "Barangay 5 Pob",
              "Barangay 6 Pob",
              "Barangay 7 Pob",
              "Barangay 8 Pob",
              "Barangay 9 Pob",
              "Barobo",
              "Burak",
              "Can Ato",
              "Candoros",
              "Canliwag",
              "Cantomco",
              "Hugpa",
              "Maca Anga",
              "Magtino",
              "Mina Anod",
              "Naubay",
              "Piliw",
              "Proper",
              "San Jose",
              "San Miguel",
              "San Roque",
              "So Ong",
              "Tabok",
              "Waso"
            ]
          },
          {
            "k": "Maydolong",
            "v": [
              "Barangay Poblacion 1",
              "Barangay Poblacion 2",
              "Barangay Poblacion 3",
              "Barangay Poblacion 4",
              "Barangay Poblacion 5",
              "Barangay Poblacion 6",
              "Barangay Poblacion 7",
              "Camada",
              "Campakerit Botay",
              "Maybocog",
              "Omawas",
              "Proper"
            ]
          },
          {
            "k": "Mercedes",
            "v": [
              "Anuron",
              "Barangay 1 Poblacion",
              "Barangay 2 Poblacion",
              "Barangay 3 Poblacion",
              "Barangay 4 Poblacion",
              "Buyayawon",
              "Palamrag Cabiliri An"
            ]
          },
          {
            "k": "Oras",
            "v": [
              "Agsam",
              "Alang Alang",
              "Bagacay",
              "Balingasag",
              "Balocawe Pob",
              "Bantayan",
              "Batang",
              "Bato",
              "Binalayan",
              "Buntay",
              "Burak",
              "Butnga Pob",
              "Cadian",
              "Cagdine",
              "Cagpile",
              "Cagtoog",
              "Camanga Pob",
              "Dalid",
              "Dao",
              "Factoria",
              "Gamot",
              "Iwayan",
              "Japay",
              "Kalaw",
              "Mabuhay",
              "Malingon",
              "Minap Os",
              "Nadacpan",
              "Naga",
              "Pangudtan",
              "Paypayon Pob",
              "Proper",
              "Riverside Pob",
              "Rizal",
              "Sabang",
              "San Eduardo",
              "San Roque Pob",
              "Santa Monica",
              "Saugan",
              "Saurong",
              "Tawagan Pob",
              "Tiguib Pob",
              "Trinidad Maycorot"
            ]
          },
          {
            "k": "Salcedo",
            "v": [
              "Bagtong",
              "Balud",
              "Barangay 1 Pob",
              "Barangay 10 Pob",
              "Barangay 11 Pob",
              "Barangay 12 Pob",
              "Barangay 13 Pob",
              "Barangay 2 Pob",
              "Barangay 3 Pob",
              "Barangay 4 Pob",
              "Barangay 5 Pob",
              "Barangay 6 Pob",
              "Barangay 7 Pob",
              "Barangay 8 Pob",
              "Barangay 9 Pob",
              "Buabua",
              "Casili On",
              "Naparaan",
              "Tagbacan",
              "Talangdawan"
            ]
          },
          {
            "k": "San Julian",
            "v": [
              "Barangay No 1 Poblacion",
              "Barangay No 2 Poblacion",
              "Barangay No 3 Poblacion",
              "Barangay No 4 Poblacion",
              "Barangay No 5 Poblacion",
              "Barangay No 6 Poblacion",
              "Bunacan",
              "Campidhan",
              "Casoroy",
              "Libas",
              "Lunang",
              "Nena Luna",
              "Pagbabangnan",
              "Proper",
              "Putong",
              "San Isidro",
              "San Miguel"
            ]
          },
          {
            "k": "Sulat",
            "v": [
              "A Et",
              "Abucay Pob",
              "Baybay Pob",
              "Del Remedio",
              "Kandalakit",
              "Loyola Heights Pob",
              "Mabini",
              "Maglipay Pob",
              "Maramara Pob",
              "Proper",
              "Riverside Pob",
              "San Francisco",
              "San Isidro",
              "San Juan",
              "San Mateo",
              "San Vicente",
              "Santo Nino",
              "Santo Tomas",
              "Tabi Pob"
            ]
          },
          {
            "k": "Taft",
            "v": [
              "Batiawan",
              "Beto",
              "Binaloan",
              "Bongdo",
              "Dacul",
              "Danao",
              "Del Remedios",
              "Gayam",
              "Lomatud Burak",
              "Mabuhay",
              "Malinao",
              "Mantang",
              "Nato",
              "Pangabutan",
              "Poblacion Barangay 1",
              "Poblacion Barangay 2",
              "Poblacion Barangay 3",
              "Poblacion Barangay 4",
              "Poblacion Barangay 5",
              "Poblacion Barangay 6",
              "Polangi",
              "Proper",
              "San Luis",
              "San Pablo",
              "San Rafael"
            ]
          }
        ],
        "names": [
          "Balangkayan",
          "Borongan City",
          "Can Avid",
          "Dolores",
          "Guiuan",
          "Llorente",
          "Maydolong",
          "Mercedes",
          "Oras",
          "Salcedo",
          "San Julian",
          "Sulat",
          "Taft"
        ]
      }
    },
    {
      "k": "Guimaras",
      "v": {
        "lookup": [
          {
            "k": "Buenavista",
            "v": [
              "Agsanayan",
              "Avila",
              "Bacjao (Calumingan)",
              "Banban",
              "Cansilayan",
              "Daragan",
              "East Valencia",
              "Getulio",
              "Mabini",
              "Mclain",
              "Montpiller",
              "Navalas",
              "Nazaret",
              "New Poblacion (Calingao)",
              "Old Poblacion",
              "PiÃ±a",
              "Rizal",
              "Salvacion",
              "San Fernando",
              "San Isidro",
              "San Miguel",
              "San Nicolas",
              "San Pedro",
              "San Roque",
              "Santo Rosario",
              "Supang",
              "Taminla",
              "Tastasan",
              "Tinadtaran",
              "Zaldivar"
            ]
          },
          {
            "k": "Jordan",
            "v": [
              "Alaguisoc",
              "Balcon Maravilla",
              "Balcon Melliza",
              "Bugnay",
              "Buluangan",
              "Espinosa",
              "Hoskyn",
              "Lawi",
              "Poblacion",
              "Rizal",
              "San Miguel",
              "Santa Teresa",
              "Sinapsapan"
            ]
          },
          {
            "k": "Nueva Valencia",
            "v": [
              "Cabalagnan",
              "Calaya",
              "Canhawan",
              "Dolores",
              "Igang",
              "Igdarapdap",
              "La Paz",
              "Lanipe",
              "Lucmayan",
              "Magamay",
              "Napandong",
              "Oracon Sur",
              "Pandaraonan",
              "Poblacion",
              "Salvacion",
              "San Antonio",
              "San Roque",
              "Santo Domingo"
            ]
          },
          {
            "k": "San Lorenzo",
            "v": [
              "Aguilar",
              "Cabano",
              "Cabungahan",
              "Constancia",
              "Gaban",
              "Igcawayan",
              "M. Chavez",
              "San Enrique (Lebas)",
              "Sapal",
              "Sebario",
              "Suclaran",
              "Tamborong"
            ]
          },
          {
            "k": "Sibunag",
            "v": [
              "Alegria",
              "Ayangan",
              "Bubog",
              "Concordia",
              "Dasal",
              "Maabay",
              "Millan",
              "Oracon",
              "Ravina",
              "Sabang",
              "San Isidro",
              "Sebaste",
              "Tanglad"
            ]
          }
        ],
        "names": [
          "Buenavista",
          "Jordan",
          "Nueva Valencia",
          "San Lorenzo",
          "Sibunag"
        ]
      }
    },
    {
      "k": "Ifugao",
      "v": {
        "lookup": [
          {
            "k": "Lagawe",
            "v": [
              "Boliwong",
              "Burnay",
              "Cudog",
              "Poblacion East",
              "Poblacion North",
              "Poblacion South",
              "Poblacion West",
              "Tungngod"
            ]
          },
          { "k": "Lamut", "v": ["Poblacion East", "Poblacion West"] }
        ],
        "names": ["Lagawe", "Lamut"]
      }
    },
    {
      "k": "Ilocos Norte",
      "v": {
        "lookup": [
          {
            "k": "Bacarra",
            "v": [
              "Bani",
              "Buyon",
              "Cabaruan",
              "Cabulalaan",
              "Cabusligan",
              "Cadaratan",
              "Calioet-libong",
              "Corocor",
              "Duripes",
              "Libtong",
              "Nambaran",
              "Natba",
              "Pasiocan",
              "Pasngal",
              "Pipias",
              "Pulangi",
              "Pungto",
              "San Agustin I (Pob.)",
              "San Agustin Ii (Pob.)",
              "San Andres I (Pob.)",
              "San Andres Ii (Pob.)",
              "San Gabriel I (Pob.)",
              "San Gabriel Ii (Pob.)",
              "San Pedro I (Pob.)",
              "San Pedro Ii (Pob.)",
              "San Roque I (Pob.)",
              "San Roque Ii (Pob.)",
              "San Simon I (Pob.)",
              "San Simon Ii (Pob.)",
              "San Vicente (Pob.)",
              "Sangil",
              "Santa Filomena I (Pob.)",
              "Santa Filomena Ii (Pob.)",
              "Santa Rita (Pob.)",
              "Santo Cristo I (Pob.)",
              "Santo Cristo Ii (Pob.)",
              "Tambidao",
              "Teppang"
            ]
          },
          {
            "k": "Badoc",
            "v": [
              "Alay-nangbabaan (Alay 15-b)",
              "Alogoog",
              "Ar-arusip",
              "Aring",
              "Balbaldez",
              "Bato",
              "Canaan (Pob.)",
              "Caraitan",
              "Gabut Norte",
              "Gabut Sur",
              "Garreta (Pob.)",
              "La Virgen Milagrosa (Paguetpet)",
              "Labut",
              "Lacuben",
              "Lubigan",
              "Mabusag Norte",
              "Mabusag Sur",
              "Morong",
              "Nagrebcan",
              "Napu",
              "Pagsanahan Norte",
              "Pagsanahan Sur",
              "Paltit",
              "Parang",
              "Pasuc",
              "Santa Cruz Norte",
              "Santa Cruz Sur",
              "Saud",
              "Turod"
            ]
          },
          {
            "k": "Bangui",
            "v": [
              "Abaca",
              "Banban",
              "Baruyen",
              "Dadaor",
              "Lanao",
              "Malasin",
              "Manayon",
              "Masikil",
              "Nagbalagan",
              "Payac",
              "San Lorenzo (Pob.)",
              "Taguiporo",
              "Utol"
            ]
          },
          {
            "k": "Banna (Espiritu)",
            "v": [
              "Bangsar",
              "Barbarangay",
              "Bugasi",
              "Caestebanan",
              "Caribquib",
              "Catagtaguen",
              "Crispina",
              "Hilario (Pob.)",
              "Lorenzo (Pob.)",
              "Macayepyep",
              "Marcos (Pob.)",
              "Tabtabagan",
              "Valdez",
              "Valenciano (Pob.)"
            ]
          },
          {
            "k": "Batac",
            "v": [
              "Ablan Pob. (Labucao)",
              "Acosta Pob. (Iloilo)",
              "Aglipay (Pob.)",
              "Baay",
              "Baligat",
              "Baoa East",
              "Baoa West",
              "Barani (Pob.)",
              "Ben-agan (Pob.)",
              "Bil-loca",
              "Biningan",
              "Bungon",
              "Callaguip (Pob.)",
              "Camandingan",
              "Camguidan",
              "Cangrunaan (Pob.)",
              "Capacuan",
              "Caunayan (Pob.)",
              "Dariwdiw",
              "Lacub (Pob.)",
              "Mabaleng",
              "Magnuang",
              "Naguirangan",
              "Palongpong",
              "Palpalicong (Pob.)",
              "Parangopong",
              "Quiling Norte",
              "Quiling Sur",
              "Quiom",
              "Rayuray",
              "Ricarte Pob. (Nalasin)",
              "San Julian (Pob.)",
              "San Mateo",
              "San Pedro",
              "Suabit (Pob.)",
              "Sumader",
              "Tabug",
              "Valdez Pob. (Caoayan)"
            ]
          },
          {
            "k": "Burgos",
            "v": [
              "Ablan Sarat",
              "Bobon",
              "Buduan (Malituek)",
              "Pagali",
              "Poblacion"
            ]
          },
          {
            "k": "Currimao",
            "v": [
              "Anggapang Norte",
              "Anggapang Sur",
              "Bimmanga",
              "Cabuusan",
              "Comcomloong",
              "Gaang",
              "Lang-ayan-baramban",
              "Lioes",
              "Maglaoi Centro",
              "Maglaoi Norte",
              "Maglaoi Sur",
              "Paguludan-salindeg",
              "Pangil",
              "Pias Norte",
              "Pias Sur",
              "Poblacion I",
              "Poblacion Ii",
              "Salugan",
              "San Simeon",
              "Santa Cruz",
              "Tapao-tigue",
              "Torre",
              "Victoria"
            ]
          },
          {
            "k": "Dingras",
            "v": [
              "Albano (Pob.)",
              "Bacsil",
              "Bagut",
              "Baresbes",
              "Bungcag",
              "Cali",
              "Capasan",
              "Dancel (Pob.)",
              "Elizabeth",
              "Espiritu",
              "Foz",
              "Guerrero (Pob.)",
              "Lumbad",
              "Madamba (Pob.)",
              "Medina",
              "Parado (Bangay)",
              "Peralta (Pob.)",
              "Puruganan (Pob.)",
              "Root (Baldias)",
              "Sagpatan",
              "Saludares",
              "San Francisco (Surrate)",
              "San Marcos",
              "Sulquiano (Sidiran)",
              "Suyo"
            ]
          },
          {
            "k": "Laoag City",
            "v": [
              "Bgy. No. 1, San Lorenzo (Pob.)",
              "Bgy. No. 10, San Jose (Pob.)",
              "Bgy. No. 11, Santa Balbina (Pob.)",
              "Bgy. No. 12, San Isidro (Pob.)",
              "Bgy. No. 13, Nstra. Sra. De Visitacion (Pob.)",
              "Bgy. No. 14, Santo Tomas (Pob.)",
              "Bgy. No. 15, San Guillermo (Pob.)",
              "Bgy. No. 16, San Jacinto (Pob.)",
              "Bgy. No. 17, San Francisco (Pob.)",
              "Bgy. No. 19, Santa Marcela (Pob.)",
              "Bgy. No. 2, Santa Joaquina (Pob.)",
              "Bgy. No. 20, San Miguel (Pob.)",
              "Bgy. No. 21, San Pedro (Pob.)",
              "Bgy. No. 22, San Andres (Pob.)",
              "Bgy. No. 23, San Matias (Pob.)",
              "Bgy. No. 24, Nstra. Sra. De Consolacion (Pob.)",
              "Bgy. No. 25, Santa Cayetana (Pob.)",
              "Bgy. No. 26, San Marcelino (Pob.)",
              "Bgy. No. 27, Nstra. Sra. De Soledad (Pob.)",
              "Bgy. No. 28, San Bernardo (Pob.)",
              "Bgy. No. 29, Santo Tomas (Pob.)",
              "Bgy. No. 3, Nstra. Sra. Del Rosario (Pob.)",
              "Bgy. No. 30-a, Suyo",
              "Bgy. No. 30-b, Santa Maria",
              "Bgy. No. 31, Talingaan",
              "Bgy. No. 32-a, La Paz East",
              "Bgy. No. 32-b, La Paz West",
              "Bgy. No. 32-c La Paz East",
              "Bgy. No. 33-a, La Paz Proper",
              "Bgy. No. 33-b, La Paz Proper",
              "Bgy. No. 34-a, Gabu Norte West",
              "Bgy. No. 34-b, Gabu Norte East",
              "Bgy. No. 35, Gabu Sur",
              "Bgy. No. 36, Araniw",
              "Bgy. No. 37, Calayab",
              "Bgy. No. 38-a, Mangato East",
              "Bgy. No. 38-b, Mangato West",
              "Bgy. No. 39, Santa Rosa",
              "Bgy. No. 4, San Guillermo (Pob.)",
              "Bgy. No. 40, Balatong",
              "Bgy. No. 41, Balacad",
              "Bgy. No. 42, Apaya",
              "Bgy. No. 43, Cavit",
              "Bgy. No. 44, Zamboanga",
              "Bgy. No. 45, Tangid",
              "Bgy. No. 46, Nalbo",
              "Bgy. No. 47, Bengcag",
              "Bgy. No. 48-b, Cabungaan South",
              "Bgy. No. 49-a, Darayday",
              "Bgy. No. 49-b, Raraburan",
              "Bgy. No. 5, San Pedro (Pob.)",
              "Bgy. No. 50, Buttong",
              "Bgy. No. 51-a, Nangalisan East",
              "Bgy. No. 51-b, Nangalisan West",
              "Bgy. No. 52-a, San Mateo",
              "Bgy. No. 52-b, Lataag",
              "Bgy. No. 53, Rioeng",
              "Bgy. No. 54-a, Lagui-sail",
              "Bgy. No. 54-b, Camangaan",
              "Bgy. No. 55-a, Barit-pandan",
              "Bgy. No. 55-b, Salet-bulangon",
              "Bgy. No. 55-c, Vira",
              "Bgy. No. 56-a, Bacsil North",
              "Bgy. No. 56-b, Bacsil South",
              "Bgy. No. 57, Pila",
              "Bgy. No. 58, Casili",
              "Bgy. No. 59-a, Dibua South",
              "Bgy. No. 59-b, Dibua North",
              "Bgy. No. 6, San Agustin (Pob.)",
              "Bgy. No. 60-a, Caaoacan",
              "Bgy. No. 60-b, Madiladig",
              "Bgy. No. 61, Cataban",
              "Bgy. No. 62-a, Navotas North",
              "Bgy. No. 62-b, Navotas South",
              "Bgy. No. 7-a, Nstra. Sra. De Natividad (Pob.)",
              "Bgy. No. 7-b, Nstra. Sra. De Natividad (Pob.)",
              "Bgy. No. 8, San Vicente (Pob.)",
              "Bgy. No. 9, Santa Angela (Pob.)",
              "Bry. No. 18, San Quirino (Pob.)",
              "Bry. No. 48-a, Cabungaan North"
            ]
          },
          {
            "k": "Marcos",
            "v": [
              "Daquioag",
              "Escoda",
              "Fortuna",
              "Lydia (Pob.)",
              "Mabuti",
              "Valdez (Biding)"
            ]
          },
          { "k": "Nueva Era", "v": ["Poblacion"] },
          {
            "k": "Pagudpud",
            "v": [
              "Baduang",
              "Balaoi",
              "Burayoc",
              "Caparispisan",
              "Pancian",
              "Pasaleng",
              "Poblacion 1",
              "Poblacion 2",
              "Saud",
              "Subec",
              "Tarrag"
            ]
          },
          {
            "k": "Paoay",
            "v": [
              "Bacsil",
              "Cabagoan",
              "Cabangaran",
              "Callaguip",
              "Cayubog",
              "Dolores",
              "Laoa",
              "Masintoc",
              "Monte",
              "Mumulaan",
              "Nagbacalan",
              "Nalasin",
              "Nanguyudan",
              "Oaig-upay-abulao",
              "Pambaran",
              "Pannaratan (Pob.)",
              "Paratong",
              "Pasil",
              "Salbang (Pob.)",
              "San Agustin",
              "San Blas (Pob.)",
              "San Juan",
              "San Pedro",
              "San Roque (Pob.)",
              "Sangladan Pob. (Nalbuan)",
              "Santa Rita (Pob.)",
              "Sideg",
              "Suba",
              "Sungadan",
              "Surgui",
              "Veronica"
            ]
          },
          {
            "k": "Pasuquin",
            "v": [
              "Batuli",
              "Binsang",
              "Caruan",
              "Carusipan",
              "Dadaeman",
              "Darupidip",
              "Davila",
              "Dilanis",
              "Dilavo",
              "Estancia",
              "Naglicuan",
              "Nagsanga",
              "Nalvo (Cababaan-nalvo)",
              "Ngabangab",
              "Pangil",
              "Poblacion 1",
              "Poblacion 2",
              "Poblacion 3",
              "Poblacion 4",
              "Pragata (Pragata-bungro)",
              "Puyupuyan",
              "Salpad",
              "Sapat",
              "Sulbec",
              "Tabungao",
              "Tadao"
            ]
          },
          {
            "k": "Piddig",
            "v": [
              "Ab-abut",
              "Anao (Pob.)",
              "Arua-ay",
              "Bimmanga",
              "Boyboy",
              "Cabaroan (Pob.)",
              "Callusa",
              "Estancia",
              "Gayamat",
              "Lagandit",
              "Libnaoan",
              "Loing (Pob.)",
              "Maab-abaca",
              "Mangitayag",
              "San Antonio",
              "Santa Maria",
              "Sucsuquen",
              "Tangaoan",
              "Tonoton"
            ]
          },
          {
            "k": "Pinili",
            "v": [
              "Aglipay",
              "Apatut-lubong",
              "Badio",
              "Barbar",
              "Buanga",
              "Bulbulala",
              "Capangdanan",
              "Dalayap",
              "Darat",
              "Gulpeng",
              "Pagdilao (Pob.)",
              "Puritac",
              "Puzol",
              "Tartarabang",
              "Upon",
              "Valbuena (Pob.)"
            ]
          },
          {
            "k": "San Nicolas",
            "v": [
              "San Agustin",
              "San Baltazar (Pob.)",
              "San Bartolome (Pob.)",
              "San Cayetano (Pob.)",
              "San Eugenio (Pob.)",
              "San Fernando (Pob.)",
              "San Francisco (Pob.)",
              "San Gregorio (Pob.)",
              "San Guillermo",
              "San Ildefonso (Pob.)",
              "San Jose (Pob.)",
              "San Juan Bautista (Pob.)",
              "San Lorenzo",
              "San Lucas (Pob.)",
              "San Marcos (Payas)",
              "San Miguel (Pob.)",
              "San Pablo",
              "San Paulo (Pob.)",
              "San Pedro (Bingao)",
              "San Rufino (Pob.)",
              "San Silvestre (Pob.)",
              "Santa Asuncion (Samac)",
              "Santa Cecilia (Barabar)",
              "Santa Monica (Nagrebcan)"
            ]
          },
          {
            "k": "Sarrat",
            "v": [
              "San Agustin (Pob.)",
              "San Andres",
              "San Antonio",
              "San Bernabe",
              "San Cristobal",
              "San Felipe",
              "San Francisco (Pob.)",
              "San Isidro",
              "San Joaquin (Pob.)",
              "San Jose",
              "San Juan",
              "San Leandro (Pob.)",
              "San Lorenzo",
              "San Manuel",
              "San Marcos",
              "San Nicolas",
              "San Pedro",
              "San Roque",
              "San Vicente (Pob.)",
              "Santa Barbara (Pob.)",
              "Santa Magdalena",
              "Santa Rosa",
              "Santo Santiago",
              "Santo Tomas"
            ]
          },
          {
            "k": "Solsona",
            "v": [
              "Aguitap",
              "Bagbag",
              "Bagbago",
              "Barcelona",
              "Capurictan",
              "Darasdas",
              "Juan (Pob.)",
              "Laureta (Pob.)",
              "Manalpac",
              "Nagpatpatan",
              "Puttao",
              "San Juan",
              "San Julian",
              "Santa Ana",
              "Santiago",
              "Talugtog"
            ]
          },
          {
            "k": "Vintar",
            "v": [
              "Alejo Malasig",
              "Alsem",
              "Bulbulala",
              "Cabangaran",
              "Columbia",
              "Dagupan",
              "Lubnac",
              "Margaay",
              "Parparoroc",
              "Parut",
              "Salsalamagui",
              "San Nicolas (Pob.)",
              "San Pedro (Pob.)",
              "San Ramon (Pob.)",
              "San Roque (Pob.)",
              "Santa Maria (Pob.)"
            ]
          }
        ],
        "names": [
          "Bacarra",
          "Badoc",
          "Bangui",
          "Banna (Espiritu)",
          "Batac",
          "Burgos",
          "Currimao",
          "Dingras",
          "Laoag City",
          "Marcos",
          "Nueva Era",
          "Pagudpud",
          "Paoay",
          "Pasuquin",
          "Piddig",
          "Pinili",
          "San Nicolas",
          "Sarrat",
          "Solsona",
          "Vintar"
        ]
      }
    },
    {
      "k": "Ilocos Sur",
      "v": {
        "lookup": [
          {
            "k": "Banayoyo",
            "v": [
              "Bagbagotot",
              "Banbanaal",
              "Bisangol",
              "Cadanglaan",
              "Casilagan Norte",
              "Casilagan Sur",
              "Elefante",
              "Guardia",
              "Lintic",
              "Lopez",
              "Montero",
              "Naguimba",
              "Pila",
              "Poblacion"
            ]
          },
          {
            "k": "Bantay",
            "v": [
              "Aggay",
              "An-annam",
              "Balaleng",
              "Banaoang",
              "Barangay 1 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 4 (Pob.)",
              "Barangay 5 (Pob.)",
              "Barangay 6 (Pob.)",
              "Bulag",
              "Buquig",
              "Cabalanggan",
              "Cabaroan",
              "Cabusligan",
              "Capangdanan",
              "Guimod",
              "Lingsat",
              "Malingeb",
              "Mira",
              "Naguiddayan",
              "Ora",
              "Paing",
              "Puspus",
              "Quimmarayan",
              "Sagneb",
              "Sagpat",
              "San Isidro",
              "San Julian",
              "San Mariano (Sallacong)",
              "Sinabaan",
              "Taguiporo",
              "Taleb",
              "Tay-ac"
            ]
          },
          {
            "k": "Burgos",
            "v": [
              "Ambugat",
              "Balugang",
              "Bangbangar",
              "Bessang",
              "Cabcaburao",
              "Cadacad",
              "Dayanki",
              "Luna",
              "Mambug",
              "Nagpanaoan",
              "Patac",
              "Poblacion Norte (Bato)",
              "Poblacion Sur (Masingit)",
              "Sabangan Pinggan",
              "Subadi Norte",
              "Subadi Sur"
            ]
          },
          {
            "k": "Cabugao",
            "v": [
              "Alinaay",
              "Aragan",
              "Arnap",
              "Baclig (Pob.)",
              "Bato",
              "Bonifacio (Pob.)",
              "Bungro",
              "Cacadiran",
              "Caellayan",
              "Carusipan",
              "Catucdaan",
              "Cuancabal",
              "Cuantacla",
              "Daclapan",
              "Dardarat",
              "Lipit",
              "Margaay",
              "Nagsantaan",
              "Nagsincaoan",
              "Namruangan",
              "Pila",
              "Pug-os",
              "Quezon (Pob.)",
              "Reppaac",
              "Rizal (Pob.)",
              "Sabang",
              "Sagayaden",
              "Salapasap",
              "Salomague",
              "Sisim",
              "Turod",
              "Turod-patac"
            ]
          },
          {
            "k": "Candon City",
            "v": [
              "Allangigan Primero",
              "Allangigan Segundo",
              "Amguid",
              "Ayudante",
              "Bagani Camposanto",
              "Bagani Gabor",
              "Bagani Tocgo",
              "Bagani Ubbog",
              "Bagar",
              "Balingaoan",
              "Bugnay",
              "Calaoaan",
              "Calongbuyan",
              "Caterman",
              "Cubcubboot",
              "Darapidap",
              "Langlangca Primero",
              "Langlangca Segundo",
              "Oaig-daya",
              "Palacapac",
              "Paras",
              "Parioc Primero",
              "Parioc Segundo",
              "Patpata Primero",
              "Patpata Segundo",
              "Paypayad",
              "Salvador Primero",
              "Salvador Segundo",
              "San Agustin",
              "San Andres",
              "San Antonio (Pob.)",
              "San Isidro (Pob.)",
              "San Jose (Pob.)",
              "San Juan (Pob.)",
              "San Nicolas",
              "San Pedro",
              "Santo Tomas",
              "Tablac",
              "Talogtog",
              "Tamurong Primero",
              "Tamurong Segundo",
              "Villarica"
            ]
          },
          {
            "k": "Caoayan",
            "v": [
              "Anonang Mayor",
              "Anonang Menor",
              "Baggoc",
              "Callaguip",
              "Caparacadan",
              "Don Alejandro Quirolgico (Pob.)",
              "Don Dimas Querubin (Pob.)",
              "Don Lorenzo Querubin (Pob.)",
              "Fuerte",
              "Manangat",
              "Naguilian",
              "Nansuagao",
              "Pandan",
              "Pantay Tamurong",
              "Pantay-quitiquit",
              "Villamar"
            ]
          },
          {
            "k": "Galimuyod",
            "v": [
              "Abaya",
              "Baracbac",
              "Bidbiday",
              "Bitong",
              "Borobor",
              "Calimugtong",
              "Calongbuyan",
              "Calumbaya",
              "Daldagan",
              "Kilang",
              "Legaspi",
              "Mabayag",
              "Matanubong",
              "Mckinley",
              "Nagsingcaoan",
              "Oaig-daya",
              "Pagangpang",
              "Patac",
              "Poblacion",
              "Rubio",
              "Sabangan-bato",
              "Sacaang",
              "San Vicente",
              "Sapang"
            ]
          },
          {
            "k": "Lidlidda",
            "v": [
              "Bequi-walin",
              "Bugui",
              "Calungbuyan",
              "Carcarabasa",
              "Poblacion Norte",
              "Poblacion Sur",
              "Suysuyan",
              "Tay-ac"
            ]
          },
          {
            "k": "Magsingal",
            "v": [
              "Alangan",
              "Bacar",
              "Barbarit",
              "Bungro",
              "Cabaroan",
              "Caraisan",
              "Dacutan",
              "Labut",
              "Maas-asin",
              "Macatcatud",
              "Manzante",
              "Maratudo",
              "Miramar",
              "Namalpalan",
              "Napo",
              "Pagsanaan Norte",
              "Pagsanaan Sur",
              "Panay Norte",
              "Panay Sur",
              "Patong",
              "Puro",
              "San Basilio (Pob.)",
              "San Clemente (Pob.)",
              "San Julian (Pob.)",
              "San Lucas (Pob.)",
              "San Ramon (Pob.)",
              "San Vicente (Pob.)",
              "Santa Monica",
              "Sarsaracat"
            ]
          },
          { "k": "Nagbukel", "v": ["Mission"] },
          {
            "k": "Narvacan",
            "v": [
              "Abuor",
              "Aquib",
              "Bantay Abot",
              "Bulanos",
              "Cagayungan",
              "Camarao",
              "Codoog",
              "Dasay",
              "Dinalaoan",
              "Lungog",
              "Margaay",
              "Naguneg",
              "Orence",
              "Pantoc",
              "Paratong",
              "Quinarayan",
              "Rivadavia",
              "San Antonio",
              "San Jose (Pob.)",
              "San Pablo",
              "San Pedro",
              "Santa Lucia (Pob.)",
              "Sulvec",
              "Turod"
            ]
          },
          {
            "k": "Salcedo (Baugen)",
            "v": [
              "Atabay",
              "Balidbid",
              "Baybayading",
              "Calangcuasan",
              "Kaliwakiw",
              "Poblacion Norte",
              "Poblacion Sur",
              "San Tiburcio"
            ]
          },
          { "k": "San Emilio", "v": ["Cabaroan (Pob.)"] },
          {
            "k": "San Esteban",
            "v": [
              "Ansad",
              "Apatot",
              "Bateria",
              "Cabaroan",
              "Cappa-cappa",
              "Poblacion",
              "San Nicolas",
              "San Pablo",
              "San Rafael",
              "Villa Quirino"
            ]
          },
          {
            "k": "San Ildefonso",
            "v": [
              "Arnap",
              "Bahet",
              "Belen",
              "Bungro",
              "Busiing Norte",
              "Busiing Sur",
              "Dongalo",
              "Gongogong",
              "Iboy",
              "Kinamantirisan",
              "Otol-patac",
              "Poblacion East",
              "Poblacion West",
              "Sagneb",
              "Sagsagat"
            ]
          },
          {
            "k": "San Juan (Lapog)",
            "v": [
              "Asilang",
              "Bacsil",
              "Baliw",
              "Bannuar (Pob.)",
              "Barbar",
              "Cabanglotan",
              "Cacandongan",
              "Camanggaan",
              "Camindoroan",
              "Caronoan",
              "Darao",
              "Dardarat",
              "Guimod Norte",
              "Guimod Sur",
              "Immayos Norte",
              "Immayos Sur",
              "Labnig",
              "Lapting",
              "Lira (Pob.)",
              "Malamin",
              "Muraya",
              "Nagsabaran",
              "Nagsupotan",
              "Pandayan (Pob.)",
              "Refaro",
              "Resurreccion (Pob.)",
              "Sabangan",
              "San Isidro",
              "Saoang",
              "Solotsolot",
              "Sunggiam",
              "Surngit"
            ]
          },
          {
            "k": "San Vicente",
            "v": [
              "Bantaoay",
              "Bayubay Norte",
              "Bayubay Sur",
              "Lubong",
              "Poblacion",
              "Pudoc",
              "San Sebastian"
            ]
          },
          {
            "k": "Santa",
            "v": [
              "Ampandula",
              "Banaoang",
              "Basug",
              "Bucalag",
              "Cabangaran",
              "Calungboyan",
              "Casiber",
              "Labut Norte",
              "Labut Sur",
              "Mabilbila Norte",
              "Mabilbila Sur",
              "Magsaysay District (Pob.)",
              "Manueva",
              "Marcos (Pob.)",
              "Nagpanaoan",
              "Namalangan",
              "Oribi",
              "Pasungol",
              "Quezon (Pob.)",
              "Quirino (Pob.)",
              "Rancho",
              "Rizal",
              "Sacuyya Norte",
              "Sacuyya Sur",
              "Tabucolan"
            ]
          },
          {
            "k": "Santa Catalina",
            "v": [
              "Cabaroan",
              "Cabittaogan",
              "Cabuloan",
              "Pangada",
              "Paratong",
              "Poblacion",
              "Sinabaan",
              "Subec",
              "Tamorong"
            ]
          },
          {
            "k": "Santa Cruz",
            "v": [
              "Bacsayan",
              "Bayugao Este",
              "Bayugao Oeste",
              "Calaoaan",
              "Camanggaan",
              "Candalican",
              "Capariaan",
              "Casilagan",
              "Dili",
              "Gabor Norte",
              "Gabor Sur",
              "Lalong",
              "Lantag",
              "Las-ud",
              "Mambog",
              "Mantanas",
              "Nagtengnga",
              "Padaoil",
              "Paratong",
              "Pattiqui",
              "Pidpid",
              "Pilar",
              "Pinipin",
              "Poblacion Este",
              "Poblacion Norte",
              "Poblacion Sur",
              "Poblacion Weste",
              "Quinfermin",
              "Quinsoriano",
              "Sagat",
              "San Pedro",
              "Saoat",
              "Sevilla",
              "Sidaoen",
              "Suyo",
              "Tampugo",
              "Turod",
              "Villa Garcia",
              "Villa Hermosa",
              "Villa Laurencia"
            ]
          },
          {
            "k": "Santa Lucia",
            "v": [
              "Alincaoeg",
              "Angkileng",
              "Ayusan (Pob.)",
              "Bao-as",
              "Barangobong (Pob.)",
              "Burgos (Pob.)",
              "Catayagan",
              "Conconig West",
              "Damacuag",
              "Luba",
              "Lubong",
              "Nagrebcan",
              "Nagtablaan",
              "Namatican",
              "Nangalisan",
              "Palali Norte",
              "Palali Sur",
              "Paratong",
              "Pila East",
              "Pila West",
              "Quinabalayangan",
              "Ronda",
              "Sabuanan",
              "San Juan",
              "San Pedro",
              "Suagayan",
              "Vical"
            ]
          },
          {
            "k": "Santa Maria",
            "v": [
              "Ampuagan",
              "Baliw Daya",
              "Baliw Laud",
              "Bia-o",
              "Cabaroan",
              "Danuman East",
              "Danuman West",
              "Dunglayan",
              "Langaoan",
              "Laslasong Norte",
              "Laslasong Sur",
              "Laslasong West",
              "Lingsat",
              "Lubong",
              "Lubong Laud",
              "Maynganay Norte",
              "Maynganay Sur",
              "Nagsayaoan",
              "Nagtupacan",
              "Nalvo",
              "Poblacion Norte",
              "Poblacion Sur",
              "Silag",
              "Sumagui",
              "Suso",
              "Tangaoan",
              "Tinaan"
            ]
          },
          {
            "k": "Santiago",
            "v": [
              "Ambucao",
              "Baybayabas",
              "Bigbiga",
              "Bulbulala",
              "Busel-busel",
              "Butol",
              "Caburao",
              "Dan-ar",
              "Gabao",
              "Guinabang",
              "Imus",
              "Lang-ayan",
              "Mambug",
              "Nalasin",
              "Olo-olo Norte",
              "Olo-olo Sur",
              "Poblacion Norte",
              "Poblacion Sur",
              "Sabangan",
              "San Jose (Baraoas)",
              "San Roque",
              "Ubbog"
            ]
          },
          {
            "k": "Santo Domingo",
            "v": [
              "Binalayangan",
              "Binongan",
              "Borobor",
              "Cabaritan",
              "Cabigbigaan",
              "Calautit",
              "Calay-ab",
              "Camestizoan",
              "Casili",
              "Flora",
              "Lagatit",
              "Laoingen",
              "Lussoc",
              "Naglaoa-an",
              "Nalasin",
              "Nambaran",
              "Nanerman",
              "Napo",
              "Padu Chico",
              "Padu Grande",
              "Paguraper",
              "Panay",
              "Pangpangdan",
              "Parada",
              "Paras",
              "Poblacion",
              "Puerta Real",
              "Pussuac",
              "Quimmarayan",
              "San Pablo",
              "Santa Cruz",
              "Santo Tomas",
              "Sived",
              "Suksukit",
              "Vacunero"
            ]
          },
          {
            "k": "Sinait",
            "v": [
              "Baliw",
              "Ballaigui (Pob.)",
              "Baracbac",
              "Barikir",
              "Battog",
              "Binacud",
              "Cabangtalan",
              "Cabarambanan",
              "Cabulalaan",
              "Cadanglaan",
              "Calanutian",
              "Curtin",
              "Dadalaquiten Norte",
              "Dadalaquiten Sur",
              "Dean Leopoldo Yabes (Pug-os)",
              "Duyayyat",
              "Jordan",
              "Katipunan",
              "Macabiag (Pob.)",
              "Magsaysay",
              "Marnay",
              "Masadag",
              "Nagbalioartian",
              "Nagcullooban",
              "Nagongburan",
              "Namnama (Pob.)",
              "Pacis",
              "Paratong",
              "Purag",
              "Quimmallogong",
              "Rang-ay (Pob.)",
              "Ricudo",
              "SabaÃ±gan (Marcos)",
              "Sallacapo",
              "Santa Cruz",
              "Tapao",
              "Teppeng",
              "Tubigay",
              "Ubbog",
              "Zapat"
            ]
          },
          {
            "k": "Tagudin",
            "v": [
              "Ag-aguman",
              "Baracbac",
              "Bario-an",
              "Baritao",
              "Becques",
              "Bimmanga",
              "Bio",
              "Bitalag",
              "Borono",
              "Bucao East",
              "Bucao West",
              "Cabaroan",
              "Cabulanglangan",
              "Dacutan",
              "Dardarat",
              "Del Pilar (Pob.)",
              "Farola",
              "Gabur",
              "Garitan",
              "Jardin",
              "Lacong",
              "Lantag",
              "Las-ud",
              "Libtong",
              "Lubnac",
              "Magsaysay (Pob.)",
              "MalacaÃ±ang",
              "Pula",
              "Quirino (Pob.)",
              "Ranget",
              "Rizal (Pob.)",
              "Salvacion",
              "San Miguel",
              "Tallaoen",
              "Tampugo",
              "Tarangotong"
            ]
          },
          {
            "k": "Vigan City",
            "v": [
              "Ayusan Norte",
              "Ayusan Sur",
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Barangay Ix",
              "Barangay V (Pob.)",
              "Barangay Vi (Pob.)",
              "Barangay Vii",
              "Barangay Viii",
              "Barraca",
              "Beddeng Daya",
              "Beddeng Laud",
              "Bongtolan",
              "Bulala",
              "Cabalangegan",
              "Cabaroan Daya",
              "Cabaroan Laud",
              "Camangaan",
              "Capangpangan",
              "Mindoro",
              "Nagsangalan",
              "Pantay Daya",
              "Pantay Fatima",
              "Pantay Laud",
              "Paoa",
              "Paratong",
              "Pong-ol",
              "Purok-a-bassit",
              "Purok-a-dackel",
              "Raois",
              "Rugsuanan",
              "Salindeg",
              "San Jose",
              "San Julian Norte",
              "San Julian Sur",
              "San Pedro",
              "Tamag"
            ]
          }
        ],
        "names": [
          "Banayoyo",
          "Bantay",
          "Burgos",
          "Cabugao",
          "Candon City",
          "Caoayan",
          "Galimuyod",
          "Lidlidda",
          "Magsingal",
          "Nagbukel",
          "Narvacan",
          "Salcedo (Baugen)",
          "San Emilio",
          "San Esteban",
          "San Ildefonso",
          "San Juan (Lapog)",
          "San Vicente",
          "Santa",
          "Santa Catalina",
          "Santa Cruz",
          "Santa Lucia",
          "Santa Maria",
          "Santiago",
          "Santo Domingo",
          "Sinait",
          "Tagudin",
          "Vigan City"
        ]
      }
    },
    {
      "k": "Iloilo",
      "v": {
        "lookup": [
          {
            "k": "Ajuy",
            "v": [
              "Central",
              "Lanjagan",
              "Pinantan Diel",
              "Poblacion",
              "Puente Bunglas",
              "San Antonio",
              "Tanduyan",
              "Tipacla"
            ]
          },
          {
            "k": "Alimodian",
            "v": [
              "Baguingin-lanot",
              "Balabago",
              "Bancal",
              "Binalud",
              "Buhay",
              "Poblacion"
            ]
          },
          {
            "k": "Anilao",
            "v": [
              "Badiang",
              "Dangula-an",
              "Poblacion",
              "Sambag Culob",
              "San Carlos",
              "Santa Rita",
              "Serallo"
            ]
          },
          {
            "k": "Arevalo",
            "v": [
              "Calaparan",
              "Dulonan",
              "Mohon",
              "San Jose (Arevalo)",
              "Santa Cruz",
              "Santa Filomena",
              "Santo NiÃ±o Norte",
              "Santo NiÃ±o Sur",
              "So-oc",
              "Yulo Drive"
            ]
          },
          { "k": "Badiangan", "v": ["Poblacion (Badiangan)"] },
          {
            "k": "Balasan",
            "v": [
              "Aranjuez",
              "Balanti-an",
              "Camambugan",
              "Ipil",
              "Kinalkalan",
              "Mamhut Sur",
              "Maya",
              "Pani-an",
              "Poblacion Norte",
              "Poblacion Sur"
            ]
          },
          {
            "k": "Banate",
            "v": [
              "Alacaygan",
              "Belen",
              "Bularan",
              "Carmelo",
              "Fuentes*",
              "Merced",
              "Poblacion",
              "Talokgangan",
              "Zona Sur"
            ]
          },
          {
            "k": "Barotac Nuevo",
            "v": [
              "Acuit",
              "Agcuyawan Calsada",
              "Baras",
              "Bungca",
              "Cruz",
              "Ilaud Poblacion",
              "Ilaya Poblacion",
              "Jalaud",
              "Lagubang",
              "Lico-an",
              "Tabuc-suba",
              "Tabucan",
              "Tinorian",
              "Tiwi"
            ]
          },
          { "k": "Barotac Viejo", "v": ["Natividad", "Poblacion"] },
          {
            "k": "Batad",
            "v": [
              "Batad Viejo",
              "Binon-an",
              "Cabagohan",
              "Caw-i",
              "Hamod",
              "Poblacion",
              "Santa Ana",
              "Tapi-an"
            ]
          },
          {
            "k": "Bingawan",
            "v": ["Agba-o", "Cairohan", "Ngingi-an", "Poblacion", "Quinar-upan"]
          },
          {
            "k": "Cabatuan",
            "v": [
              "Amerang",
              "Ayaman",
              "Bacan",
              "Baluyan",
              "Banguit",
              "Gines Patag",
              "Pungtod",
              "Tabucan",
              "Tacdangan",
              "Talanghauan",
              "Tigbauan Road",
              "Tiring",
              "Zone I Pob. (Barangay 1)",
              "Zone Ii Pob. (Barangay 2)",
              "Zone Iii Pob. (Barangay 3)",
              "Zone Iv Pob. (Barangay 4)",
              "Zone Ix Pob. (Barangay 9)",
              "Zone V Pob. (Barangay 5)",
              "Zone Vi Pob. (Barangay 6 )",
              "Zone Vii Pob. (Barangay 7)",
              "Zone Viii Pob. (Barangay 8)",
              "Zone X Pob. (Barangay 10)",
              "Zone Xi Pob. (Barangay 11)"
            ]
          },
          {
            "k": "Calinog",
            "v": [
              "Baje San Julian",
              "Barrio Calinog",
              "Cabagiao",
              "Cabugao",
              "Camalongo",
              "Canabajan",
              "Carvasana",
              "Dalid",
              "Guiso",
              "Lampaya",
              "Libot",
              "Poblacion Centro",
              "Poblacion Delgado",
              "Poblacion Ilaya",
              "Poblacion Rizal Ilaud",
              "Simsiman"
            ]
          },
          {
            "k": "Carles",
            "v": [
              "Abong",
              "Bancal",
              "Barosbos",
              "Bolo",
              "Cabilao PequeÃ±o",
              "Cawayan",
              "Dayhagan",
              "Guinticgan",
              "Nalumsan",
              "Poblacion",
              "Tarong"
            ]
          },
          {
            "k": "City Of Passi",
            "v": [
              "Imbang Grande",
              "Man-it",
              "Poblacion Ilawod",
              "Poblacion Ilaya"
            ]
          },
          {
            "k": "Concepcion",
            "v": [
              "Aglosong",
              "Agnaga",
              "Bacjawan Norte",
              "Bacjawan Sur",
              "Batiti",
              "Calamigan",
              "Jamul-awon",
              "Poblacion"
            ]
          },
          {
            "k": "Dingle",
            "v": [
              "Abangay",
              "Dawis",
              "Ilajas",
              "Libo-o",
              "Licu-an",
              "Moroboro",
              "Pandan",
              "Poblacion",
              "San Jose",
              "San Matias",
              "Tabugon",
              "Tanghawan",
              "Tinocuan"
            ]
          },
          {
            "k": "DueÃ±as",
            "v": [
              "Capuling",
              "Pandan",
              "Poblacion A",
              "Poblacion B",
              "Poblacion C",
              "Poblacion D",
              "Ponong Grande",
              "Tinocuan"
            ]
          },
          {
            "k": "Dumangas",
            "v": [
              "Aurora-del Pilar (Pob.)",
              "Bacong",
              "Balabag",
              "Basa-mabini Bonifacio (Pob.)",
              "Buenaflor Embarkadero (Pob.)",
              "Burgos-regidor (Pob.)",
              "Dacutan",
              "Ilaya 1st",
              "Ilaya 2nd",
              "Ilaya 3rd",
              "Jardin",
              "Lopez Jaena - Rizal (Pob.)",
              "Pd Monfort North (Lublub)",
              "Pd Monfort South (Guinsampanan)",
              "Pulao",
              "Sulangan",
              "Tabucan"
            ]
          },
          {
            "k": "Estancia",
            "v": [
              "Bayuyan",
              "Botongon",
              "BulaqueÃ±a",
              "Cano-an",
              "Malbog",
              "Pa-on",
              "Poblacion Zone 1",
              "Poblacion Zone Ii",
              "Poblacion Zone Iii",
              "Santa Ana",
              "Tabu-an",
              "Tacbuyan",
              "Villa Pani-an"
            ]
          },
          {
            "k": "Guimbal",
            "v": [
              "Anono-o",
              "Bagumbayan Pob. (Bagumbayan-granada)",
              "Balantad-carlos Fruto (Pob.)",
              "Baras",
              "Bongol San Vicente",
              "Bulad",
              "Burgos-gengos (Pob.)",
              "Cabasi",
              "Cabubugan",
              "Generosa-cristobal Colon (Pob.)",
              "Gerona-gimeno (Pob.)",
              "Girado-magsaysay (Pob.)",
              "Gotera (Pob.)",
              "Libo-on Gonzales (Pob.)",
              "Torreblanca-blumentritt (Pob.)"
            ]
          },
          {
            "k": "Igbaras",
            "v": [
              "Barangay 1 Poblacion",
              "Barangay 2 Poblacion",
              "Barangay 3 Poblacion",
              "Barangay 4 Poblacion",
              "Barangay 5 Poblacion",
              "Barangay 6 Poblacion"
            ]
          },
          {
            "k": "Iloilo City",
            "v": [
              "Arsenal Aduana",
              "Baybay Tanza",
              "Bonifacio (Arevalo)",
              "Bonifacio Tanza",
              "Concepcion-montes",
              "Danao",
              "Delgado-jalandoni-bagumbayan",
              "Edganzon",
              "Flores",
              "General Hughes-montes",
              "Gloria",
              "Hipodromo",
              "Inday",
              "Jalandoni-wilson",
              "Kahirupan",
              "Katilingban",
              "Kauswagan",
              "Legaspi Dela Rama",
              "Liberation",
              "Lopez Jaena Norte",
              "Lopez Jaena Sur",
              "M. V. Hechanova",
              "Mabolo-delgado",
              "Magsaysay Village",
              "Malipayon-delgado",
              "Maria Clara",
              "Maria Cristina",
              "Monica Blumentritt",
              "Montinola",
              "Muelle Loney-montes",
              "Nonoy",
              "Ortiz",
              "President Roxas",
              "Quezon",
              "Rizal Estanzuela",
              "Rizal Ibarra",
              "Rizal Palapala I",
              "Rizal Palapala Ii",
              "Roxas Village",
              "Sampaguita",
              "San Agustin",
              "San Felix",
              "San Jose (City Proper)",
              "San Roque",
              "Santo Rosario-duran",
              "Tanza-esperanza",
              "Timawa Tanza I",
              "Timawa Tanza Ii",
              "Veterans Village",
              "Villa Anita",
              "Yulo-arroyo",
              "Zamora-melliza"
            ]
          },
          {
            "k": "Janiuay",
            "v": [
              "Aquino Nobleza East (Pob.)",
              "Aquino Nobleza West (Pob.)",
              "Capt. A. Tirador (Pob.)",
              "Concepcion Pob. (D.g. Abordo)",
              "Crispin Salazar North (Pob.)",
              "Crispin Salazar South (Pob.)",
              "Don T. Lutero Center (Pob.)",
              "Don T. Lutero East (Pob.)",
              "Don T. Lutero West Pob. (Don T. Lutero North)",
              "Golgota (Pob.)",
              "Locsin (Pob.)",
              "R. Armada (Pob.)",
              "S. M. Villa (Pob.)",
              "San Julian (Pob.)",
              "San Pedro (Pob.)",
              "Santa Rita (Pob.)"
            ]
          },
          {
            "k": "Jaro",
            "v": [
              "Arguelles",
              "Balabago",
              "Balantang",
              "Benedicto (Jaro)",
              "Bito-on",
              "Buhang",
              "Buhang Taft North",
              "Buntatala",
              "Calubihan",
              "Camalig",
              "Cuartero",
              "Cubay",
              "Democracia",
              "Desamparados",
              "Dungon",
              "El 98 Castilla (Claudio Lopez)",
              "Fajardo",
              "Javellana",
              "Lanit",
              "Libertad, Santa Isabel",
              "Lopez Jaena (Jaro)",
              "Luna (Jaro)",
              "Macarthur",
              "Our Lady Of Fatima",
              "Our Lady Of Lourdes",
              "Quintin Salas",
              "Sambag",
              "San Isidro (Jaro)",
              "San Jose (Jaro)",
              "San Pedro (Jaro)",
              "San Vicente",
              "Santa Rosa",
              "Santo Domingo",
              "Seminario (Burgos Jalandoni)",
              "Simon Ledesma",
              "Tabuc Suba (Jaro)",
              "Tacas",
              "Tagbac",
              "Ungka"
            ]
          },
          { "k": "Lambunao", "v": ["Poblacion Ilawod", "Poblacion Ilaya"] },
          {
            "k": "Lapaz",
            "v": [
              "Aguinaldo",
              "Baldoza",
              "Bantud",
              "Banuyao",
              "Burgos-mabini-plaza",
              "Caingin",
              "Divinagracia",
              "Gustilo",
              "Hinactacan",
              "Ingore",
              "Jereos",
              "Laguda",
              "Luna (La Paz)",
              "Magdalo",
              "Magsaysay",
              "Nabitasan",
              "Railway",
              "Rima-rizal",
              "Rizal (La Paz)",
              "San Isidro (La Paz)",
              "San Nicolas",
              "Tabuc Suba (La Paz)",
              "Ticud (La Paz)"
            ]
          },
          {
            "k": "Lapuz",
            "v": [
              "Alalasan Lapuz",
              "Don Esteban-lapuz",
              "Jalandoni Estate-lapuz",
              "Lapuz Norte",
              "Lapuz Sur",
              "Libertad-lapuz",
              "Loboc-lapuz",
              "Mansaya-lapuz",
              "Obrero-lapuz",
              "Progreso-lapuz",
              "Punong-lapuz",
              "Sinikway (Bangkerohan Lapuz)"
            ]
          },
          { "k": "Leganes", "v": ["Poblacion"] },
          { "k": "Lemery", "v": ["Poblacion Nw Zone", "Poblacion Se Zone"] },
          { "k": "Leon", "v": ["Poblacion"] },
          {
            "k": "Maasin",
            "v": [
              "Agrocel Pob. (Aguinaldo-roxas--celso Mayor)",
              "Amerang",
              "Bagacay East",
              "Bagacay West",
              "Delcar Pob. (Delgado-cartagena)",
              "Mabriz Pob. (Mabini-rizal)",
              "Mandog",
              "Thtp Pob. (Taft-thompson--hughes-del Pilar)"
            ]
          },
          {
            "k": "Mandurriao",
            "v": [
              "Abeto Mirasol Taft South (Quirino Abeto)",
              "Airport (Tabucan Airport)",
              "Bakhaw",
              "Bolilao",
              "Calahunan",
              "Dungon A",
              "Dungon B",
              "Guzman-jesena",
              "Hibao-an Norte",
              "Hibao-an Sur",
              "Navais",
              "OÃ±ate De Leon",
              "Pale Benedicto Rizal (Mandurriao)",
              "Phhc Block 17",
              "Phhc Block 22 Nha",
              "San Rafael",
              "Tabucan",
              "Taytay Zone Ii"
            ]
          },
          {
            "k": "Miagao",
            "v": [
              "Aguiauan",
              "Bacauan",
              "Banbanan",
              "Bangladan",
              "Banuyao",
              "Baybay Norte (Pob.)",
              "Baybay Sur (Pob.)",
              "Bolho (Pob.)",
              "Bugtong Naulid",
              "Caitib",
              "Calampitao",
              "Damilisan",
              "Dingle",
              "Durog",
              "Gines",
              "Guibongan",
              "Igpuro",
              "Igtuba",
              "Kirayan Norte",
              "Kirayan Sur",
              "Kirayan Tacas",
              "Lumangan",
              "Malagyan",
              "Maninila",
              "Maringyan",
              "Mat-y (Pob.)",
              "Narat-an",
              "Naulid",
              "Oyungan",
              "Palaca",
              "Potrido",
              "San Fernando",
              "San Rafael",
              "Tabunacan",
              "Tacas (Pob.)",
              "Tan-agan",
              "Ubos Ilawod (Pob.)",
              "Ubos Ilaya (Pob.)",
              "Valencia"
            ]
          },
          { "k": "Mina", "v": ["Mina East (Pob.)", "Mina West (Pob.)"] },
          {
            "k": "Molo",
            "v": [
              "Calumpang",
              "Cochero",
              "Compania",
              "East Baluarte",
              "East Timawa",
              "Habog-habog Salvacion",
              "Infante",
              "Kasingkasing",
              "Marcelo H. Del Pilar",
              "Molo Boulevard",
              "North AvanceÃ±a",
              "North Baluarte",
              "North Fundidor",
              "North San Jose",
              "OsmeÃ±a",
              "Poblacion Molo",
              "San Antonio",
              "San Juan",
              "San Pedro (Molo)",
              "South Baluarte",
              "South Fundidor",
              "South San Jose",
              "Taal",
              "Tap-oc",
              "West Habog-habog",
              "West Timawa"
            ]
          },
          { "k": "New Lucena", "v": ["Poblacion"] },
          {
            "k": "Oton",
            "v": [
              "Botong",
              "Poblacion East",
              "Poblacion North",
              "Poblacion South",
              "Poblacion West",
              "San Antonio",
              "San Nicolas",
              "Trapiche"
            ]
          },
          {
            "k": "Pavia",
            "v": [
              "Aganan",
              "Amparo",
              "Anilao",
              "Balabag",
              "Pandac",
              "Purok I (Pob.)",
              "Purok Ii (Pob.)",
              "Purok Iii (Pob.)",
              "Purok Iv (Pob.)",
              "Tigum",
              "Ungka I",
              "Ungka Ii"
            ]
          },
          {
            "k": "Pototan",
            "v": [
              "Fernando Parcon Ward (Pob.)",
              "Lopez Jaena Ward (Pob.)",
              "Primitivo Ledesma Ward (Pob.)",
              "San Jose Ward (Pob.)"
            ]
          },
          { "k": "San Dionisio", "v": ["Poblacion"] },
          {
            "k": "San Enrique",
            "v": ["Imbang PequeÃ±o", "Poblacion Ilawod", "Poblacion Ilaya"]
          },
          {
            "k": "San Joaquin",
            "v": [
              "Purok 1 (Pob.)",
              "Purok 2 (Pob.)",
              "Purok 3 (Pob.)",
              "Purok 4 (Pob.)",
              "Purok 5 (Pob.)"
            ]
          },
          {
            "k": "San Miguel",
            "v": [
              "Bgy. 1 Pob. (Roxas St.)",
              "Bgy. 10 (Pob. R.v.sanchez St. South)",
              "Bgy. 11 Pob. (R.v.sanchez St. North)",
              "Bgy. 12 Pob. (Sales Malaga Saliedo)",
              "Bgy. 13 Pob. (Sta.rita-saclauso St.)",
              "Bgy. 14 Pob. (San Miguel North)",
              "Bgy. 15 Pob. (San Miguel South)",
              "Bgy. 16 Pob. (San Agustin St.)",
              "Bgy. 2 Pob. (Savilla Sto. Rosario)",
              "Bgy. 3 Pob. (A.s.suarez St. Zone 1)",
              "Bgy. 4 Pob. (A.s.suarez East)",
              "Bgy. 5 Pob. (Santiago St.north)",
              "Bgy. 6 Pob. (Santiago St.south)",
              "Bgy. 7 Pob. (San Roque St.south)",
              "Bgy. 8 Pob. (Montano - San Roque)",
              "Bgy. 9 Pob. (Salazar San Jose)",
              "Consolacion",
              "Igtambo",
              "San Antonio",
              "San Jose",
              "Santa Cruz",
              "Santa Teresa",
              "Santo Angel",
              "Santo NiÃ±o"
            ]
          },
          { "k": "San Rafael", "v": ["Poblacion"] },
          {
            "k": "Santa Barbara",
            "v": [
              "Agutayan",
              "Bagumbayan",
              "Balabag",
              "Balibagan Este",
              "Balibagan Oeste",
              "Barangay Zone I (Pob.)",
              "Barangay Zone Ii (Pob.)",
              "Barangay Zone Iii (Pob.)",
              "Barangay Zone Iv (Pob.)",
              "Barangay Zone V (Pob.)",
              "Barangay Zone Vi (Pob.)",
              "Barasan Este",
              "Barasan Oeste",
              "Bolong Este",
              "Bolong Oeste",
              "Buyo",
              "Cabugao Norte",
              "Cabugao Sur",
              "Cadagmayan Norte",
              "Cadagmayan Sur",
              "Cafe",
              "Daga",
              "Duyanduyan",
              "Gen. Martin T. Delgado",
              "Guno",
              "Inangayan",
              "Jibao-an",
              "Lanag",
              "Magancina",
              "Malawog",
              "Mambuyo",
              "Manhayang",
              "Nasugban",
              "San Sebastian",
              "Sangcate",
              "Talanghauan",
              "Tigtig",
              "Tugas"
            ]
          },
          {
            "k": "Sara",
            "v": ["Poblacion Ilawod", "Poblacion Ilaya", "Poblacion Market"]
          },
          {
            "k": "Tigbauan",
            "v": [
              "Barangay 1 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 4 (Pob.)",
              "Barangay 5 (Pob.)",
              "Barangay 6 (Pob.)",
              "Barangay 7 (Pob.)",
              "Barangay 8 (Pob.)",
              "Barangay 9 (Pob.)"
            ]
          },
          {
            "k": "Tubungan",
            "v": ["Zone I (Pob.)", "Zone Ii (Pob.)", "Zone Iii (Pob.)"]
          },
          { "k": "Zarraga", "v": ["Ilawod Poblacion", "Ilaya Poblacion"] }
        ],
        "names": [
          "Ajuy",
          "Alimodian",
          "Anilao",
          "Arevalo",
          "Badiangan",
          "Balasan",
          "Banate",
          "Barotac Nuevo",
          "Barotac Viejo",
          "Batad",
          "Bingawan",
          "Cabatuan",
          "Calinog",
          "Carles",
          "City Of Passi",
          "Concepcion",
          "Dingle",
          "DueÃ±as",
          "Dumangas",
          "Estancia",
          "Guimbal",
          "Igbaras",
          "Iloilo City",
          "Janiuay",
          "Jaro",
          "Lambunao",
          "Lapaz",
          "Lapuz",
          "Leganes",
          "Lemery",
          "Leon",
          "Maasin",
          "Mandurriao",
          "Miagao",
          "Mina",
          "Molo",
          "New Lucena",
          "Oton",
          "Pavia",
          "Pototan",
          "San Dionisio",
          "San Enrique",
          "San Joaquin",
          "San Miguel",
          "San Rafael",
          "Santa Barbara",
          "Sara",
          "Tigbauan",
          "Tubungan",
          "Zarraga"
        ]
      }
    },
    {
      "k": "Isabela",
      "v": {
        "lookup": [
          {
            "k": "Alicia",
            "v": [
              "Antonino (Pob.)",
              "Apanay",
              "Aurora",
              "Burgos",
              "Calaocan (Pob.)",
              "Callao",
              "Dagupan",
              "Linglingay",
              "M.h. Del Pilar",
              "Mabini",
              "Magsaysay (Pob.)",
              "Paddad",
              "Rizal",
              "Rizaluna",
              "Salvacion",
              "San Antonio (Pob.)",
              "San Fernando",
              "Santa Cruz",
              "Santa Maria",
              "Santo Tomas",
              "Victoria",
              "Zamora"
            ]
          },
          {
            "k": "Angadanan",
            "v": [
              "Baniket",
              "Calabayan-minanga",
              "Centro I (Pob.)",
              "Centro Ii (Pob.)",
              "Centro Iii (Pob.)",
              "Dalakip",
              "Lomboy",
              "Minanga Proper",
              "Pissay"
            ]
          },
          {
            "k": "Aurora",
            "v": [
              "Bagong Tanza",
              "Ballesteros",
              "Bolinao",
              "Caipilan",
              "Camarunggayan",
              "Diamantina",
              "Esperanza East",
              "Kalabaza",
              "Malasin",
              "San Jose (Pob.)",
              "San Juan (Pob.)",
              "San Pedro-san Pablo (Pob.)",
              "Santa Rita",
              "Santa Rosa",
              "Saranay",
              "Villa Nuesa"
            ]
          },
          {
            "k": "Benito Soliven",
            "v": [
              "Andabuen",
              "District I (Pob.)",
              "District Ii (Pob.)",
              "Sinipit",
              "Yeban Norte",
              "Yeban Sur"
            ]
          },
          {
            "k": "Burgos",
            "v": ["Bacnor East", "Bacnor West", "Caliguian (Pob.)", "Masigun"]
          },
          {
            "k": "Cabagan",
            "v": [
              "Anao",
              "Angancasilian",
              "Balasig",
              "Cansan",
              "Casibarag Norte",
              "Casibarag Sur",
              "Catabayungan",
              "Centro (Pob.)",
              "Cubag",
              "Garita",
              "Luquilu",
              "Magassi",
              "Masipi East",
              "Masipi West",
              "Ngarag",
              "Pilig Abajo",
              "San Bernardo",
              "San Juan",
              "Saui",
              "Tallag",
              "Ugad",
              "Union"
            ]
          },
          {
            "k": "Cabatuan",
            "v": [
              "Calaocan",
              "Centro (Pob.)",
              "Culing Centro",
              "Culing East",
              "Culing West",
              "Del Corpuz",
              "Del Pilar",
              "Diamantina",
              "Luzon",
              "Macalaoat",
              "Magdalena",
              "Namnama",
              "Nueva Era",
              "Paraiso",
              "Rang-ay (Caggong)",
              "Sampaloc",
              "San Andres (Teodoro Abad)",
              "Saranay",
              "Tandul"
            ]
          },
          {
            "k": "Cauayan City",
            "v": [
              "Alicaocao",
              "Alinam",
              "Andarayan",
              "Baringin Norte",
              "Baringin Sur",
              "Buena Suerte",
              "Bugallon",
              "Buyon",
              "Cabaruan",
              "Cabugao",
              "Carabatan Bacareno",
              "Carabatan Chica",
              "Cassap Fuera",
              "Catalina",
              "Culalabat",
              "Dabburab",
              "District I (Pob.)",
              "District Ii (Pob.)",
              "District Iii (Pob.)",
              "Duminit",
              "Faustino (Sipay)",
              "Labinab",
              "Marabulig I",
              "Marabulig Ii",
              "Minante I",
              "Minante Ii",
              "Naganacan",
              "Nagrumbuan",
              "Nungnungan I",
              "Nungnungan Ii",
              "Pinoma",
              "San Antonio",
              "San Fermin",
              "San Francisco",
              "San Isidro",
              "Santa Luciana (Daburab 2)",
              "Sillawit",
              "Tagaran",
              "Turayong"
            ]
          },
          {
            "k": "City Of Santiago",
            "v": [
              "Abra",
              "Ambalatungan",
              "Balintocatoc",
              "Baluarte",
              "Bannawag Norte",
              "Batal",
              "Buenavista",
              "Cabulay",
              "Calao East (Pob.)",
              "Calao West (Pob.)",
              "Calaocan",
              "Centro East (Pob.)",
              "Centro West (Pob.)",
              "Divisoria",
              "Dubinan East",
              "Dubinan West",
              "Luna",
              "Mabini",
              "Malvar",
              "Nabbuan",
              "Naggasican",
              "Patul",
              "Plaridel",
              "Rizal",
              "Rosario",
              "Sagana",
              "Salvador",
              "San Andres",
              "San Isidro",
              "San Jose",
              "Santa Rosa",
              "Sinili",
              "Sinsayon",
              "Victory Norte",
              "Victory Sur",
              "Villa Gonzaga",
              "Villasis"
            ]
          },
          {
            "k": "Cordon",
            "v": [
              "Aguinaldo (Rizaluna Este)",
              "Calimaturod",
              "Capirpiriwan",
              "Caquilingan (San Luis)",
              "Gayong",
              "Laurel (Centro Norte)",
              "Magsaysay (Centro Sur Oeste)",
              "Malapat",
              "Osmena (Centro Sur Este)",
              "Quezon (Centro Norte Este)",
              "Quirino (Manasin)",
              "Roxas Pob. (Centro Sur)",
              "Sagat",
              "San Juan (San Juan Este)",
              "Tanggal",
              "Tarinsing",
              "Turod Norte",
              "Turod Sur"
            ]
          },
          {
            "k": "Delfin Albano (Magsaysay)",
            "v": [
              "Aga",
              "Andarayan",
              "Bayabo",
              "Capitol",
              "Carmencita",
              "Concepcion",
              "Maui",
              "Quibal",
              "Ragan Almacen",
              "Ragan Norte",
              "Ragan Sur (Pob.)",
              "Rizal (Ragan Almacen Alto)",
              "San Andres",
              "San Antonio",
              "San Isidro",
              "San Jose",
              "San Juan",
              "San Macario",
              "San Nicolas (Fusi)",
              "San Patricio",
              "San Roque",
              "Santo Rosario",
              "Santor",
              "Villa Luz",
              "Villa Pereda",
              "Visitacion"
            ]
          },
          {
            "k": "Echague",
            "v": [
              "Angoluan",
              "Annafunan",
              "Babaran",
              "Buneg",
              "Cabugao (Pob.)",
              "Carulay",
              "Castillo",
              "Dammang East",
              "Dammang West",
              "Dugayong",
              "Fugu",
              "Garit Norte",
              "Garit Sur",
              "Gucab",
              "Gumbauan",
              "Ipil",
              "Libertad",
              "Mabuhay",
              "Malibago",
              "Maligaya",
              "Malitao",
              "Pag-asa",
              "Pangal Norte",
              "Pangal Sur",
              "Salay",
              "Salvacion",
              "San Antonio Minit",
              "San Antonio Ugad",
              "San Fabian",
              "San Felipe",
              "San Juan",
              "San Salvador",
              "Santa Cruz",
              "Santo Domingo",
              "Silauan Norte (Pob.)",
              "Silauan Sur (Pob.)",
              "Sinabbaran",
              "Soyung",
              "Taggappan",
              "Tuguegarao"
            ]
          },
          {
            "k": "Gamu",
            "v": [
              "District I (Pob.)",
              "District Ii (Pob.)",
              "District Iii (Pob.)",
              "Guibang",
              "Lenzon",
              "Linglingay",
              "Upi"
            ]
          },
          {
            "k": "Ilagan City",
            "v": [
              "Alibagu",
              "Allinguigan 1st",
              "Allinguigan 2nd",
              "Allinguigan 3rd",
              "Baculod (Pob.)",
              "Bagong Silang",
              "Bagumbayan (Pob.)",
              "Baligatan",
              "Calamagui 1st",
              "Calamagui 2nd",
              "Camunatan",
              "Centro Poblacion",
              "Guinatan",
              "Imelda Bliss Village",
              "Manaring",
              "Marana I",
              "Marana Ii",
              "Marana Iii",
              "OsmeÃ±a (Sinippil)",
              "Rugao",
              "San Andres (Angarilla)",
              "San Felipe",
              "San Isidro",
              "San Juan",
              "San Vicente (Pob.)",
              "Santa Barbara (Pob.)",
              "Santo Tomas",
              "Sipay",
              "Tangcul",
              "Villa Imelda (Maplas)"
            ]
          },
          {
            "k": "Jones",
            "v": [
              "Abulan",
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangcuag",
              "Dalibubon",
              "Daligan",
              "Diarao",
              "Dicamay I",
              "Disimpit",
              "Fugu",
              "Linamanan",
              "Linomot",
              "Napaliong",
              "Palagao",
              "Papan Este",
              "Papan Weste",
              "Payac",
              "San Vicente",
              "Usol"
            ]
          },
          {
            "k": "Luna",
            "v": [
              "Bustamante",
              "Centro 1 (Pob.)",
              "Centro 2 (Pob.)",
              "Centro 3 (Pob.)",
              "Dadap",
              "Harana",
              "Lalog 1",
              "Lalog 2",
              "MacaÃ±ao",
              "Mambabanga",
              "Pulay"
            ]
          },
          {
            "k": "Mallig",
            "v": [
              "Centro I (Pob.)",
              "Centro Ii (Pob.)",
              "Holy Friday",
              "Siempre Viva Sur"
            ]
          },
          {
            "k": "Naguilian",
            "v": [
              "Cabaruan",
              "Magsaysay (Pob.)",
              "Minanga",
              "Palattao",
              "Quezon (Pob.)",
              "Quinalabasa",
              "Quirino (Pob.)",
              "Roxas (Pob.)",
              "San Manuel"
            ]
          },
          {
            "k": "Quezon",
            "v": [
              "Abut",
              "Alunan (Pob.)",
              "Arellano (Pob.)",
              "Aurora",
              "Calangigan (Calamagui)",
              "Estrada",
              "Lepanto",
              "Mangga",
              "Samonte (Pob.)",
              "San Juan"
            ]
          },
          {
            "k": "Quirino",
            "v": [
              "Cabaruan",
              "Rizal",
              "San Isidro",
              "San Jose",
              "San Juan",
              "San Vicente",
              "Santa Lucia (Pob.)"
            ]
          },
          {
            "k": "Ramon",
            "v": [
              "Ambatali",
              "Bugallon Norte",
              "Bugallon Proper (Pob.)",
              "Burgos",
              "General Aguinaldo",
              "Oscariz",
              "Pagrang-ayan",
              "Raniag",
              "San Antonio",
              "San Miguel",
              "San Sebastian"
            ]
          },
          {
            "k": "Reina Mercedes",
            "v": [
              "Cutog Grande",
              "Cutog PequeÃ±o",
              "District I (Pob.)",
              "District Ii (Pob.)",
              "Labinab Grande (Pob.)",
              "Labinab PequeÃ±o (Pob.)",
              "Napaccu Grande",
              "Napaccu PequeÃ±o",
              "Salucong",
              "Sinippil",
              "Tallungan (Pob.)"
            ]
          },
          {
            "k": "Roxas",
            "v": [
              "Anao",
              "Bantug (Pob.)",
              "DoÃ±a Concha",
              "Imbiao",
              "Lanting",
              "Lucban",
              "Luna (Pob.)",
              "Marcos",
              "Masigun",
              "Matusalem",
              "MuÃ±oz East",
              "MuÃ±oz West",
              "Quiling",
              "Rang-ayan",
              "Rizal (Pob.)",
              "San Antonio",
              "San Jose",
              "San Luis",
              "San Pedro",
              "San Placido",
              "San Rafael",
              "Simimbaan",
              "Sinamar",
              "Sotero Nuesa",
              "Villa Concepcion",
              "Vira (Pob.)"
            ]
          },
          {
            "k": "San Agustin",
            "v": [
              "Masaya Centro (Pob.)",
              "Masaya Norte",
              "Masaya Sur",
              "Panang",
              "Santos"
            ]
          },
          {
            "k": "San Isidro",
            "v": [
              "Camarag",
              "Gomez",
              "Patanad",
              "Quezon",
              "Ramos East",
              "Ramos West",
              "Rizal East (Pob.)",
              "Rizal West (Pob.)"
            ]
          },
          {
            "k": "San Manuel",
            "v": [
              "Agliam",
              "Babanuang",
              "Cabaritan",
              "Caraniogan",
              "District 1 (Pob.)",
              "District 2 (Pob.)",
              "District 3 (Pob.)",
              "District 4 (Pob.)",
              "Eden",
              "Malalinta",
              "Mararigue",
              "Nueva Era",
              "Pisang",
              "San Francisco",
              "Sandiat Centro",
              "Sandiat East",
              "Sandiat West",
              "Santa Cruz",
              "Villanueva"
            ]
          },
          {
            "k": "San Mariano",
            "v": [
              "Santa Filomina",
              "Zone I (Pob.)",
              "Zone Ii (Pob.)",
              "Zone Iii (Pob.)"
            ]
          },
          {
            "k": "San Mateo",
            "v": [
              "BacareÃ±a",
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Bella Luz",
              "Dagupan",
              "Daramuangan Norte",
              "Daramuangan Sur",
              "Estrella",
              "Gaddanan",
              "Malasin",
              "Mapuroc",
              "Marasat Grande",
              "Marasat PequeÃ±o",
              "Old Centro I",
              "Old Centro Ii",
              "Salinungan East",
              "Salinungan West",
              "San Andres",
              "San Antonio",
              "San Ignacio",
              "San Marcos",
              "San Maul",
              "San Roque",
              "Sinamar Norte",
              "Sinamar Sur",
              "Victoria",
              "Villa Gamiao (Buyon)",
              "Villa Magat",
              "Villafuerte"
            ]
          },
          {
            "k": "San Pablo",
            "v": [
              "Auitan",
              "Ballacayu",
              "Binguang",
              "Bungad",
              "Calamagui",
              "Caralucud",
              "Minanga Norte",
              "Minanga Sur",
              "Poblacion",
              "San Jose"
            ]
          },
          {
            "k": "Santa Maria",
            "v": [
              "Bangad",
              "Lingaling",
              "Mozzozzin North",
              "Mozzozzin Sur",
              "Poblacion 1",
              "Poblacion 2",
              "Poblacion 3",
              "San Antonio"
            ]
          },
          {
            "k": "Santo Tomas",
            "v": [
              "Antagan",
              "Bagabag",
              "Balelleng",
              "Bolinao-culalabo",
              "Calanigan Norte",
              "Calanigan Sur",
              "Calinaoan Centro",
              "Calinaoan Malasin",
              "Calinaoan Norte",
              "Centro"
            ]
          },
          {
            "k": "Tumauini",
            "v": [
              "Annafunan",
              "Arcon",
              "Balug",
              "Barangay District 1 (Pob.)",
              "Barangay District 2 (Pob.)",
              "Barangay District 3 (Pob.)",
              "Barangay District 4 (Pob.)",
              "Carpentero",
              "Compania",
              "Lalauanan",
              "Lanna",
              "Lingaling",
              "Liwanag",
              "Maligaya",
              "Minanga",
              "San Mateo",
              "San Pedro",
              "Santa",
              "Santa Visitacion (Maggayu)",
              "Ugad"
            ]
          }
        ],
        "names": [
          "Alicia",
          "Angadanan",
          "Aurora",
          "Benito Soliven",
          "Burgos",
          "Cabagan",
          "Cabatuan",
          "Cauayan City",
          "City Of Santiago",
          "Cordon",
          "Delfin Albano (Magsaysay)",
          "Echague",
          "Gamu",
          "Ilagan City",
          "Jones",
          "Luna",
          "Mallig",
          "Naguilian",
          "Quezon",
          "Quirino",
          "Ramon",
          "Reina Mercedes",
          "Roxas",
          "San Agustin",
          "San Isidro",
          "San Manuel",
          "San Mariano",
          "San Mateo",
          "San Pablo",
          "Santa Maria",
          "Santo Tomas",
          "Tumauini"
        ]
      }
    },
    {
      "k": "Kalinga",
      "v": {
        "lookup": [
          {
            "k": "Tabuk",
            "v": [
              "Appas",
              "Bulanao",
              "Bulanao Norte",
              "Casigayan",
              "Dagupan Centro (Pob.)",
              "Dagupan Weste",
              "Dilag",
              "Dupag",
              "Magsaysay",
              "San Juan"
            ]
          }
        ],
        "names": ["Tabuk"]
      }
    },
    {
      "k": "La Union",
      "v": {
        "lookup": [
          {
            "k": "Agoo",
            "v": [
              "Balawarte",
              "Consolacion (Pob.)",
              "Macalva Central",
              "Macalva Norte",
              "Macalva Sur",
              "Nazareno",
              "Purok",
              "San Agustin East",
              "San Agustin Norte",
              "San Agustin Sur",
              "San Antonino",
              "San Antonio",
              "San Francisco",
              "San Isidro",
              "San Joaquin Norte",
              "San Joaquin Sur",
              "San Jose Norte",
              "San Jose Sur",
              "San Juan",
              "San Julian Central",
              "San Julian East",
              "San Julian Norte",
              "San Julian West",
              "San Manuel Norte",
              "San Manuel Sur",
              "San Marcos",
              "San Miguel",
              "San Nicolas Central (Pob.)",
              "San Nicolas East",
              "San Nicolas Norte (Pob.)",
              "San Nicolas Sur (Pob.)",
              "San Nicolas West",
              "San Pedro",
              "San Roque East",
              "San Roque West",
              "San Vicente Norte",
              "San Vicente Sur",
              "Santa Ana",
              "Santa Barbara (Pob.)",
              "Santa Fe",
              "Santa Maria",
              "Santa Monica",
              "Santa Rita (Nalinac)",
              "Santa Rita East",
              "Santa Rita Norte",
              "Santa Rita Sur",
              "Santa Rita West"
            ]
          },
          {
            "k": "Aringay",
            "v": [
              "Alaska",
              "Dulao",
              "Poblacion",
              "Samara",
              "San Benito Norte",
              "San Benito Sur",
              "San Eugenio",
              "San Simon East",
              "San Simon West",
              "Santa Cecilia",
              "Santa Lucia",
              "Santa Rita East",
              "Santa Rita West",
              "Santo Rosario East",
              "Santo Rosario West"
            ]
          },
          {
            "k": "Bacnotan",
            "v": [
              "Agtipal",
              "Bacqui",
              "Bacsil",
              "Bagutot",
              "Ballogo",
              "Baroro",
              "Bitalag",
              "Bulala",
              "Burayoc",
              "Bussaoit",
              "Cabaroan",
              "Cabarsican",
              "Carcarmay",
              "Casiaman",
              "Galongen",
              "Guinabang",
              "Legleg",
              "Lisqueb",
              "Mabanengbeng 1st",
              "Mabanengbeng 2nd",
              "Maragayap",
              "Nagatiran",
              "Nagsaraboan",
              "Nagsimbaanan",
              "Nangalisan",
              "Narra",
              "Pandan",
              "Pang-pang",
              "Poblacion",
              "Quirino",
              "Raois",
              "Salincob",
              "San Martin",
              "Santa Cruz",
              "Santa Rita",
              "Sapilang",
              "Sayoan",
              "Sipulo",
              "Tammocalao",
              "Ubbog",
              "Zaragosa"
            ]
          },
          { "k": "Bagulin", "v": ["Suyo (Pob.)"] },
          {
            "k": "Balaoan",
            "v": [
              "Almieda",
              "Antonino",
              "Apatut",
              "Ar-arampang",
              "Baracbac Este",
              "Baracbac Oeste",
              "Bet-ang",
              "Bulbulala",
              "Bungol",
              "Butubut Oeste",
              "Butubut Sur",
              "Cabuaan Oeste (Pob.)",
              "Calungbuyan",
              "Camiling",
              "Dr. Camilo Osias Pob. (Cabuaan Este)",
              "Masupe",
              "Nagsabaran Norte",
              "Nalasin",
              "Napaset",
              "Pa-o",
              "Pantar Norte",
              "Pantar Sur",
              "Paraoir",
              "Patpata",
              "Sablut",
              "San Pablo",
              "Sinapangan Norte",
              "Sinapangan Sur",
              "Tallipugo"
            ]
          },
          {
            "k": "Bangar",
            "v": [
              "Agdeppa",
              "Alzate",
              "Bangaoilan East",
              "Bangaoilan West",
              "Barraca",
              "Cadapli",
              "Central East No. 1 (Pob.)",
              "Central East No. 2 (Pob.)",
              "Central West No. 1 (Pob.)",
              "Central West No. 2 (Pob.)",
              "Central West No. 3 (Pob.)",
              "Consuegra",
              "General Prim East",
              "General Prim West",
              "General Terrero",
              "Maria Cristina East",
              "Maria Cristina West",
              "Nagsabaran",
              "Paratong No. 3",
              "Paratong No. 4",
              "Paratong Norte",
              "Reyna Regente",
              "Rissing",
              "San Blas",
              "San Cristobal",
              "Sinapangan Norte",
              "Sinapangan Sur",
              "Ubbog"
            ]
          },
          {
            "k": "Bauang",
            "v": [
              "Acao",
              "Baccuit Norte",
              "Baccuit Sur",
              "Bagbag",
              "Ballay",
              "Bawanta",
              "Boy-utan",
              "Bucayab",
              "Cabalayangan",
              "Cabisilan",
              "Calumbaya",
              "Carmay",
              "Casilagan",
              "Central East (Pob.)",
              "Central West (Pob.)",
              "Dili",
              "Disso-or",
              "Guerrero",
              "Lower San Agustin",
              "Nagrebcan",
              "Pagdalagan Sur",
              "Palintucang",
              "Parian Este",
              "Parian Oeste",
              "Paringao",
              "Payocpoc Norte Este",
              "Payocpoc Norte Oeste",
              "Payocpoc Sur",
              "Pilar",
              "Pottot",
              "Pudoc",
              "Pugo",
              "Quinavite",
              "Santa Monica",
              "Santiago",
              "Taberna",
              "Urayong"
            ]
          },
          {
            "k": "Burgos",
            "v": [
              "Bilis",
              "Caoayan",
              "Imelda",
              "New Poblacion",
              "Old Poblacion"
            ]
          },
          {
            "k": "Caba",
            "v": [
              "Bautista",
              "Gana",
              "Juan Cartas",
              "Las-ud",
              "Poblacion Norte",
              "Poblacion Sur",
              "San Carlos",
              "San Cornelio",
              "San Gregorio",
              "San Jose",
              "Santiago Norte",
              "Santiago Sur",
              "Sobredillo",
              "Urayong",
              "Wenceslao"
            ]
          },
          {
            "k": "City Of San Fernando",
            "v": [
              "Bangcusay",
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Bato",
              "Biday",
              "Bungro",
              "Cabaroan (Negro)",
              "Cadaclan",
              "Camansi",
              "Canaoay",
              "Carlatan",
              "Catbangen",
              "Dallangayan Este",
              "Dallangayan Oeste",
              "Dalumpinas Este",
              "Dalumpinas Oeste",
              "Ilocanos Norte",
              "Ilocanos Sur",
              "Langcuas",
              "Lingsat",
              "Madayegdeg",
              "Mameltac",
              "Namtutan",
              "Narra Este",
              "Narra Oeste",
              "Pagdalagan",
              "Pagdaraoan",
              "Pagudpud",
              "Parian",
              "Pias",
              "Poro",
              "Puspus",
              "Sagayad",
              "San Agustin",
              "San Francisco",
              "San Vicente",
              "Santiago Norte",
              "Santiago Sur",
              "Saoay",
              "Sevilla",
              "Tanqui",
              "Tanquigan"
            ]
          },
          {
            "k": "Luna",
            "v": [
              "Alcala (Pob.)",
              "Barangobong",
              "Barrientos",
              "Bungro",
              "Buselbusel",
              "Cantoria No. 1",
              "Cantoria No. 2",
              "Cantoria No. 3",
              "Cantoria No. 4",
              "Carisquis",
              "Darigayos",
              "Magallanes (Pob.)",
              "Mamay",
              "Nagrebcan",
              "Nalvo Norte",
              "Nalvo Sur",
              "Napaset",
              "Oaqui No. 1",
              "Oaqui No. 2",
              "Oaqui No. 3",
              "Oaqui No. 4",
              "Pila",
              "Rimos No. 1",
              "Rimos No. 2",
              "Rimos No. 3",
              "Rimos No. 4",
              "Rimos No. 5",
              "Rissing",
              "Salcedo (Pob.)",
              "Santo Domingo Norte",
              "Santo Domingo Sur",
              "Sucoc Norte",
              "Sucoc Sur",
              "Suyo",
              "Victoria (Pob.)"
            ]
          },
          {
            "k": "Naguilian",
            "v": [
              "Angin",
              "Baraoas Norte",
              "Baraoas Sur",
              "Bariquir",
              "Bato",
              "Bimmotobot",
              "Cabaritan Norte",
              "Cabaritan Sur",
              "Dal-lipaoen",
              "Guesset",
              "Gusing Norte",
              "Gusing Sur",
              "Imelda",
              "Lioac Norte",
              "Lioac Sur",
              "Natividad (Pob.)",
              "Ortiz (Pob.)",
              "Sili",
              "Suguidan Norte",
              "Tuddingan"
            ]
          },
          {
            "k": "Pugo",
            "v": [
              "Ambalite",
              "Cares",
              "Cuenca",
              "Duplas",
              "Maoasoas Norte",
              "Maoasoas Sur",
              "Poblacion East",
              "Poblacion West",
              "San Luis",
              "Saytan",
              "Tavora East",
              "Tavora Proper"
            ]
          },
          {
            "k": "Rosario",
            "v": [
              "Alipang",
              "Ambangonan",
              "Amlang",
              "Bacani",
              "Bangar",
              "Bani",
              "Benteng-sapilang",
              "Camp One",
              "Casilagan",
              "Cataguingtingan",
              "Concepcion",
              "Damortis",
              "Nagtagaan",
              "Nangcamotian",
              "Poblacion East",
              "Poblacion West",
              "Puzon",
              "Rabon",
              "San Jose",
              "Subusub",
              "Tabtabungao",
              "Tanglag",
              "Tay-ac",
              "Udiao",
              "Vila"
            ]
          },
          {
            "k": "San Gabriel",
            "v": ["Bucao", "Lipay Este", "Lipay Proper", "Poblacion"]
          },
          {
            "k": "San Juan",
            "v": [
              "Allangigan",
              "Aludaid",
              "Bacsayan",
              "Bambanay",
              "Caarusipan",
              "Cabaroan",
              "Cabugnayan",
              "Cacapian",
              "Caculangan",
              "Calincamasan",
              "Catdongan",
              "Dangdangla",
              "Guinguinabang",
              "Ili Norte (Pob.)",
              "Ili Sur (Pob.)",
              "Legleg",
              "Lubing",
              "Nadsaag",
              "Naguirangan",
              "Naguituban",
              "Oaquing",
              "Panicsican",
              "Quidem",
              "San Felipe",
              "Santa Rosa",
              "Santo Rosario",
              "Sinapangan",
              "Taboc",
              "Talogtog",
              "Urbiztondo"
            ]
          },
          {
            "k": "Santo Tomas",
            "v": [
              "Balsaan",
              "Casantaan",
              "Casilagan",
              "Cupang",
              "Damortis",
              "Linong",
              "Lomboy",
              "Malabago",
              "Namboongan",
              "Namonitan",
              "Patac",
              "Poblacion",
              "Tococ",
              "Tubod",
              "Ubagan"
            ]
          },
          {
            "k": "Sudipen",
            "v": [
              "Castro",
              "Ilocano",
              "Ipet",
              "Namaltugan",
              "Old Central",
              "Poblacion",
              "Sengngat",
              "Turod"
            ]
          },
          {
            "k": "Tubao",
            "v": [
              "Amallapay",
              "Anduyan",
              "Francia Sur",
              "Francia West",
              "Garcia",
              "Gonzales",
              "Leones East",
              "Leones West",
              "Lloren",
              "Magsaysay",
              "Poblacion",
              "Santa Teresa"
            ]
          }
        ],
        "names": [
          "Agoo",
          "Aringay",
          "Bacnotan",
          "Bagulin",
          "Balaoan",
          "Bangar",
          "Bauang",
          "Burgos",
          "Caba",
          "City Of San Fernando",
          "Luna",
          "Naguilian",
          "Pugo",
          "Rosario",
          "San Gabriel",
          "San Juan",
          "Santo Tomas",
          "Sudipen",
          "Tubao"
        ]
      }
    },
    {
      "k": "Laguna",
      "v": {
        "lookup": [
          {
            "k": "Alaminos",
            "v": [
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Del Carmen",
              "San Agustin",
              "San Andres",
              "San Benito",
              "San Gregorio",
              "San Ildefonso",
              "San Juan",
              "San Miguel",
              "San Roque",
              "Santa Rosa"
            ]
          },
          {
            "k": "Bay",
            "v": [
              "Bitin",
              "Calo",
              "Dila",
              "Maitim",
              "Masaya",
              "Paciano Rizal",
              "Puypuy",
              "San Agustin (Pob.)",
              "San Antonio",
              "San Isidro",
              "San Nicolas (Pob.)",
              "Santa Cruz",
              "Santo Domingo",
              "Tagumpay",
              "Tranca"
            ]
          },
          {
            "k": "BiÃ±an",
            "v": [
              "BiÃ±an (Poblacion)",
              "Bungahan",
              "Canlalay",
              "Casile",
              "De La Paz",
              "Ganado",
              "Langkiwa",
              "Loma",
              "Malaban",
              "Malamig",
              "Mampalasan",
              "Platero",
              "Poblacion",
              "San Antonio",
              "San Francisco (Halang)",
              "San Jose",
              "San Vicente",
              "Santo Domingo",
              "Santo NiÃ±o",
              "Santo Tomas (Calabuso)",
              "Soro-soro",
              "Timbao",
              "Tubigan",
              "Zapote"
            ]
          },
          {
            "k": "Cabuyao",
            "v": [
              "Baclaran",
              "Banaybanay",
              "Banlic",
              "Barangay Dos (Pob.)",
              "Barangay Tres (Pob.)",
              "Barangay Uno (Pob.)",
              "Bigaa",
              "Butong",
              "Diezmo",
              "Gulod",
              "Mamatid",
              "Marinig",
              "Niugan",
              "Pittland",
              "Pulo",
              "Sala",
              "San Isidro"
            ]
          },
          {
            "k": "Calamba",
            "v": [
              "Bagong Kalsada",
              "Banadero",
              "Banlic",
              "Barandal",
              "Barangay 1 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 4 (Pob.)",
              "Barangay 5 (Pob.)",
              "Barangay 6 (Pob.)",
              "Barangay 7 (Pob.)",
              "Batino",
              "Bubuyan",
              "Bucal",
              "Burol",
              "Camaligan",
              "Canlubang",
              "Halang",
              "La Mesa",
              "Laguerta",
              "Lawa",
              "Lecheria",
              "Lingga",
              "Looc",
              "Majada Labas",
              "Makiling",
              "Mapagong",
              "Masili",
              "Maunong",
              "Mayapa",
              "Milagrosa (Tulo)",
              "Paciano Rizal",
              "Palingon",
              "Palo-alto",
              "Pansol",
              "Parian",
              "Prinza",
              "Punta",
              "Puting Lupa",
              "Real",
              "Saimsim",
              "Sampiruhan",
              "San Cristobal",
              "San Jose",
              "San Juan",
              "Sirang Lupa",
              "Sucol",
              "Turbina",
              "Uwisan"
            ]
          },
          {
            "k": "Calauan",
            "v": [
              "Balayhangin",
              "Bangyas",
              "Dayap",
              "Hanggan",
              "Imok",
              "Kanluran (Pob.)",
              "Lamot 1",
              "Lamot 2",
              "Mabacan",
              "Masiit",
              "Paliparan",
              "Prinza",
              "San Isidro",
              "Santo Tomas",
              "Silangan (Pob.)"
            ]
          },
          {
            "k": "Cavinti",
            "v": [
              "Anglas",
              "Bulajo",
              "Duhat",
              "Kanluran Talaongan",
              "Layasin",
              "Poblacion",
              "Silangan Talaongan",
              "Sisilmin",
              "Tibatib",
              "Udia"
            ]
          },
          {
            "k": "Famy",
            "v": [
              "Asana (Pob.)",
              "Bagong Pag-asa (Pob.)",
              "Banaba (Pob.)",
              "Batuhan",
              "Bulihan",
              "Caballero (Pob.)",
              "Calumpang (Pob.)",
              "Damayan (Pob.)",
              "Kapatalan",
              "Liyang",
              "Magdalo (Pob.)",
              "Mayatba"
            ]
          },
          { "k": "Kalayaan", "v": ["Longos", "San Juan (Pob.)"] },
          {
            "k": "Liliw",
            "v": [
              "Bagong Anyo (Pob.)",
              "Bayate",
              "Bongkol",
              "Bubukal",
              "Cabuyao",
              "Calumpang",
              "Culoy",
              "Dagatan",
              "Ibabang Palina",
              "Ibabang San Roque",
              "Ibabang Sungi",
              "Ibabang Taykin",
              "Ilayang Palina",
              "Ilayang San Roque",
              "Ilayang Sungi",
              "Ilayang Taykin",
              "Kanlurang Bukal",
              "Laguan",
              "Masikap (Pob.)",
              "Maslun (Pob.)",
              "Mojon",
              "Pag-asa (Pob.)",
              "Palayan",
              "Rizal (Pob.)"
            ]
          },
          {
            "k": "Los BaÃ±os",
            "v": [
              "Anos",
              "Bagong Silang",
              "Bambang",
              "Batong Malake",
              "Baybayin (Pob.)",
              "Bayog",
              "Lalakay",
              "Maahas",
              "Malinta",
              "Mayondon",
              "Putho Tuntungin",
              "San Antonio",
              "Tadlak",
              "Timugan (Pob.)"
            ]
          },
          {
            "k": "Luisiana",
            "v": [
              "Barangay Zone I (Pob.)",
              "Barangay Zone Ii (Pob.)",
              "Barangay Zone Iii (Pob.)",
              "Barangay Zone Iv (Pob.)",
              "Barangay Zone V (Pob.)",
              "Barangay Zone Vi (Pob.)",
              "Barangay Zone Vii (Pob.)",
              "Barangay Zone Viii (Pob.)",
              "San Antonio",
              "San Diego",
              "San Isidro",
              "San Luis",
              "San Pedro",
              "San Roque",
              "Santo Tomas"
            ]
          },
          {
            "k": "Lumban",
            "v": [
              "Bagong Silang",
              "Balimbingan (Pob.)",
              "Balubad",
              "Caliraya",
              "Concepcion",
              "Lewin",
              "Maracta (Pob.)",
              "Maytalang I",
              "Maytalang Ii",
              "Primera Parang (Pob.)",
              "Primera Pulo (Pob.)",
              "Salac (Pob.)",
              "Santo NiÃ±o (Pob.)",
              "Segunda Parang (Pob.)",
              "Segunda Pulo (Pob.)",
              "Wawa"
            ]
          },
          {
            "k": "Mabitac",
            "v": [
              "Amuyong",
              "Bayanihan (Pob.)",
              "Lambac (Pob.)",
              "Libis Ng Nayon (Pob.)",
              "Lucong",
              "Maligaya (Pob.)",
              "Masikap (Pob.)",
              "Matalatala",
              "Nanguma",
              "Pag-asa (Pob.)",
              "San Antonio",
              "Sinagtala (Pob.)"
            ]
          },
          {
            "k": "Magdalena",
            "v": [
              "Buenavista",
              "Bungkol",
              "Burlungan",
              "Cigaras",
              "Ibabang Butnong",
              "Ilayang Butnong",
              "Malaking Ambling",
              "Poblacion",
              "Salasad",
              "Tipunan"
            ]
          },
          {
            "k": "Majayjay",
            "v": [
              "Bakia",
              "Botocan",
              "Coralao",
              "Gagalot",
              "Ibabang Banga",
              "Ilayang Banga",
              "Olla",
              "Origuel (Pob.)",
              "Pangil",
              "San Francisco (Pob.)",
              "San Isidro",
              "San Miguel (Pob.)",
              "Santa Catalina (Pob.)",
              "Suba",
              "Talortor",
              "Villa Nogales"
            ]
          },
          {
            "k": "Nagcarlan",
            "v": [
              "Alibungbungan",
              "Bambang",
              "Banago",
              "Buboy",
              "Buhanginan",
              "Calumpang",
              "Malaya",
              "Palayan",
              "Palina",
              "Poblacion I (Pob.)",
              "Poblacion Ii (Pob.)",
              "Poblacion Iii (Pob.)",
              "San Francisco",
              "Santa Lucia",
              "Silangan Ilaya",
              "Silangan Kabubuhayan",
              "Talangan",
              "Taytay",
              "Yukos"
            ]
          },
          {
            "k": "Paete",
            "v": [
              "Bagumbayan (Pob.)",
              "Bangkusay (Pob.)",
              "Ermita (Pob.)",
              "Ibaba Del Norte (Pob.)",
              "Ibaba Del Sur (Pob.)",
              "Ilaya Del Norte (Pob.)",
              "Ilaya Del Sur (Pob.)",
              "Maytoong (Pob.)",
              "Quinale (Pob.)"
            ]
          },
          {
            "k": "Pagsanjan",
            "v": [
              "Anibong",
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "BiÃ±an",
              "Buboy",
              "Cabanbanan",
              "Calusiche",
              "Dingin",
              "Lambac",
              "Layugan",
              "Magdapio",
              "Maulawin",
              "Pinagsanjan",
              "Sabang",
              "Sampaloc",
              "San Isidro"
            ]
          },
          {
            "k": "Pakil",
            "v": [
              "Banilan",
              "BaÃ±o (Pob.)",
              "Burgos (Pob.)",
              "Casa Real",
              "Dorado",
              "Gonzales (Pob.)",
              "Matikiw",
              "Rizal (Pob.)",
              "Saray",
              "Taft (Pob.)",
              "Tavera (Pob.)"
            ]
          },
          {
            "k": "Pangil",
            "v": [
              "Balian",
              "Galalan",
              "Isla (Pob.)",
              "Natividad (Pob.)",
              "San Jose (Pob.)",
              "Sulib"
            ]
          },
          {
            "k": "Pila",
            "v": [
              "Aplaya",
              "Bagong Pook",
              "Bukal",
              "Bulilan Norte (Pob.)",
              "Bulilan Sur (Pob.)",
              "Concepcion",
              "Labuin",
              "Linga",
              "Masico",
              "Mojon",
              "Pansol",
              "Pinagbayanan",
              "San Antonio",
              "San Miguel",
              "Santa Clara Norte (Pob.)",
              "Santa Clara Sur (Pob.)",
              "Tubuan"
            ]
          },
          {
            "k": "Rizal",
            "v": [
              "Antipolo",
              "East Poblacion",
              "Entablado",
              "Laguan",
              "Paule 1",
              "Paule 2",
              "Pook",
              "Tala",
              "Talaga",
              "Tuy",
              "West Poblacion"
            ]
          },
          {
            "k": "San Pablo",
            "v": [
              "Atisan",
              "Bagong Bayan Ii-a (Pob.)",
              "Bagong Pook Vi-c (Pob.)",
              "Barangay I-a (Pob.)",
              "Barangay I-b (Pob.)",
              "Barangay Ii-a (Pob.)",
              "Barangay Ii-b (Pob.)",
              "Barangay Ii-c (Pob.)",
              "Barangay Ii-d (Pob.)",
              "Barangay Ii-e (Pob.)",
              "Barangay Ii-f (Pob.)",
              "Barangay Iii-a (Pob.)",
              "Barangay Iii-b (Pob.)",
              "Barangay Iii-c (Pob.)",
              "Barangay Iii-d (Pob.)",
              "Barangay Iii-e (Pob.)",
              "Barangay Iii-f (Pob.)",
              "Barangay Iv-a (Pob.)",
              "Barangay Iv-b (Pob.)",
              "Barangay Iv-c (Pob.)",
              "Barangay V-a (Pob.)",
              "Barangay V-b (Pob.)",
              "Barangay V-c (Pob.)",
              "Barangay V-d (Pob.)",
              "Barangay Vi-a (Pob.)",
              "Barangay Vi-b (Pob.)",
              "Barangay Vi-d (Pob.)",
              "Barangay Vi-e (Pob.)",
              "Barangay Vii-a (Pob.)",
              "Barangay Vii-b (Pob.)",
              "Barangay Vii-c (Pob.)",
              "Barangay Vii-d (Pob.)",
              "Barangay Vii-e (Pob.)",
              "Bautista",
              "Concepcion",
              "Del Remedio",
              "Dolores",
              "San Antonio 1",
              "San Antonio 2",
              "San Bartolome",
              "San Buenaventura",
              "San Crispin",
              "San Cristobal",
              "San Diego",
              "San Francisco",
              "San Gabriel",
              "San Gregorio",
              "San Ignacio",
              "San Isidro",
              "San Joaquin",
              "San Jose",
              "San Juan",
              "San Lorenzo",
              "San Lucas 1",
              "San Lucas 2",
              "San Marcos",
              "San Mateo",
              "San Miguel",
              "San Nicolas",
              "San Pedro",
              "San Rafael",
              "San Roque",
              "San Vicente",
              "Santa Ana",
              "Santa Catalina",
              "Santa Cruz",
              "Santa Elena",
              "Santa Felomina",
              "Santa Isabel",
              "Santa Maria",
              "Santa Maria Magdalena",
              "Santa Monica",
              "Santa Veronica",
              "Santiago I",
              "Santiago Ii",
              "Santisimo Rosario",
              "Santo Angel",
              "Santo Cristo",
              "Santo NiÃ±o",
              "Soledad"
            ]
          },
          {
            "k": "San Pedro",
            "v": [
              "Bagong Silang",
              "Calendola",
              "Chrysanthemum",
              "Cuyab",
              "Estrella",
              "Fatima",
              "G.s.i.s.",
              "Landayan",
              "Langgam",
              "Laram",
              "Magsaysay",
              "Maharlika",
              "Narra",
              "Nueva",
              "Pacita 1",
              "Pacita 2",
              "Poblacion",
              "Riverside",
              "Rosario",
              "Sampaguita Village",
              "San Antonio",
              "San Lorenzo Ruiz",
              "San Roque",
              "San Vicente",
              "Santo NiÃ±o",
              "United Bayanihan",
              "United Better Living"
            ]
          },
          {
            "k": "Santa Cruz",
            "v": [
              "Alipit",
              "Bagumbayan",
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Barangay V (Pob.)",
              "Bubukal",
              "Calios",
              "Duhat",
              "Gatid",
              "Jasaan",
              "Labuin",
              "Malinao",
              "Oogong",
              "Pagsawitan",
              "Palasan",
              "Patimbao",
              "San Jose",
              "San Juan",
              "San Pablo Norte",
              "San Pablo Sur",
              "Santisima Cruz",
              "Santo Angel Central",
              "Santo Angel Norte",
              "Santo Angel Sur"
            ]
          },
          {
            "k": "Santa Maria",
            "v": [
              "Bagong Pook",
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Jose Rizal",
              "Kayhakat"
            ]
          },
          {
            "k": "Santa Rosa",
            "v": [
              "Aplaya",
              "Balibago",
              "Caingin",
              "Dila",
              "Dita",
              "Don Jose",
              "Ibaba",
              "Kanluran (Pob.)",
              "Labas",
              "Macabling",
              "Malitlit",
              "Malusak (Pob.)",
              "Market Area (Pob.)",
              "Pook",
              "Pulong Santa Cruz",
              "Santo Domingo",
              "Sinalhan",
              "Tagapo"
            ]
          },
          {
            "k": "Siniloan",
            "v": [
              "Acevida",
              "Bagong Pag-asa (Pob.)",
              "Bagumbarangay (Pob.)",
              "Buhay",
              "G. Redor (Pob.)",
              "Gen. Luna",
              "Halayhayin",
              "J. Rizal (Pob.)",
              "Kapatalan",
              "Laguio",
              "Liyang",
              "Llavac",
              "Macatad",
              "Magsaysay",
              "Mayatba",
              "Mendiola",
              "P. Burgos",
              "Pandeno",
              "Salubungan",
              "Wawa"
            ]
          },
          {
            "k": "Victoria",
            "v": [
              "Banca-banca",
              "Daniw",
              "Masapang",
              "Nanhaya (Pob.)",
              "Pagalangan",
              "San Benito",
              "San Felix",
              "San Francisco",
              "San Roque (Pob.)"
            ]
          }
        ],
        "names": [
          "Alaminos",
          "Bay",
          "BiÃ±an",
          "Cabuyao",
          "Calamba",
          "Calauan",
          "Cavinti",
          "Famy",
          "Kalayaan",
          "Liliw",
          "Los BaÃ±os",
          "Luisiana",
          "Lumban",
          "Mabitac",
          "Magdalena",
          "Majayjay",
          "Nagcarlan",
          "Paete",
          "Pagsanjan",
          "Pakil",
          "Pangil",
          "Pila",
          "Rizal",
          "San Pablo",
          "San Pedro",
          "Santa Cruz",
          "Santa Maria",
          "Santa Rosa",
          "Siniloan",
          "Victoria"
        ]
      }
    },
    {
      "k": "Lanao Del Norte",
      "v": {
        "lookup": [
          {
            "k": "Bacolod",
            "v": [
              "Bandol",
              "Binuni",
              "Demologan",
              "Esperanza",
              "Liangan East",
              "Minaulon",
              "Poblacion Bacolod",
              "Rupagan"
            ]
          },
          {
            "k": "Baroy",
            "v": [
              "Bagong Dawis",
              "Baroy Daku",
              "Pange",
              "Poblacion",
              "Princesa",
              "Rawan Point",
              "Sagadan Sagadan Lower",
              "Sagadan Upper",
              "San Juan"
            ]
          },
          {
            "k": "Iligan City",
            "v": [
              "Abuno",
              "Acmac",
              "Bagong Silang",
              "Buru Un",
              "City Proper",
              "Dalipuga",
              "Del Carmen",
              "Ditucalan",
              "Hinaplanon",
              "Kiwalan",
              "Luinab",
              "Mahayhay",
              "Maria Cristina",
              "Palao",
              "Poblacion",
              "Puga An",
              "San Miguel",
              "San Roque",
              "Santa Elena",
              "Santa Filomena",
              "Santiago",
              "Santo Rosario",
              "Saray Tibanga",
              "Suarez",
              "Tambacan",
              "Tibanga",
              "Tipanoy",
              "Tominobo Proper",
              "Tominobo Upper",
              "Tubod",
              "Ubaldo Laya",
              "Villa Verde"
            ]
          },
          {
            "k": "Kapatagan",
            "v": [
              "Bagong Silang",
              "Balili",
              "Butadon",
              "Cathedral Falls",
              "Concepcion",
              "Curvada",
              "Lapinig",
              "Maranding",
              "Margos",
              "Poblacion",
              "Santa Cruz",
              "Taguitic"
            ]
          },
          {
            "k": "Kauswagan",
            "v": [
              "Bagumbayan Pob",
              "Kawit Occidental",
              "Kawit Oriental",
              "Libertad",
              "Poblacion",
              "Tacub",
              "Tugar"
            ]
          },
          {
            "k": "Kolambugan",
            "v": [
              "Austin Heights",
              "Baybay",
              "Caromatan",
              "Kulasihan",
              "Libertad",
              "Manga",
              "Mukas",
              "Muntay",
              "Poblacion",
              "Rebucon",
              "Riverside",
              "Simbuco",
              "Tabigue",
              "Titunod"
            ]
          },
          {
            "k": "Lala",
            "v": [
              "Abaga",
              "Cabasagan",
              "Lala Proper Pob",
              "Lanipao",
              "Magpatao",
              "Maranding",
              "Pacita",
              "Raw An",
              "San Isidro Lower",
              "San Isidro Upper",
              "Tenazas"
            ]
          },
          {
            "k": "Linamon",
            "v": ["Larapan", "Magoong", "Napo", "Poblacion", "Samburon"]
          },
          {
            "k": "Maigo",
            "v": [
              "Balagatasa",
              "Camp 1",
              "Claro M Recto",
              "Kulasihan Villanueva",
              "Labuay",
              "Liangan West",
              "Poblacion",
              "Sogapod"
            ]
          },
          {
            "k": "Tubod",
            "v": [
              "Barakanas",
              "Bulod",
              "Kakai Renabor",
              "Malingao",
              "Patudan",
              "Pigcarangan",
              "Pinpin",
              "Poblacion",
              "Pualas",
              "San Antonio",
              "Tangueguiron",
              "Tubaran"
            ]
          }
        ],
        "names": [
          "Bacolod",
          "Baroy",
          "Iligan City",
          "Kapatagan",
          "Kauswagan",
          "Kolambugan",
          "Lala",
          "Linamon",
          "Maigo",
          "Tubod"
        ]
      }
    },
    {
      "k": "Lanao Del Sur",
      "v": {
        "lookup": [
          {
            "k": "Bacolod Kalawi Bacolod Grande",
            "v": ["Poblacion I", "Poblacion Ii"]
          },
          {
            "k": "Wao",
            "v": [
              "Bo-ot",
              "Buntongun",
              "Katutungan (Pob)",
              "Kilikili East",
              "Kilikili West",
              "Panang",
              "Serran Village"
            ]
          }
        ],
        "names": ["Bacolod Kalawi Bacolod Grande", "Wao"]
      }
    },
    {
      "k": "Leyte",
      "v": {
        "lookup": [
          {
            "k": "Abuyog",
            "v": [
              "Alangilan",
              "Anibongan",
              "Balinsasayao",
              "Balocawe",
              "Balocawehay",
              "Barayong",
              "Bito Pob",
              "Bunga",
              "Buntay Pob",
              "Burubud An",
              "Cadac An",
              "Cagbolo",
              "Can Aporong",
              "Can Uguib Pob",
              "Canmarating",
              "Combis",
              "Guintagbucan Pob",
              "Hampipila",
              "Katipunan",
              "Laray",
              "Lawa An",
              "Libertad",
              "Loyonsawang Pob",
              "Mag Atubang",
              "Mahagna New Cagbolo",
              "Matagnao",
              "Nalibunan Pob",
              "Nebga",
              "New Taligue",
              "Odiongan",
              "Pagsang An",
              "Paguite",
              "Parasanon",
              "Proper",
              "Salvacion",
              "San Isidro",
              "Santa Fe Pob",
              "Santa Lucia Pob",
              "Santo Nino Pob",
              "Tabigue",
              "Tadoc",
              "Tinalian",
              "Tuy A",
              "Victory Pob"
            ]
          },
          {
            "k": "Alangalang",
            "v": [
              "Aslum",
              "Astorga Burabod",
              "Bato",
              "Binongto An",
              "Binotong",
              "Blumentritt Pob",
              "Bobonon",
              "Borseth",
              "Buenavista",
              "Bugho",
              "Buri",
              "Cabadsan",
              "Calaasan",
              "Cambahanon",
              "Cambolao",
              "Canvertudes",
              "Capiz",
              "Cavite",
              "Cogon",
              "Dapdap",
              "Divisoria",
              "Ekiran",
              "Hinapolan",
              "Holy Child I Pob",
              "Holy Child Ii Pob",
              "Hubang",
              "Hupit",
              "Langit",
              "Lingayon",
              "Lourdes",
              "Lukay",
              "Magsaysay",
              "Milagrosa Pob",
              "Mudboron",
              "P Barrantes",
              "Penalosa",
              "Pepita",
              "Proper",
              "Salvacion",
              "Salvacion Poblacion",
              "San Antonio",
              "San Antonio Pob Patong Norte",
              "San Diego",
              "San Francisco East",
              "San Francisco West",
              "San Isidro",
              "San Pedro",
              "San Roque Pob",
              "San Vicente",
              "Santiago",
              "Santo Nino Pob",
              "Santol",
              "Tabangohay",
              "Tombo",
              "Veteranos"
            ]
          },
          {
            "k": "Albuera",
            "v": [
              "Antipolo",
              "Balugo",
              "Benolho",
              "Cambalading",
              "Damula An",
              "Mahayag",
              "Poblacion",
              "San Pedro",
              "Seguinon",
              "Tabgas",
              "Talisayan",
              "Tinag An"
            ]
          },
          {
            "k": "Babatngon",
            "v": [
              "Bacong",
              "Bagong Silang",
              "Biasong",
              "Gov E Jaro Bagahupi",
              "Guintigui An",
              "Lukay",
              "Malibago",
              "Naga Asan",
              "Pagsulhugon",
              "Planza",
              "Poblacion District I",
              "Poblacion District Ii",
              "Poblacion District Iii",
              "Poblacion District Iv",
              "Proper",
              "Rizal I",
              "Rizal Ii",
              "San Agustin",
              "San Isidro",
              "San Ricardo",
              "Sangputan",
              "Uban",
              "Victory",
              "Villa Magsaysay"
            ]
          },
          {
            "k": "Barugo",
            "v": [
              "Abango",
              "Bulod",
              "Calingcaguing",
              "Canomantag",
              "Cuta",
              "Domogdog",
              "Hilaba",
              "Hinugayan",
              "Minuhang",
              "Minuswang",
              "Poblacion Dist I",
              "Poblacion Dist Ii",
              "Poblacion Dist Iii",
              "Poblacion Dist Iv",
              "Poblacion Dist V",
              "San Isidro",
              "San Roque",
              "Santa Rosa",
              "Santarin"
            ]
          },
          {
            "k": "Bato",
            "v": [
              "Bagong Bayan District Pob",
              "Daan Lungsod",
              "Dolho",
              "Guerrero District Pob",
              "Iniguihan District Pob",
              "Kalanggaman District Pob",
              "Mabini",
              "Poblacion",
              "Ponong",
              "Tinago District Pob"
            ]
          },
          {
            "k": "Baybay City",
            "v": [
              "Banahao",
              "Biasong",
              "Bitanhuan",
              "Buenavista",
              "Bunga",
              "Butigan",
              "Candadam",
              "Caridad",
              "Ga As",
              "Gabas",
              "Gubang",
              "Higuloan",
              "Hilapnitan",
              "Hipusngo",
              "Imelda",
              "Jaena",
              "Kabalasan",
              "Kabatuan",
              "Kambonggan",
              "Kansungka",
              "Kantagnos",
              "Kilim",
              "Mailhi",
              "Maitum",
              "Makinhas",
              "Marcos",
              "Maslug",
              "Maybog",
              "Palhi",
              "Pangasungan",
              "Plaridel",
              "Poblacion Zone 1",
              "Poblacion Zone 10",
              "Poblacion Zone 11",
              "Poblacion Zone 12",
              "Poblacion Zone 13",
              "Poblacion Zone 14",
              "Poblacion Zone 15",
              "Poblacion Zone 16",
              "Poblacion Zone 17",
              "Poblacion Zone 18",
              "Poblacion Zone 19",
              "Poblacion Zone 2",
              "Poblacion Zone 20",
              "Poblacion Zone 21",
              "Poblacion Zone 22",
              "Poblacion Zone 23",
              "Poblacion Zone 3",
              "Poblacion Zone 4",
              "Poblacion Zone 5",
              "Poblacion Zone 6",
              "Poblacion Zone 7",
              "Poblacion Zone 8",
              "Poblacion Zone 9",
              "Punta",
              "Sabang",
              "San Agustin",
              "Santa Cruz",
              "Santo Rosario",
              "Villa Solidaridad"
            ]
          },
          {
            "k": "Burauen",
            "v": [
              "Abuyogon",
              "Arado",
              "Balorinay",
              "Buri",
              "Calsadahay",
              "Gamay",
              "Kalipayan",
              "Libas",
              "Maghubas",
              "Malabca",
              "Moguing",
              "Paitan",
              "Patong",
              "Poblacion District I",
              "Poblacion District Ii",
              "Poblacion District Iii",
              "Poblacion District Iv",
              "Poblacion District Ix",
              "Poblacion District V",
              "Poblacion District Vi",
              "Poblacion District Vii",
              "Poblacion District Viii",
              "San Esteban",
              "San Jose East",
              "San Jose West",
              "San Pablo"
            ]
          },
          {
            "k": "Capoocan",
            "v": [
              "Balucanad",
              "Balud",
              "Culasian",
              "Pinamopoan",
              "Poblacion",
              "Poblacion Zone I",
              "Poblacion Zone Ii"
            ]
          },
          {
            "k": "Carigara",
            "v": [
              "Barugohay Central",
              "Barugohay Norte",
              "Barugohay Sur",
              "Baybay Pob",
              "Bislig",
              "Canal",
              "Cutay",
              "Jugaban Pob",
              "Nauguisan",
              "Poblacion",
              "Ponong Pob",
              "Sagkahan",
              "San Juan",
              "Tagak",
              "Tangnan",
              "Uyawan"
            ]
          },
          {
            "k": "Dagami",
            "v": [
              "Abaca",
              "Abre",
              "Balilit",
              "Balugo",
              "Banayon",
              "Bayabas",
              "Bolirao",
              "Caanislagan",
              "Cabariwan",
              "Cabuloran",
              "Cabunga An",
              "Calipayan",
              "Calsadahay",
              "Caluctogan",
              "Calutan",
              "Camono An",
              "Candagara",
              "Canlingga",
              "Cansamada East",
              "Cansamada West",
              "Capulhan",
              "Digahongan",
              "Guinarona",
              "Hiabangan",
              "Hilabago",
              "Hinabuyan",
              "Hinologan",
              "Lapu Lapu Pob Dist 2",
              "Lobe Lobe",
              "Lobe Lobe East",
              "Los Martires",
              "Lusad Pob Dist 6",
              "Macaalang",
              "Maliwaliw",
              "Maragondong",
              "Paraiso",
              "Patoc",
              "Plaridel",
              "Proper",
              "Rizal",
              "Sampaguita",
              "Sampao East Pob Dist 9",
              "Sampao West Pob Dist 8",
              "San Antonio Pob Dist 5",
              "San Benito",
              "San Jose Pob Dist 1",
              "San Roque Pob Dist 3",
              "Santo Domingo",
              "Sawahon",
              "Sirab",
              "Sta Mesa Pob Dist 7",
              "Tagkip",
              "Talinhugon",
              "Tin Ao",
              "Tunga Pob Dist 4"
            ]
          },
          {
            "k": "Dulag",
            "v": [
              "Alegre",
              "Arado",
              "Barbo Pob",
              "Batug",
              "Bolongtohan",
              "Bulod",
              "Buntay Pob",
              "Cabacungan",
              "Cabarasan",
              "Cabato An",
              "Calipayan",
              "Calubian",
              "Cambula District Pob",
              "Camitoc",
              "Camote",
              "Candao Pob",
              "Catmonan Pob",
              "Combis Pob",
              "Dacay",
              "Del Carmen",
              "Del Pilar",
              "Fatima",
              "General Roxas",
              "Highway Pob",
              "Luan",
              "Magsaysay",
              "Maricum",
              "Market Site Pob",
              "Proper",
              "Rawis",
              "Rizal",
              "Romualdez",
              "Sabang Daguitan",
              "Salvacion",
              "San Agustin",
              "San Antonio",
              "San Isidro",
              "San Jose",
              "San Miguel Pob",
              "San Rafael",
              "San Vicente",
              "Serrano Pob",
              "Sungi Pob",
              "Tabu",
              "Tigbao",
              "Victory"
            ]
          },
          {
            "k": "Hilongos",
            "v": [
              "Bantigue",
              "Catandog 1",
              "Catandog 2",
              "Central Barangay Pob",
              "Eastern Barangay Pob",
              "Lunang",
              "Pontod",
              "San Isidro",
              "San Juan",
              "Talisay"
            ]
          },
          {
            "k": "Hindang",
            "v": [
              "Bontoc",
              "Bulacan",
              "Doos Del Norte",
              "Doos Del Sur",
              "Poblacion 1",
              "Poblacion 2",
              "San Vicente",
              "Tabok",
              "Tagbibi"
            ]
          },
          {
            "k": "Inopacan",
            "v": [
              "Esperanza",
              "Guadalupe",
              "Maljo",
              "Marao",
              "Poblacion",
              "Tahud",
              "Tinago"
            ]
          },
          {
            "k": "Isabel",
            "v": [
              "Anislag",
              "Antipolo",
              "Apale",
              "Bantigue",
              "Benog",
              "Bilwang",
              "Can Andan",
              "Cangag",
              "Consolacion",
              "Honan",
              "Libertad",
              "Mahayag",
              "Marvel Pob",
              "Matlang",
              "Monte Alegre",
              "Puting Bato",
              "San Francisco",
              "San Roque",
              "Santa Cruz",
              "Santo Nino Pob",
              "Santo Rosario",
              "Tabunok",
              "Tolingon",
              "Tubod"
            ]
          },
          {
            "k": "Jaro",
            "v": ["Hiagsam", "Hibucawan", "Macopa", "Olotan", "Poblacion"]
          },
          {
            "k": "Javier Bugho",
            "v": [
              "Batug",
              "Binulho",
              "Calzada",
              "Inayupan",
              "Laray",
              "Magsaysay Responde",
              "Malitbogay",
              "Manarug",
              "Manlilisid",
              "Naliwatan",
              "Odiong",
              "Picas Norte",
              "Pinocawan",
              "Poblacion Zone 1",
              "Poblacion Zone 2",
              "Proper",
              "Rizal",
              "Santa Cruz",
              "Talisayan"
            ]
          },
          {
            "k": "Julita",
            "v": [
              "Dita",
              "Poblacion District I",
              "Poblacion District Ii",
              "Poblacion District Iii",
              "Poblacion District Iv"
            ]
          },
          {
            "k": "Kananga",
            "v": [
              "Aguiting",
              "Kawayan",
              "Libongao",
              "Lonoy",
              "Masarayao",
              "Monte Alegre",
              "Naghalin",
              "Natubgan",
              "Santo Nino",
              "Tongonan",
              "Tugbong"
            ]
          },
          {
            "k": "La Paz",
            "v": [
              "Bagacay East",
              "Bagacay West",
              "Bocawon",
              "Bongtod",
              "Buracan",
              "Caabangan",
              "Cacao",
              "Cagngaran",
              "Calabnian",
              "Calaghusan",
              "Caltayan",
              "Canbanez",
              "Cogon",
              "Duyog",
              "Gimenarat East",
              "Gimenarat West",
              "Limba",
              "Lubi Lubi",
              "Luneta",
              "Mag Aso",
              "Moroboro",
              "Pansud",
              "Pawa",
              "Piliway",
              "Poblacion District 1",
              "Poblacion District 2",
              "Poblacion District 3",
              "Poblacion District 4",
              "Proper",
              "Quiong",
              "Rizal",
              "San Victoray",
              "Santa Ana",
              "Santa Elena",
              "Tabang",
              "Tarugan"
            ]
          },
          {
            "k": "Macarthur",
            "v": [
              "Batug",
              "Burabod",
              "Capudlosan",
              "Casuntingan",
              "Causwagan",
              "Danao",
              "Dona Josefa",
              "General Luna",
              "Kiling",
              "Lanawan",
              "Liwayway",
              "Maya",
              "Oguisan",
              "Osmena",
              "Palale 1",
              "Palale 2",
              "Poblacion District 1",
              "Poblacion District 2",
              "Poblacion District 3",
              "Pongon",
              "Proper",
              "Quezon",
              "Romualdez",
              "Salvacion",
              "San Antonio",
              "San Isidro",
              "San Pedro",
              "San Vicente",
              "Santa Isabel",
              "Tinawan",
              "Tuyo",
              "Villa Imelda"
            ]
          },
          {
            "k": "Mahaplag",
            "v": [
              "Cuatro De Agosto",
              "Hilusig",
              "Liberacion",
              "Mabuhay",
              "Mahayahay",
              "Malinao",
              "Paril",
              "Poblacion",
              "Polahongon",
              "San Isidro",
              "Uguis",
              "Upper Mahaplag"
            ]
          },
          {
            "k": "Matag Ob",
            "v": ["Balagtas", "San Guillermo", "San Vicente", "Santo Rosario"]
          },
          {
            "k": "Matalom",
            "v": [
              "Agbanga",
              "Caridad Norte",
              "Caridad Sur",
              "Itum",
              "Punong",
              "San Isidro Pob",
              "San Juan",
              "San Pedro Pob",
              "Santa Fe",
              "Santo Nino Pob",
              "Zaragoza"
            ]
          },
          {
            "k": "Mayorga",
            "v": [
              "A Bonifacio",
              "Burgos",
              "Calipayan",
              "Camansi",
              "General Antonio Luna",
              "Liberty",
              "Mabini",
              "Ormocay",
              "Poblacion Zone 1",
              "Poblacion Zone 2",
              "Poblacion Zone 3",
              "San Roque",
              "Santa Cruz",
              "Talisay",
              "Union",
              "Wilson"
            ]
          },
          {
            "k": "Merida",
            "v": [
              "Binabaye",
              "Calunangan",
              "Calunasan",
              "Can Unzo",
              "Canbantug",
              "Casilda",
              "Lamanoc",
              "Libas",
              "Libjo",
              "Macario",
              "Mahalit",
              "Mahayag",
              "Poblacion",
              "Puerto Bello",
              "San Isidro",
              "San Jose"
            ]
          },
          {
            "k": "Ormoc City",
            "v": [
              "Airport",
              "Alegria",
              "Alta Vista",
              "Bagong Buhay",
              "Bantigue",
              "Barangay 1 Pob",
              "Barangay 10 Pob",
              "Barangay 11 Pob",
              "Barangay 12 Pob",
              "Barangay 13 Pob",
              "Barangay 14 Pob",
              "Barangay 15 Pob",
              "Barangay 16 Pob",
              "Barangay 17 Pob",
              "Barangay 18 Pob",
              "Barangay 19 Pob",
              "Barangay 2 Pob",
              "Barangay 20 Pob",
              "Barangay 21 Pob",
              "Barangay 22 Pob",
              "Barangay 23 Pob",
              "Barangay 24 Pob",
              "Barangay 25 Pob",
              "Barangay 26 Pob",
              "Barangay 27 Pob",
              "Barangay 28 Pob",
              "Barangay 29 Pob",
              "Barangay 3 Pob",
              "Barangay 4 Pob",
              "Barangay 5 Pob",
              "Barangay 6 Pob",
              "Barangay 7 Pob",
              "Barangay 8 Pob",
              "Barangay 9 Pob",
              "Batuan",
              "Biliboy",
              "Cabulihan",
              "Cagbuhangin",
              "Camp Downes",
              "Can Adieng",
              "Cogon Combado",
              "Concepcion",
              "Curva",
              "Danhug",
              "Dayhagan",
              "Dolores",
              "Don Felipe Larrazabal",
              "Dona Feliza Z Mejia",
              "Donghol",
              "Ipil",
              "Juaton",
              "Kadaohan",
              "Labrador Balion",
              "Lao",
              "Libertad",
              "Licuma",
              "Liloan",
              "Linao",
              "Luna",
              "Macabug",
              "Margen",
              "Mas In",
              "Matica A",
              "Milagro",
              "Nasunogan",
              "Naungan",
              "Proper",
              "Punta",
              "Rufina M Tan",
              "Sabang Bao",
              "Salvacion",
              "San Antonio",
              "San Isidro",
              "San Jose",
              "San Juan",
              "San Pablo Simangan",
              "Tambulilid",
              "Valencia"
            ]
          },
          {
            "k": "Palo",
            "v": [
              "Anahaway",
              "Arado",
              "Baras",
              "Barayong",
              "Buri Pob",
              "Cabarasan Daku",
              "Cabarasan Guti",
              "Campetik",
              "Candahug",
              "Cangumbang",
              "Canhidoc",
              "Capirawan",
              "Castilla",
              "Cavite East Pob",
              "Cavite West Pob",
              "Cogon Cogon San Joaquin",
              "Gacao",
              "Guindapunan",
              "Libertad",
              "Luntad Pob",
              "Naga Naga",
              "Pawing",
              "Salvacion",
              "San Agustin",
              "San Antonio",
              "San Fernando",
              "San Isidro",
              "San Joaquin",
              "San Jose",
              "Tacuranga",
              "Teraza"
            ]
          },
          {
            "k": "Palompon",
            "v": [
              "Bitaog Pob Ypil Iii",
              "Cantandoy",
              "Central 1 Pob",
              "Central 2 Pob",
              "Guiwan 1 Pob",
              "Guiwan 2 Pob",
              "Hinablayan Pob Central 3",
              "Liberty",
              "Lomonon",
              "Mazawalo Pob Lili On",
              "Pinagdait Pob Ypil I",
              "Pinaghi Usa Pob Ypil Ii",
              "Poblacion",
              "Sabang",
              "San Isidro",
              "San Juan",
              "San Miguel",
              "Tinabilan",
              "Tinago",
              "Tinubdan"
            ]
          },
          {
            "k": "Pastrana",
            "v": [
              "Arabunog",
              "Aringit",
              "Bahay",
              "Cabaohan",
              "Calsadahay",
              "Cancaraja",
              "Capilla",
              "Colawen",
              "District 1 Pob",
              "District 2 Pob",
              "District 3 Pob",
              "District 4 Pob",
              "Guindapunan",
              "Halaba",
              "Jones",
              "Lima",
              "Lourdes",
              "Macalpiay",
              "Malitbogay",
              "Manaybanay",
              "Maricum",
              "Patong",
              "Proper",
              "Sapsap",
              "Socsocon",
              "Tingib",
              "Yapad"
            ]
          },
          {
            "k": "San Miguel",
            "v": [
              "Bagacay",
              "Bahay",
              "Bairan",
              "Cabatianuhan",
              "Canap",
              "Capilihan",
              "Caraycaray",
              "Cayare West Poblacion",
              "Guinciaman",
              "Libtong East Poblacion",
              "Lukay",
              "Malaguinabot",
              "Malpag",
              "Mawodpawod",
              "Patong",
              "Pinarigusan",
              "San Andres",
              "Santa Cruz",
              "Santol"
            ]
          },
          {
            "k": "Santa Fe",
            "v": [
              "Baculanad",
              "Badiangay",
              "Bulod",
              "Catoogan",
              "Cutay",
              "Gapas",
              "Katipunan",
              "Milagrosa",
              "Pilit",
              "Pitogo",
              "Proper",
              "San Isidro",
              "San Juan",
              "San Miguelay",
              "San Roque",
              "Tibak",
              "Victoria",
              "Zone 1 Pob",
              "Zone 2 Pob",
              "Zone 3 Pob",
              "Zone 4 Pob Cabangcalan"
            ]
          },
          {
            "k": "Tabontabon",
            "v": [
              "District I Pob Quezon",
              "District Ii Pob Rizal",
              "District Iii Pob Bonifacio",
              "District Iv Pob Macarthur",
              "Mering"
            ]
          },
          {
            "k": "Tacloban City",
            "v": [
              "Barangay 100 San Roque",
              "Barangay 101 New Kawayan",
              "Barangay 102 Old Kawayan",
              "Barangay 103 A San Paglaum",
              "Barangay 103 Palanog",
              "Barangay 104 Salvacion",
              "Barangay 105 Suhi",
              "Barangay 106 Santo Nino",
              "Barangay 107 Santa Elena",
              "Barangay 108 Tagapuro",
              "Barangay 109 A",
              "Barangay 109 V And G Subd",
              "Barangay 110 Utap",
              "Barangay 12 Palanog Resettlement",
              "Barangay 13",
              "Barangay 14",
              "Barangay 15",
              "Barangay 16",
              "Barangay 17",
              "Barangay 18",
              "Barangay 19",
              "Barangay 2",
              "Barangay 20",
              "Barangay 21",
              "Barangay 21 A",
              "Barangay 22",
              "Barangay 23",
              "Barangay 23 A",
              "Barangay 24",
              "Barangay 25",
              "Barangay 26",
              "Barangay 27",
              "Barangay 28",
              "Barangay 29",
              "Barangay 30",
              "Barangay 31",
              "Barangay 32",
              "Barangay 33",
              "Barangay 34",
              "Barangay 35",
              "Barangay 35 A",
              "Barangay 36",
              "Barangay 36 A",
              "Barangay 37",
              "Barangay 37 A",
              "Barangay 38",
              "Barangay 39",
              "Barangay 40",
              "Barangay 41",
              "Barangay 42",
              "Barangay 42 A",
              "Barangay 43",
              "Barangay 43 A",
              "Barangay 43 B",
              "Barangay 44",
              "Barangay 44 A",
              "Barangay 45",
              "Barangay 46",
              "Barangay 47",
              "Barangay 48",
              "Barangay 48 A",
              "Barangay 48 B",
              "Barangay 49",
              "Barangay 5",
              "Barangay 5 A",
              "Barangay 50",
              "Barangay 50 A",
              "Barangay 50 B",
              "Barangay 51",
              "Barangay 51 A",
              "Barangay 52",
              "Barangay 53",
              "Barangay 54",
              "Barangay 54 A",
              "Barangay 56",
              "Barangay 56 A",
              "Barangay 57",
              "Barangay 58",
              "Barangay 59",
              "Barangay 59 A",
              "Barangay 59 B",
              "Barangay 6",
              "Barangay 6 A",
              "Barangay 60",
              "Barangay 60 A",
              "Barangay 61",
              "Barangay 62",
              "Barangay 62 A",
              "Barangay 62 B",
              "Barangay 63",
              "Barangay 64",
              "Barangay 65",
              "Barangay 66",
              "Barangay 66 A",
              "Barangay 67",
              "Barangay 68",
              "Barangay 69",
              "Barangay 7",
              "Barangay 70",
              "Barangay 71",
              "Barangay 72",
              "Barangay 73",
              "Barangay 74",
              "Barangay 75",
              "Barangay 76",
              "Barangay 77",
              "Barangay 78 Marasbaras",
              "Barangay 79 Marasbaras",
              "Barangay 8",
              "Barangay 8 A",
              "Barangay 80 Marasbaras",
              "Barangay 81 Marasbaras",
              "Barangay 82 Marasbaras",
              "Barangay 83 A San Jose",
              "Barangay 83 B",
              "Barangay 83 C San Jose",
              "Barangay 83 San Jose",
              "Barangay 84 San Jose",
              "Barangay 85 San Jose",
              "Barangay 86",
              "Barangay 87",
              "Barangay 88",
              "Barangay 89",
              "Barangay 90 San Jose",
              "Barangay 91 Abucay",
              "Barangay 92 Apitong",
              "Barangay 93 Bagacay",
              "Barangay 94 A",
              "Barangay 94 Tigbao",
              "Barangay 95 A Caibaan",
              "Barangay 95 Caibaan",
              "Barangay 96 Calanipawan",
              "Barangay 97 Cabalawan",
              "Barangay 98 Camansihay",
              "Barangay 99 Diit",
              "El Reposo Barangays 55 And 55a",
              "Libertad Barangays 1 And 4",
              "Nula Tula Bgys 3 And 3a",
              "Proper"
            ]
          },
          {
            "k": "Tanauan",
            "v": [
              "Ada",
              "Amanluran",
              "Arado",
              "Atipolo",
              "Bangon",
              "Bantagan",
              "Baras",
              "Binolo",
              "Binongto An",
              "Bislig",
              "Buntay Pob",
              "Cabalagnan",
              "Cabarasan Guti",
              "Cabonga An",
              "Cabuynan",
              "Cahumayhumayan",
              "Calogcog",
              "Calsadahay",
              "Camire",
              "Canbalisara",
              "Canramos Pob",
              "Catigbian",
              "Catmon",
              "Cogon",
              "Guindag An",
              "Guingawan",
              "Hilagpad",
              "Kiling",
              "Lapay",
              "Licod Pob",
              "Limbuhan Daku",
              "Limbuhan Guti",
              "Linao",
              "Magay",
              "Maghulod",
              "Malaguicay",
              "Maribi",
              "Mohon",
              "Pago",
              "Pasil",
              "Pikas",
              "Sacme",
              "Salvador",
              "San Isidro",
              "San Miguel Pob",
              "San Roque Pob",
              "San Victor",
              "Santa Cruz",
              "Santa Elena",
              "Santo Nino Pob Haclagan",
              "Solano",
              "Talolora",
              "Tugop"
            ]
          },
          {
            "k": "Tolosa",
            "v": [
              "Burak",
              "Canmogsay",
              "Cantariwis",
              "Capangihan",
              "Dona Brigida",
              "Imelda",
              "Malbog",
              "Olot",
              "Opong",
              "Poblacion",
              "Quilao",
              "San Roque",
              "San Vicente",
              "Tanghas",
              "Telegrafo"
            ]
          },
          {
            "k": "Tunga",
            "v": [
              "Astorga Upart",
              "Balire",
              "San Antonio Pob",
              "San Pedro",
              "San Roque",
              "San Vicente Pob",
              "Santo Nino Pob"
            ]
          },
          {
            "k": "Villaba",
            "v": [
              "Abijao",
              "Balite",
              "Bangcal",
              "Fatima Pob",
              "Hinabuyan",
              "Jordan",
              "Poblacion",
              "Poblacion Norte",
              "Poblacion Sur",
              "Silad"
            ]
          }
        ],
        "names": [
          "Abuyog",
          "Alangalang",
          "Albuera",
          "Babatngon",
          "Barugo",
          "Bato",
          "Baybay City",
          "Burauen",
          "Capoocan",
          "Carigara",
          "Dagami",
          "Dulag",
          "Hilongos",
          "Hindang",
          "Inopacan",
          "Isabel",
          "Jaro",
          "Javier Bugho",
          "Julita",
          "Kananga",
          "La Paz",
          "Macarthur",
          "Mahaplag",
          "Matag Ob",
          "Matalom",
          "Mayorga",
          "Merida",
          "Ormoc City",
          "Palo",
          "Palompon",
          "Pastrana",
          "San Miguel",
          "Santa Fe",
          "Tabontabon",
          "Tacloban City",
          "Tanauan",
          "Tolosa",
          "Tunga",
          "Villaba"
        ]
      }
    },
    {
      "k": "Maguindanao",
      "v": {
        "lookup": [
          {
            "k": "Cotabato City",
            "v": [
              "Poblacion",
              "Poblacion I",
              "Poblacion Ii",
              "Poblacion Iii",
              "Poblacion Iv",
              "Poblacion Ix",
              "Poblacion V",
              "Poblacion Vi",
              "Poblacion Vii",
              "Rosary Heights",
              "Rosary Heights I",
              "Rosary Heights Ii",
              "Rosary Heights Iii",
              "Rosary Heights Iv",
              "Rosary Heights Ix",
              "Rosary Heights V",
              "Rosary Heights Vi",
              "Rosary Heights Vii",
              "Rosary Heights Viii",
              "Rosary Heights X",
              "Rosary Heights Xi",
              "Rosary Heights Xii",
              "Rosary Heights Xiii",
              "Tamontaka",
              "Tamontaka I"
            ]
          },
          {
            "k": "Parang",
            "v": [
              "Barangay Magsaysay",
              "Barangay Making",
              "Barangay Sarmiento (Except Hulbot)",
              "Notre Dame Site",
              "Nursery Site",
              "Poblacion 1",
              "Poblacion 2 (Except Pacuma)",
              "Polloc"
            ]
          }
        ],
        "names": ["Cotabato City", "Parang"]
      }
    },
    {
      "k": "Marinduque",
      "v": {
        "lookup": [
          {
            "k": "Boac",
            "v": [
              "Agot",
              "Amoingon",
              "Apitong",
              "Balagasan",
              "Balaring",
              "Balimbing",
              "Balogo",
              "Bangbangalon",
              "Bantad",
              "Bantay",
              "Boton",
              "Buliasnin",
              "Bunganay",
              "Caganhao",
              "Cawit",
              "Daypay",
              "Ihatub",
              "Isok I (Pob.)",
              "Isok Ii Pob. (Kalamias)",
              "Laylay",
              "Lupac",
              "Maligaya",
              "Malusak (Pob.)",
              "Mansiwat",
              "Mataas Na Bayan (Pob.)",
              "Mercado (Pob.)",
              "Murallon (Pob.)",
              "Pili",
              "Poras",
              "San Miguel (Pob.)",
              "Santol",
              "Sawi",
              "Tabi",
              "Tabigue",
              "Tampus (Pob.)",
              "Tanza",
              "Tugos"
            ]
          },
          {
            "k": "Buenavista",
            "v": [
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Bicas-bicas",
              "Caigangan",
              "Daykitin",
              "Libas",
              "Malbog",
              "Sihi",
              "Timbo (Sanggulong)",
              "Tungib-lipata",
              "Yook"
            ]
          },
          {
            "k": "Gasan",
            "v": [
              "Antipolo",
              "Bachao Ibaba",
              "Bachao Ilaya",
              "Bacongbacong",
              "Bahi",
              "Bangbang",
              "Banot",
              "Banuyo",
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Bognuyan",
              "Cabugao",
              "Dawis",
              "Dili",
              "Libtangin",
              "Mahunig",
              "Mangiliol",
              "Masiga",
              "Matandang Gasan",
              "Pangi",
              "Pingan",
              "Tapuyan",
              "Tiguion"
            ]
          },
          {
            "k": "Mogpog",
            "v": [
              "Anapog-sibucao",
              "Balanacan",
              "Banto",
              "Bintakay",
              "Bocboc",
              "Butansapa",
              "Candahon",
              "Capayang",
              "Danao",
              "Dulong Bayan (Pob.)",
              "Gitnang Bayan (Pob.)",
              "Ino",
              "Janagdong",
              "Lamesa",
              "Laon",
              "Magapua",
              "Malusak",
              "Mangyan-mababad",
              "Market Site (Pob.)",
              "Mataas Na Bayan",
              "Nangka I",
              "Nangka Ii",
              "Paye",
              "Pili",
              "Sumangga",
              "Tarug",
              "Villa Mendez (Pob.)"
            ]
          },
          {
            "k": "Santa Cruz",
            "v": [
              "Aturan",
              "Bagong Silang Pob. (2nd Zone)",
              "Baliis",
              "Balogo",
              "Banahaw Pob. (3rd Zone)",
              "Buyabod",
              "Dolores",
              "Hupi",
              "Ipil",
              "Lamesa",
              "Landy",
              "Lapu-lapu Pob. (5th Zone)",
              "Libjo",
              "Lipa",
              "Lusok",
              "Maharlika Pob. (1st Zone)",
              "Matalaba",
              "Napo",
              "Pag-asa Pob. (4th Zone)",
              "Pantayin",
              "Tagum",
              "Tamayo",
              "Tawiran",
              "Taytay"
            ]
          },
          {
            "k": "Torrijos",
            "v": [
              "Bonliw",
              "Buangan",
              "Cabuyo",
              "Cagpo",
              "Dampulan",
              "Kay Duke",
              "Mabuhay",
              "Makawayan",
              "Malibago",
              "Malinao",
              "Mariangga",
              "Matuyatuya",
              "Poblacion",
              "Poctoy",
              "Suha",
              "Tigwi"
            ]
          }
        ],
        "names": [
          "Boac",
          "Buenavista",
          "Gasan",
          "Mogpog",
          "Santa Cruz",
          "Torrijos"
        ]
      }
    },
    {
      "k": "Masbate",
      "v": {
        "lookup": [
          {
            "k": "Aroroy",
            "v": [
              "Ambolong",
              "Bagauma",
              "Bangon",
              "Jaboyoan",
              "Luy-a",
              "Panique",
              "Poblacion",
              "Puro",
              "Tinago"
            ]
          },
          {
            "k": "Baleno",
            "v": [
              "Cagara",
              "Cagpandan",
              "Cancahorao",
              "Canjunday",
              "Gangao",
              "Lagta",
              "Lahong Proper",
              "Manoboc",
              "Poblacion",
              "Potoson",
              "Sog-ong",
              "Tinapian"
            ]
          },
          {
            "k": "Cataingan",
            "v": [
              "Aguada",
              "Bagumbayan",
              "Domorog",
              "Gahit",
              "Pawican",
              "Poblacion",
              "Santa Teresita"
            ]
          },
          {
            "k": "Dimasalang",
            "v": [
              "Balocawe",
              "Banahao",
              "Canomay",
              "Gaid",
              "Gregorio Alino (Pia-ong)",
              "Poblacion"
            ]
          },
          {
            "k": "Mandaon",
            "v": [
              "Bat-ongan",
              "Cabitan",
              "Centro",
              "Dayao",
              "Mabatobato",
              "Poblacion"
            ]
          },
          {
            "k": "Masbate City",
            "v": [
              "Asid",
              "Bagumbayan",
              "Bantigue",
              "Bapor (Pob.)",
              "Centro (Pob.)",
              "Espinosa",
              "F. Magallanes",
              "Ibingay",
              "Igang",
              "Kalipay (Pob.)",
              "Kinamaligan",
              "Malinta",
              "Mayngaran",
              "Nursery",
              "Pating (Pob.)",
              "Sinalongan",
              "Tugbo",
              "Usab"
            ]
          },
          {
            "k": "Milagros",
            "v": [
              "Bacolod",
              "Calasuche",
              "Cayabon",
              "Matiporon",
              "Narangasan",
              "Poblacion East",
              "Poblacion West",
              "Tawad"
            ]
          },
          {
            "k": "Mobo",
            "v": [
              "Bagacay",
              "Balatucan",
              "Dacu",
              "Lalaguna",
              "Luyong Catungan",
              "Marintoc",
              "Nasunduan",
              "Pinamarbuhan",
              "Poblacion Dist. I",
              "Poblacion Dist. Ii",
              "Tabuc",
              "Tugbo",
              "Umabay Exterior"
            ]
          },
          {
            "k": "Palanas",
            "v": ["Maravilla", "Nabangig", "Nipa", "Poblacion", "Santa Cruz"]
          },
          {
            "k": "Pio V. Corpuz (Limbuhan)",
            "v": [
              "Alegria",
              "Buenasuerte",
              "Bunducan",
              "Cabangrayan",
              "Calongongan",
              "Labigan",
              "Lampuyang",
              "Mabuhay",
              "Palho",
              "Poblacion"
            ]
          },
          {
            "k": "Placer",
            "v": [
              "Aguada",
              "Daanlungsod",
              "Katipunan",
              "Libas",
              "Locso-an",
              "Luna",
              "Mahayag",
              "Mahayahay",
              "Manlut-od",
              "Poblacion",
              "Puro",
              "Villa Inocencio"
            ]
          },
          {
            "k": "Uson",
            "v": [
              "Arado",
              "Armenia",
              "Buenasuerte",
              "Buenavista",
              "Campana",
              "Centro",
              "Crossing",
              "Dapdap",
              "Del Carmen",
              "Del Rosario",
              "Libertad",
              "Mabini",
              "Mabuhay",
              "Magsaysay",
              "Marcella",
              "Mongahay",
              "Morocborocan",
              "Paguihaman",
              "Poblacion",
              "Quezon",
              "San Isidro",
              "San Jose"
            ]
          }
        ],
        "names": [
          "Aroroy",
          "Baleno",
          "Cataingan",
          "Dimasalang",
          "Mandaon",
          "Masbate City",
          "Milagros",
          "Mobo",
          "Palanas",
          "Pio V. Corpuz (Limbuhan)",
          "Placer",
          "Uson"
        ]
      }
    },
    {
      "k": "Metro Manila",
      "v": {
        "lookup": [
          {
            "k": "Caloocan City",
            "v": [
              "Barangay 1",
              "Barangay 10",
              "Barangay 100",
              "Barangay 101",
              "Barangay 102",
              "Barangay 103",
              "Barangay 104",
              "Barangay 105",
              "Barangay 106",
              "Barangay 107",
              "Barangay 108",
              "Barangay 109",
              "Barangay 11",
              "Barangay 110",
              "Barangay 111",
              "Barangay 112",
              "Barangay 113",
              "Barangay 114",
              "Barangay 115",
              "Barangay 116",
              "Barangay 117",
              "Barangay 118",
              "Barangay 119",
              "Barangay 12",
              "Barangay 120",
              "Barangay 121",
              "Barangay 122",
              "Barangay 123",
              "Barangay 124",
              "Barangay 125",
              "Barangay 126",
              "Barangay 127",
              "Barangay 128",
              "Barangay 129",
              "Barangay 13",
              "Barangay 130",
              "Barangay 131",
              "Barangay 132",
              "Barangay 133",
              "Barangay 134",
              "Barangay 135",
              "Barangay 136",
              "Barangay 137",
              "Barangay 138",
              "Barangay 139",
              "Barangay 14",
              "Barangay 140",
              "Barangay 141",
              "Barangay 142",
              "Barangay 143",
              "Barangay 144",
              "Barangay 145",
              "Barangay 146",
              "Barangay 147",
              "Barangay 148",
              "Barangay 149",
              "Barangay 15",
              "Barangay 150",
              "Barangay 151",
              "Barangay 152",
              "Barangay 153",
              "Barangay 154",
              "Barangay 155",
              "Barangay 156",
              "Barangay 157",
              "Barangay 158",
              "Barangay 159",
              "Barangay 16",
              "Barangay 160",
              "Barangay 161",
              "Barangay 162",
              "Barangay 163",
              "Barangay 164",
              "Barangay 165",
              "Barangay 166",
              "Barangay 167",
              "Barangay 168",
              "Barangay 169",
              "Barangay 17",
              "Barangay 170",
              "Barangay 171",
              "Barangay 172",
              "Barangay 173",
              "Barangay 174",
              "Barangay 175",
              "Barangay 176",
              "Barangay 177",
              "Barangay 178",
              "Barangay 179",
              "Barangay 18",
              "Barangay 180",
              "Barangay 181",
              "Barangay 182",
              "Barangay 183",
              "Barangay 184",
              "Barangay 185",
              "Barangay 186",
              "Barangay 187",
              "Barangay 188",
              "Barangay 19",
              "Barangay 2",
              "Barangay 20",
              "Barangay 21",
              "Barangay 22",
              "Barangay 23",
              "Barangay 24",
              "Barangay 25",
              "Barangay 26",
              "Barangay 27",
              "Barangay 28",
              "Barangay 29",
              "Barangay 3",
              "Barangay 30",
              "Barangay 31",
              "Barangay 32",
              "Barangay 33",
              "Barangay 34",
              "Barangay 35",
              "Barangay 36",
              "Barangay 37",
              "Barangay 38",
              "Barangay 39",
              "Barangay 4",
              "Barangay 40",
              "Barangay 41",
              "Barangay 42",
              "Barangay 43",
              "Barangay 44",
              "Barangay 45",
              "Barangay 46",
              "Barangay 47",
              "Barangay 48",
              "Barangay 49",
              "Barangay 5",
              "Barangay 50",
              "Barangay 51",
              "Barangay 52",
              "Barangay 53",
              "Barangay 54",
              "Barangay 55",
              "Barangay 56",
              "Barangay 57",
              "Barangay 58",
              "Barangay 59",
              "Barangay 6",
              "Barangay 60",
              "Barangay 61",
              "Barangay 62",
              "Barangay 63",
              "Barangay 64",
              "Barangay 65",
              "Barangay 66",
              "Barangay 67",
              "Barangay 68",
              "Barangay 69",
              "Barangay 7",
              "Barangay 70",
              "Barangay 71",
              "Barangay 72",
              "Barangay 73",
              "Barangay 74",
              "Barangay 75",
              "Barangay 76",
              "Barangay 77",
              "Barangay 78",
              "Barangay 79",
              "Barangay 8",
              "Barangay 80",
              "Barangay 81",
              "Barangay 82",
              "Barangay 83",
              "Barangay 84",
              "Barangay 85",
              "Barangay 86",
              "Barangay 87",
              "Barangay 88",
              "Barangay 89",
              "Barangay 9",
              "Barangay 90",
              "Barangay 91",
              "Barangay 92",
              "Barangay 93",
              "Barangay 94",
              "Barangay 95",
              "Barangay 96",
              "Barangay 97",
              "Barangay 98",
              "Barangay 99"
            ]
          },
          {
            "k": "Las PiÃ±as City",
            "v": [
              "Almanza Dos",
              "Almanza Uno",
              "B F International Village",
              "Daniel Fajardo",
              "Elias Aldana",
              "Ilaya",
              "Manuyo Dos",
              "Manuyo Uno",
              "Pamplona Dos",
              "Pamplona Tres",
              "Pamplona Uno",
              "Pilar",
              "Pulang Lupa Dos",
              "Pulang Lupa Uno",
              "Talon Dos",
              "Talon Kuatro",
              "Talon Singko",
              "Talon Tres",
              "Talon Uno",
              "Zapote"
            ]
          },
          {
            "k": "Makati City",
            "v": [
              "Bangkal",
              "Bel Air 1",
              "Bel Air 2",
              "Bel Air 3",
              "Bel Air 4",
              "Carmona",
              "Cembo",
              "Comembo",
              "DasmariÃ±as",
              "East Rembo",
              "Forbes Park",
              "Guadalupe Nuevo",
              "Guadalupe Viejo",
              "Kasilawan",
              "La Paz",
              "Magallanes",
              "Olympia",
              "Palanan",
              "Pembo",
              "Pinagkaisahan",
              "Pio Del Pilar",
              "Pitogo",
              "Poblacion",
              "Post Proper Northside",
              "Post Proper Southside",
              "Rizal",
              "San Antonio",
              "San Isidro",
              "San Lorenzo",
              "Santa Cruz",
              "Singkamas",
              "South Cembo",
              "Tejeros",
              "Urdaneta",
              "Valenzuela",
              "West Rembo"
            ]
          },
          {
            "k": "Malabon City",
            "v": [
              "Acacia",
              "Baritan",
              "Bayanbayanan",
              "Catmon",
              "Concepcion",
              "Dampalit",
              "Flores",
              "Hulong Duhat",
              "Ibaba",
              "Longos",
              "Maysilo",
              "Muzon",
              "Niugan",
              "Panghulo",
              "Potrero",
              "San Agustin",
              "Santolan",
              "TaÃ±ong Pob",
              "Tinajeros",
              "Tonsuya",
              "Tugatog"
            ]
          },
          {
            "k": "Mandaluyong City",
            "v": [
              "Addition Hills",
              "Bagong Silang",
              "Barangka Drive",
              "Barangka Ibaba",
              "Barangka Ilaya",
              "Barangka Itaas",
              "Buayang Bato",
              "Burol",
              "Daang Bakal",
              "Hagdang Bato Itaas",
              "Hagdang Bato Libis",
              "Harapin Ang Bukas",
              "Highway Hills",
              "Hulo",
              "Mabinij Rizal",
              "Malamig",
              "Mauway",
              "Namayan",
              "New ZaÃ±iga",
              "Old ZaÃ±iga",
              "Pagasa",
              "Plainview",
              "Pleasant Hills",
              "Poblacion",
              "San Jose",
              "Vergara",
              "Wackwack Greenhills"
            ]
          },
          {
            "k": "Manila City",
            "v": [
              "Barangay 1",
              "Barangay 100",
              "Barangay 101",
              "Barangay 102",
              "Barangay 103",
              "Barangay 104",
              "Barangay 105",
              "Barangay 106",
              "Barangay 107",
              "Barangay 108",
              "Barangay 109",
              "Barangay 11",
              "Barangay 110",
              "Barangay 111",
              "Barangay 112",
              "Barangay 116",
              "Barangay 117",
              "Barangay 118",
              "Barangay 119",
              "Barangay 12",
              "Barangay 120",
              "Barangay 121",
              "Barangay 122",
              "Barangay 123",
              "Barangay 124",
              "Barangay 125",
              "Barangay 126",
              "Barangay 127",
              "Barangay 128",
              "Barangay 129",
              "Barangay 13",
              "Barangay 130",
              "Barangay 131",
              "Barangay 132",
              "Barangay 133",
              "Barangay 134",
              "Barangay 135",
              "Barangay 136",
              "Barangay 137",
              "Barangay 138",
              "Barangay 139",
              "Barangay 14",
              "Barangay 140",
              "Barangay 141",
              "Barangay 142",
              "Barangay 143",
              "Barangay 144",
              "Barangay 145",
              "Barangay 146",
              "Barangay 147",
              "Barangay 148",
              "Barangay 149",
              "Barangay 15",
              "Barangay 150",
              "Barangay 151",
              "Barangay 152",
              "Barangay 153",
              "Barangay 154",
              "Barangay 155",
              "Barangay 156",
              "Barangay 157",
              "Barangay 158",
              "Barangay 159",
              "Barangay 16",
              "Barangay 160",
              "Barangay 161",
              "Barangay 162",
              "Barangay 163",
              "Barangay 164",
              "Barangay 165",
              "Barangay 166",
              "Barangay 167",
              "Barangay 168",
              "Barangay 169",
              "Barangay 17",
              "Barangay 170",
              "Barangay 171",
              "Barangay 172",
              "Barangay 173",
              "Barangay 174",
              "Barangay 175",
              "Barangay 176",
              "Barangay 177",
              "Barangay 178",
              "Barangay 179",
              "Barangay 18",
              "Barangay 180",
              "Barangay 181",
              "Barangay 182",
              "Barangay 183",
              "Barangay 184",
              "Barangay 185",
              "Barangay 186",
              "Barangay 187",
              "Barangay 188",
              "Barangay 189",
              "Barangay 19",
              "Barangay 190",
              "Barangay 191",
              "Barangay 192",
              "Barangay 193",
              "Barangay 194",
              "Barangay 195",
              "Barangay 196",
              "Barangay 197",
              "Barangay 198",
              "Barangay 199",
              "Barangay 2",
              "Barangay 20",
              "Barangay 200",
              "Barangay 201",
              "Barangay 202",
              "Barangay 202a",
              "Barangay 203",
              "Barangay 204",
              "Barangay 205",
              "Barangay 206",
              "Barangay 207",
              "Barangay 208",
              "Barangay 209",
              "Barangay 210",
              "Barangay 211",
              "Barangay 212",
              "Barangay 213",
              "Barangay 214",
              "Barangay 215",
              "Barangay 216",
              "Barangay 217",
              "Barangay 218",
              "Barangay 219",
              "Barangay 220",
              "Barangay 221",
              "Barangay 222",
              "Barangay 223",
              "Barangay 224",
              "Barangay 225",
              "Barangay 226",
              "Barangay 227",
              "Barangay 228",
              "Barangay 229",
              "Barangay 230",
              "Barangay 231",
              "Barangay 232",
              "Barangay 233",
              "Barangay 234",
              "Barangay 235",
              "Barangay 236",
              "Barangay 237",
              "Barangay 238",
              "Barangay 239",
              "Barangay 240",
              "Barangay 241",
              "Barangay 242",
              "Barangay 243",
              "Barangay 244",
              "Barangay 245",
              "Barangay 246",
              "Barangay 247",
              "Barangay 248",
              "Barangay 249",
              "Barangay 25",
              "Barangay 250",
              "Barangay 251",
              "Barangay 252",
              "Barangay 253",
              "Barangay 254",
              "Barangay 255",
              "Barangay 256",
              "Barangay 257",
              "Barangay 258",
              "Barangay 259",
              "Barangay 26",
              "Barangay 260",
              "Barangay 261",
              "Barangay 262",
              "Barangay 263",
              "Barangay 264",
              "Barangay 265",
              "Barangay 266",
              "Barangay 267",
              "Barangay 268",
              "Barangay 269",
              "Barangay 270",
              "Barangay 271",
              "Barangay 272",
              "Barangay 273",
              "Barangay 274",
              "Barangay 275",
              "Barangay 276",
              "Barangay 28",
              "Barangay 281",
              "Barangay 282",
              "Barangay 283",
              "Barangay 284",
              "Barangay 285",
              "Barangay 286",
              "Barangay 287",
              "Barangay 288",
              "Barangay 289",
              "Barangay 29",
              "Barangay 290",
              "Barangay 291",
              "Barangay 292",
              "Barangay 293",
              "Barangay 294",
              "Barangay 295",
              "Barangay 296",
              "Barangay 297",
              "Barangay 298",
              "Barangay 299",
              "Barangay 3",
              "Barangay 30",
              "Barangay 300",
              "Barangay 301",
              "Barangay 302",
              "Barangay 303",
              "Barangay 304",
              "Barangay 305",
              "Barangay 306",
              "Barangay 307",
              "Barangay 308",
              "Barangay 309",
              "Barangay 31",
              "Barangay 310",
              "Barangay 311",
              "Barangay 312",
              "Barangay 313",
              "Barangay 314",
              "Barangay 315",
              "Barangay 316",
              "Barangay 317",
              "Barangay 318",
              "Barangay 319",
              "Barangay 32",
              "Barangay 320",
              "Barangay 321",
              "Barangay 322",
              "Barangay 323",
              "Barangay 324",
              "Barangay 325",
              "Barangay 326",
              "Barangay 327",
              "Barangay 328",
              "Barangay 329",
              "Barangay 33",
              "Barangay 330",
              "Barangay 331",
              "Barangay 332",
              "Barangay 333",
              "Barangay 334",
              "Barangay 335",
              "Barangay 336",
              "Barangay 337",
              "Barangay 338",
              "Barangay 339",
              "Barangay 34",
              "Barangay 340",
              "Barangay 341",
              "Barangay 342",
              "Barangay 343",
              "Barangay 344",
              "Barangay 345",
              "Barangay 346",
              "Barangay 347",
              "Barangay 348",
              "Barangay 349",
              "Barangay 35",
              "Barangay 350",
              "Barangay 351",
              "Barangay 352",
              "Barangay 353",
              "Barangay 354",
              "Barangay 355",
              "Barangay 356",
              "Barangay 357",
              "Barangay 358",
              "Barangay 359",
              "Barangay 36",
              "Barangay 360",
              "Barangay 361",
              "Barangay 362",
              "Barangay 363",
              "Barangay 364",
              "Barangay 365",
              "Barangay 366",
              "Barangay 367",
              "Barangay 368",
              "Barangay 369",
              "Barangay 37",
              "Barangay 370",
              "Barangay 371",
              "Barangay 372",
              "Barangay 373",
              "Barangay 374",
              "Barangay 375",
              "Barangay 376",
              "Barangay 377",
              "Barangay 378",
              "Barangay 379",
              "Barangay 38",
              "Barangay 380",
              "Barangay 381",
              "Barangay 382",
              "Barangay 383",
              "Barangay 384",
              "Barangay 385",
              "Barangay 386",
              "Barangay 387",
              "Barangay 388",
              "Barangay 389",
              "Barangay 39",
              "Barangay 390",
              "Barangay 391",
              "Barangay 392",
              "Barangay 393",
              "Barangay 394",
              "Barangay 395",
              "Barangay 396",
              "Barangay 397",
              "Barangay 398",
              "Barangay 399",
              "Barangay 4",
              "Barangay 400",
              "Barangay 401",
              "Barangay 402",
              "Barangay 403",
              "Barangay 404",
              "Barangay 405",
              "Barangay 406",
              "Barangay 407",
              "Barangay 408",
              "Barangay 409",
              "Barangay 41",
              "Barangay 410",
              "Barangay 411",
              "Barangay 412",
              "Barangay 413",
              "Barangay 414",
              "Barangay 415",
              "Barangay 416",
              "Barangay 417",
              "Barangay 418",
              "Barangay 419",
              "Barangay 42",
              "Barangay 420",
              "Barangay 421",
              "Barangay 422",
              "Barangay 423",
              "Barangay 424",
              "Barangay 425",
              "Barangay 426",
              "Barangay 427",
              "Barangay 428",
              "Barangay 429",
              "Barangay 43",
              "Barangay 430",
              "Barangay 431",
              "Barangay 432",
              "Barangay 433",
              "Barangay 434",
              "Barangay 435",
              "Barangay 436",
              "Barangay 437",
              "Barangay 438",
              "Barangay 439",
              "Barangay 44",
              "Barangay 440",
              "Barangay 441",
              "Barangay 442",
              "Barangay 443",
              "Barangay 444",
              "Barangay 445",
              "Barangay 446",
              "Barangay 447",
              "Barangay 448",
              "Barangay 449",
              "Barangay 45",
              "Barangay 450",
              "Barangay 451",
              "Barangay 452",
              "Barangay 453",
              "Barangay 454",
              "Barangay 455",
              "Barangay 456",
              "Barangay 457",
              "Barangay 458",
              "Barangay 459",
              "Barangay 46",
              "Barangay 460",
              "Barangay 461",
              "Barangay 462",
              "Barangay 463",
              "Barangay 464",
              "Barangay 465",
              "Barangay 466",
              "Barangay 467",
              "Barangay 468",
              "Barangay 469",
              "Barangay 47",
              "Barangay 470",
              "Barangay 471",
              "Barangay 472",
              "Barangay 473",
              "Barangay 474",
              "Barangay 475",
              "Barangay 476",
              "Barangay 477",
              "Barangay 478",
              "Barangay 479",
              "Barangay 48",
              "Barangay 480",
              "Barangay 481",
              "Barangay 482",
              "Barangay 483",
              "Barangay 484",
              "Barangay 485",
              "Barangay 486",
              "Barangay 487",
              "Barangay 488",
              "Barangay 489",
              "Barangay 49",
              "Barangay 490",
              "Barangay 491",
              "Barangay 492",
              "Barangay 493",
              "Barangay 494",
              "Barangay 495",
              "Barangay 496",
              "Barangay 497",
              "Barangay 498",
              "Barangay 499",
              "Barangay 5",
              "Barangay 50",
              "Barangay 500",
              "Barangay 501",
              "Barangay 502",
              "Barangay 503",
              "Barangay 504",
              "Barangay 505",
              "Barangay 506",
              "Barangay 507",
              "Barangay 508",
              "Barangay 509",
              "Barangay 51",
              "Barangay 510",
              "Barangay 511",
              "Barangay 512",
              "Barangay 513",
              "Barangay 514",
              "Barangay 515",
              "Barangay 516",
              "Barangay 517",
              "Barangay 518",
              "Barangay 519",
              "Barangay 52",
              "Barangay 520",
              "Barangay 521",
              "Barangay 522",
              "Barangay 523",
              "Barangay 524",
              "Barangay 525",
              "Barangay 526",
              "Barangay 527",
              "Barangay 528",
              "Barangay 529",
              "Barangay 53",
              "Barangay 530",
              "Barangay 531",
              "Barangay 532",
              "Barangay 533",
              "Barangay 534",
              "Barangay 535",
              "Barangay 536",
              "Barangay 537",
              "Barangay 538",
              "Barangay 539",
              "Barangay 54",
              "Barangay 540",
              "Barangay 541",
              "Barangay 542",
              "Barangay 543",
              "Barangay 544",
              "Barangay 545",
              "Barangay 546",
              "Barangay 547",
              "Barangay 548",
              "Barangay 549",
              "Barangay 55",
              "Barangay 550",
              "Barangay 551",
              "Barangay 552",
              "Barangay 553",
              "Barangay 554",
              "Barangay 555",
              "Barangay 556",
              "Barangay 557",
              "Barangay 558",
              "Barangay 559",
              "Barangay 56",
              "Barangay 560",
              "Barangay 561",
              "Barangay 562",
              "Barangay 563",
              "Barangay 564",
              "Barangay 565",
              "Barangay 566",
              "Barangay 567",
              "Barangay 568",
              "Barangay 569",
              "Barangay 57",
              "Barangay 570",
              "Barangay 571",
              "Barangay 572",
              "Barangay 573",
              "Barangay 574",
              "Barangay 575",
              "Barangay 576",
              "Barangay 577",
              "Barangay 578",
              "Barangay 579",
              "Barangay 58",
              "Barangay 580",
              "Barangay 581",
              "Barangay 582",
              "Barangay 583",
              "Barangay 584",
              "Barangay 585",
              "Barangay 586",
              "Barangay 587",
              "Barangay 587a",
              "Barangay 588",
              "Barangay 589",
              "Barangay 59",
              "Barangay 590",
              "Barangay 591",
              "Barangay 592",
              "Barangay 593",
              "Barangay 594",
              "Barangay 595",
              "Barangay 596",
              "Barangay 597",
              "Barangay 598",
              "Barangay 599",
              "Barangay 6",
              "Barangay 60",
              "Barangay 600",
              "Barangay 601",
              "Barangay 602",
              "Barangay 603",
              "Barangay 604",
              "Barangay 605",
              "Barangay 606",
              "Barangay 607",
              "Barangay 608",
              "Barangay 609",
              "Barangay 61",
              "Barangay 610",
              "Barangay 611",
              "Barangay 612",
              "Barangay 613",
              "Barangay 614",
              "Barangay 615",
              "Barangay 616",
              "Barangay 617",
              "Barangay 618",
              "Barangay 619",
              "Barangay 62",
              "Barangay 620",
              "Barangay 621",
              "Barangay 622",
              "Barangay 623",
              "Barangay 624",
              "Barangay 625",
              "Barangay 626",
              "Barangay 627",
              "Barangay 628",
              "Barangay 629",
              "Barangay 63",
              "Barangay 630",
              "Barangay 631",
              "Barangay 632",
              "Barangay 633",
              "Barangay 634",
              "Barangay 635",
              "Barangay 636",
              "Barangay 637",
              "Barangay 638",
              "Barangay 639",
              "Barangay 64",
              "Barangay 640",
              "Barangay 641",
              "Barangay 642",
              "Barangay 643",
              "Barangay 644",
              "Barangay 645",
              "Barangay 646",
              "Barangay 647",
              "Barangay 648",
              "Barangay 649",
              "Barangay 65",
              "Barangay 650",
              "Barangay 651",
              "Barangay 652",
              "Barangay 653",
              "Barangay 654",
              "Barangay 655",
              "Barangay 656",
              "Barangay 657",
              "Barangay 658",
              "Barangay 659",
              "Barangay 659a",
              "Barangay 66",
              "Barangay 660",
              "Barangay 660a",
              "Barangay 661",
              "Barangay 662",
              "Barangay 663",
              "Barangay 663a",
              "Barangay 664",
              "Barangay 664a",
              "Barangay 666",
              "Barangay 667",
              "Barangay 668",
              "Barangay 669",
              "Barangay 67",
              "Barangay 670",
              "Barangay 671",
              "Barangay 672",
              "Barangay 673",
              "Barangay 674",
              "Barangay 675",
              "Barangay 676",
              "Barangay 677",
              "Barangay 678",
              "Barangay 679",
              "Barangay 68",
              "Barangay 680",
              "Barangay 681",
              "Barangay 682",
              "Barangay 683",
              "Barangay 684",
              "Barangay 685",
              "Barangay 686",
              "Barangay 687",
              "Barangay 688",
              "Barangay 689",
              "Barangay 69",
              "Barangay 690",
              "Barangay 691",
              "Barangay 692",
              "Barangay 693",
              "Barangay 694",
              "Barangay 695",
              "Barangay 696",
              "Barangay 697",
              "Barangay 698",
              "Barangay 699",
              "Barangay 7",
              "Barangay 70",
              "Barangay 700",
              "Barangay 701",
              "Barangay 702",
              "Barangay 703",
              "Barangay 704",
              "Barangay 705",
              "Barangay 706",
              "Barangay 707",
              "Barangay 708",
              "Barangay 709",
              "Barangay 71",
              "Barangay 710",
              "Barangay 711",
              "Barangay 712",
              "Barangay 713",
              "Barangay 714",
              "Barangay 715",
              "Barangay 716",
              "Barangay 717",
              "Barangay 718",
              "Barangay 719",
              "Barangay 72",
              "Barangay 720",
              "Barangay 721",
              "Barangay 722",
              "Barangay 723",
              "Barangay 724",
              "Barangay 725",
              "Barangay 726",
              "Barangay 727",
              "Barangay 728",
              "Barangay 729",
              "Barangay 73",
              "Barangay 730",
              "Barangay 731",
              "Barangay 732",
              "Barangay 733",
              "Barangay 734",
              "Barangay 735",
              "Barangay 736",
              "Barangay 737",
              "Barangay 738",
              "Barangay 739",
              "Barangay 74",
              "Barangay 740",
              "Barangay 741",
              "Barangay 742",
              "Barangay 743",
              "Barangay 744",
              "Barangay 745",
              "Barangay 746",
              "Barangay 747",
              "Barangay 748",
              "Barangay 749",
              "Barangay 75",
              "Barangay 750",
              "Barangay 751",
              "Barangay 752",
              "Barangay 753",
              "Barangay 754",
              "Barangay 755",
              "Barangay 756",
              "Barangay 757",
              "Barangay 758",
              "Barangay 759",
              "Barangay 76",
              "Barangay 760",
              "Barangay 761",
              "Barangay 762",
              "Barangay 763",
              "Barangay 764",
              "Barangay 765",
              "Barangay 766",
              "Barangay 767",
              "Barangay 768",
              "Barangay 769",
              "Barangay 77",
              "Barangay 770",
              "Barangay 771",
              "Barangay 772",
              "Barangay 773",
              "Barangay 774",
              "Barangay 775",
              "Barangay 776",
              "Barangay 777",
              "Barangay 778",
              "Barangay 779",
              "Barangay 78",
              "Barangay 780",
              "Barangay 781",
              "Barangay 782",
              "Barangay 783",
              "Barangay 784",
              "Barangay 785",
              "Barangay 786",
              "Barangay 787",
              "Barangay 788",
              "Barangay 789",
              "Barangay 79",
              "Barangay 790",
              "Barangay 791",
              "Barangay 792",
              "Barangay 793",
              "Barangay 794",
              "Barangay 795",
              "Barangay 796",
              "Barangay 797",
              "Barangay 798",
              "Barangay 799",
              "Barangay 8",
              "Barangay 80",
              "Barangay 800",
              "Barangay 801",
              "Barangay 802",
              "Barangay 803",
              "Barangay 804",
              "Barangay 805",
              "Barangay 806",
              "Barangay 807",
              "Barangay 808",
              "Barangay 809",
              "Barangay 81",
              "Barangay 810",
              "Barangay 811",
              "Barangay 812",
              "Barangay 813",
              "Barangay 814",
              "Barangay 815",
              "Barangay 816",
              "Barangay 817",
              "Barangay 818",
              "Barangay 818a",
              "Barangay 819",
              "Barangay 82",
              "Barangay 820",
              "Barangay 821",
              "Barangay 822",
              "Barangay 823",
              "Barangay 824",
              "Barangay 825",
              "Barangay 826",
              "Barangay 827",
              "Barangay 828",
              "Barangay 829",
              "Barangay 83",
              "Barangay 830",
              "Barangay 831",
              "Barangay 832",
              "Barangay 833",
              "Barangay 834",
              "Barangay 835",
              "Barangay 836",
              "Barangay 837",
              "Barangay 838",
              "Barangay 839",
              "Barangay 84",
              "Barangay 840",
              "Barangay 841",
              "Barangay 842",
              "Barangay 843",
              "Barangay 844",
              "Barangay 845",
              "Barangay 846",
              "Barangay 847",
              "Barangay 848",
              "Barangay 849",
              "Barangay 85",
              "Barangay 850",
              "Barangay 851",
              "Barangay 852",
              "Barangay 853",
              "Barangay 855",
              "Barangay 856",
              "Barangay 857",
              "Barangay 858",
              "Barangay 859",
              "Barangay 86",
              "Barangay 860",
              "Barangay 861",
              "Barangay 862",
              "Barangay 863",
              "Barangay 864",
              "Barangay 865",
              "Barangay 866",
              "Barangay 867",
              "Barangay 868",
              "Barangay 869",
              "Barangay 87",
              "Barangay 870",
              "Barangay 871",
              "Barangay 872",
              "Barangay 873",
              "Barangay 874",
              "Barangay 875",
              "Barangay 876",
              "Barangay 877",
              "Barangay 878",
              "Barangay 879",
              "Barangay 88",
              "Barangay 880",
              "Barangay 881",
              "Barangay 882",
              "Barangay 883",
              "Barangay 884",
              "Barangay 885",
              "Barangay 886",
              "Barangay 887",
              "Barangay 888",
              "Barangay 889",
              "Barangay 89",
              "Barangay 890",
              "Barangay 891",
              "Barangay 892",
              "Barangay 893",
              "Barangay 894",
              "Barangay 895",
              "Barangay 896",
              "Barangay 897",
              "Barangay 898",
              "Barangay 899",
              "Barangay 9",
              "Barangay 90",
              "Barangay 900",
              "Barangay 901",
              "Barangay 902",
              "Barangay 903",
              "Barangay 904",
              "Barangay 905",
              "Barangay 91",
              "Barangay 92",
              "Barangay 93",
              "Barangay 94",
              "Barangay 95",
              "Barangay 96",
              "Barangay 97",
              "Barangay 98",
              "Barangay 99"
            ]
          },
          {
            "k": "Marikina City",
            "v": [
              "Barangka",
              "Calumpang",
              "Concepcion Dos",
              "Concepcion Uno",
              "Fortune",
              "Industrial Valley",
              "Jesus De La PeÃ±a",
              "Malanday",
              "Marikina Heights Concepcion",
              "Nangka",
              "Parang",
              "San Roque",
              "Santa Elena Pob",
              "Santo NiÃ±o",
              "TaÃ±ong",
              "Tumana"
            ]
          },
          {
            "k": "Muntinlupa City",
            "v": [
              "Alabang",
              "Bayanan",
              "Buli",
              "Cupang",
              "New Alabang Village",
              "Poblacion",
              "Putatan",
              "Sucat",
              "Tunasan"
            ]
          },
          {
            "k": "Navotas City",
            "v": [
              "Almacen",
              "Bagumbayan North",
              "Bagumbayan South",
              "Bangculasi",
              "Daanghari",
              "Navotas East",
              "Navotas West",
              "North Bay Blvd North",
              "North Bay Blvd South",
              "San Jose Pob",
              "San Rafael Village",
              "San Roque",
              "Sipac",
              "Tangos",
              "Tanza"
            ]
          },
          {
            "k": "ParaÃ±aque City",
            "v": [
              "B F Homes",
              "Baclaran",
              "Bf Homes 1",
              "Bf Homes 2",
              "Don Bosco",
              "Don Galo",
              "La Huerta",
              "Marcelo Green Village",
              "Merville",
              "Moonwalk",
              "San Antonio Valley 1",
              "San Antonio Valley 2",
              "San Dionisio",
              "San Isidro",
              "San Martin De Porres",
              "Santo NiÃ±o",
              "Sun Valley",
              "Tambo",
              "Vitalez"
            ]
          },
          {
            "k": "Pasay City",
            "v": [
              "Barangay 1",
              "Barangay 10",
              "Barangay 100",
              "Barangay 101",
              "Barangay 102",
              "Barangay 103",
              "Barangay 104",
              "Barangay 105",
              "Barangay 106",
              "Barangay 107",
              "Barangay 108",
              "Barangay 109",
              "Barangay 11",
              "Barangay 110",
              "Barangay 111",
              "Barangay 112",
              "Barangay 113",
              "Barangay 114",
              "Barangay 115",
              "Barangay 116",
              "Barangay 117",
              "Barangay 118",
              "Barangay 119",
              "Barangay 12",
              "Barangay 120",
              "Barangay 121",
              "Barangay 122",
              "Barangay 123",
              "Barangay 124",
              "Barangay 125",
              "Barangay 126",
              "Barangay 127",
              "Barangay 128",
              "Barangay 129",
              "Barangay 13",
              "Barangay 130",
              "Barangay 131",
              "Barangay 132",
              "Barangay 133",
              "Barangay 134",
              "Barangay 135",
              "Barangay 136",
              "Barangay 137",
              "Barangay 138",
              "Barangay 139",
              "Barangay 14",
              "Barangay 140",
              "Barangay 141",
              "Barangay 142",
              "Barangay 143",
              "Barangay 144",
              "Barangay 145",
              "Barangay 146",
              "Barangay 147",
              "Barangay 148",
              "Barangay 149",
              "Barangay 15",
              "Barangay 150",
              "Barangay 151",
              "Barangay 152",
              "Barangay 153",
              "Barangay 154",
              "Barangay 155",
              "Barangay 156",
              "Barangay 157",
              "Barangay 158",
              "Barangay 159",
              "Barangay 16",
              "Barangay 160",
              "Barangay 161",
              "Barangay 162",
              "Barangay 163",
              "Barangay 164",
              "Barangay 165",
              "Barangay 166",
              "Barangay 167",
              "Barangay 168",
              "Barangay 169",
              "Barangay 17",
              "Barangay 170",
              "Barangay 171",
              "Barangay 172",
              "Barangay 173",
              "Barangay 174",
              "Barangay 175",
              "Barangay 176",
              "Barangay 177",
              "Barangay 178",
              "Barangay 179",
              "Barangay 18",
              "Barangay 180",
              "Barangay 181",
              "Barangay 182",
              "Barangay 183",
              "Barangay 184",
              "Barangay 185",
              "Barangay 186",
              "Barangay 187",
              "Barangay 188",
              "Barangay 189",
              "Barangay 19",
              "Barangay 190",
              "Barangay 191",
              "Barangay 192",
              "Barangay 193",
              "Barangay 194",
              "Barangay 195",
              "Barangay 196",
              "Barangay 197",
              "Barangay 198",
              "Barangay 199",
              "Barangay 2",
              "Barangay 20",
              "Barangay 200",
              "Barangay 201",
              "Barangay 21",
              "Barangay 22",
              "Barangay 23",
              "Barangay 24",
              "Barangay 25",
              "Barangay 26",
              "Barangay 27",
              "Barangay 28",
              "Barangay 29",
              "Barangay 3",
              "Barangay 30",
              "Barangay 31",
              "Barangay 32",
              "Barangay 33",
              "Barangay 34",
              "Barangay 35",
              "Barangay 36",
              "Barangay 37",
              "Barangay 38",
              "Barangay 39",
              "Barangay 4",
              "Barangay 40",
              "Barangay 41",
              "Barangay 42",
              "Barangay 43",
              "Barangay 44",
              "Barangay 45",
              "Barangay 46",
              "Barangay 47",
              "Barangay 48",
              "Barangay 49",
              "Barangay 5",
              "Barangay 50",
              "Barangay 51",
              "Barangay 52",
              "Barangay 53",
              "Barangay 54",
              "Barangay 55",
              "Barangay 56",
              "Barangay 57",
              "Barangay 58",
              "Barangay 59",
              "Barangay 6",
              "Barangay 60",
              "Barangay 61",
              "Barangay 62",
              "Barangay 63",
              "Barangay 64",
              "Barangay 65",
              "Barangay 66",
              "Barangay 67",
              "Barangay 68",
              "Barangay 69",
              "Barangay 7",
              "Barangay 70",
              "Barangay 71",
              "Barangay 72",
              "Barangay 73",
              "Barangay 74",
              "Barangay 75",
              "Barangay 76",
              "Barangay 77",
              "Barangay 78",
              "Barangay 79",
              "Barangay 8",
              "Barangay 80",
              "Barangay 81",
              "Barangay 82",
              "Barangay 83",
              "Barangay 84",
              "Barangay 85",
              "Barangay 86",
              "Barangay 87",
              "Barangay 88",
              "Barangay 89",
              "Barangay 9",
              "Barangay 90",
              "Barangay 91",
              "Barangay 92",
              "Barangay 93",
              "Barangay 94",
              "Barangay 95",
              "Barangay 96",
              "Barangay 97",
              "Barangay 98",
              "Barangay 99"
            ]
          },
          {
            "k": "Pasig City",
            "v": [
              "Bagong Ilog",
              "Bagong Katipunan",
              "Bambang",
              "Buting",
              "Caniogan",
              "Dela Paz",
              "Kalawaan Norte",
              "Kalawaan Sur",
              "Kapasigan",
              "Kapitolyo",
              "Malinao",
              "Manggahan",
              "Maybunga",
              "Oranbo",
              "Palatiw",
              "Pinagbuhatan",
              "Pineda",
              "Rosario",
              "Sagad",
              "San Antonio",
              "San Joaquin",
              "San Jose",
              "San Miguel",
              "San Nicolas Pob",
              "Santa Cruz",
              "Santa Lucia",
              "Santa Rosa",
              "Santo Tomas",
              "Santolan",
              "Sumilang",
              "Ugong",
              "Ugong Norte",
              "Ugong Sur"
            ]
          },
          {
            "k": "Pateros",
            "v": [
              "Aguho",
              "Magtanggol",
              "Martires Del 96",
              "Poblacion",
              "San Pedro",
              "San Roque",
              "Santa Ana",
              "Santo Rosariokanluran",
              "Santo Rosariosilangan",
              "Tabacalera"
            ]
          },
          {
            "k": "Quezon City",
            "v": [
              "Alicia",
              "Amihan",
              "Apolonio Samson",
              "Aurora",
              "Baesa",
              "Bagbag",
              "Bagong Lipunan Ng Crame",
              "Bagong Pagasa",
              "Bagong Silangan",
              "Bagumbayan",
              "Bagumbuhay",
              "Bahay Toro",
              "Balingasa",
              "Balong Bato",
              "Batasan Hills",
              "Bayanihan",
              "Blue Ridge A",
              "Blue Ridge B",
              "Botocan",
              "Bungad",
              "Camp Aguinaldo",
              "Capri",
              "Central",
              "Claro",
              "Commonwealth",
              "Culiat",
              "Damar",
              "Damayan",
              "Damayang Lagi",
              "Del Monte",
              "Dioquino Zobel",
              "Don Manuel",
              "DoÃ±a Imelda",
              "DoÃ±a Josefa",
              "Duyanduyan",
              "E Rodriguez",
              "East Kamias",
              "Escopa I",
              "Escopa Ii",
              "Escopa Iii",
              "Escopa Iv",
              "Fairview",
              "Greater Lagro",
              "Gulod",
              "Holy Spirit",
              "Horseshoe",
              "Immaculate Concepcion",
              "Kaligayahan",
              "Kalusugan",
              "Kamuning",
              "Katipunan",
              "Kaunlaran",
              "Kristong Hari",
              "Krus Na Ligas",
              "Laging Handa",
              "Libis",
              "Lourdes",
              "Loyola Heights",
              "Maharlika",
              "Malaya",
              "Mangga",
              "Manresa",
              "Mariana",
              "Mariblo",
              "Marilag",
              "Masagana",
              "Masambong",
              "Matandang Balara",
              "Milagrosa",
              "Nagkaisang Nayon",
              "Nayong Kanluran",
              "New Era Constitution Hills",
              "North Fairview",
              "Novaliches Proper",
              "Ns Amoranto Gintong Silahis",
              "Obrero",
              "Old Capitol Site",
              "Paang Bundok",
              "Pagibig Sa Nayon",
              "Paligsahan",
              "Paltok",
              "Pansol",
              "Paraiso",
              "Pasong Putik Proper Pasong Putik",
              "Pasong Tamo",
              "Payatas",
              "Philam",
              "Pinagkaisahan",
              "Pinyahan",
              "Project 6",
              "Quirino 2a",
              "Quirino 2b",
              "Quirino 2c",
              "Quirino 3a",
              "Ramon Magsaysay",
              "Roxas",
              "Sacred Heart",
              "Saint Ignatius",
              "Saint Peter",
              "Salvacion",
              "San Agustin",
              "San Antonio",
              "San Bartolome",
              "San Isidro",
              "San Isidro Labrador",
              "San Jose",
              "San Martin De Porres",
              "San Roque",
              "San Vicente",
              "Sangandaan",
              "Santa Cruz",
              "Santa Lucia",
              "Santa Monica",
              "Santa Teresita",
              "Santo Cristo",
              "Santo Domingo Matalahib",
              "Santo NiÃ±o",
              "Santol",
              "Sauyo",
              "Sienna",
              "Sikatuna Village",
              "Silangan",
              "Socorro",
              "South Triangle",
              "Tagumpay",
              "Talayan",
              "Talipapa",
              "Tandang Sora",
              "Tatalon",
              "Teachers Village East",
              "Teachers Village West",
              "Ugong Norte",
              "Unang Sigaw",
              "Up Campus",
              "Up Village",
              "Valencia",
              "Vasra",
              "Veterans Village",
              "Villa Maria Clara",
              "West Kamias",
              "West Triangle",
              "White Plains"
            ]
          },
          {
            "k": "San Juan City",
            "v": [
              "Addition Hills",
              "Balongbato",
              "Batis",
              "Corazon De Jesus",
              "ErmitaÃ±o",
              "Greenhills",
              "Halohalo St Joseph",
              "Isabelita",
              "Kabayanan",
              "Little Baguio",
              "Maytunas",
              "Onse",
              "PasadeÃ±a",
              "Pedro Cruz",
              "Progreso",
              "Rivera",
              "Salapan",
              "San Perfecto",
              "Santa Lucia",
              "Tibagan",
              "West Crame"
            ]
          },
          {
            "k": "Taguig",
            "v": [
              "Bagumbayan",
              "Bambang",
              "Calzada",
              "Central Bicutan",
              "Central Signal Village",
              "Fort Bonifacio",
              "Hagonoy",
              "Ibayo",
              "Katuparan",
              "Ligid",
              "Lower Bicutan",
              "Maharlika Village",
              "Napindan",
              "New Lower Bicutan",
              "North Daang Hari",
              "North Signal Village",
              "Palingon",
              "Pinagsama",
              "San Miguel",
              "Santa Ana",
              "South Daang Hari",
              "South Signal Village",
              "Tanyag",
              "Tipas",
              "Tuktukan",
              "Upper Bicutan",
              "Ususan",
              "Wawa",
              "Western Bicutan"
            ]
          },
          {
            "k": "Valenzuela City",
            "v": [
              "Arkong Bato",
              "Bagbaguin",
              "Balangkas",
              "Bignay",
              "Bisig",
              "Canumay East",
              "Canumay West",
              "Coloong",
              "Dalandanan",
              "Hen T De Leon",
              "Isla",
              "Karuhatan",
              "Lawang Bato",
              "Lingunan",
              "Mabolo",
              "Malanday",
              "Malinta",
              "Mapulang Lupa",
              "Marulas",
              "Maysan",
              "Palasan",
              "Parada",
              "Pariancillo Villa",
              "Paso De Blas",
              "Pasolo",
              "Poblacion",
              "Pulo",
              "Punturin",
              "Rincon",
              "Tagalag",
              "Ugong",
              "Viente Reales",
              "Wawang Pulo"
            ]
          }
        ],
        "names": [
          "Caloocan City",
          "Las PiÃ±as City",
          "Makati City",
          "Malabon City",
          "Mandaluyong City",
          "Manila City",
          "Marikina City",
          "Muntinlupa City",
          "Navotas City",
          "ParaÃ±aque City",
          "Pasay City",
          "Pasig City",
          "Pateros",
          "Quezon City",
          "San Juan City",
          "Taguig",
          "Valenzuela City"
        ]
      }
    },
    {
      "k": "Misamis Occidental",
      "v": {
        "lookup": [
          {
            "k": "Aloran",
            "v": [
              "Banisilon",
              "Casusan",
              "Conat",
              "Dalisay",
              "Dullan",
              "Ibabao",
              "Labo",
              "Lawa An",
              "Lobogon",
              "Lumbayao",
              "Mahon",
              "Makawa",
              "Matipaz",
              "Maular",
              "Ospital Pob",
              "Palayan",
              "Pelong",
              "Tawi Tawi"
            ]
          },
          { "k": "Bonifacio", "v": ["Poblacion Centro"] },
          {
            "k": "Calamba",
            "v": [
              "Bonifacio",
              "Bunawan",
              "Calaran",
              "Del Pilar",
              "Don Bernard Neri",
              "Don Bernardo Neri Pob Tres De Mayo",
              "Kapayas",
              "Langub Lower",
              "Langub Upper",
              "Magcamiguing",
              "Napisik",
              "Northern Poblacion",
              "Pandayan",
              "Poblacion",
              "Salvador",
              "Solinog",
              "Southwestern Poblacion"
            ]
          },
          {
            "k": "Clarin",
            "v": [
              "Lapasan",
              "Lupagan",
              "Mialen",
              "Pan Ay",
              "Poblacion I",
              "Poblacion Ii",
              "Poblacion Iii",
              "Poblacion Iv",
              "Tinacla An"
            ]
          },
          {
            "k": "Jimenez",
            "v": [
              "Butuay",
              "Corrales",
              "Dicoloc",
              "Gata",
              "Nacional Pob",
              "Naga Pob",
              "Palilan",
              "Rizal Pob",
              "Santa Cruz Pob",
              "Tabo O",
              "Taraka Pob"
            ]
          },
          {
            "k": "Lopez Jaena",
            "v": [
              "Biasong",
              "Eastern Poblacion",
              "Katipa",
              "Luzaran",
              "Mansabay Alto",
              "Mansabay Bajo",
              "Molatuhan Alto",
              "Molatuhan Bajo",
              "Puntod",
              "Sibugon",
              "Sibula",
              "Western Poblacion"
            ]
          },
          {
            "k": "Oroquieta City",
            "v": [
              "Canubay",
              "Ciriaco C Pastrano Nilabo",
              "Lamac Lower",
              "Lamac Upper",
              "Langcangan Lower",
              "Langcangan Proper",
              "Langcangan Upper",
              "Layawan",
              "Loboc Lower",
              "Loboc Upper",
              "Mobod",
              "Paypayan",
              "Pines",
              "Poblacion I",
              "Poblacion Ii",
              "Talairon",
              "Talic",
              "Tipan",
              "Villaflor"
            ]
          },
          {
            "k": "Ozamis City",
            "v": [
              "50th District Pob",
              "Aguada Pob",
              "Bacolod",
              "Bagakay",
              "Banadero Pob",
              "Baybay San Roque",
              "Baybay Santa Cruz",
              "Baybay Triunfo",
              "Bongbong",
              "Calabayan",
              "Carangan",
              "Carmen Misamis Annex",
              "Catadman Manabay",
              "Diguan",
              "Dimaluna",
              "Dona Consuelo",
              "Gango",
              "Labinay",
              "Labo",
              "Lam An",
              "Malaubang",
              "Maningcol",
              "Molicay",
              "Pulot",
              "San Antonio",
              "Tabid",
              "Tinago"
            ]
          },
          {
            "k": "Panaon",
            "v": ["Dela Paz", "Lutao", "Mohon", "Poblacion", "Villalin"]
          },
          {
            "k": "Plaridel",
            "v": [
              "Balanlinao",
              "Barra",
              "Bato",
              "Calaca An",
              "Cataman",
              "Clarin",
              "Divisoria",
              "Eastern Looc",
              "Lao Proper",
              "Lao Santa Cruz",
              "Looc Proper",
              "Mamanga Daku",
              "Northern Poblacion",
              "Panalsalan",
              "Puntod",
              "Santa Cruz",
              "Southern Looc",
              "Southern Poblacion",
              "Tipolo"
            ]
          },
          {
            "k": "Sapang Dalaga",
            "v": [
              "Bitibut",
              "Bougainvilla",
              "Boundary",
              "Dioyo",
              "El Paraiso",
              "Jasmin",
              "Poblacion",
              "Trucat"
            ]
          },
          {
            "k": "Sinacaban",
            "v": [
              "Cagay Anon",
              "Casoy",
              "Colupan Bajo",
              "Lawi",
              "Libertad Bajo",
              "Lutao",
              "Macabayao",
              "Maikay Diot",
              "Mangga",
              "Poblacion",
              "San Vicente",
              "Sinonoc"
            ]
          },
          {
            "k": "Tangub City",
            "v": [
              "Balatacan",
              "Barangay I City Hall Pob",
              "Barangay Ii Marilou Annex Pob",
              "Barangay Iii Market Kalubian Pob",
              "Barangay Iv St Michael Pob",
              "Barangay V Malubog Pob",
              "Barangay Vi Lower Polao Pob",
              "Barangay Vii Upper Polao Pob",
              "Bliss",
              "Bocator",
              "Garang",
              "Isidro D. Tan",
              "Labuyo",
              "Liloan",
              "Lorenzo Tan",
              "Maloro",
              "Mangga",
              "Mantic",
              "Maquilao",
              "Migcanaway",
              "Minsubong",
              "Mitan-ag",
              "Panalsalan",
              "Pangabuan"
            ]
          },
          {
            "k": "Tudela",
            "v": [
              "Barra",
              "Barrio Biernes",
              "Basirang",
              "Cabol-anonan",
              "Camarin",
              "Centro Hulpa Pob",
              "Centro Napu Pob",
              "Centro Upper Pob",
              "Nailon",
              "Napurong",
              "Pan-ay Diot",
              "Sebac",
              "Taguima",
              "Tigdok"
            ]
          }
        ],
        "names": [
          "Aloran",
          "Bonifacio",
          "Calamba",
          "Clarin",
          "Jimenez",
          "Lopez Jaena",
          "Oroquieta City",
          "Ozamis City",
          "Panaon",
          "Plaridel",
          "Sapang Dalaga",
          "Sinacaban",
          "Tangub City",
          "Tudela"
        ]
      }
    },
    {
      "k": "Misamis Oriental",
      "v": {
        "lookup": [
          {
            "k": "Alubijid",
            "v": [
              "Baybay",
              "Calatcat",
              "Lanao",
              "Loguilo",
              "Lumbo",
              "Poblacion",
              "Sampatulog",
              "Talaba"
            ]
          },
          {
            "k": "Balingasag",
            "v": [
              "Balagnan",
              "Baliwagan",
              "Barangay 1 Pob",
              "Barangay 2 Pob",
              "Barangay 3 Pob",
              "Barangay 4 Pob",
              "Barangay 5 Pob",
              "Barangay 6 Pob",
              "Binitinan",
              "Blanco",
              "Cogon",
              "Dumarait",
              "Hermano",
              "Linggangao",
              "Mambayaan",
              "Mandangoa",
              "Napaliran",
              "Rosario",
              "San Francisco",
              "San Isidro",
              "Talusan",
              "Waterfall"
            ]
          },
          {
            "k": "Balingoan",
            "v": ["Bolisong", "Mantangale", "Poblacion", "San Alonzo"]
          },
          {
            "k": "Binuangan",
            "v": ["Dampias", "Kabunungan", "Mabini", "Poblacion"]
          },
          {
            "k": "Cagayan De Oro City",
            "v": [
              "Agusan",
              "Baikingon",
              "Balubal",
              "Balulang",
              "Barangay 1 Pob",
              "Barangay 10 Pob",
              "Barangay 11 Pob",
              "Barangay 12 Pob",
              "Barangay 13 Pob",
              "Barangay 14 Pob",
              "Barangay 15 Pob",
              "Barangay 16 Pob",
              "Barangay 17 Pob",
              "Barangay 18 Pob",
              "Barangay 19 Pob",
              "Barangay 2 Pob",
              "Barangay 20 Pob",
              "Barangay 21 Pob",
              "Barangay 22 Pob",
              "Barangay 23 Pob",
              "Barangay 24 Pob",
              "Barangay 25 Pob",
              "Barangay 26 Pob",
              "Barangay 27 Pob",
              "Barangay 28 Pob",
              "Barangay 29 Pob",
              "Barangay 3 Pob",
              "Barangay 30 Pob",
              "Barangay 31 Pob",
              "Barangay 32 Pob",
              "Barangay 33 Pob",
              "Barangay 34 Pob",
              "Barangay 35 Pob",
              "Barangay 36 Pob",
              "Barangay 37 Pob",
              "Barangay 38 Pob",
              "Barangay 39 Pob",
              "Barangay 4 Pob",
              "Barangay 40 Pob",
              "Barangay 5 Pob",
              "Barangay 6 Pob",
              "Barangay 7 Pob",
              "Barangay 8 Pob",
              "Barangay 9 Pob",
              "Bayabas",
              "Bayanga",
              "Besigan",
              "Bonbon",
              "Bugo",
              "Bulua",
              "Camaman An",
              "Canito An",
              "Carmen",
              "Consolacion",
              "Cugman",
              "Dansolihon",
              "F S Catanico",
              "Gusa",
              "Indahag",
              "Iponan",
              "Kauswagan",
              "Lapasan",
              "Lumbia",
              "Macabalan",
              "Macasandig",
              "Mambuaya",
              "Nazareth",
              "Pagalungan",
              "Pagatpat",
              "Patag",
              "Pigsag An",
              "Puerto Lower",
              "Puerto Upper",
              "Puntod",
              "San Simon",
              "Tablon",
              "Taglimao",
              "Tagpangi",
              "Tignapoloan",
              "Tuburan",
              "Tumpagon"
            ]
          },
          {
            "k": "El Salvador City",
            "v": [
              "Amoros",
              "Bolobolo",
              "Cogon",
              "Molugan",
              "Poblacion",
              "Sambulawan",
              "Sinaloc",
              "Taytay",
              "Ulaliman"
            ]
          },
          {
            "k": "Gingoog City",
            "v": [
              "Agay Ayan",
              "Anakan",
              "Barangay 1 Pob",
              "Barangay 10 Pob",
              "Barangay 11 Pob",
              "Barangay 12 Pob",
              "Barangay 13 Pob",
              "Barangay 14 Pob",
              "Barangay 15 Pob",
              "Barangay 16 Pob",
              "Barangay 17 Pob",
              "Barangay 18 A Pob",
              "Barangay 18 Pob",
              "Barangay 19 Pob",
              "Barangay 2 Pob",
              "Barangay 20 Pob",
              "Barangay 21 Pob",
              "Barangay 22 A Pob",
              "Barangay 22 Pob",
              "Barangay 23 Pob",
              "Barangay 24 A Pob",
              "Barangay 24 Pob",
              "Barangay 25 Pob",
              "Barangay 26 Pob",
              "Barangay 3 Pob",
              "Barangay 4 Pob",
              "Barangay 5 Pob",
              "Barangay 6 Pob",
              "Barangay 7 Pob",
              "Barangay 8 Pob",
              "Barangay 9 Pob",
              "Daan Lungsod",
              "Lunao",
              "Odiongan",
              "Pangasihan",
              "Punong",
              "San Juan",
              "San Luis",
              "Santiago",
              "Talisay"
            ]
          },
          {
            "k": "Gitagum",
            "v": [
              "Burnay",
              "Cogon",
              "Kilangit",
              "Matangad",
              "Pangayawan",
              "Poblacion",
              "Quezon"
            ]
          },
          {
            "k": "Initao",
            "v": [
              "Aluna",
              "Apas",
              "Gimangpang",
              "Jampason",
              "Kamelon",
              "Pagahan",
              "Poblacion",
              "San Pedro",
              "Tubigan"
            ]
          },
          {
            "k": "Jasaan",
            "v": [
              "Aplaya",
              "Bobontugan",
              "Jampason",
              "Kimaya",
              "Lower Jasaan Pob",
              "Luz Banzon",
              "San Antonio",
              "Solana",
              "Town Proper",
              "Upper Jasaan Pob"
            ]
          },
          {
            "k": "Kinoguitan",
            "v": [
              "Buko",
              "Calubo",
              "Esperanza",
              "Panabol",
              "Poblacion",
              "Sumalag"
            ]
          },
          {
            "k": "Lagonglong",
            "v": [
              "Banglay",
              "Dampil",
              "Gaston",
              "Kabulawan",
              "Kauswagan",
              "Lumbo",
              "Poblacion",
              "Tabok",
              "Umagos"
            ]
          },
          {
            "k": "Laguindingan",
            "v": [
              "Gasi",
              "Kibaghot",
              "Liberty",
              "Mauswagon",
              "Moog",
              "Poblacion",
              "Sambulawan",
              "Sinai"
            ]
          },
          { "k": "Libertad", "v": ["Dulong", "Gimaylan", "Poblacion"] },
          {
            "k": "Lugait",
            "v": ["Aya Aya", "Biga", "Calangahan", "Poblacion", "Town Proper"]
          },
          {
            "k": "Manticao",
            "v": [
              "Argayoso",
              "Cabalantian",
              "Pagawan",
              "Paniangan",
              "Patag",
              "Poblacion",
              "Punta Silum",
              "Tuod"
            ]
          },
          {
            "k": "Medina",
            "v": [
              "Bulua",
              "Duka",
              "North Poblacion",
              "Pahindong",
              "Portulin",
              "South Poblacion",
              "Tup On"
            ]
          },
          {
            "k": "Naawan",
            "v": [
              "Don Pedro",
              "Linangkayan",
              "Lubilan",
              "Mapulog",
              "Maputi",
              "Mat I",
              "Patag",
              "Poblacion",
              "Tagbalogo",
              "Tuboran"
            ]
          },
          {
            "k": "Opol",
            "v": [
              "Barra",
              "Bonbon",
              "Igpit",
              "Luyongbonbon",
              "Malanang",
              "Patag",
              "Poblacion",
              "Taboc"
            ]
          },
          {
            "k": "Salay",
            "v": [
              "Alipuaton",
              "Ampenican",
              "Bunal",
              "Dinagsaan",
              "Inobulan",
              "Looc",
              "Matampa",
              "Poblacion",
              "Salay River I",
              "Salay River Ii"
            ]
          },
          {
            "k": "Sugbongcogon",
            "v": ["Alicomohan", "Kiraging", "Poblacion", "Santa Cruz Mabini"]
          },
          {
            "k": "Tagoloan",
            "v": [
              "Baluarte",
              "Casinglot",
              "Gracia",
              "Mohon",
              "Natumolan",
              "Poblacion",
              "Santa Ana",
              "Santa Cruz",
              "Sugbongcogon"
            ]
          },
          {
            "k": "Talisayan",
            "v": [
              "Calamcam",
              "Mandahilag",
              "Mintabon",
              "Pangpangon",
              "Poblacion",
              "Punta Santiago",
              "San Jose",
              "Santa Ines",
              "Tagbocboc"
            ]
          },
          {
            "k": "Villanueva",
            "v": [
              "Balacanas",
              "Dayawan",
              "Imelda",
              "Katipunan",
              "Kimaya",
              "Looc",
              "Poblacion 1",
              "Poblacion 2",
              "Poblacion 3",
              "San Martin",
              "Tambobong"
            ]
          }
        ],
        "names": [
          "Alubijid",
          "Balingasag",
          "Balingoan",
          "Binuangan",
          "Cagayan De Oro City",
          "El Salvador City",
          "Gingoog City",
          "Gitagum",
          "Initao",
          "Jasaan",
          "Kinoguitan",
          "Lagonglong",
          "Laguindingan",
          "Libertad",
          "Lugait",
          "Manticao",
          "Medina",
          "Naawan",
          "Opol",
          "Salay",
          "Sugbongcogon",
          "Tagoloan",
          "Talisayan",
          "Villanueva"
        ]
      }
    },
    {
      "k": "Mountain Province",
      "v": {
        "lookup": [
          {
            "k": "Bontoc",
            "v": [
              "Alab Oriente",
              "Alab Proper",
              "Bontoc Ili",
              "Calutit",
              "Poblacion (Bontoc)",
              "Samoki"
            ]
          }
        ],
        "names": ["Bontoc"]
      }
    },
    {
      "k": "Negros Occidental",
      "v": {
        "lookup": [
          {
            "k": "Bacolod City",
            "v": [
              "Alangilan",
              "Alijis",
              "Banago",
              "Barangay 1 (Pob.)",
              "Barangay 10 (Pob.)",
              "Barangay 11 (Pob.)",
              "Barangay 12 (Pob.)",
              "Barangay 13 (Pob.)",
              "Barangay 14 (Pob.)",
              "Barangay 15 (Pob.)",
              "Barangay 16 (Pob.)",
              "Barangay 17 (Pob.)",
              "Barangay 18 (Pob.)",
              "Barangay 19 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 20 (Pob.)",
              "Barangay 21 (Pob.)",
              "Barangay 22 (Pob.)",
              "Barangay 23 (Pob.)",
              "Barangay 24 (Pob.)",
              "Barangay 25 (Pob.)",
              "Barangay 26 (Pob.)",
              "Barangay 27 (Pob.)",
              "Barangay 28 (Pob.)",
              "Barangay 29 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 30 (Pob.)",
              "Barangay 31 (Pob.)",
              "Barangay 32 (Pob.)",
              "Barangay 33 (Pob.)",
              "Barangay 34 (Pob.)",
              "Barangay 35 (Pob.)",
              "Barangay 36 (Pob.)",
              "Barangay 37 (Pob.)",
              "Barangay 38 (Pob.)",
              "Barangay 39 (Pob.)",
              "Barangay 4 (Pob.)",
              "Barangay 40 (Pob.)",
              "Barangay 41 (Pob.)",
              "Barangay 5 (Pob.)",
              "Barangay 6 (Pob.)",
              "Barangay 7 (Pob.)",
              "Barangay 8 (Pob.)",
              "Barangay 9 (Pob.)",
              "Bata",
              "Cabug",
              "Estefania",
              "Felisa(proper Only)",
              "Granada",
              "Handumanan",
              "Mandalagan",
              "Mansilingan",
              "Montevista",
              "Pahanocoy",
              "Punta Taytay",
              "Singcang-airport",
              "Sum-ag",
              "Taculing",
              "Tangub",
              "Villamonte",
              "Vista Alegre"
            ]
          },
          { "k": "Bago City", "v": ["Lag-asan", "Poblacion", "Sampinit"] },
          {
            "k": "Binalbagan",
            "v": [
              "Bagroy (Proper Only)",
              "Canmoros (Pob.)",
              "Enclaro",
              "Paglaum (Pob.)",
              "Progreso (Pob.)",
              "San Pedro (Pob.)",
              "San Teodoro (Pob.)",
              "San Vicente (Pob.)",
              "Santo Rosario (Pob.)"
            ]
          },
          {
            "k": "Cadiz City",
            "v": [
              "Barangay 1 Pob. (Zone 1)",
              "Barangay 2 Pob. (Zone 2)",
              "Barangay 3 Pob. (Zone 3)",
              "Barangay 4 Pob. (Zone 4)",
              "Barangay 5 Pob. (Zone 5)",
              "Barangay 6 Pob. (Zone 6)"
            ]
          },
          {
            "k": "Escalante City",
            "v": ["Balintawak (Pob.)", "Jonobjonob", "Washington"]
          },
          {
            "k": "Himamaylan City",
            "v": [
              "Aguisan",
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)"
            ]
          },
          {
            "k": "Hinigaran",
            "v": [
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)"
            ]
          },
          {
            "k": "Kabankalan City",
            "v": [
              "Barangay 1 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 4 (Pob.)",
              "Barangay 5 (Pob.)",
              "Barangay 6 (Pob.)",
              "Barangay 7 (Pob.)",
              "Barangay 8 (Pob.)",
              "Barangay 9 (Pob.)",
              "Binicuil",
              "Camansi",
              "Camugao",
              "Daan Banua",
              "Hilamonan",
              "Salong",
              "Talubangi"
            ]
          },
          { "k": "Murcia", "v": ["Zone I (Pob.)", "Zone Ii (Pob.)"] },
          {
            "k": "Pulupandan",
            "v": [
              "Barangay Zone 1 (Pob.)",
              "Barangay Zone 1-a (Pob.)",
              "Barangay Zone 2 (Pob.)",
              "Barangay Zone 3 (Pob.)",
              "Barangay Zone 4 (Pob.)",
              "Barangay Zone 4-a (Pob.)",
              "Barangay Zone 5 (Pob.)",
              "Barangay Zone 6 (Pob.)",
              "Barangay Zone 7 (Pob.)",
              "Canjusa",
              "Crossing Pulupandan",
              "Culo",
              "Mabini",
              "Pag-ayon",
              "Palaka Norte",
              "Palaka Sur",
              "Patic",
              "Tapong",
              "Ubay",
              "Utod"
            ]
          },
          {
            "k": "Sagay City",
            "v": ["Poblacion I (Barangay 1)", "Poblacion Ii (Barangay 2)"]
          },
          {
            "k": "San Carlos City",
            "v": [
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Barangay V (Pob.)",
              "Barangay Vi (Pob.)"
            ]
          },
          {
            "k": "Silay City",
            "v": [
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Barangay V (Pob.)",
              "Lantad",
              "Mambulac",
              "Rizal"
            ]
          },
          {
            "k": "Talisay City",
            "v": [
              "Zone 1 (Pob.)",
              "Zone 10 (Pob.)",
              "Zone 12 (Pob.)",
              "Zone 14 (Pob.)",
              "Zone 15 (Pob.)",
              "Zone 16 (Pob.)",
              "Zone 2 (Pob.)",
              "Zone 3 (Pob.)",
              "Zone 4 (Pob.)",
              "Zone 5 (Pob.)",
              "Zone 6 (Pob.)",
              "Zone 7 (Pob.)",
              "Zone 8 (Pob.)",
              "Zone 9 (Pob.)"
            ]
          },
          {
            "k": "Victorias City",
            "v": [
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Barangay Ix (Daan Banwa)",
              "Barangay V (Pob.)",
              "Barangay Vi (Pob.)",
              "Barangay Vi-a",
              "Barangay Vii (Pob.)",
              "Barangay Viii (Pob.)",
              "Barangay X (Estado)",
              "Barangay Xii",
              "Barangay Xiii",
              "Barangay Xiv",
              "Barangay Xix",
              "Barangay Xv",
              "Barangay Xv-a",
              "Barangay Xvi",
              "Barangay Xvi-a",
              "Barangay Xvii",
              "Barangay Xviii",
              "Barangay Xviii-a",
              "Barangay Xx",
              "Barangay Xxi"
            ]
          }
        ],
        "names": [
          "Bacolod City",
          "Bago City",
          "Binalbagan",
          "Cadiz City",
          "Escalante City",
          "Himamaylan City",
          "Hinigaran",
          "Kabankalan City",
          "Murcia",
          "Pulupandan",
          "Sagay City",
          "San Carlos City",
          "Silay City",
          "Talisay City",
          "Victorias City"
        ]
      }
    },
    {
      "k": "Negros Oriental",
      "v": {
        "lookup": [
          {
            "k": "Bacong",
            "v": [
              "Banilad",
              "Buntis",
              "Combado",
              "North Poblacion",
              "Sacsac",
              "San Miguel",
              "South Poblacion",
              "Tubod",
              "West Poblacion"
            ]
          },
          {
            "k": "Bais City",
            "v": ["Barangay I (Pob.)", "Barangay Ii (Pob.)", "La Paz"]
          },
          {
            "k": "Bayawan City (Tulong)",
            "v": [
              "Banga",
              "Boyco",
              "Malabugas",
              "Pagatban",
              "Poblacion",
              "Suba (Pob.)",
              "Tinago (Pob.)",
              "Ubos (Pob.)",
              "Villareal"
            ]
          },
          {
            "k": "Dumaguete City",
            "v": [
              "Bagacay",
              "Bajumpandan",
              "Balugo",
              "Banilad",
              "Bantayan",
              "Batinguel",
              "Bunao",
              "Cadawinonan",
              "Calindagan",
              "Camanjac",
              "Candau-ay",
              "Cantil-e",
              "Daro",
              "Junob",
              "Looc",
              "Mangnao-canal",
              "Motong",
              "Piapi",
              "Poblacion No. 1 (Barangay 1)",
              "Poblacion No. 2 (Barangay 2)",
              "Poblacion No. 3 (Barangay 3)",
              "Poblacion No. 4 (Barangay 4)",
              "Poblacion No. 5 (Barangay 5)",
              "Poblacion No. 6 (Barangay 6)",
              "Poblacion No. 7 (Barangay 7)",
              "Poblacion No. 8 (Barangay 8)",
              "Pulantubig",
              "Tabuctubig",
              "Taclobo",
              "Talay"
            ]
          },
          {
            "k": "Manjuyod",
            "v": [
              "Alangilanan",
              "Bolisong",
              "Campuyo",
              "Dungo-an",
              "Maaslum",
              "Poblacion",
              "San Jose",
              "Santa Monica",
              "Suba",
              "Sundo-an",
              "Tupas"
            ]
          },
          { "k": "Santa Catalina", "v": ["Caranoche", "Poblacion"] },
          {
            "k": "Sibulan",
            "v": [
              "Agan-an",
              "Ajong",
              "Bolocboloc",
              "Cangmating",
              "Looc",
              "Magatas",
              "Maslog",
              "Poblacion",
              "Tubtubon"
            ]
          },
          {
            "k": "Valencia (Luzurriaga)",
            "v": [
              "Balabag East",
              "Balabag West",
              "Balugo",
              "Bong-ao",
              "Jawa",
              "North Poblacion",
              "South Poblacion"
            ]
          }
        ],
        "names": [
          "Bacong",
          "Bais City",
          "Bayawan City (Tulong)",
          "Dumaguete City",
          "Manjuyod",
          "Santa Catalina",
          "Sibulan",
          "Valencia (Luzurriaga)"
        ]
      }
    },
    {
      "k": "Northern Samar",
      "v": {
        "lookup": [
          {
            "k": "Allen",
            "v": [
              "Cabacungan",
              "Calarayan",
              "Guin Arawayan",
              "Jubasan",
              "Kinabranan Zone I Pob",
              "Kinabranan Zone Ii Pob",
              "Kinaguitman",
              "Lo Oc",
              "Londres",
              "Sabang Zone I Pob",
              "Sabang Zone Ii Pob",
              "Victoria"
            ]
          },
          {
            "k": "Bobon",
            "v": [
              "Acerida",
              "Arellano",
              "Dancalan",
              "Gen Lucban Pob",
              "Jose Abad Santos",
              "Magsaysay Doce",
              "Quezon Panicayan",
              "Salvacion",
              "San Isidro",
              "San Juan Pob",
              "Santa Clara Pob"
            ]
          },
          {
            "k": "Catarman",
            "v": [
              "Acacia Pob",
              "Aguinaldo",
              "Airport Village",
              "Bangkerohan",
              "Baybay",
              "Calachuchi Pob",
              "Cawayan",
              "Cervantes",
              "Daganas",
              "Dalakit Pob",
              "Galutan",
              "Ipil Ipil Pob",
              "Jose Abad Santos Pob",
              "Jose P Rizal Pob",
              "Lapu Lapu Pob",
              "Mabolo Pob",
              "Macagtas",
              "Molave Pob",
              "Narra Pob",
              "Old Rizal",
              "Paticua",
              "Polangi",
              "Sampaguita Pob",
              "Santol Pob",
              "Somoge",
              "Talisay Pob",
              "Uep I",
              "Uep Ii",
              "Uep Iii",
              "Washington",
              "Yakal Pob"
            ]
          },
          {
            "k": "Catubig",
            "v": [
              "Barangay 1 Pob",
              "Barangay 2 Pob",
              "Barangay 3 Pob",
              "Barangay 4 Pob",
              "Barangay 5 Pob",
              "Barangay 6 Pob",
              "Barangay 7 Pob",
              "Barangay 8 Pob",
              "Osmena"
            ]
          },
          {
            "k": "Gamay",
            "v": [
              "Baybay District Pob",
              "Burabod Pob",
              "Gamay Central Pob",
              "Gamay Occidental I Pob",
              "Gamay Oriental I Pob",
              "Libertad Pob",
              "Occidental Ii Pob",
              "Oriental Ii Pob"
            ]
          },
          {
            "k": "Laoang",
            "v": [
              "Aroganga",
              "Baybay Pob",
              "Bobolosan",
              "Burabud",
              "Calintaan Pob Sto Nino",
              "Guilaoangi Pob",
              "Little Venice Pob",
              "Rawis",
              "San Miguel Heights Pob",
              "Tumaguingting Pob"
            ]
          },
          {
            "k": "Lapinig",
            "v": ["Lapinig Del Norte Pob", "Lapinig Del Sur Pob"]
          },
          {
            "k": "Las Navas",
            "v": [
              "H Jolejole",
              "H Jolejole District Pob",
              "Osmena",
              "Quirino",
              "Quirino District Pob"
            ]
          },
          {
            "k": "Lavezares",
            "v": [
              "Caburihan Pob",
              "Caragas Pob",
              "Cataogan Pob",
              "Enriqueta",
              "Libas",
              "Libertad",
              "Macarthur",
              "Sabong Tabok",
              "San Miguel",
              "Urdaneta"
            ]
          },
          {
            "k": "Lope De Vega",
            "v": [
              "Bayho",
              "Bonifacio",
              "Getigo",
              "Lope De Vega Pob",
              "Lower Caynaga",
              "Magsaysay",
              "San Francisco",
              "San Jose",
              "San Miguel"
            ]
          },
          {
            "k": "Mapanas",
            "v": ["Del Norte Pob", "Del Sur Pob", "Santa Potenciana Pob"]
          },
          {
            "k": "Mondragon",
            "v": [
              "Bagasbas",
              "Bugko",
              "Cablangan",
              "Chitongco Pob",
              "Dona Lucia",
              "Eco Pob",
              "Imelda",
              "La Trinidad",
              "Makiwalo",
              "Roxas",
              "San Agustin",
              "San Juan"
            ]
          },
          {
            "k": "Palapag",
            "v": [
              "Asum Pob",
              "Laniwan Pob",
              "Natawo",
              "Osmena",
              "Tambangan Pob",
              "Tinampo Pob"
            ]
          },
          {
            "k": "Pambujan",
            "v": [
              "Cababto An",
              "Cabari An",
              "Camparanga",
              "Canjumadal",
              "Dona Anecita",
              "Paninirongan",
              "Poblacion District 1",
              "Poblacion District 2",
              "Poblacion District 3",
              "Poblacion District 4",
              "Poblacion District 5",
              "Poblacion District 6",
              "Poblacion District 7",
              "Poblacion District 8",
              "Sixto T Balanguit, Sr"
            ]
          },
          {
            "k": "Rosario",
            "v": [
              "Aguadahan",
              "Commonwealth",
              "Poblacion",
              "Salhag",
              "San Lorenzo"
            ]
          },
          {
            "k": "San Isidro",
            "v": [
              "Alegria",
              "Balite",
              "Buenavista",
              "Caglanipao",
              "Mabuhay",
              "Palanit",
              "Poblacion Norte",
              "Poblacion Sur",
              "Salvacion",
              "San Juan",
              "San Roque",
              "Veriato"
            ]
          },
          {
            "k": "San Jose",
            "v": [
              "Aguadahan",
              "Balite",
              "Da O",
              "Geratag",
              "Layuhan",
              "P Tingzon"
            ]
          },
          {
            "k": "San Roque",
            "v": [
              "Dale",
              "Zone 1 Pob",
              "Zone 2 Pob",
              "Zone 3 Pob",
              "Zone 4 Pob",
              "Zone 5 Pob",
              "Zone 6 Pob"
            ]
          },
          {
            "k": "Victoria",
            "v": [
              "Buenos Aires",
              "Colab Og",
              "Erenas",
              "Libertad",
              "San Lazaro",
              "Zone I Pob",
              "Zone Ii Pob",
              "Zone Iii Pob"
            ]
          }
        ],
        "names": [
          "Allen",
          "Bobon",
          "Catarman",
          "Catubig",
          "Gamay",
          "Laoang",
          "Lapinig",
          "Las Navas",
          "Lavezares",
          "Lope De Vega",
          "Mapanas",
          "Mondragon",
          "Palapag",
          "Pambujan",
          "Rosario",
          "San Isidro",
          "San Jose",
          "San Roque",
          "Victoria"
        ]
      }
    },
    {
      "k": "Nueva Ecija",
      "v": {
        "lookup": [
          {
            "k": "Aliaga",
            "v": [
              "Betes",
              "Bibiclat",
              "Bucot",
              "La Purisima",
              "Macabucod",
              "Magsaysay",
              "Pantoc",
              "Poblacion Centro",
              "Poblacion East I",
              "Poblacion East Ii",
              "Poblacion West Iii",
              "Poblacion West Iv",
              "San Carlos",
              "San Emiliano",
              "San Eustacio",
              "San Felipe Bata",
              "San Felipe Matanda",
              "San Juan",
              "San Pablo Bata",
              "San Pablo Matanda",
              "Santiago",
              "Santo Rosario",
              "Santo Tomas",
              "Sunson",
              "Umangan"
            ]
          },
          {
            "k": "Bongabon",
            "v": [
              "Antipolo",
              "Commercial (Pob.)",
              "Cruz",
              "Curva (J. Tomacruz)",
              "Kaingin (Pob.)",
              "Larcon",
              "Lusok",
              "Macabaclay",
              "Magtanggol (Pob.)",
              "Mantile (Pob.)",
              "Palo Maria (Pob.)",
              "Pesa",
              "Rizal (Pob.)",
              "Sampalucan (Pob.)",
              "San Roque (Pob.)",
              "Santor",
              "Sinipit (Pob.)",
              "Sisilang Na Ligaya (Pob.)",
              "Social (Pob.)",
              "Tugatug",
              "Tulay Na Bato (Pob.)",
              "Vega"
            ]
          },
          {
            "k": "Cabanatuan City",
            "v": [
              "Aduas Centro (Aduas)",
              "Aduas Norte",
              "Aduas Sur",
              "Bagong Buhay",
              "Bagong Sikat",
              "Bakero",
              "Bakod Bayan",
              "Balite",
              "Bangad",
              "Bantug Bulalo",
              "Bantug Norte",
              "Barlis",
              "Barrera District (Pob.)",
              "Bernardo District (Pob.)",
              "Bitas",
              "Bonifacio District (Pob.)",
              "Buliran",
              "Caalibangbangan",
              "Cabu",
              "Calawagan (Kalawagan)",
              "Campo Tinio",
              "Caridad",
              "Caudillo",
              "Cinco-cinco",
              "City Supermarket (Pob.)",
              "Communal",
              "Cruz Roja",
              "Daang Sarile",
              "Dalampang",
              "Dicarma (Pob.)",
              "Dimasalang (Pob.)",
              "Dionisio S. Garcia",
              "Fatima (Pob.)",
              "General Luna (Pob.)",
              "Hermogenes C. Concepcion, Sr.",
              "Ibabao Bana",
              "Imelda District",
              "Isla (Pob.)",
              "Kalikid Norte",
              "Kalikid Sur",
              "Kapitan Pepe (Pob.)",
              "Lagare",
              "Lourdes (Matungal-tungal)",
              "M. S. Garcia",
              "Mabini Extension",
              "Mabini Homesite",
              "Macatbong",
              "Magsaysay District",
              "Magsaysay South",
              "Maria Theresa",
              "Matadero (Pob.)",
              "Mayapyap Norte",
              "Mayapyap Sur",
              "Melojavilla (Pob.)",
              "Nabao (Pob.)",
              "Obrero",
              "Padre Burgos (Pob.)",
              "Padre Crisostomo",
              "Pagas",
              "Palagay",
              "Pamaldan",
              "Pangatian",
              "Patalac",
              "Polilio",
              "Pula",
              "Quezon District (Pob.)",
              "Rizdelis (Pob.)",
              "Samon",
              "San Isidro",
              "San Josef Norte",
              "San Josef Sur",
              "San Juan Pob. (Acofa)",
              "San Roque Norte",
              "San Roque Sur",
              "Sanbermicristi (Pob.)",
              "Sangitan",
              "Sangitan East",
              "Santa Arcadia",
              "Santo NiÃ±o",
              "Sapang",
              "Sumacab Este",
              "Sumacab Norte",
              "Sumacab South",
              "Talipapa",
              "Valdefuente",
              "Valle Cruz",
              "Vijandre District (Pob.)",
              "Villa Ofelia-caridad",
              "Zulueta District (Pob.)"
            ]
          },
          {
            "k": "Cabiao",
            "v": [
              "Bagong Buhay",
              "Bagong Sikat",
              "Bagong Silang",
              "Concepcion",
              "Entablado",
              "Maligaya",
              "Natividad North (Pob.)",
              "Natividad South (Pob.)",
              "Palasinan",
              "Polilio",
              "San Antonio",
              "San Carlos",
              "San Fernando Norte",
              "San Fernando Sur",
              "San Gregorio",
              "San Juan North (Pob.)",
              "San Juan South (Pob.)",
              "San Roque",
              "San Vicente",
              "Santa Ines",
              "Santa Isabel",
              "Santa Rita",
              "Sinipit"
            ]
          },
          {
            "k": "Carranglan",
            "v": [
              "D. L. Maglanoc Pob. (Bgy.iii)",
              "F. C. Otic Pob. (Bgy. Ii)",
              "G. S. Rosario Pob. (Bgy. Iv)",
              "Joson (Digidig)",
              "Puncan",
              "T. L. Padilla Pob. (Bgy. I)"
            ]
          },
          {
            "k": "Cuyapo",
            "v": [
              "Bentigan",
              "Bulala",
              "District I (Pob. I)",
              "District Ii (Pob. Ii)",
              "District Iv (Pob. Iv)",
              "District V (Pob. V)",
              "District Vi (Pob. Vi)",
              "District Vii (Pob. Vii)",
              "District Viii (Pob. Viii)",
              "Latap",
              "Malbeg-patalan"
            ]
          },
          {
            "k": "Gabaldon (Bitulok And Sabani)",
            "v": [
              "Bagting",
              "Bateria",
              "Bitulok (North Pob.)",
              "Calabasa",
              "Ligaya",
              "Macasandal",
              "Malinao",
              "Pantoc",
              "South Poblacion"
            ]
          },
          {
            "k": "Gapan City",
            "v": [
              "Balante",
              "Bayanihan",
              "Bulak",
              "Bungo",
              "Kapalangan",
              "Mabunga",
              "Maburak",
              "Mahipon",
              "Makabaclay",
              "Malimba",
              "Mangino",
              "Marelo",
              "Pambuan",
              "Parcutela",
              "Puting Tubig",
              "San Lorenzo (Pob.)",
              "San Nicolas",
              "San Roque",
              "San Vicente (Pob.)",
              "Santa Cruz",
              "Santo Cristo Norte",
              "Santo Cristo Sur",
              "Santo NiÃ±o"
            ]
          },
          {
            "k": "General Mamerto Natividad",
            "v": [
              "Balangkare Norte",
              "Balangkare Sur",
              "Belen",
              "Bravo",
              "Burol",
              "Kabulihan",
              "Mag-asawang Sampaloc",
              "Manarog",
              "Mataas Na Kahoy",
              "Pinahan",
              "Platero",
              "Poblacion",
              "Pula",
              "Sapang Bato",
              "Talabutab Norte",
              "Talabutab Sur"
            ]
          },
          {
            "k": "General Tinio (Papaya)",
            "v": [
              "Bago",
              "Concepcion",
              "Nazareth",
              "Padolina",
              "Palale",
              "Pias",
              "Poblacion Central",
              "Poblacion East",
              "Poblacion West",
              "Pulong Matong",
              "Rio Chico",
              "Sampaguita",
              "San Pedro (Pob.)"
            ]
          },
          {
            "k": "Guimba",
            "v": [
              "Agcano",
              "Ayos Lomboy",
              "Bacayao",
              "Bagong Barrio",
              "Balbalino",
              "Banitan",
              "Bulakid",
              "Bunol",
              "Caballero",
              "Cabaruan",
              "Caingin Tabing Ilog",
              "Calem",
              "Camiling",
              "Cardinal",
              "Casongsong",
              "Catimon",
              "Cavite",
              "Cawayan Bugtong",
              "Consuelo",
              "Culong",
              "Escano",
              "Faigal",
              "Galvan",
              "Lamorito",
              "Macamias",
              "Macapabellag",
              "Macatcatuit",
              "Manacsac",
              "Manggang Marikit",
              "Maturanoc",
              "Maybubon",
              "Naglabrahan",
              "Narvacan Ii",
              "Partida Ii",
              "Pasong Inchic",
              "Saint John District (Pob.)",
              "San Agustin",
              "San Andres",
              "San Bernardino",
              "San Marcelino",
              "San Miguel",
              "San Rafael",
              "San Roque",
              "Santa Lucia",
              "Santa Veronica District (Pob.)",
              "Santo Cristo District (Pob.)",
              "Saranay District (Pob.)",
              "Subol",
              "Tampac I",
              "Tampac Ii And Iii",
              "Triala"
            ]
          },
          {
            "k": "Jaen",
            "v": [
              "Calabasa",
              "Dampulan (Pob.)",
              "Don Mariano Marcos (Pob.)",
              "Hilera",
              "Imbunia",
              "Imelda Pob. (DoÃ±a Aurora)",
              "Lambakin",
              "Langla",
              "Magsalisi",
              "Malabon-kaingin",
              "Marawa",
              "Niyugan",
              "Ocampo-rivera District (Pob.)",
              "Pakol",
              "Pamacpacan",
              "Pinanggaan",
              "Putlod",
              "San Jose",
              "San Josef (Nabao)",
              "San Pablo",
              "San Roque",
              "San Vicente",
              "Santa Rita",
              "Santo Tomas North",
              "Santo Tomas South",
              "Sapang",
              "Ulanin-pitak"
            ]
          },
          {
            "k": "Laur",
            "v": [
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Betania",
              "Canantong",
              "Nauzon",
              "Pangarulong",
              "Pinagbayanan",
              "Sagana",
              "San Felipe",
              "San Fernando",
              "San Isidro",
              "San Josef",
              "San Juan",
              "San Vicente",
              "Siclong"
            ]
          },
          {
            "k": "Licab",
            "v": [
              "Aquino",
              "Linao",
              "Poblacion Norte",
              "Poblacion Sur",
              "San Casimiro",
              "San Cristobal",
              "San Jose",
              "San Juan",
              "Santa Maria",
              "Tabing Ilog",
              "Villarosa"
            ]
          },
          {
            "k": "Llanera",
            "v": [
              "A. Bonifacio",
              "Bagumbayan (Pob.)",
              "Bosque",
              "Caridad Norte",
              "Caridad Sur",
              "Casile",
              "Florida Blanca",
              "General Luna",
              "General Ricarte",
              "Gomez",
              "Inanama",
              "Ligaya",
              "Mabini",
              "Murcon",
              "Plaridel",
              "San Felipe",
              "San Francisco",
              "San Nicolas",
              "San Vicente",
              "Santa Barbara",
              "Victoria",
              "Villa Viniegas"
            ]
          },
          {
            "k": "Lupao",
            "v": [
              "Agupalo Este",
              "Agupalo Weste",
              "Alalay Chica",
              "Alalay Grande",
              "Bagong Flores",
              "Balbalungao",
              "Burgos",
              "Cordero",
              "J. U. Tienzo (Arimal)",
              "Mapangpang",
              "Namulandayan",
              "Parista",
              "Poblacion East",
              "Poblacion North",
              "Poblacion South",
              "Poblacion West",
              "Salvacion I",
              "Salvacion Ii",
              "San Antonio Este",
              "San Antonio Weste",
              "San Isidro",
              "San Pedro",
              "San Roque",
              "Santo Domingo"
            ]
          },
          {
            "k": "Nampicuan",
            "v": [
              "Ambasador Alzate Village",
              "Cabaducan East (Pob.)",
              "Cabaducan West (Pob.)",
              "East Central Poblacion",
              "Estacion (Pob.)",
              "North Poblacion",
              "Northwest Poblacion",
              "South Central Poblacion",
              "Southeast Poblacion",
              "Southwest Poblacion",
              "West Central Poblacion",
              "West Poblacion"
            ]
          },
          {
            "k": "Palayan City",
            "v": [
              "Atate",
              "Aulo",
              "Bagong Buhay",
              "Bo. Militar (Fort Magsaysay)",
              "Caballero",
              "Caimito",
              "DoÃ±a Josefa",
              "Ganaderia",
              "Imelda Valley",
              "Langka",
              "Malate (Pob.)",
              "Maligaya",
              "Manacnac",
              "Mapait",
              "Marcos Village",
              "Popolon Pagas",
              "Santolan",
              "Sapang Buho",
              "Singalat"
            ]
          },
          {
            "k": "Pantabangan",
            "v": [
              "Cadaclan",
              "Cambitala",
              "Conversion",
              "Fatima",
              "Ganduz",
              "Liberty",
              "Malbang",
              "Poblacion East",
              "Poblacion West",
              "Sampaloc",
              "San Juan",
              "Villarica"
            ]
          },
          {
            "k": "PeÃ±aranda",
            "v": [
              "Callos",
              "Las PiÃ±as",
              "Poblacion I",
              "Poblacion Ii",
              "Poblacion Iii",
              "Poblacion Iv",
              "San Josef",
              "San Mariano (Maugat)",
              "Santo Tomas",
              "Sinasajan"
            ]
          },
          {
            "k": "Quezon",
            "v": [
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Bertese",
              "DoÃ±a Lucia",
              "Dulong Bayan",
              "Ilog Baliwag",
              "Pulong Bahay",
              "San Andres Ii",
              "San Manuel",
              "San Miguel",
              "Santa Clara",
              "Santa Rita",
              "Santo Tomas Feria"
            ]
          },
          {
            "k": "Rizal",
            "v": [
              "Agbannawag",
              "Aglipay (Curva)",
              "Bicos",
              "Cabucbucan",
              "Calaocan District",
              "Canaan East",
              "Canaan West",
              "Casilagan",
              "Del Pilar",
              "Estrella",
              "General Luna",
              "Macapsing",
              "Maligaya",
              "Paco Roman",
              "Pag-asa",
              "Poblacion Central",
              "Poblacion East",
              "Poblacion Norte",
              "Poblacion Sur",
              "Poblacion West",
              "Portal",
              "San Esteban",
              "San Gregorio",
              "Santa Monica",
              "Villa Labrador",
              "Villa Paraiso"
            ]
          },
          {
            "k": "San Antonio",
            "v": [
              "Buliran",
              "Cama Juan",
              "Julo",
              "Lawang Kupang",
              "Luyos",
              "Maugat",
              "Panabingan",
              "Papaya",
              "Poblacion",
              "San Francisco",
              "San Jose",
              "San Mariano",
              "Santa Barbara",
              "Santa Cruz",
              "Santo Cristo",
              "Tikiw"
            ]
          },
          {
            "k": "San Isidro",
            "v": [
              "Alua",
              "Calaba",
              "Malapit",
              "Mangga",
              "Poblacion",
              "Pulo",
              "San Roque",
              "Santo Cristo",
              "Tabon"
            ]
          },
          {
            "k": "San Jose City",
            "v": [
              "A. Pascual",
              "Abar 2nd",
              "Abar Ist",
              "Bagong Sikat",
              "Caanawan",
              "Calaocan",
              "Camanacsacan",
              "Canuto Ramos Pob. (District Iii)",
              "Crisanto Sanchez Pob. (District V)",
              "Culaylay",
              "Dizol",
              "Ferdinand E. Marcos Pob. (District Ii)",
              "Kaliwanagan",
              "Kita-kita",
              "Malasin",
              "Manicla",
              "Palestina",
              "Parang Mangga",
              "Pinili",
              "Porais",
              "Rafael Rueda, Sr. Pob. (District I)",
              "Raymundo Eugenio Pob. (District Iv)",
              "San Agustin",
              "San Juan",
              "San Mauricio",
              "Santo NiÃ±o 1st",
              "Santo NiÃ±o 2nd",
              "Santo NiÃ±o 3rd",
              "Santo Tomas",
              "Sibut",
              "Sinipit Bubon",
              "Tabulac",
              "Tayabo",
              "Tondod",
              "Tulat",
              "Villa Floresca",
              "Villa Joson (Parilla)",
              "Villa Marina"
            ]
          },
          {
            "k": "San Leonardo",
            "v": [
              "Bonifacio District (Pob.)",
              "Burgos District (Pob.)",
              "Castellano",
              "Diversion",
              "Magpapalayoc",
              "Mallorca",
              "Mambangnan",
              "Nieves",
              "Rizal District (Pob.)",
              "San Anton",
              "San Bartolome (Pob.)",
              "San Roque",
              "Tabuating",
              "Tagumpay",
              "Tambo Adorable"
            ]
          },
          {
            "k": "Santa Rosa",
            "v": [
              "Aguinaldo",
              "Berang",
              "Burgos",
              "Cojuangco (Pob.)",
              "Del Pilar",
              "Gomez",
              "Inspector",
              "Isla",
              "La Fuente",
              "Liwayway",
              "Lourdes",
              "Luna",
              "Mabini",
              "MalacaÃ±ang",
              "Maliolio",
              "Mapalad",
              "Rajal Centro",
              "Rajal Norte",
              "Rajal Sur",
              "Rizal (Pob.)",
              "San Gregorio",
              "San Isidro",
              "San Josep",
              "San Mariano",
              "San Pedro",
              "Santa Teresita",
              "Santo Rosario",
              "Sapsap",
              "Soledad",
              "Tagpos",
              "Tramo",
              "Valenzuela (Pob.)",
              "Zamora (Pob.)"
            ]
          },
          {
            "k": "Santo Domingo",
            "v": [
              "Baloc",
              "Buasao",
              "Burgos",
              "Cabugao",
              "Casulucan",
              "Comitang",
              "Concepcion",
              "Dolores",
              "General Luna",
              "Hulo",
              "Mabini",
              "Malasin",
              "Malaya (Pook Malaya)",
              "Malayantoc",
              "Mambarao",
              "Poblacion",
              "Pulong Buli",
              "Sagaba",
              "San Agustin",
              "San Fabian",
              "San Francisco",
              "San Pascual",
              "Santa Rita",
              "Santo Rosario"
            ]
          },
          {
            "k": "Science City Of MuÃ±oz",
            "v": [
              "Bagong Sikat",
              "Balante",
              "Bantug",
              "Bical",
              "Calabalabaan",
              "Catalanacan",
              "Curva",
              "Franza",
              "Gabaldon",
              "Labney",
              "Licaong",
              "Linglingay",
              "Magtanggol",
              "Maligaya",
              "Mangandingay",
              "Mapangpang",
              "Maragol",
              "Matingkis",
              "Naglabrahan",
              "Palusapis",
              "Pandalla",
              "Poblacion East",
              "Poblacion North",
              "Poblacion South",
              "Poblacion West",
              "Rang-ayan",
              "Rizal",
              "San Andres",
              "San Antonio",
              "San Felipe",
              "Sapang Cawayan",
              "Villa Cuizon",
              "Villa Isla",
              "Villa Nati",
              "Villa Santos"
            ]
          },
          {
            "k": "Talavera",
            "v": [
              "Andal Alino (Pob.)",
              "Bagong Sikat",
              "Bagong Silang",
              "Bakal I",
              "Bakal Ii",
              "Bakal Iii",
              "Baluga",
              "Bantug",
              "Bantug Hacienda",
              "Bantug Hamog (Basang Hamog)",
              "Bugtong Na Buli",
              "Bulac",
              "Burnay",
              "Caaniplahan",
              "Cabubulaonan",
              "Calipahan",
              "Campos",
              "Caputican",
              "Casulucan Este",
              "Collado",
              "Dimasalang Norte",
              "Dimasalang Sur",
              "Dinarayat",
              "Esguerra District (Pob.)",
              "Gulod",
              "Homestead I",
              "Homestead Ii",
              "Kinalanguyan",
              "La Torre",
              "Lomboy",
              "Mabuhay",
              "Maestrang Kikay (Pob.)",
              "Mamandil",
              "Marcos District (Pob.)",
              "Matingkis",
              "Minabuyoc",
              "Pag-asa (Pob.)",
              "Paludpod",
              "Pantoc Bulac",
              "Pinagpanaan",
              "Poblacion Sur",
              "Pula",
              "Pulong San Miguel (Pob.)",
              "Purok Matias (Pob.)",
              "Sampaloc",
              "San Miguel Na Munti",
              "San Pascual",
              "San Ricardo",
              "Sibul",
              "Sicsican Matanda",
              "Tabacao",
              "Tagaytay",
              "Valle"
            ]
          },
          {
            "k": "Talugtug",
            "v": [
              "Alula",
              "Baybayabas",
              "Buted",
              "Cabiangan",
              "Calisitan",
              "Culiat",
              "Mayamot I",
              "Mayamot Ii",
              "OsmeÃ±a (Pob.)",
              "Pangit",
              "Patola",
              "Quezon (Pob.)",
              "Roxas (Pob.)",
              "Saguing",
              "Santa Catalina",
              "Saringaya",
              "Saverona",
              "Tandoc",
              "Tibag",
              "Villa Rosario"
            ]
          },
          {
            "k": "Zaragoza",
            "v": [
              "Batitang",
              "Carmen",
              "Concepcion",
              "Del Pilar",
              "General Luna",
              "H. Romero",
              "Macarse",
              "Manaul",
              "Mayamot",
              "Pantoc",
              "San Isidro",
              "San Rafael",
              "San Vicente (Pob.)",
              "Santa Cruz",
              "Santa Lucia Old",
              "Santa Lucia Young",
              "Santo Rosario Old",
              "Santo Rosario Young",
              "Valeriana"
            ]
          }
        ],
        "names": [
          "Aliaga",
          "Bongabon",
          "Cabanatuan City",
          "Cabiao",
          "Carranglan",
          "Cuyapo",
          "Gabaldon (Bitulok And Sabani)",
          "Gapan City",
          "General Mamerto Natividad",
          "General Tinio (Papaya)",
          "Guimba",
          "Jaen",
          "Laur",
          "Licab",
          "Llanera",
          "Lupao",
          "Nampicuan",
          "Palayan City",
          "Pantabangan",
          "PeÃ±aranda",
          "Quezon",
          "Rizal",
          "San Antonio",
          "San Isidro",
          "San Jose City",
          "San Leonardo",
          "Santa Rosa",
          "Santo Domingo",
          "Science City Of MuÃ±oz",
          "Talavera",
          "Talugtug",
          "Zaragoza"
        ]
      }
    },
    {
      "k": "Nueva Vizcaya",
      "v": {
        "lookup": [
          {
            "k": "Aritao",
            "v": [
              "Baan",
              "Banganan",
              "Beti",
              "Bone North",
              "Bone South",
              "Calitlitan",
              "Canarem",
              "Comon",
              "Cutar",
              "Darapidap",
              "Kirang",
              "Latar-nocnoc-san Francisco",
              "Nagcuartelan",
              "Poblacion",
              "Tucanon"
            ]
          },
          {
            "k": "Bagabag",
            "v": [
              "Bakir",
              "Baretbet",
              "Careb",
              "Lantap",
              "Murong",
              "Nangalisan",
              "Paniki",
              "Pogonsino",
              "Quirino (Pob.)",
              "San Geronimo (Pob.)",
              "San Pedro (Pob.)",
              "Santa Cruz",
              "Santa Lucia",
              "Tuao North",
              "Tuao South",
              "Villa Coloma (Pob.)",
              "Villaros"
            ]
          },
          {
            "k": "Bambang",
            "v": [
              "Abian",
              "Abinganan",
              "Aliaga",
              "Almaguer North",
              "Almaguer South",
              "Banggot (Pob.)",
              "Barat",
              "Buag (Pob.)",
              "Calaocan (Pob.)",
              "Dullao",
              "Homestead",
              "Indiana",
              "Mabuslo",
              "Macate",
              "Magsaysay Hills",
              "Manamtam",
              "Mauan",
              "Pallas",
              "Salinas",
              "San Antonio North",
              "San Antonio South",
              "San Fernando",
              "San Leonardo",
              "Santo Domingo (Tabangan)",
              "Santo Domingo West"
            ]
          },
          {
            "k": "Bayombong",
            "v": [
              "Bansing",
              "Bonfal East",
              "Bonfal Proper",
              "Bonfal West",
              "Buenavista (Vista Hills)",
              "Busilac",
              "Cabuaan",
              "Casat",
              "District Iii Pob. (Don M. Perez)",
              "District Iv (Pob.)",
              "Don Domingo Maddela Pob. (District I)",
              "Don Mariano Marcos",
              "Don Tomas Maddela Pob. (District Ii)",
              "Ipil-cuneg",
              "La Torre North",
              "La Torre South",
              "Luyang",
              "Magapuy",
              "Magsaysay",
              "Masoc",
              "Paitan",
              "Salvacion",
              "San Nicolas North (Luyang)",
              "Santa Rosa",
              "Vista Alegre (B. Baringin)"
            ]
          },
          {
            "k": "Dupax Del Norte",
            "v": ["Ineangan", "Lamo", "Mabasa", "Malasin (Pob.)"]
          },
          {
            "k": "Dupax Del Sur",
            "v": [
              "Bagumbayan",
              "Balsain",
              "Domang",
              "Dopaj",
              "Mangayang",
              "Santa Maria"
            ]
          },
          {
            "k": "Lamut Ifugao",
            "v": [
              "Bannit High-way/soldiers Camp",
              "Denr",
              "Iscaf",
              "Lto",
              "Nayon High-way/iscaf",
              "Nia",
              "Payawan High-way",
              "Poblacion East",
              "Poblacion West"
            ]
          },
          {
            "k": "Solano",
            "v": [
              "Aggub",
              "Bagahabag",
              "Bangaan",
              "Bangar",
              "Bascaran",
              "Communal",
              "Concepcion (Calalabangan)",
              "Curifang",
              "Dadap",
              "Lactawan",
              "OsmeÃ±a",
              "Pilar D. Galima",
              "Poblacion North",
              "Poblacion South",
              "Quezon",
              "Quirino",
              "Roxas",
              "San Juan",
              "San Luis",
              "Tucal",
              "Uddiawan",
              "Wacal"
            ]
          },
          {
            "k": "Villaverde",
            "v": [
              "Bintawan Norte",
              "Bintawan Sur",
              "Ibung",
              "Nagbitin",
              "Pieza",
              "Poblacion (Turod)",
              "Sawmill"
            ]
          }
        ],
        "names": [
          "Aritao",
          "Bagabag",
          "Bambang",
          "Bayombong",
          "Dupax Del Norte",
          "Dupax Del Sur",
          "Lamut Ifugao",
          "Solano",
          "Villaverde"
        ]
      }
    },
    {
      "k": "Occidental Mindoro",
      "v": {
        "lookup": [
          { "k": "Calintaan", "v": ["New Dagupan", "Poblacion"] },
          {
            "k": "Magsaysay",
            "v": ["Caguray", "Calawag", "Laste", "Poblacion", "Sibalat"]
          },
          {
            "k": "Mamburao",
            "v": [
              "Balansay",
              "Fatima (Tii)",
              "Poblacion 1 (Barangay 1)",
              "Poblacion 2 (Barangay 2)",
              "Poblacion 3 (Barangay 3)",
              "Poblacion 4 (Barangay 4)",
              "Poblacion 5 (Barangay 5)",
              "Poblacion 6 (Barangay 6)",
              "Poblacion 7 (Barangay 7)",
              "Poblacion 8 (Barangay 8)",
              "Talabaan",
              "Tayamaan"
            ]
          },
          {
            "k": "Rizal",
            "v": [
              "Adela",
              "Magsikap",
              "Malawaan",
              "Rizal",
              "Rumbang",
              "Salvacion",
              "San Pedro",
              "Santo NiÃ±o"
            ]
          },
          {
            "k": "Sablayan",
            "v": [
              "Batong Buhay",
              "Buenavista",
              "Burgos",
              "General Emilio Aguinaldo",
              "Ibud",
              "Ligaya",
              "Malisbong",
              "Paetan",
              "Pag-asa",
              "Poblacion (Lumangbayan)",
              "San Agustin",
              "San Francisco",
              "San Nicolas",
              "San Vicente",
              "Santa Lucia",
              "Santo NiÃ±o",
              "Tuban"
            ]
          },
          {
            "k": "San Jose",
            "v": [
              "Bagong Sikat",
              "Barangay 1 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 4 (Pob.)",
              "Barangay 5 (Pob.)",
              "Barangay 6 (Pob.)",
              "Barangay 7 (Pob.)",
              "Barangay 8 (Pob.)",
              "Bayotbot",
              "Bubog",
              "Camburay",
              "Caminawit",
              "Central",
              "La Curva",
              "Labangan Poblacion",
              "Mabini",
              "Magbay",
              "Mangarin",
              "Mapaya",
              "Murtha",
              "Pag-asa",
              "San Isidro",
              "San Roque"
            ]
          },
          {
            "k": "Santa Cruz",
            "v": [
              "Alacaak",
              "Mulawin",
              "Poblacion I (Barangay 1)",
              "Poblacion Ii (Barangay 2)",
              "San Vicente"
            ]
          }
        ],
        "names": [
          "Calintaan",
          "Magsaysay",
          "Mamburao",
          "Rizal",
          "Sablayan",
          "San Jose",
          "Santa Cruz"
        ]
      }
    },
    {
      "k": "Oriental Mindoro",
      "v": {
        "lookup": [
          {
            "k": "Baco",
            "v": ["Alag", "Poblacion", "Santa Rosa I", "Santa Rosa Ii"]
          },
          { "k": "Bansud", "v": ["Poblacion", "Salcedo", "Sumagui"] },
          {
            "k": "Bongabong",
            "v": [
              "Anilao",
              "Aplaya",
              "Bagumbayan I",
              "Bagumbayan Ii",
              "Bukal",
              "Camantigue",
              "Carmundo",
              "Cawayan",
              "Dayhagan",
              "Formon",
              "Hagan",
              "Ipil",
              "Kaligtasan",
              "Labasan",
              "Labonan",
              "Libertad",
              "Malitbog",
              "Masaguisi",
              "Mina De Oro",
              "Ogbot",
              "Orconuma",
              "Poblacion",
              "Polusahi",
              "Sagana",
              "San Isidro",
              "San Jose",
              "San Juan",
              "Santa Cruz"
            ]
          },
          {
            "k": "Bulalacao (San Pedro)",
            "v": [
              "Cambunang (Pob.)",
              "Campaasan (Pob.)",
              "Nasukob (Pob.)",
              "Poblacion",
              "San Juan",
              "San Roque (Buyayao)"
            ]
          },
          {
            "k": "City Of Calapan",
            "v": [
              "Balingayan",
              "Balite",
              "Baruyan",
              "Batino",
              "Bayanan I",
              "Bayanan Ii",
              "Biga",
              "Bondoc",
              "Bucayao",
              "Buhuan",
              "Bulusan",
              "Calero (Pob.)",
              "Camansihan",
              "Camilmil",
              "Canubing I",
              "Comunal",
              "Guinobatan",
              "Gulod",
              "Gutad",
              "Ibaba East (Pob.)",
              "Ibaba West (Pob.)",
              "Ilaya (Pob.)",
              "Lalud",
              "Lazareto",
              "Libis (Pob.)",
              "Lumang Bayan",
              "Mahal Na Pangalan",
              "Malad",
              "Malamig",
              "Managpi",
              "Masipit",
              "Nag-iba I",
              "Pachoca",
              "Palhi",
              "Panggalaan",
              "Parang",
              "Patas",
              "Personas",
              "Putingtubig",
              "Salong",
              "San Antonio",
              "San Vicente Central (Pob.)",
              "San Vicente East (Pob.)",
              "San Vicente North (Pob.)",
              "San Vicente South (Pob.)",
              "San Vicente West (Pob.)",
              "Santa Isabel",
              "Santa Maria Village",
              "Santa Rita (Bungahan)",
              "Santo NiÃ±o",
              "Sapul",
              "Silonay",
              "Suqui",
              "Tawagan",
              "Tawiran",
              "Tibag",
              "Wawa"
            ]
          },
          {
            "k": "Gloria",
            "v": [
              "Agos",
              "Andres Bonifacio",
              "Balete",
              "Bulaklakan",
              "Gaudencio Antonino",
              "Kawit",
              "Lucio Laurel",
              "Maligaya Poblacion",
              "Narra",
              "Santa Maria",
              "Tambong"
            ]
          },
          {
            "k": "Mansalay",
            "v": [
              "B. Del Mundo",
              "Balugo",
              "Bonbon",
              "Budburan",
              "Cabalwa",
              "Don Pedro",
              "Maliwanag",
              "Manaul",
              "Poblacion",
              "Roma",
              "Santa Brigida",
              "Santa Maria",
              "Santa Teresita",
              "Villa Celestial",
              "Wasig",
              "Waygan"
            ]
          },
          {
            "k": "Naujan",
            "v": [
              "Andres Ilagan (Mag-asawang Tubig)",
              "Bacungan",
              "Barcenaga",
              "Buhangin",
              "Estrella",
              "Gamao",
              "Kalinisan",
              "Mabini",
              "Motoderazo",
              "Nag-iba I",
              "Nag-iba Ii",
              "Pagkakaisa",
              "Pinagsabangan I",
              "Pinagsabangan Ii",
              "PiÃ±ahan",
              "Poblacion I (Barangay I)",
              "Poblacion Ii (Barangay Ii)",
              "Poblacion Iii (Barangay Iii)",
              "Sampaguita",
              "San Agustin I",
              "San Agustin Ii",
              "San Antonio",
              "Santa Maria",
              "Santiago",
              "Santo NiÃ±o"
            ]
          },
          {
            "k": "Pinamalayan",
            "v": [
              "Anoling",
              "Bacungan",
              "Bangbang",
              "Cacawan",
              "Del Razon",
              "Guinhawa",
              "Inclanay",
              "Lumangbayan",
              "Malaya",
              "Maliangcog",
              "Maningcol",
              "Marfrancisco",
              "Nabuslot",
              "Pagalagala",
              "Palayan",
              "Pambisan Malaki",
              "Pambisan Munti",
              "Panggulayan",
              "Papandayan",
              "Pili",
              "Quinabigan",
              "Rosario",
              "Santa Isabel",
              "Santa Maria",
              "Santa Rita",
              "Santo NiÃ±o",
              "Wawa",
              "Zone I (Pob.)",
              "Zone Ii (Pob.)",
              "Zone Iii (Pob.)",
              "Zone Iv (Pob.)"
            ]
          },
          { "k": "Pola", "v": ["Maluanluan", "Matulatula", "Panikihan"] },
          {
            "k": "Puerto Galera",
            "v": [
              "Aninuan",
              "Balatero",
              "Dulangan",
              "Palangan",
              "Poblacion",
              "Sabang",
              "San Isidro",
              "Santo Nino",
              "Sinandigan",
              "Tabinay",
              "Villaflor"
            ]
          },
          {
            "k": "Roxas",
            "v": [
              "Bagumbayan (Pob.)",
              "Cantil",
              "Libertad",
              "Libtong",
              "Little Tanauan",
              "Mabuhay",
              "Maraska",
              "Odiong",
              "Paclasan (Pob.)",
              "San Aquilino",
              "San Isidro",
              "San Jose",
              "San Mariano",
              "San Miguel",
              "San Rafael",
              "Uyao",
              "Victoria"
            ]
          },
          {
            "k": "San Teodoro",
            "v": ["Bigaan", "Calangatan", "Ilag", "Lumangbayan", "Poblacion"]
          },
          {
            "k": "Socorro",
            "v": [
              "Bagsok",
              "Batong Dalig",
              "Catiningan",
              "Happy Valley",
              "Leuteboro I",
              "Leuteboro Ii",
              "Matungao",
              "Monteverde",
              "Pasi I",
              "Pasi Ii",
              "Santo Domingo (Lapog)",
              "Subaan",
              "Zone I (Pob.)",
              "Zone Ii (Pob.)",
              "Zone Iii (Pob.)",
              "Zone Iv (Pob.)"
            ]
          },
          {
            "k": "Victoria",
            "v": [
              "Alcate",
              "Babangonan",
              "Bagong Buhay",
              "Bagong Silang",
              "Bambanin",
              "Bethel",
              "Canaan",
              "Duongan",
              "Jose Leido Jr.",
              "Mabini",
              "Macatoc",
              "Ordovilla",
              "Pakyas",
              "Poblacion I",
              "Poblacion Ii",
              "Poblacion Iii",
              "Poblacion Iv",
              "San Antonio",
              "San Gabriel",
              "San Gelacio",
              "San Isidro",
              "San Juan",
              "Urdaneta"
            ]
          }
        ],
        "names": [
          "Baco",
          "Bansud",
          "Bongabong",
          "Bulalacao (San Pedro)",
          "City Of Calapan",
          "Gloria",
          "Mansalay",
          "Naujan",
          "Pinamalayan",
          "Pola",
          "Puerto Galera",
          "Roxas",
          "San Teodoro",
          "Socorro",
          "Victoria"
        ]
      }
    },
    {
      "k": "Palawan",
      "v": {
        "lookup": [
          { "k": "Aborlan", "v": ["Poblacion"] },
          {
            "k": "Bataraza",
            "v": [
              "Bono-bono",
              "Inogbong",
              "Iwahig",
              "Rio Tuba",
              "Sandoval",
              "Tarusan"
            ]
          },
          {
            "k": "Brooke's Point",
            "v": [
              "Barong-barong",
              "Calasaguen",
              "Ipilan",
              "Maasin",
              "Malis",
              "Oring-oring",
              "Pangobilian",
              "Poblacion I",
              "Poblacion Ii",
              "Salogon",
              "Tubtub"
            ]
          },
          {
            "k": "Coron",
            "v": [
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Barangay V (Pob.)",
              "Barangay Vi (Pob.)",
              "Guadalupe",
              "Marcilla",
              "San Jose",
              "San Nicolas",
              "Tagumpay"
            ]
          },
          {
            "k": "El Nido (Bacuit)",
            "v": [
              "Aberawan",
              "Bagong Bayan",
              "Buena Suerte Pob. (Barangay 2)",
              "Corong-corong Pob. (Barangay 4)",
              "Maligaya Pob. (Barangay 1)",
              "Masagana Pob. (Barangay 3)",
              "PasadeÃ±a"
            ]
          },
          {
            "k": "Narra",
            "v": [
              "Antipuluan",
              "Batang-batang",
              "Caguisan",
              "Elvita",
              "Malatgao",
              "Malinao",
              "Narra (Pob.)",
              "Panacan",
              "Panacan 2",
              "Princess Urduja",
              "Sandoval",
              "Taritien"
            ]
          },
          {
            "k": "Puerto Princesa City",
            "v": [
              "Bacungan",
              "Bagong Pag-asa (Pob.)",
              "Bagong Sikat (Pob.)",
              "Bagong Silang (Pob.)",
              "Bancao-bancao",
              "Irawan",
              "Kalipay (Pob.)",
              "Liwanag (Pob.)",
              "Luzviminda",
              "Mabuhay (Pob.)",
              "Magkakaibigan (Pob.)",
              "Maligaya (Pob.)",
              "Mandaragat",
              "Manggahan (Pob.)",
              "Maningning (Pob.)",
              "Masigla (Pob.)",
              "Masikap (Pob.)",
              "Masipag (Pob.)",
              "Matahimik (Pob.)",
              "Matiyaga (Pob.)",
              "Maunlad (Pob.)",
              "Milagrosa (Pob.)",
              "Model (Pob.)",
              "Pagkakaisa (Pob.)",
              "Princesa (Pob.)",
              "San Jose",
              "San Manuel",
              "San Miguel",
              "San Pedro",
              "Santa Lourdes",
              "Santa Monica",
              "Seaside (Pob.)",
              "Sicsican",
              "Tagburos",
              "Tagumpay (Pob.)",
              "Tanglaw (Pob.)",
              "Tiniguiban"
            ]
          },
          { "k": "Quezon", "v": ["Alfonso Xiii (Pob.)"] },
          { "k": "Riotuba", "v": ["Riotuba"] },
          {
            "k": "Roxas",
            "v": [
              "Abaroan",
              "Barangay 1 (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Caramay",
              "Dumarao",
              "Iraan",
              "Malcampo",
              "Narra (Minara)",
              "New Barbacan (Retac)",
              "San Jose",
              "San Nicolas",
              "Sandoval",
              "Tagumpay"
            ]
          },
          { "k": "San Vicente", "v": ["Poblacion (San Vicente)"] },
          {
            "k": "Sofronio EspaÃ±ola",
            "v": ["Pulot Center", "Pulot Shore (Pulot I)"]
          },
          { "k": "Taytay", "v": ["Abongan", "Poblacion"] }
        ],
        "names": [
          "Aborlan",
          "Bataraza",
          "Brooke's Point",
          "Coron",
          "El Nido (Bacuit)",
          "Narra",
          "Puerto Princesa City",
          "Quezon",
          "Riotuba",
          "Roxas",
          "San Vicente",
          "Sofronio EspaÃ±ola",
          "Taytay"
        ]
      }
    },
    {
      "k": "Pampanga",
      "v": {
        "lookup": [
          {
            "k": "Angeles City",
            "v": [
              "Agapito Del Rosario",
              "Amsic",
              "Anunas",
              "Balibago",
              "Capaya",
              "Claro M. Recto",
              "Cuayan",
              "Cutcut",
              "Cutud",
              "Lourdes North West",
              "Lourdes Sur",
              "Lourdes Sur East",
              "Malabanias",
              "Margot",
              "Mining",
              "Ninoy Aquino (Marisol)",
              "Pampang",
              "Pandan",
              "Pulung Cacutud",
              "Pulung Maragul",
              "Pulungbulu",
              "Salapungan",
              "San Jose",
              "San Nicolas",
              "Santa Teresita",
              "Santa Trinidad",
              "Santo Cristo",
              "Santo Domingo",
              "Santo Rosario (Pob.)",
              "Sapalibutad",
              "Sapangbato",
              "Tabun",
              "Virgen Delos Remedios"
            ]
          },
          {
            "k": "Apalit",
            "v": [
              "Balucuc",
              "Calantipe",
              "Cansinala",
              "Capalangan",
              "Colgante",
              "Paligui",
              "Sampaloc",
              "San Juan (Pob.)",
              "San Vicente",
              "Sucad",
              "Sulipan (Ibaba)",
              "Sulipan (Itaas)",
              "Tabuyuc (Santo Rosario)"
            ]
          },
          {
            "k": "Arayat",
            "v": [
              "Arenas",
              "Baliti",
              "Batasan",
              "Buensuceso",
              "Candating",
              "Cupang (Santa Lucia)",
              "Gatiawin",
              "Guemasan",
              "Kaledian (Camba)",
              "La Paz (Turu)",
              "Lacmit",
              "Lacquios",
              "Mangga-cacutud",
              "Mapalad",
              "Matamo (Santa Lucia)",
              "Panlinlang",
              "Paralaya",
              "Plazang Luma",
              "Poblacion",
              "San Agustin Norte",
              "San Agustin Sur",
              "San Antonio",
              "San Jose Mesulo",
              "San Juan Bano",
              "San Mateo",
              "San Nicolas",
              "San Roque Bitas",
              "Santo NiÃ±o Tabuan",
              "Suclayin",
              "Telapayong"
            ]
          },
          {
            "k": "Bacolor",
            "v": [
              "Balas",
              "Cabalantian",
              "Cabambangan (Pob.)",
              "Cabetican",
              "Calibutbut",
              "Concepcion",
              "Dolores",
              "Duat",
              "Macabacle",
              "Magliman",
              "Maliwalu",
              "Mesalipit",
              "Parulog",
              "Potrero",
              "San Antonio",
              "San Isidro",
              "San Vicente",
              "Santa Barbara",
              "Santa Ines",
              "Talba",
              "Tinajero"
            ]
          },
          {
            "k": "Candaba",
            "v": [
              "Bahay Pare",
              "Bambang",
              "Barit",
              "Buas (Pob.)",
              "Dalayap",
              "Dulong Ilog",
              "Gulap",
              "Lanang",
              "Mandasig",
              "Mangga",
              "Paligui",
              "Pangclara",
              "Pansinao",
              "Paralaya (Pob.)",
              "Pasig",
              "Pescadores (Pob.)",
              "Pulong Palazan",
              "San Agustin (Pob.)",
              "Santo Rosario",
              "Talang",
              "Tenejero",
              "Vizal San Pablo",
              "Vizal Santo Cristo"
            ]
          },
          {
            "k": "Floridablanca",
            "v": [
              "Anon",
              "Apalit",
              "Basa Air Base",
              "Benedicto",
              "Bodega",
              "Cabangcalan",
              "Calantas",
              "Carmencita",
              "Consuelo",
              "Dampe",
              "Del Carmen",
              "Fortuna",
              "Gutad",
              "Mabical",
              "Maligaya",
              "Pabanlag",
              "Paguiruan",
              "Palmayo",
              "Pandaguirig",
              "Poblacion",
              "San Antonio",
              "San Isidro",
              "San Jose",
              "San Nicolas",
              "San Pedro",
              "San Roque",
              "Santa Monica",
              "Santo Rosario (Malabo)",
              "Solib",
              "Valdez"
            ]
          },
          {
            "k": "Guagua",
            "v": [
              "Ascomo",
              "Bancal",
              "Jose Abad Santos (Siran)",
              "Lambac",
              "Magsaysay",
              "Maquiapo",
              "Natividad",
              "Plaza Burgos (Pob.)",
              "Pulungmasle",
              "Rizal",
              "San Agustin",
              "San Antonio",
              "San Isidro",
              "San Jose",
              "San Juan",
              "San Juan Bautista",
              "San Juan Nepomuceno",
              "San Matias",
              "San Miguel (Betis)",
              "San Nicolas 1st",
              "San Nicolas 2nd",
              "San Pablo",
              "San Pedro",
              "San Rafael (Duck Island)",
              "San Roque",
              "San Vicente (Ibus)",
              "Santa Filomena (Pob.)",
              "Santa Ines",
              "Santa Ursula",
              "Santo Cristo",
              "Santo NiÃ±o (Pob.)"
            ]
          },
          {
            "k": "Lubao",
            "v": [
              "Balantacan",
              "Baruya (San Rafael)",
              "Calangain",
              "Concepcion",
              "De La Paz",
              "Del Carmen",
              "Don Ignacio Dimson",
              "Lourdes (Lauc Pau)",
              "Prado Siongco",
              "Remedios",
              "San Agustin",
              "San Antonio",
              "San Francisco",
              "San Isidro",
              "San Jose Apunan",
              "San Jose Gumi",
              "San Juan (Pob)",
              "San Matias",
              "San Miguel",
              "San Nicolas 1st (Pob.)",
              "San Nicolas 2nd",
              "San Pablo 1st",
              "San Pablo 2nd",
              "San Pedro Palcarangan",
              "San Pedro Saug",
              "San Roque Arbol",
              "San Roque Dau",
              "San Vicente",
              "Santa Barbara",
              "Santa Catalina",
              "Santa Cruz",
              "Santa Lucia (Pob.)",
              "Santa Maria",
              "Santa Monica",
              "Santa Rita",
              "Santa Teresa 1st",
              "Santiago",
              "Santo Cristo",
              "Santo Domingo",
              "Santo NiÃ±o (Prado Aruba)",
              "Santo Tomas (Pob.)"
            ]
          },
          {
            "k": "Mabalacat",
            "v": [
              "Atlu-bola",
              "Bical",
              "Bundagul",
              "Cacutud",
              "Calumpang",
              "Camachiles",
              "Dapdap",
              "Dau",
              "Dolores",
              "Duquit",
              "Lakandula",
              "Mabiga",
              "Macapagal Village",
              "Mamatitang",
              "Mangalit",
              "Marcos Village",
              "Mawaque",
              "Paralayunan",
              "Poblacion",
              "San Francisco",
              "San Joaquin",
              "Santa Ines",
              "Santa Maria",
              "Santo Rosario",
              "Sapang Balen",
              "Sapang Biabas",
              "Tabun"
            ]
          },
          { "k": "Mabalacat City", "v": ["Clark Freeport Zone (Cfz)"] },
          {
            "k": "Macabebe",
            "v": [
              "Batasan",
              "Caduang Tete",
              "Candelaria",
              "Castuli",
              "Dalayap",
              "Mataguiti",
              "San Francisco",
              "San Gabriel (Pob.)",
              "San Isidro",
              "San Jose",
              "San Juan",
              "San Rafael",
              "San Roque",
              "San Vicente",
              "Santa Cruz (Pob.)",
              "Santa Lutgarda",
              "Santa Maria",
              "Santa Rita (Pob.)",
              "Santo NiÃ±o",
              "Santo Rosario (Pob.)",
              "Saplad David",
              "Tacasan",
              "Telacsan"
            ]
          },
          {
            "k": "Magalang",
            "v": [
              "Ayala",
              "Bucanan",
              "Camias",
              "Dolores",
              "La Paz",
              "Navaling",
              "San Agustin",
              "San Antonio",
              "San Francisco",
              "San Isidro",
              "San Jose",
              "San Miguel",
              "San Nicolas 1st (Pob.)",
              "San Nicolas 2nd",
              "San Pablo (Pob.)",
              "San Pedro I",
              "San Pedro Ii",
              "San Roque",
              "San Vicente",
              "Santa Cruz (Pob.)",
              "Santa Lucia",
              "Santa Maria",
              "Santo NiÃ±o",
              "Santo Rosario"
            ]
          },
          {
            "k": "Masantol",
            "v": [
              "Bebe Anac",
              "Bebe Matua",
              "Malauli",
              "Palimpe",
              "Sagrada (Tibagin)",
              "San Agustin (Caingin)",
              "San Isidro Matua (Pob.)",
              "San Nicolas (Pob.)",
              "San Pedro",
              "Santa Lucia Anac (Pob.)",
              "Santa Lucia Matua",
              "Santa Lucia Paguiba",
              "Santa Lucia Wakas",
              "Santa Monica (Caingin)",
              "Santo NiÃ±o",
              "Sua"
            ]
          },
          {
            "k": "Mexico",
            "v": [
              "Acli",
              "Anao",
              "Balas",
              "Buenavista",
              "Camuning",
              "Cawayan",
              "Concepcion",
              "Culubasa",
              "Divisoria",
              "Dolores (Piring)",
              "Eden",
              "Gandus",
              "Lagundi",
              "Laput",
              "Laug",
              "Masamat",
              "Masangsang",
              "Nueva Victoria",
              "Pandacaqui",
              "Pangatlan",
              "Panipuan",
              "Parian (Pob.)",
              "Sabanilla",
              "San Antonio",
              "San Carlos",
              "San Jose Malino",
              "San Jose Matulid",
              "San Juan",
              "San Lorenzo",
              "San Miguel",
              "San Nicolas",
              "San Pablo",
              "San Patricio",
              "San Rafael",
              "San Roque",
              "San Vicente",
              "Santa Cruz",
              "Santa Maria",
              "Santo Domingo",
              "Santo Rosario",
              "Sapang Maisac",
              "Suclaban",
              "Tangle (Tanglay)"
            ]
          },
          {
            "k": "Minalin",
            "v": [
              "Bulac",
              "Dawe",
              "Lourdes",
              "Maniango",
              "San Francisco 1st",
              "San Francisco 2nd",
              "San Isidro",
              "San Nicolas (Pob.)",
              "San Pedro",
              "Santa Catalina",
              "Santa Maria",
              "Santa Rita",
              "Santo Domingo",
              "Santo Rosario (Pob.)",
              "Saplad"
            ]
          },
          {
            "k": "Porac",
            "v": [
              "Babo Pangulo",
              "Babo Sacan",
              "Balubad",
              "Calzadang Bayu",
              "Camias",
              "Cangatba",
              "Diaz",
              "Dolores (Hacienda Dolores)",
              "Jalung",
              "Mancatian",
              "Manibaug Libutad",
              "Manibaug Paralaya",
              "Manibaug Pasig",
              "Manuali",
              "Mitla Proper",
              "Palat",
              "Pias",
              "Pio",
              "Planas",
              "Poblacion",
              "Pulong Santol",
              "Salu",
              "San Jose Mitla",
              "Santa Cruz",
              "Sepung Bulaun",
              "Sinura",
              "Villa Maria (Aetas)"
            ]
          },
          {
            "k": "San Fernando City",
            "v": [
              "Alasas",
              "Baliti",
              "Bulaon",
              "Calulut",
              "Del Carmen",
              "Del Pilar",
              "Del Rosario",
              "Dela Paz Norte",
              "Dela Paz Sur",
              "Dolores",
              "Juliana",
              "Lara",
              "Lourdes",
              "Magliman",
              "Maimpis",
              "Malino",
              "Malpitic",
              "Pandaras",
              "Panipuan",
              "Pulung Bulu",
              "Quebiauan",
              "Saguin",
              "San Agustin",
              "San Felipe",
              "San Isidro",
              "San Jose",
              "San Juan",
              "San Nicolas",
              "San Pedro",
              "Santa Lucia",
              "Santa Teresita",
              "Santo NiÃ±o",
              "Santo Rosario (Pob.)",
              "Sindalan",
              "Telabastagan"
            ]
          },
          {
            "k": "San Luis",
            "v": [
              "San Agustin",
              "San Carlos",
              "San Isidro",
              "San Jose",
              "San Juan",
              "San Nicolas",
              "San Roque",
              "San Sebastian",
              "Santa Catalina",
              "Santa Cruz Pambilog",
              "Santa Cruz Poblacion",
              "Santa Lucia",
              "Santa Monica",
              "Santa Rita",
              "Santo NiÃ±o",
              "Santo Rosario",
              "Santo Tomas"
            ]
          },
          {
            "k": "San Simon",
            "v": [
              "Concepcion",
              "De La Paz",
              "San Agustin",
              "San Isidro",
              "San Jose",
              "San Juan (Pob.)",
              "San Miguel",
              "San Nicolas",
              "San Pablo Libutad",
              "San Pablo Proper",
              "San Pedro",
              "Santa Cruz",
              "Santa Monica",
              "Santo NiÃ±o"
            ]
          },
          {
            "k": "Santa Ana",
            "v": [
              "San Agustin",
              "San Bartolome",
              "San Isidro",
              "San Joaquin (Pob.)",
              "San Jose",
              "San Juan",
              "San Nicolas",
              "San Pablo",
              "San Pedro",
              "San Roque",
              "Santa Lucia",
              "Santa Maria",
              "Santiago",
              "Santo Rosario"
            ]
          },
          {
            "k": "Santa Rita",
            "v": [
              "Becuran",
              "Dila-dila",
              "San Agustin",
              "San Basilio",
              "San Isidro",
              "San Jose (Pob.)",
              "San Juan",
              "San Matias",
              "San Vicente",
              "Santa Monica"
            ]
          },
          {
            "k": "Santo Tomas",
            "v": [
              "Moras De La Paz",
              "Poblacion",
              "San Bartolome",
              "San Matias",
              "San Vicente",
              "Santo Rosario (Pau)",
              "Sapa (Santo NiÃ±o)"
            ]
          },
          {
            "k": "Sasmuan (Sexmoan)",
            "v": [
              "Batang 1st",
              "Batang 2nd",
              "San Antonio",
              "San Nicolas 1st",
              "San Nicolas 2nd",
              "San Pedro",
              "Santa Lucia (Pob.)",
              "Santa Monica",
              "Santo Tomas"
            ]
          }
        ],
        "names": [
          "Angeles City",
          "Apalit",
          "Arayat",
          "Bacolor",
          "Candaba",
          "Floridablanca",
          "Guagua",
          "Lubao",
          "Mabalacat",
          "Mabalacat City",
          "Macabebe",
          "Magalang",
          "Masantol",
          "Mexico",
          "Minalin",
          "Porac",
          "San Fernando City",
          "San Luis",
          "San Simon",
          "Santa Ana",
          "Santa Rita",
          "Santo Tomas",
          "Sasmuan (Sexmoan)"
        ]
      }
    },
    {
      "k": "Pangasinan",
      "v": {
        "lookup": [
          {
            "k": "Agno",
            "v": [
              "Allabon",
              "Aloleng",
              "Bangan-oda",
              "Baruan",
              "Boboy",
              "Cayungnan",
              "Dangley",
              "Gayusan",
              "Macaboboni",
              "Magsaysay",
              "Patar",
              "Poblacion East",
              "Poblacion West",
              "San Juan",
              "Tupa",
              "Viga"
            ]
          },
          {
            "k": "Aguilar",
            "v": [
              "Bayaoas",
              "Baybay",
              "Bocacliw",
              "Bocboc West",
              "Buer",
              "Calsib",
              "Laoag",
              "Manlocboc",
              "Ninoy",
              "Poblacion",
              "Pogomboa",
              "Pogonsili",
              "Tampac"
            ]
          },
          {
            "k": "Alaminos City",
            "v": [
              "Alos",
              "Amandiego",
              "Amangbangan",
              "Balangobong",
              "Balayang",
              "Baleyadaan",
              "Bisocol",
              "Bolaney",
              "Bued",
              "Cabatuan",
              "Cayucay",
              "Dulacac",
              "Inerangan",
              "Landoc",
              "Linmansangan",
              "Lucap",
              "Lucap Inansuana",
              "Lucap National Road To Warf",
              "Lucap Recudo",
              "Lucap Sitio Galiz",
              "Maawi",
              "Macatiw",
              "Magsaysay",
              "Mona",
              "Palamis",
              "Pandan",
              "Pangapisan",
              "Poblacion",
              "Pocalpocal",
              "Pogo",
              "Polo",
              "Quibuar",
              "San Antonio (R. Magsaysay)",
              "San Jose",
              "San Roque",
              "San Vicente",
              "Santa Maria",
              "Tanaytay",
              "Tangcarang",
              "Tawintawin",
              "Telbang",
              "Victoria"
            ]
          },
          {
            "k": "Alcala",
            "v": [
              "Anulid",
              "Bersamin",
              "Canarvacanan",
              "Kasikis",
              "Laoac",
              "Pindangan Centro",
              "Pindangan East",
              "Pindangan West",
              "Poblacion East",
              "Poblacion West",
              "San Juan",
              "San Nicolas",
              "San Pedro Apartado",
              "San Pedro Ili",
              "San Vicente",
              "Vacante"
            ]
          },
          {
            "k": "Anda",
            "v": [
              "Awag",
              "Awile",
              "Cabungan",
              "Carot",
              "Catubig",
              "Dolaoan",
              "Imbo",
              "Macaleeng",
              "Macandocandong",
              "Mal-ong",
              "Namagbagan",
              "Poblacion",
              "Roxas",
              "Sablig",
              "San Jose",
              "Tondol",
              "Toritori"
            ]
          },
          {
            "k": "Asingan",
            "v": [
              "Ariston Este",
              "Ariston Weste",
              "Bantog",
              "Baro",
              "Bobonan",
              "Cabalitian",
              "Calepaan",
              "Carosucan Norte",
              "Carosucan Sur",
              "Coldit",
              "Domanpot",
              "Dupac",
              "Macalong",
              "Palaris",
              "Poblacion East",
              "Poblacion West",
              "San Vicente East",
              "San Vicente West",
              "Sanchez",
              "Sobol",
              "Toboy"
            ]
          },
          {
            "k": "Balungao",
            "v": [
              "Angayan Norte",
              "Angayan Sur",
              "Capulaan",
              "Esmeralda",
              "Kita-kita",
              "Mauban",
              "Poblacion",
              "Pugaro",
              "Rajal",
              "San Andres",
              "San Aurelio 1st",
              "San Aurelio 2nd",
              "San Aurelio 3rd",
              "San Joaquin",
              "San Julian",
              "San Leon",
              "San Marcelino",
              "San Miguel",
              "San Raymundo"
            ]
          },
          {
            "k": "Bani",
            "v": [
              "Ambabaay",
              "Aporao",
              "Arwas",
              "Ballag",
              "Banog Norte",
              "Banog Sur",
              "Calabeng",
              "Centro Toma",
              "Colayo",
              "Dacap Norte",
              "Dacap Sur",
              "Garrita",
              "Luac",
              "Macabit",
              "Masidem",
              "Poblacion",
              "Quinaoayanan",
              "Ranao",
              "Ranom Iloco",
              "San Jose",
              "San Miguel",
              "San Vicente",
              "Tiep",
              "Tipor",
              "Tugui Grande",
              "Tugui Norte"
            ]
          },
          {
            "k": "Basista",
            "v": [
              "Anambongan",
              "Bayoyong",
              "Cabeldatan",
              "Dumpay",
              "Malimpec East",
              "Nalneran",
              "Navatat",
              "Obong",
              "Osmena Sr.",
              "Palma",
              "Patacbo",
              "Poblacion"
            ]
          },
          {
            "k": "Bautista",
            "v": [
              "Artacho",
              "Baluyot",
              "Cabuaan",
              "Cacandongan",
              "Ketegan",
              "Nandacan",
              "Nibaliw Norte",
              "Nibaliw Sur",
              "Palisoc",
              "Poblacion East",
              "Poblacion West",
              "Sinabaan",
              "Vacante"
            ]
          },
          {
            "k": "Bayambang",
            "v": [
              "Alinggan",
              "Amamperez",
              "Amancosiling Norte",
              "Amancosiling Sur",
              "Apalen",
              "Asin",
              "Ataynan",
              "Bacnono",
              "Balaybuaya",
              "Banaban",
              "Bani",
              "Bical Norte",
              "Bical Sur",
              "Bongato East",
              "Bongato West",
              "Buayaen",
              "Buenlag 1st",
              "Buenlag 2nd",
              "Cadre Site",
              "Ligue",
              "M. H. Del Pilar",
              "Magsaysay",
              "Malimpec",
              "Mangayao",
              "Nalsian Norte",
              "Nalsian Sur",
              "Pangdel",
              "Poblacion Sur",
              "Sangcagulis",
              "Sapang",
              "Tamaro",
              "Tambac",
              "Tatarao",
              "Telbang",
              "Tococ East",
              "Tococ West",
              "Zone I (Pob.)",
              "Zone Ii (Pob.)",
              "Zone Iii (Pob.)",
              "Zone Iv (Pob.)",
              "Zone V (Pob.)",
              "Zone Vi (Pob.)",
              "Zone Vii (Pob.)"
            ]
          },
          {
            "k": "Binalonan",
            "v": [
              "Balangobong",
              "Bued",
              "Bugayong",
              "Camangaan",
              "Canarvacanan",
              "Capas",
              "Cili",
              "Dumayat",
              "Linmansangan",
              "Mangcasuy",
              "Moreno",
              "Pasileng Norte",
              "Pasileng Sur",
              "Poblacion",
              "San Felipe Central",
              "San Felipe Sur",
              "San Pablo",
              "Santa Catalina",
              "Santa Maria Norte",
              "Santiago",
              "Santo NiÃ±o",
              "Sumabnit",
              "Tabuyoc",
              "Vacante"
            ]
          },
          {
            "k": "Binmaley",
            "v": [
              "Amancoro",
              "Balagan",
              "Balogo",
              "Basing",
              "Baybay Lopez",
              "Baybay Polong",
              "Biec",
              "Buenlag",
              "Calit",
              "Caloocan Dupo",
              "Caloocan Norte",
              "Caloocan Sur",
              "Camaley",
              "Canaoalan",
              "Dulag",
              "Gayaman",
              "Linoc",
              "Lomboy",
              "Malindong",
              "Manat",
              "Nagpalangan",
              "Naguilayan",
              "Pallas",
              "Papagueyan",
              "Parayao",
              "Poblacion",
              "San Isidro Norte",
              "San Isidro Sur",
              "Santa Rosa",
              "Tombor"
            ]
          },
          {
            "k": "Bolinao",
            "v": [
              "Arnedo",
              "Balingasay",
              "Cabuyao",
              "Catuday",
              "Catungi",
              "Concordia (Pob.)",
              "Culang",
              "Estanza",
              "Germinal (Pob.)",
              "Ilogmalino",
              "Lambes (Lames)",
              "Liwa-liwa",
              "Luciente 1.0",
              "Luciente 2.0",
              "Luna",
              "Patar",
              "Samang Norte",
              "Samang Sur",
              "Sampaloc",
              "Tara",
              "Victory",
              "Zaragoza"
            ]
          },
          {
            "k": "Bugallon",
            "v": [
              "Asinan",
              "Bacabac",
              "Banaga",
              "Bolaoen",
              "Buenlag",
              "Cabayaoasan",
              "Cayanga",
              "Gueset",
              "Hacienda",
              "Laguit Centro",
              "Laguit Padilla",
              "Magtaking",
              "Pantal",
              "Poblacion",
              "Polong",
              "Portic",
              "Salasa",
              "Salomague Norte",
              "Salomague Sur",
              "Samat",
              "Umanday"
            ]
          },
          {
            "k": "Burgos",
            "v": [
              "Anapao (Bur Anapac)",
              "Cacayasen",
              "Concordia",
              "Don Matias",
              "Ilio-ilio (Iliw-iliw)",
              "Papallasen",
              "Poblacion",
              "Pogoruac",
              "San Pascual",
              "San Vicente",
              "Tambacan"
            ]
          },
          {
            "k": "Calasiao",
            "v": [
              "Ambonao",
              "Ambuetel",
              "Banaoang",
              "Bued",
              "Buenlag",
              "Cabilocaan",
              "Dinalaoan",
              "Doyong",
              "Gabon",
              "Lasip",
              "Longos",
              "Lumbang",
              "Macabito",
              "Malabago",
              "Mancup",
              "Nagsaing",
              "Nalsian",
              "Poblacion East",
              "Poblacion West",
              "Quesban",
              "San Miguel",
              "San Vicente",
              "Songkoy",
              "Talibaew"
            ]
          },
          {
            "k": "Dagupan",
            "v": [
              "Bacayao Norte",
              "Bacayao Sur",
              "Barangay I (T. Bugallon)",
              "Barangay Ii (Nueva)",
              "Barangay Iv (Zamora)",
              "Bolosan",
              "Bonuan Binloc",
              "Bonuan Boquig",
              "Bonuan Gueset",
              "Caranglaan",
              "Herrero",
              "Lasip Chico",
              "Lasip Grande",
              "Lucao",
              "Malued",
              "Mamalingling",
              "Mangin",
              "Mayombo",
              "Pantal",
              "Poblacion Oeste",
              "Pogo Chico",
              "Pogo Grande",
              "Salisay",
              "Tambac",
              "Tapuac",
              "Tebeng"
            ]
          },
          {
            "k": "Dasol",
            "v": [
              "Alilao",
              "Amalbalan",
              "Bobonot",
              "Eguia",
              "Gais-guipe",
              "Hermosa",
              "Macalang",
              "OsmeÃ±a",
              "Petal",
              "Poblacion",
              "San Vicente",
              "Tambobong",
              "Uli",
              "Viga"
            ]
          },
          {
            "k": "Infanta",
            "v": [
              "Babuyan",
              "Bamban",
              "Batang",
              "Bayambang",
              "Cato",
              "Doliman",
              "Fatima",
              "Maya",
              "Nangalisan",
              "Nayom",
              "Pita",
              "Poblacion",
              "Potol"
            ]
          },
          {
            "k": "Labrador",
            "v": [
              "Bolo",
              "Bongalon",
              "Dulig",
              "Laois",
              "Magsaysay",
              "Poblacion",
              "San Gonzalo",
              "San Jose",
              "Tobuan",
              "Uyong"
            ]
          },
          {
            "k": "Laoac",
            "v": [
              "Anis",
              "Balligi",
              "Banuar",
              "Botique",
              "Caaringayan",
              "Cabilaoan West",
              "Cabulalaan",
              "Calaoagan",
              "Calmay",
              "Casampagaan",
              "Casanestebanan",
              "Casantiagoan",
              "Domingo Alarcio (Cabilaoan East)",
              "Inmanduyan",
              "Lebueg",
              "Maraboc",
              "Nanbagatan",
              "Poblacion (Laoac)",
              "Talogtog",
              "Turko",
              "Yatyat"
            ]
          },
          {
            "k": "Lingayen",
            "v": [
              "Baay",
              "Balangobong",
              "Balococ",
              "Bantayan",
              "Basing",
              "Capandanan",
              "Domalandan Center",
              "Domalandan East",
              "Domalandan West",
              "Dorongan",
              "Dulag",
              "Estanza",
              "Lasip",
              "Libsong East",
              "Libsong West",
              "Malimpuec",
              "Maniboc",
              "Matalava",
              "Naguelguel",
              "Namolan",
              "Pangapisan North",
              "Pangapisan Sur",
              "Poblacion",
              "Quibaol",
              "Sabangan",
              "Talogtog",
              "Tonton",
              "Tumbar"
            ]
          },
          {
            "k": "Mabini",
            "v": [
              "De Guzman",
              "Luna",
              "Magalong",
              "Nibaliw",
              "Patar",
              "Poblacion",
              "San Pedro",
              "Tagudin"
            ]
          },
          {
            "k": "Malasiqui",
            "v": [
              "Alacan",
              "Anolid",
              "Apaya",
              "Banawang",
              "Barang",
              "Bawer",
              "Binalay",
              "Bobon",
              "Bolaoit",
              "Butao",
              "Cabatling",
              "Cabueldatan",
              "Calbueg",
              "Cawayan Bogtong",
              "Don Pedro",
              "Gomez",
              "Guilig",
              "Ican",
              "Ingalagala",
              "Lasip",
              "Loqueb Este",
              "Loqueb Norte",
              "Loqueb Sur",
              "Mabulitec",
              "Malimpec",
              "Nalsian Norte",
              "Nalsian Sur",
              "Nansangaan",
              "Palong",
              "Pasima",
              "Payar",
              "Poblacion",
              "Polong Norte",
              "Polong Sur",
              "San Julian",
              "Tabo-sili",
              "Talospatang",
              "Taloy",
              "Tambac",
              "Tomling",
              "Viado",
              "Warey"
            ]
          },
          {
            "k": "Manaoag",
            "v": [
              "Babasit",
              "Baguinay",
              "Baritao",
              "Bisal",
              "Bucao",
              "Cabanbanan",
              "Calaocan",
              "Inamotan",
              "Lelemaan",
              "Licsi",
              "Lipit Norte",
              "Lipit Sur",
              "Matolong",
              "Mermer",
              "Nalsian",
              "Oraan East",
              "Oraan West",
              "Pantal",
              "Pao",
              "Parian",
              "Poblacion",
              "Pugaro",
              "San Ramon",
              "Santa Ines",
              "Sapang",
              "Tebuel"
            ]
          },
          {
            "k": "Mangaldan",
            "v": [
              "Alitaya",
              "Amansabina",
              "Anolid",
              "Banaoang",
              "Bantayan",
              "Bari",
              "Bateng",
              "Buenlag",
              "David",
              "Embarcadero",
              "Gueguesangen",
              "Guesang",
              "Guiguilonen",
              "Guilig",
              "Inlambo",
              "Lanas",
              "Landas",
              "Maasin",
              "Macayug",
              "Malabago",
              "Navaluan",
              "Nibaliw",
              "Osiem",
              "Palua",
              "Poblacion",
              "Pogo",
              "Salaan",
              "Salay",
              "Talogtog",
              "Tebag"
            ]
          },
          {
            "k": "Mangatarem",
            "v": [
              "Andangin",
              "Arellano Street (Pob.)",
              "Baracbac",
              "Bogtong Bolo",
              "Bogtong Bunao",
              "Bogtong Centro",
              "Bogtong Niog",
              "Bogtong Silag",
              "Bueno",
              "Bunlalacao",
              "Burgos Street (Pob.)",
              "Cabarabuan",
              "Cabaruan",
              "Calvo (Pob.)",
              "Casilagan",
              "Caviernesan",
              "Dorongan Ketaket",
              "Dorongan Linmansangan",
              "Dorongan Punta",
              "Dorongan Sawat",
              "Dorongan Valerio",
              "General Luna (Pob.)",
              "Historia",
              "Linmansangan",
              "Lopez (Pob.)",
              "Mabini (Pob.)",
              "Macarang",
              "Malunec",
              "Maravilla (Pob.)",
              "Maravilla-arellano Ext. (Pob.)",
              "Naguilayan East",
              "Naguilayan West",
              "Nancasalan",
              "Olegario-caoile (Pob.)",
              "Olo Cacamposan",
              "Olo Cafabrosan",
              "Olo Cagarlitan",
              "OsmeÃ±a (Pob.)",
              "Paul",
              "Pogon-lomboy (Pob.)",
              "Ponglo-muelag",
              "Quetegan (Pogon-baleg)",
              "Quezon (Pob.)",
              "Sonson Ongkit",
              "Suaco",
              "Talogtog",
              "Torre 1st",
              "Torre 2nd",
              "Torres Bugallon (Pob.)",
              "Umangan",
              "Zamora (Pob.)"
            ]
          },
          {
            "k": "Mapandan",
            "v": [
              "Amanoaoac",
              "Apaya",
              "Aserda",
              "Baloling",
              "Coral",
              "Golden",
              "Jimenez",
              "Lambayan",
              "Luyan (Luyan South)",
              "Nilombot",
              "Pias",
              "Poblacion",
              "Primicias",
              "Santa Maria (Luyan North)",
              "Torres"
            ]
          },
          {
            "k": "Natividad",
            "v": [
              "Barangobong",
              "Batchelor East",
              "Batchelor West",
              "Burgos (San Narciso)",
              "Cacandungan",
              "Calapugan",
              "Canarem",
              "Poblacion East",
              "Poblacion West",
              "Rizal",
              "Salud",
              "San Eugenio",
              "San Macario Norte",
              "San Macario Sur",
              "San Maximo",
              "San Miguel"
            ]
          },
          {
            "k": "Pozorrubio",
            "v": [
              "Alipangpang",
              "Amagbagan",
              "Balacag",
              "Banding",
              "Bantugan",
              "Batakil",
              "Bobonan",
              "Buneg",
              "Cablong",
              "Dilan",
              "Don Benito",
              "Imbalbalatong",
              "Inoman",
              "Laoac",
              "Maambal",
              "Malasin",
              "Manaol",
              "Nama",
              "Nantangalan",
              "Palacpalac",
              "Palguyod",
              "Poblacion I",
              "Poblacion Ii",
              "Poblacion Iii",
              "Poblacion Iv",
              "Rosario",
              "Sugcong",
              "Talogtog",
              "Tulnac",
              "Villegas"
            ]
          },
          {
            "k": "Rosales",
            "v": [
              "Acop",
              "Bakitbakit",
              "Balingcanaway",
              "Cabalaoangan Norte",
              "Cabalaoangan Sur",
              "Calanutan (Don Felix Coloma)",
              "Camangaan",
              "Capitan Tomas",
              "Carmay East",
              "Carmay West",
              "Carmen East",
              "Carmen West",
              "Casanicolasan",
              "Coliling",
              "Don Antonio Village",
              "Guiling",
              "Palakipak",
              "Pangaoan",
              "Rabago",
              "Rizal",
              "Salvacion",
              "San Angel",
              "San Antonio",
              "San Bartolome",
              "San Isidro",
              "San Luis",
              "San Pedro East",
              "San Pedro West",
              "Station District",
              "Tomana East",
              "Tomana West",
              "Zone I (Pob.)",
              "Zone Ii (Pob.)",
              "Zone Iii (Pob.)",
              "Zone Iv (Pob.)",
              "Zone V (Pob.)"
            ]
          },
          {
            "k": "San Carlos",
            "v": [
              "Abanon",
              "Agdao",
              "Ano",
              "Aponit",
              "Balayong",
              "Baldog",
              "Balococ",
              "Bega",
              "Bolingit",
              "Bolosan",
              "Bonifacio (Pob.)",
              "Buenglat",
              "Bugallon-posadas Street (Pob.)",
              "Burgos Padlan (Pob.)",
              "Cacaritan",
              "Caingal",
              "Calobaoan",
              "Calomboyan",
              "Caoayan-kiling",
              "Capataan",
              "Cobol",
              "Coliling",
              "Cruz",
              "Doyong",
              "Ilang",
              "Inerangan",
              "Isla",
              "Libas",
              "Lilimasan",
              "Longos",
              "Lucban (Pob.)",
              "M. Soriano",
              "Mabini (Pob.)",
              "Magtaking",
              "MalacaÃ±ang",
              "Maliwara",
              "Mamarlao",
              "Manzon",
              "Matagdem",
              "Mestizo Norte",
              "Naguilayan",
              "Nilentap",
              "Padilla-gomez",
              "Pagal",
              "Paitan-panoypoy",
              "Palaming",
              "Palaris (Pob.)",
              "Pangalangan",
              "Pangoloan",
              "Pangpang",
              "Parayao",
              "Payapa",
              "Payar",
              "Perez Boulevard (Pob.)",
              "Pnr Station Site",
              "Polo",
              "Quezon Boulevard (Pob.)",
              "Quintong",
              "Rizal (Pob.)",
              "Roxas Boulevard (Pob.)",
              "San Juan",
              "San Pedro-taloy",
              "Sapinit",
              "Talang",
              "Tandang Sora",
              "Tandoc",
              "Tarece",
              "Tarectec",
              "Tayambani",
              "Tebag",
              "Turac"
            ]
          },
          {
            "k": "San Fabian",
            "v": [
              "Angio",
              "Anonang",
              "Aramal",
              "Binday",
              "Bolasi",
              "Cabaruan",
              "Cayanga",
              "Lekep-butao",
              "Longos",
              "Longos Proper",
              "Longos-amangonan-parac-parac Fabrica",
              "Mabilao",
              "Nibaliw Central",
              "Nibaliw East",
              "Nibaliw Magliba",
              "Nibaliw Narvarte (Nibaliw West Compound)",
              "Nibaliw Vidal (Nibaliw West Proper)",
              "Poblacion",
              "Sagud-bahley",
              "Sobol",
              "Tempra-guilig",
              "Tocok"
            ]
          },
          {
            "k": "San Jacinto",
            "v": [
              "Bagong Pag-asa (Poblacion East)",
              "Capaoay (Pob.)",
              "Casibong",
              "Guibel",
              "Imelda (Decrito)",
              "Macayug",
              "Magsaysay (Capay)",
              "San Guillermo (Poblacion West)",
              "San Jose",
              "San Roque",
              "San Vicente",
              "Santa Maria",
              "Santo Tomas"
            ]
          },
          {
            "k": "San Manuel",
            "v": [
              "Cabacaraan",
              "Cabaritan",
              "Flores",
              "Guiset Norte (Pob.)",
              "Guiset Sur (Pob.)",
              "Nagsaag",
              "Narra",
              "San Antonio-arzadon",
              "San Bonifacio",
              "San Juan",
              "San Roque",
              "San Vicente",
              "Santo Domingo"
            ]
          },
          {
            "k": "San Nicolas",
            "v": [
              "Cabitnongan",
              "Caboloan",
              "Cacabugaoan",
              "Calanutian",
              "Calaocan",
              "Camanggaan",
              "Camindoroan",
              "Casaratan",
              "Dalumpinas",
              "Lungao",
              "Malilion",
              "Nagkaysa",
              "Nining",
              "Poblacion East",
              "Poblacion West",
              "Salingcob",
              "San Isidro (Sta. Cruzan)",
              "San Jose",
              "San Rafael Centro",
              "San Rafael East",
              "San Rafael West",
              "San Roque",
              "Santa Maria East",
              "Santa Maria West",
              "Santo Tomas",
              "Siblot",
              "Sobol"
            ]
          },
          {
            "k": "San Quintin",
            "v": [
              "Alac",
              "Baligayan",
              "Bantog",
              "Bolintaguen",
              "Cabalaoangan",
              "Cabangaran",
              "Calomboyan",
              "Carayacan",
              "Casantamarian",
              "Gonzalo",
              "Labuan",
              "Lagasit",
              "Lumayao",
              "Mabini",
              "Mantacdang",
              "Nangapugan",
              "Poblacion Zone I",
              "Poblacion Zone Ii",
              "Poblacion Zone Iii",
              "San Pedro",
              "Ungib"
            ]
          },
          {
            "k": "Santa Barbara",
            "v": [
              "Alibago",
              "Banaoang",
              "Banzal",
              "Botao",
              "Cablong",
              "Carusocan",
              "Dalongue",
              "Erfe",
              "Gueguesangen",
              "Leet",
              "Malanay",
              "Maningding",
              "Maronong",
              "Maticmatic",
              "Minien East",
              "Minien West",
              "Nilombot",
              "Patayac",
              "Payas",
              "Poblacion Norte",
              "Poblacion Sur",
              "Primicias (Ventinilla West)",
              "Sapang",
              "Sonquil",
              "Tebag East",
              "Tebag West",
              "Tuliao",
              "Ventinilla (Ventinilla East)"
            ]
          },
          {
            "k": "Santa Maria",
            "v": [
              "Bal-loy",
              "Bantog",
              "Caboluan",
              "Cal-litang",
              "Capandanan",
              "Cauplasan",
              "Dalayap",
              "Libsong",
              "Namagbagan",
              "Paitan",
              "Pataquid",
              "Pilar",
              "Poblacion East",
              "Poblacion West",
              "Pugot",
              "Samon",
              "San Alejandro",
              "San Mariano",
              "San Pablo",
              "San Patricio",
              "San Vicente",
              "Santa Cruz",
              "Santa Rosa"
            ]
          },
          {
            "k": "Santo Tomas",
            "v": [
              "La Luna",
              "Poblacion East",
              "Poblacion West",
              "Salvacion",
              "San Agustin",
              "San Antonio",
              "San Jose",
              "San Marcos",
              "Santo Domingo",
              "Santo NiÃ±o"
            ]
          },
          {
            "k": "Sison",
            "v": [
              "Agat",
              "Alibeng",
              "Amagbagan",
              "Artacho",
              "Asan Norte",
              "Asan Sur",
              "Bantay Insik",
              "Bila",
              "Binmeckeg",
              "Calunetan",
              "Camangaan",
              "Cauringan",
              "Dungon",
              "Esperanza",
              "Labayug",
              "Paldit",
              "Pinmilapil",
              "Poblacion Central",
              "Poblacion Norte",
              "Poblacion Sur"
            ]
          },
          {
            "k": "Sual",
            "v": [
              "Baquioen",
              "Baybay Norte",
              "Baybay Sur",
              "Bolaoen",
              "Calumbuyan",
              "Camagsingalan",
              "Caoayan",
              "Capantolan",
              "Macaycayawan",
              "Paitan East",
              "Paitan West",
              "Pangascasan",
              "Poblacion",
              "Santo Domingo",
              "Seselangen",
              "Sioasio East",
              "Sioasio West",
              "Victoria"
            ]
          },
          {
            "k": "Tayug",
            "v": [
              "Agno",
              "Amistad",
              "Barangay A (Pob.)",
              "Barangay B (Pob.)",
              "Barangay C (Pob.)",
              "Barangay D (Pob.)",
              "Barangobong",
              "C. Lichauco",
              "Carriedo",
              "Evangelista",
              "Lawak",
              "Legaspi",
              "Libertad",
              "Magallanes",
              "Panganiban",
              "Saleng",
              "Santo Domingo",
              "Toketec",
              "Trenchera",
              "Zamora"
            ]
          },
          {
            "k": "Umingan",
            "v": [
              "Abot Molina",
              "Alo-o",
              "Amaronan",
              "Annam",
              "Bantug",
              "Baracbac",
              "Barat",
              "Buenavista",
              "Cabalitian",
              "Cabangaran",
              "Cabaruan",
              "Cabatuan",
              "Cadiz",
              "Calitlitan",
              "Capas",
              "Carayungan Sur",
              "Carosalesan",
              "Casilan",
              "Caurdanetaan",
              "Concepcion",
              "Decreto",
              "Del Rosario",
              "Diket",
              "Don Justo Abalos (Caroan)",
              "Don Montano",
              "Esperanza",
              "Evangelista",
              "Flores",
              "Fulgosino",
              "Gonzales",
              "La Paz",
              "Labuan",
              "Lauren",
              "Lubong",
              "Mantacdang",
              "Maseil-seil",
              "Nampalcan",
              "Nancalabasaan",
              "Papallasen",
              "Pemienta",
              "Poblacion East",
              "Poblacion West",
              "Prado",
              "Resurreccion",
              "Ricos",
              "San Andres",
              "San Juan",
              "San Leon",
              "San Pablo",
              "San Vicente",
              "Santa Maria",
              "Santa Rosa",
              "Sinabaan",
              "Tanggal Sawang"
            ]
          },
          { "k": "Urbiztondo", "v": ["Bayaoas", "Dalangiring", "Poblacion"] },
          {
            "k": "Urdaneta City",
            "v": [
              "Anonas",
              "Bactad East",
              "Bayaoas",
              "Bolaoen",
              "Cabaruan",
              "Cabuloan",
              "Camanang",
              "Camantiles",
              "Casantaan",
              "Catablan",
              "Cayambanan",
              "Consolacion",
              "Dilan Paurido",
              "Dr. Pedro T. Orata (Bactad Proper)",
              "Labit Proper",
              "Labit West",
              "Mabanogbog",
              "Macalong",
              "Nancalobasaan",
              "Nancamaliran East",
              "Nancamaliran West",
              "Nancayasan",
              "Oltama",
              "Palina East",
              "Palina West",
              "Pinmaludpod",
              "Poblacion",
              "San Jose",
              "San Vicente",
              "Santa Lucia",
              "Santo Domingo",
              "Sugcong",
              "Tipuso",
              "Tulong"
            ]
          },
          {
            "k": "Villasis",
            "v": [
              "Amamperez",
              "Bacag",
              "Barangobong",
              "Barraca",
              "Capulaan",
              "Caramutan",
              "Labit",
              "Lipay",
              "Lomboy",
              "Piaz (Plaza)",
              "Puelay",
              "San Blas",
              "San Nicolas",
              "Tombod",
              "Unzad",
              "Zone I (Pob.)",
              "Zone Ii (Pob.)",
              "Zone Iii (Pob.)",
              "Zone Iv (Pob.)",
              "Zone V (Pob.)"
            ]
          }
        ],
        "names": [
          "Agno",
          "Aguilar",
          "Alaminos City",
          "Alcala",
          "Anda",
          "Asingan",
          "Balungao",
          "Bani",
          "Basista",
          "Bautista",
          "Bayambang",
          "Binalonan",
          "Binmaley",
          "Bolinao",
          "Bugallon",
          "Burgos",
          "Calasiao",
          "Dagupan",
          "Dasol",
          "Infanta",
          "Labrador",
          "Laoac",
          "Lingayen",
          "Mabini",
          "Malasiqui",
          "Manaoag",
          "Mangaldan",
          "Mangatarem",
          "Mapandan",
          "Natividad",
          "Pozorrubio",
          "Rosales",
          "San Carlos",
          "San Fabian",
          "San Jacinto",
          "San Manuel",
          "San Nicolas",
          "San Quintin",
          "Santa Barbara",
          "Santa Maria",
          "Santo Tomas",
          "Sison",
          "Sual",
          "Tayug",
          "Umingan",
          "Urbiztondo",
          "Urdaneta City",
          "Villasis"
        ]
      }
    },
    {
      "k": "Quezon",
      "v": {
        "lookup": [
          {
            "k": "Agdangan",
            "v": [
              "Binagbag",
              "Dayap",
              "Ibabang Kinagunan",
              "Ilayang Kinagunan",
              "Kanlurang Calutan",
              "Kanlurang Maligaya",
              "Poblacion I",
              "Poblacion Ii",
              "Salvacion",
              "Silangang Calutan",
              "Silangang Maligaya",
              "Sildora"
            ]
          },
          {
            "k": "Atimonan",
            "v": [
              "Angeles",
              "Balubad",
              "Balugohin",
              "Barangay Zone 1 (Pob.)",
              "Barangay Zone 2 (Pob.)",
              "Barangay Zone 3 (Pob.)",
              "Barangay Zone 4 (Pob.)",
              "Buhangin",
              "Caridad Ibaba",
              "Caridad Ilaya",
              "Habingan",
              "Inaclagan",
              "Inalig",
              "Kilawit",
              "Kulawit",
              "Lakip",
              "Lubi",
              "Lumutan",
              "Magsaysay",
              "Malinao Ibaba",
              "Malinao Ilaya",
              "Malusak",
              "Manggalayan Bundok",
              "Manggalayan Labak",
              "Matanag",
              "Montes Balaon",
              "Montes Kallagan",
              "Ponon",
              "Rizal",
              "San Andres Bundok",
              "San Andres Labak",
              "San Isidro",
              "San Jose Balatok",
              "San Rafael",
              "Santa Catalina",
              "Sapaan",
              "Sokol",
              "Tagbakin",
              "Talaba",
              "Tinandog",
              "Villa Ibaba",
              "Villa Ilaya"
            ]
          },
          {
            "k": "Buenavista",
            "v": ["Bukal", "Cawa", "Hagonghong", "Poblacion"]
          },
          {
            "k": "Calauag",
            "v": [
              "Baclaran",
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Barangay V (Pob.)",
              "Binutas",
              "Biyan",
              "Pinagbayanan",
              "Pinagkamaligan",
              "Pinagtalleran",
              "Sabang I",
              "Sabang Ii",
              "Santa Milagrosa",
              "Santo Domingo",
              "Sumilang",
              "Sumulong"
            ]
          },
          {
            "k": "Candelaria",
            "v": [
              "Bukal Sur",
              "Kinatihan I",
              "Malabanban Norte",
              "Malabanban Sur",
              "Mangilag Norte",
              "Mangilag Sur",
              "Masalukot I",
              "Masalukot Ii",
              "Masin Norte",
              "Masin Sur",
              "Mayabobo",
              "Pahinga Norte",
              "Pahinga Sur",
              "Poblacion",
              "Santa Catalina Norte",
              "Santa Catalina Sur"
            ]
          },
          {
            "k": "Catanauan",
            "v": [
              "Ajos",
              "Barangay 1 (Pob.)",
              "Barangay 10 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 4 (Pob.)",
              "Barangay 5 (Pob.)",
              "Barangay 6 (Pob.)",
              "Barangay 7 (Pob.)",
              "Barangay 8 (Pob.)",
              "Barangay 9 (Pob.)",
              "Bolo",
              "Catumbo",
              "Dahican",
              "Madulao",
              "Matandang Sabang Silangan",
              "Pacabit",
              "San Antonio Pala",
              "San Isidro"
            ]
          },
          {
            "k": "Dolores",
            "v": [
              "Bagong Anyo (Pob.)",
              "Bayanihan (Pob.)",
              "Bulakin I",
              "Bulakin Ii",
              "Bungoy",
              "Dagatan",
              "Maligaya (Pob.)",
              "Silanganan (Pob.)"
            ]
          },
          {
            "k": "General Luna",
            "v": [
              "Barangay 1 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 4 (Pob.)",
              "Barangay 5 (Pob.)",
              "Barangay 6 (Pob.)",
              "Barangay 7 (Pob.)",
              "Barangay 8 (Pob.)",
              "Barangay 9 (Pob.)",
              "San Ignacio Ibaba",
              "San Ignacio Ilaya",
              "San Jose"
            ]
          },
          {
            "k": "General Nakar",
            "v": ["Anoling", "Catablingan", "Pamplona", "Poblacion"]
          },
          {
            "k": "Gumaca",
            "v": [
              "Adia Bitaog",
              "Anonangin",
              "Bagong Buhay (Pob.)",
              "Bamban",
              "Binambang",
              "Buensuceso",
              "Butaguin",
              "Calumangin",
              "Camohaguin",
              "Casasahan Ibaba",
              "Casasahan Ilaya",
              "Cawayan",
              "Gayagayaan",
              "Gitnang Barrio",
              "Hagakhakin",
              "Hardinan",
              "Inaclagan",
              "Inagbuhan Ilaya",
              "Labnig",
              "Laguna",
              "Lagyo",
              "Mabini (Pob.)",
              "Manlayaan",
              "Maunlad (Pob.)",
              "Panikihan",
              "PeÃ±afrancia (Pob.)",
              "Pipisik (Pob.)",
              "Progreso",
              "Rizal (Pob.)",
              "Rosario",
              "San Diego Poblacion",
              "San Vicente",
              "Sastre",
              "Tabing Dagat (Pob.)",
              "Tumayan",
              "Villa Arcaya",
              "Villa Bota",
              "Villa Fuerte",
              "Villa M. Principe",
              "Villa Mendoza",
              "Villa Nava",
              "Villa Padua",
              "Villa Perez",
              "Villa TaÃ±ada",
              "Villa Victoria"
            ]
          },
          {
            "k": "Infanta",
            "v": [
              "Abiawin",
              "Bacong",
              "Bantilan",
              "Banugao",
              "Batican",
              "Binulasan",
              "Boboin",
              "Catambungan",
              "Comon",
              "Dinahican",
              "Gumian",
              "Ilog",
              "Ingas",
              "Libjo",
              "Lual",
              "Miswa",
              "Pilaway",
              "Poblacion 1 (Barangay 1)",
              "Poblacion 38 (Poblacion Barangay 2)",
              "Poblacion 39 (Poblacion Barangay 3)",
              "Pulo",
              "Silangan",
              "Tongohin"
            ]
          },
          {
            "k": "Lopez",
            "v": [
              "Bacungan",
              "Bagacay",
              "Banabahin Ibaba",
              "Banabahin Ilaya",
              "Bayabas",
              "Bebito",
              "Bigajo",
              "Binahian A",
              "Binahian B",
              "Binahian C",
              "Bocboc",
              "Buenavista",
              "Burgos (Pob.)",
              "Buyacanin",
              "Cagacag",
              "Calantipayan",
              "Canda Ibaba",
              "Canda Ilaya",
              "Cawayan",
              "Cawayanin",
              "Cogorin Ibaba",
              "Cogorin Ilaya",
              "Concepcion",
              "Danlagan",
              "De La Paz",
              "Del Pilar",
              "Del Rosario",
              "Esperanza Ibaba",
              "Esperanza Ilaya",
              "Gomez (Pob.)",
              "Guihay",
              "Guinuangan",
              "Guites",
              "Hondagua",
              "Inalusan",
              "Jongo",
              "Lalaguna",
              "Lourdes",
              "Mabanban",
              "Mabini",
              "Magallanes",
              "Magsaysay (Pob.)",
              "Maguilayan",
              "Mal-ay",
              "Manguisian",
              "Matinik",
              "Monteclaro",
              "Pamampangin",
              "Pansol",
              "PeÃ±afrancia",
              "Pisipis",
              "Rizal (Poblacion)",
              "Rizal (Rural)",
              "Roma",
              "Rosario",
              "Samat",
              "San Andres",
              "San Antonio",
              "San Francisco A",
              "San Francisco B",
              "San Isidro",
              "San Jose",
              "San Miguel (Dao)",
              "San Pedro",
              "San Rafael",
              "San Roque",
              "Santa Catalina",
              "Santa Elena",
              "Santa Jacobe",
              "Santa Lucia",
              "Santa Maria",
              "Santa Rosa",
              "Santa Teresa (Mayupag)",
              "Santo NiÃ±o Ibaba",
              "Santo NiÃ±o Ilaya",
              "Silang",
              "Sugod",
              "Sumalang",
              "Talolong (Pob.)",
              "Tan-ag Ibaba",
              "Tan-ag Ilaya",
              "Tocalin",
              "Vegaflor",
              "VergaÃ±a",
              "Veronica",
              "Villa Aurora",
              "Villa Espina",
              "Villa Geda",
              "Villa Hermosa",
              "Villamonte",
              "Villanacaob"
            ]
          },
          {
            "k": "Lucban",
            "v": [
              "Abang",
              "Ayuti",
              "Barangay 1 (Pob.)",
              "Barangay 10 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 4 (Pob.)",
              "Barangay 5 (Pob.)",
              "Barangay 6 (Pob.)",
              "Barangay 7 (Pob.)",
              "Barangay 8 (Pob.)",
              "Barangay 9 (Pob.)",
              "Igang",
              "Kabatete",
              "Kilib",
              "Kulapi",
              "Mahabang Parang",
              "Malupak",
              "Manasa",
              "May-it",
              "Palola",
              "Piis",
              "Tiawe",
              "Tinamnan"
            ]
          },
          {
            "k": "Lucena",
            "v": [
              "Barangay 1 (Pob.)",
              "Barangay 10 (Pob.)",
              "Barangay 11 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 4 (Pob.)",
              "Barangay 5 (Pob.)",
              "Barangay 6 (Pob.)",
              "Barangay 7 (Pob.)",
              "Barangay 8 (Pob.)",
              "Barangay 9 (Pob.)",
              "Barra",
              "Bocohan",
              "Cotta",
              "Dalahican",
              "Domoit",
              "Gulang-gulang",
              "Ibabang Dupay",
              "Ibabang Iyam",
              "Ilayang Dupay",
              "Ilayang Iyam",
              "Isabang",
              "Market View",
              "Mayao Castillo",
              "Mayao Crossing",
              "Mayao Kanluran",
              "Mayao Parada",
              "Mayao Silangan",
              "Talao-talao"
            ]
          },
          {
            "k": "Macalelon",
            "v": [
              "Amontay",
              "Anos",
              "Buyao",
              "Calantas",
              "Candangal",
              "Castillo (Pob.)",
              "Damayan (Pob.)",
              "Lahing",
              "Luctob",
              "Mabini Ibaba",
              "Mabini Ilaya",
              "Malabahay",
              "Mambog",
              "Masipag (Pob.)",
              "Olongtao Ibaba",
              "Olongtao Ilaya",
              "Padre Herrera",
              "Pag-asa (Pob.)",
              "Pajarillo",
              "Pinagbayanan",
              "Rizal (Pob.)",
              "Rodriquez (Pob.)",
              "San Isidro",
              "San Jose",
              "San Nicolas",
              "San Vicente",
              "Taguin",
              "Tubigan Ibaba",
              "Tubigan Ilaya",
              "Vista Hermosa"
            ]
          },
          {
            "k": "Mauban",
            "v": [
              "Baao",
              "Bagong Bayan (Pob.)",
              "Balaybalay",
              "Concepcion",
              "Daungan (Pob.)",
              "Liwayway",
              "Mabato (Pob.)",
              "Polo",
              "San Isidro",
              "San Lorenzo",
              "San Miguel",
              "Santo Angel",
              "Santol"
            ]
          },
          {
            "k": "Mulanay",
            "v": [
              "Ajos",
              "Amuguis",
              "Bagong Silang",
              "Bagupaye",
              "Barangay 1 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 4 (Pob.)",
              "Butanyog",
              "F. Nanadiego",
              "Ibabang Cambuga",
              "Ilayang Cambuga (Mabini)",
              "Latangan"
            ]
          },
          {
            "k": "Padre Burgos",
            "v": [
              "Basiao (Pob.)",
              "Burgos (Pob.)",
              "Cabuyao Norte",
              "Cabuyao Sur",
              "Campo (Pob.)",
              "Danlagan",
              "Duhat",
              "Hinguiwin",
              "Kinagunan Ibaba",
              "Kinagunan Ilaya",
              "Lipata",
              "Marao",
              "Marquez",
              "Punta (Pob.)",
              "Rizal",
              "San Isidro",
              "San Vicente",
              "Sipa",
              "Tulay Buhangin",
              "Villapaz",
              "Walay",
              "Yawe"
            ]
          },
          {
            "k": "Pagbilao",
            "v": [
              "Alupaye",
              "Bantigue",
              "Barangay 1 Castillo (Pob.)",
              "Barangay 2 Daungan (Pob.)",
              "Barangay 3 Del Carmen (Pob.)",
              "Barangay 4 Parang (Pob.)",
              "Barangay 5 Santa Catalina (Pob.)",
              "Barangay 6 Tambak (Pob.)",
              "Bigo",
              "Binahaan",
              "Bukal",
              "Ibabang Palsabangon",
              "Ikirin",
              "Ilayang Palsabangon",
              "Kanluran Malicboy",
              "Mapagong",
              "Mayhay",
              "Pinagbayanan",
              "Silangan Malicboy",
              "Talipan"
            ]
          },
          {
            "k": "Pitogo",
            "v": [
              "Amontay",
              "Biga",
              "Bilucao",
              "Cabulihan",
              "Castillo (Pob.)",
              "Cawayanin",
              "Cometa",
              "Dalampasigan (Pob.)",
              "Dulong Bayan (Pob.)",
              "Gangahin",
              "Ibabang Burgos",
              "Ibabang Pacatin",
              "Ibabang PiÃ±a",
              "Ibabang Soliyao",
              "Ilayang Burgos",
              "Ilayang Pacatin",
              "Ilayang PiÃ±a",
              "Ilayang Soliyao",
              "Maaliw (Pob.)",
              "Manggahan (Pob.)",
              "Masaya (Pob.)",
              "Mayubok (Pob.)",
              "Nag-cruz",
              "OsmeÃ±a",
              "Pag-asa (Pob.)",
              "Pamilihan (Pob.)",
              "Payte",
              "Pinagbayanan",
              "Poctol",
              "Quezon",
              "Quinagasan",
              "Rizalino",
              "Saguinsinan",
              "Sampaloc",
              "San Roque",
              "Sisirin",
              "Sumag Este",
              "Sumag Norte",
              "Sumag Weste"
            ]
          },
          {
            "k": "Plaridel",
            "v": [
              "Central (Pob.)",
              "Concepcion",
              "Duhat",
              "Ilaya",
              "Ilosong",
              "M. L. Tumagay Pob. (Remas Mabuton)",
              "Paang Bundok (Pob.)",
              "Pampaaralan (Pob.)",
              "Tanauan"
            ]
          },
          {
            "k": "Real",
            "v": [
              "Capalong",
              "Cawayan",
              "Kiloloran",
              "Llavac",
              "Maragondon",
              "Poblacion 61 (Barangay 2)",
              "Poblacion I (Barangay 1)",
              "Tanauan",
              "Tignoan",
              "Ungos"
            ]
          },
          {
            "k": "Sampaloc",
            "v": ["Alupay", "Bataan", "Bayongon", "Bilucao", "Ilayang Owain"]
          },
          {
            "k": "San Andres",
            "v": [
              "Alibihaban",
              "Camflora",
              "Mangero",
              "Pansoy",
              "Poblacion",
              "Tala",
              "Talisay"
            ]
          },
          {
            "k": "San Narciso",
            "v": [
              "Abuyon",
              "Bayanihan (Pob.)",
              "Binay",
              "Buenavista",
              "Calwit",
              "Guinhalinan",
              "Lacdayan",
              "Pagdadamayan (Pob.)",
              "Pagkakaisa (Pob.)",
              "Villa Reyes"
            ]
          },
          {
            "k": "Sariaya",
            "v": [
              "Balubal",
              "Barangay 1 (Pob.)",
              "Barangay 2 (Pob.)",
              "Barangay 3 (Pob.)",
              "Barangay 4 (Pob.)",
              "Barangay 5 (Pob.)",
              "Barangay 6 (Pob.)",
              "Gibanga",
              "Lutucan 1",
              "Lutucan Malabag",
              "Mamala I",
              "Mamala Ii",
              "Sampaloc 1",
              "Sampaloc 2",
              "Sampaloc Santo Cristo",
              "Tumbaga 1"
            ]
          },
          {
            "k": "Tayabas",
            "v": [
              "Alsam Ibaba",
              "Alsam Ilaya",
              "Angeles Zone I (Pob.)",
              "Angeles Zone Ii",
              "Angeles Zone Iii",
              "Angeles Zone Iv",
              "Angustias Zone I (Pob.)",
              "Angustias Zone Ii",
              "Angustias Zone Iii",
              "Angustias Zone Iv",
              "Anos",
              "Baguio",
              "Calumpang",
              "Camaysa",
              "Dapdap",
              "Domoit Kanluran",
              "Domoit Silangan",
              "Gibanga",
              "Ipilan",
              "Isabang",
              "Lalo",
              "Lawigue",
              "Lita (Pob.)",
              "Malaoa",
              "Mateuna",
              "Opias",
              "Palale Ibaba",
              "Palale Kanluran",
              "Palale Silangan",
              "Potol",
              "San Diego Zone I (Pob.)",
              "San Diego Zone Ii",
              "San Diego Zone Iii",
              "San Diego Zone Iv",
              "San Isidro Zone I (Pob.)",
              "San Isidro Zone Ii",
              "San Isidro Zone Iii",
              "San Isidro Zone Iv",
              "San Roque Zone I (Pob.)",
              "San Roque Zone Ii",
              "Tongko",
              "Wakas"
            ]
          },
          {
            "k": "Tiaong",
            "v": [
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Lagalag",
              "Lalig",
              "Lumingon",
              "Lusacan",
              "Paiisa",
              "Quipot",
              "San Isidro",
              "Talisay"
            ]
          },
          {
            "k": "Unisan",
            "v": [
              "Balagtas",
              "Balanacan",
              "Bonifacio",
              "Cabulihan Ibaba",
              "Cabulihan Ilaya",
              "Caigdal",
              "F. De Jesus (Pob.)",
              "General Luna",
              "Mabini",
              "Mairok Ilaya",
              "Maputat",
              "Muliguin",
              "Plaridel",
              "Poctol",
              "Punta",
              "R. Lapu-lapu (Pob.)",
              "Raja Soliman (Pob.)",
              "San Roque",
              "Tagumpay",
              "Tubas"
            ]
          }
        ],
        "names": [
          "Agdangan",
          "Atimonan",
          "Buenavista",
          "Calauag",
          "Candelaria",
          "Catanauan",
          "Dolores",
          "General Luna",
          "General Nakar",
          "Gumaca",
          "Infanta",
          "Lopez",
          "Lucban",
          "Lucena",
          "Macalelon",
          "Mauban",
          "Mulanay",
          "Padre Burgos",
          "Pagbilao",
          "Pitogo",
          "Plaridel",
          "Real",
          "Sampaloc",
          "San Andres",
          "San Narciso",
          "Sariaya",
          "Tayabas",
          "Tiaong",
          "Unisan"
        ]
      }
    },
    {
      "k": "Quirino",
      "v": {
        "lookup": [
          {
            "k": "Aglipay",
            "v": [
              "Alicia",
              "Cabugao",
              "Dagupan",
              "Diodol",
              "Dumabel",
              "Dungo (OsmeÃ±a)",
              "Guinalbin",
              "Ligaya",
              "Nagabgaban",
              "Palacian",
              "Pinaripad Norte",
              "Pinaripad Sur",
              "Progreso (Pob.)",
              "Ramos",
              "Rang-ayan",
              "San Antonio",
              "San Benigno",
              "San Francisco",
              "San Leonardo (Cabarroguis)",
              "San Manuel",
              "San Ramon",
              "Victoria",
              "Villa Pagaduan",
              "Villa Santiago",
              "Villa Ventura"
            ]
          },
          {
            "k": "Cabarroguis",
            "v": [
              "Banuar",
              "Burgos",
              "Calaocan",
              "Del Pilar",
              "Dibibi",
              "Dingasan",
              "Eden",
              "Gomez",
              "Gundaway (Pob.)",
              "Mangandingay (Pob.)",
              "San Marcos",
              "Santo Domingo",
              "Tucod",
              "Villa PeÃ±a (Capellangan)",
              "Villamor",
              "Villarose",
              "Zamora"
            ]
          },
          {
            "k": "Diffun",
            "v": [
              "Aklan Village",
              "Andres Bonifacio (Pob.)",
              "Aurora East (Pob.)",
              "Aurora West (Pob.)",
              "Baguio Village",
              "Balagbag",
              "Bannawag",
              "Cajel",
              "Campamento",
              "Diego Silang",
              "Don Faustino Pagaduan",
              "Don Mariano Perez, Sr.",
              "DoÃ±a Imelda",
              "Dumanisi",
              "Gabriela Silang",
              "Gregorio Pimentel",
              "Gulac",
              "Guribang",
              "Ifugao Village",
              "Isidro Paredes",
              "Liwayway",
              "Luttuad",
              "Magsaysay",
              "Makate",
              "Maria Clara",
              "Rafael Palma (Don Sergio OsmeÃ±a)",
              "Ricarte Norte",
              "Ricarte Sur",
              "Rizal (Pob.)",
              "San Antonio",
              "San Isidro",
              "San Pascual",
              "Villa Pascua"
            ]
          },
          {
            "k": "Maddela",
            "v": [
              "Abbag",
              "Balligui",
              "Buenavista",
              "Cabaruan",
              "Cabua-an",
              "Cofcaville",
              "Diduyon",
              "Dipintin",
              "Divisoria Norte",
              "Divisoria Sur (Bisangal)",
              "Dumabato Norte",
              "Dumabato Sur",
              "Jose Ancheta",
              "Lusod",
              "Manglad",
              "Pedlisan",
              "Poblacion Norte",
              "Poblacion Sur",
              "San Bernabe",
              "San Martin",
              "San Pedro",
              "San Salvador",
              "Santa Maria",
              "Santo NiÃ±o",
              "Santo Tomas",
              "Villa Agullana",
              "Villa Gracia",
              "Villa Hermosa Norte",
              "Villa Hermosa Sur",
              "Villa Jose V Ylanan",
              "Ysmael"
            ]
          },
          {
            "k": "Saguday",
            "v": [
              "Cardenas",
              "Dibul",
              "Gamis",
              "La Paz",
              "Magsaysay (Pob.)",
              "Rizal (Pob.)",
              "Salvacion",
              "Santo Tomas",
              "Tres Reyes"
            ]
          }
        ],
        "names": ["Aglipay", "Cabarroguis", "Diffun", "Maddela", "Saguday"]
      }
    },
    {
      "k": "Rizal",
      "v": {
        "lookup": [
          {
            "k": "Angono",
            "v": [
              "Bagumbayan",
              "Kalayaan",
              "Mahabang Parang",
              "Poblacion Ibaba",
              "Poblacion Itaas",
              "San Isidro",
              "San Pedro",
              "San Roque",
              "San Vicente",
              "Santo NiÃ±o"
            ]
          },
          {
            "k": "Antipolo",
            "v": [
              "Bagong Nayon",
              "Beverly Hills",
              "Calawis",
              "Cogio",
              "Cupang",
              "Dalig",
              "Dela Paz (Pob.)",
              "Inarawan",
              "Mambugan",
              "Mayamot",
              "Muntingdilaw",
              "San Isidro (Pob.)",
              "San Jose (Pob.)",
              "San Juan",
              "San Luis",
              "San Roque (Pob.)",
              "Santa Cruz"
            ]
          },
          {
            "k": "Baras",
            "v": [
              "Concepcion",
              "Evangelista",
              "Mabini",
              "Rizal (Pob.)",
              "San Jose",
              "San Juan",
              "San Miguel",
              "San Salvador",
              "Santiago"
            ]
          },
          {
            "k": "Binangonan",
            "v": [
              "Batingan",
              "Bilibiran",
              "Calumpang",
              "Ithan",
              "Kalawaan",
              "Kaytome",
              "Kinaboogan",
              "Layunan (Pob.)",
              "Libid (Pob.)",
              "Libis (Pob.)",
              "Limbon-limbon",
              "Lunsad",
              "Macamot",
              "Mahabang Parang",
              "Mambog",
              "Pag-asa",
              "Palangoy",
              "Pantok",
              "Pila Pila",
              "San Carlos",
              "Tagpos",
              "Tatala",
              "Tayuman"
            ]
          },
          {
            "k": "Cainta",
            "v": [
              "San Andres (Pob.)",
              "San Isidro",
              "San Juan",
              "San Roque",
              "Santa Rosa",
              "Santo Domingo",
              "Santo NiÃ±o"
            ]
          },
          {
            "k": "Cardona",
            "v": [
              "Calahan",
              "Dalig",
              "Del Remedio (Pob.)",
              "Iglesia (Pob.)",
              "Looc",
              "Nagsulo",
              "Patunhay",
              "Real (Pob.)",
              "Sampad",
              "San Roque (Pob.)"
            ]
          },
          {
            "k": "Jala-jala",
            "v": [
              "Bagumbong",
              "Bayugo",
              "Lubo",
              "Paalaman",
              "Pagkalinawan",
              "Palaypalay",
              "Punta",
              "Second District (Pob.)",
              "Sipsipin",
              "Special District (Pob.)",
              "Third District (Pob.)"
            ]
          },
          {
            "k": "Morong",
            "v": [
              "Bombongan",
              "Can-cal-lan (Caniogan-calero-lanang)",
              "Lagundi",
              "Maybancal",
              "San Guillermo",
              "San Jose (Pob.)",
              "San Juan (Pob.)",
              "San Pedro (Pob.)"
            ]
          },
          {
            "k": "Pililla",
            "v": [
              "Bagumbayan (Pob.)",
              "Halayhayin",
              "Hulo (Pob.)",
              "Imatong (Pob.)",
              "Malaya",
              "Niogan",
              "Quisao",
              "Takungan (Pob.)",
              "Wawa (Pob.)"
            ]
          },
          {
            "k": "Rodriguez (Montalban)",
            "v": [
              "Balite (Pob.)",
              "Burgos",
              "Geronimo",
              "Macabud",
              "Manggahan",
              "Mascap",
              "Puray",
              "Rosario",
              "San Isidro",
              "San Jose",
              "San Rafael"
            ]
          },
          {
            "k": "San Mateo",
            "v": [
              "Ampid I",
              "Ampid Ii",
              "Banaba",
              "Dulong Bayan 1",
              "Dulong Bayan 2",
              "Guinayang",
              "Guitnang Bayan I (Pob.)",
              "Guitnang Bayan Ii (Pob.)",
              "Gulod Malaya",
              "Malanday",
              "Maly",
              "Santa Ana",
              "Santo NiÃ±o",
              "Silangan"
            ]
          },
          {
            "k": "Tanay",
            "v": [
              "Cayabu",
              "Cuyambay",
              "Daraitan",
              "Katipunan-bayan (Pob.)",
              "Kaybuto (Pob.)",
              "Laiban",
              "Mag-ampon (Pob.)",
              "Pinagkamaligan (Pob.)",
              "Plaza Aldea (Pob.)",
              "Sampaloc",
              "San Andres",
              "San Isidro (Pob.)",
              "Santa Inez",
              "Santo NiÃ±o",
              "Tabing Ilog (Pob.)",
              "Tandang Kutyo (Pob.)",
              "Wawa (Pob.)"
            ]
          },
          {
            "k": "Taytay",
            "v": [
              "Dolores (Pob.)",
              "Muzon",
              "San Isidro",
              "San Juan",
              "Santa Ana"
            ]
          },
          {
            "k": "Teresa",
            "v": [
              "Bagumbayan",
              "Calumpang Santo Cristo",
              "Dalig",
              "Dulumbayan",
              "May-iba",
              "Poblacion",
              "Prinza",
              "San Gabriel",
              "San Roque"
            ]
          }
        ],
        "names": [
          "Angono",
          "Antipolo",
          "Baras",
          "Binangonan",
          "Cainta",
          "Cardona",
          "Jala-jala",
          "Morong",
          "Pililla",
          "Rodriguez (Montalban)",
          "San Mateo",
          "Tanay",
          "Taytay",
          "Teresa"
        ]
      }
    },
    {
      "k": "Romblon",
      "v": {
        "lookup": [
          {
            "k": "Alcantara",
            "v": [
              "Bagsik",
              "Bonlao",
              "Calagonsao",
              "Camili",
              "Camod-om",
              "Gui-ob",
              "Lawan",
              "Madalag",
              "Poblacion",
              "San Isidro",
              "San Roque",
              "Tugdan"
            ]
          },
          {
            "k": "Calatrava",
            "v": [
              "Balogo",
              "Linao",
              "Pagsangahan",
              "Pangulo",
              "Poblacion",
              "San Roque",
              "Talisay"
            ]
          },
          {
            "k": "Ferrol",
            "v": [
              "Agnonoc",
              "Bunsoran",
              "Claro M. Recto",
              "Hinaguman",
              "Poblacion (Ferrol)",
              "Tubigon"
            ]
          },
          {
            "k": "Looc",
            "v": [
              "Agojo",
              "Balatucan",
              "Buenavista",
              "Camandao",
              "Guinhayaan",
              "Limon Norte",
              "Limon Sur",
              "Manhac",
              "Pili",
              "Poblacion",
              "Punta",
              "Tuguis"
            ]
          },
          {
            "k": "Odiongan",
            "v": [
              "Amatong",
              "Anahao",
              "Bangon",
              "Batiano",
              "Budiong",
              "Canduyong",
              "Dapawan",
              "Gabawan",
              "Libertad",
              "Ligaya (Pob.)",
              "Liwanag (Pob.)",
              "Liwayway (Pob.)",
              "Malilico",
              "Mayha",
              "Panique",
              "Pato-o",
              "Poctoy",
              "Progreso Este",
              "Progreso Weste",
              "Rizal",
              "Tabing Dagat (Pob.)",
              "Tabobo-an",
              "Tuburan",
              "Tulay",
              "Tumingad"
            ]
          },
          {
            "k": "Romblon",
            "v": [
              "Agbaluto",
              "Agbudia",
              "Agnaga",
              "Agnay",
              "Agnipa",
              "Agpanabat",
              "Agtongo",
              "Bagacay",
              "Barangay I (Pob.)",
              "Barangay Ii (Pob.)",
              "Barangay Iii (Pob.)",
              "Barangay Iv (Pob.)",
              "Cajimos",
              "Calabogo",
              "Capaclan",
              "Ginablan",
              "Guimpingan",
              "Ilauran",
              "Lamao",
              "Li-o",
              "Lonos",
              "Lunas",
              "Macalas",
              "Mapula",
              "Palje",
              "Sablayan",
              "Sawang",
              "Tambac"
            ]
          },
          {
            "k": "San Agustin",
            "v": [
              "Bachawan",
              "Binongahan",
              "Binugusan",
              "Buli",
              "Cabolutan",
              "Cagbuaya",
              "Camantaya",
              "Carmen",
              "Cawayan",
              "DoÃ±a Juana",
              "Dubduban",
              "Lusong",
              "Mahabang Baybay (Long Beach)",
              "Poblacion",
              "Sugod"
            ]
          },
          {
            "k": "San Andres",
            "v": [
              "Agpudios",
              "Calunacon",
              "DoÃ±a Trinidad",
              "Juncarlo",
              "Linawan",
              "Mabini",
              "Marigondon Norte",
              "Marigondon Sur",
              "Matutuna",
              "Pag-alad",
              "Poblacion",
              "Tan-agan",
              "Victoria"
            ]
          },
          {
            "k": "Santa Fe",
            "v": [
              "Agmanic",
              "Canyayo",
              "Danao Norte",
              "Danao Sur",
              "Guinbirayan",
              "Guintigbasan",
              "Magsaysay",
              "Mat-i",
              "Pandan",
              "Poblacion",
              "Tabugon"
            ]
          },
          {
            "k": "Santa Maria (Imelda)",
            "v": [
              "Bonga",
              "Concepcion Norte (Pob.)",
              "Concepcion Sur",
              "Paroyhog",
              "San Isidro",
              "Santo NiÃ±o"
            ]
          }
        ],
        "names": [
          "Alcantara",
          "Calatrava",
          "Ferrol",
          "Looc",
          "Odiongan",
          "Romblon",
          "San Agustin",
          "San Andres",
          "Santa Fe",
          "Santa Maria (Imelda)"
        ]
      }
    },
    {
      "k": "Samar",
      "v": {
        "lookup": [
          {
            "k": "Basey",
            "v": [
              "Amandayehan",
              "Anglit",
              "Bacubac",
              "Balante",
              "Baloog",
              "Basiao",
              "Baybay Pob",
              "Binongtu An",
              "Buenavista",
              "Bulao",
              "Burgos",
              "Buscada Pob",
              "Cambayan",
              "Can Abay",
              "Cancaiyas",
              "Canmanila",
              "Catadman",
              "Cogon",
              "Del Pilar",
              "Dolongan",
              "Guintigui An",
              "Iba",
              "Inuntan",
              "Lawa An Pob",
              "Loog",
              "Loyo Pob",
              "Mabini",
              "Manlilinab",
              "May It",
              "Mercado Pob",
              "Mongabong",
              "New San Agustin",
              "Nouvelas Occidental",
              "Palaypay Pob",
              "Panugmonon",
              "Pelit",
              "Proper",
              "Roxas",
              "Salvacion",
              "San Antonio",
              "San Fernando Nouvelas Oriental",
              "Sawa",
              "Serum",
              "Sugca",
              "Sugponon",
              "Sulod Pob",
              "Tinaogan",
              "Tingib"
            ]
          },
          {
            "k": "Calbayog City",
            "v": [
              "Aguit Itan Pob",
              "Amampacang",
              "Anislag",
              "Awang East Pob",
              "Awang West Pob",
              "Ba Ay",
              "Bagacay",
              "Balud Pob",
              "Bante",
              "Bantian",
              "Basud",
              "Bayo",
              "Begaho",
              "Binaliw",
              "Bugtong",
              "Cacaransan",
              "Cag Olango",
              "Cagboborac",
              "Cagmanipes Norte",
              "Cagmanipes Sur",
              "Cagnipa",
              "Cagsalaosao",
              "Capoocan",
              "Carayman",
              "Carmen",
              "Central Pob",
              "Dagum",
              "Danao I",
              "Gadgaran",
              "Guimbaoyan Norte",
              "Guimbaoyan Sur",
              "Guin On",
              "Hamorawon",
              "Longsob",
              "Lonoy",
              "Maguino O",
              "Malaga",
              "Malajog",
              "Malopalo",
              "Marcatubig",
              "Matobato",
              "Maysalong",
              "Nijaga",
              "Obrero",
              "Oquendo Pob",
              "Payahan",
              "Pena",
              "Rawis",
              "San Joaquin",
              "San Policarpio",
              "Tabawan",
              "Tinambacan Norte",
              "Tinaplacan",
              "Trinidad Sabang"
            ]
          },
          {
            "k": "Calbiga",
            "v": [
              "Bacyaran",
              "Barangay 1 Pob",
              "Barangay 2 Pob",
              "Barangay 3 Pob",
              "Barangay 4 Pob",
              "Barangay 5 Pob",
              "Barangay 6 Pob",
              "Barangay 7 Pob",
              "Canticum",
              "Polangi",
              "Timbangan"
            ]
          },
          {
            "k": "Catbalogan City",
            "v": [
              "Bunuanan",
              "Canlapwas Pob",
              "Guindaponan",
              "Guinsorongan",
              "Iguid",
              "Lagundi",
              "Maulong",
              "Mercedes",
              "Munoz Poblacion 14",
              "New Mahayag",
              "Old Mahayag",
              "Payao",
              "Poblacion 1 Barangay 1",
              "Poblacion 10 Barangay 10",
              "Poblacion 11 Barangay 11",
              "Poblacion 12 Barangay 12",
              "Poblacion 13 Barangay 13",
              "Poblacion 2 Barangay 2",
              "Poblacion 3 Barangay 3",
              "Poblacion 4 Barangay 4",
              "Poblacion 5 Barangay 5",
              "Poblacion 6 Barangay 6",
              "Poblacion 7 Barangay 7",
              "Poblacion 8 Barangay 8",
              "Poblacion 9 Barangay 9",
              "Pupua",
              "San Andres",
              "San Pablo",
              "San Vicente",
              "Silanga",
              "Socorro"
            ]
          },
          {
            "k": "Gandara",
            "v": [
              "Adela Heights Pob",
              "Burabod I Pob",
              "Burabod Ii Pob",
              "Catorse De Agosto",
              "Diaz",
              "Dumalo Ong Pob",
              "Minda Pob",
              "San Agustin"
            ]
          },
          {
            "k": "Hinabangan",
            "v": [
              "Osmena",
              "Poblacion 1 Barangay 1",
              "Poblacion 2 Barangay 2",
              "Rawis"
            ]
          },
          {
            "k": "Jiabong",
            "v": [
              "Barangay No 1 Pob",
              "Barangay No 2 Pob",
              "Barangay No 3 Pob",
              "Barangay No 4 Pob",
              "Barangay No 5 Pob",
              "Barangay No 6 Pob",
              "Barangay No 7 Pob",
              "Barangay No 8 Pob",
              "Catalina",
              "Jia An",
              "Malobago Villalinda"
            ]
          },
          {
            "k": "Marabut",
            "v": [
              "Legaspi",
              "Mabuhay",
              "Osmena",
              "Pinalanga",
              "Pinamitinan Pob",
              "San Roque",
              "Tinabanan"
            ]
          },
          {
            "k": "Motiong",
            "v": ["Bayog", "Oyandic", "Poblacion I", "Poblacion I A"]
          },
          {
            "k": "Paranas Wright",
            "v": [
              "Apolonia",
              "Buray Binogho",
              "Lipata",
              "Pabanog",
              "Poblacion 1",
              "Poblacion 2",
              "Poblacion 3",
              "Poblacion 4",
              "Poblacion 5",
              "Poblacion 6",
              "Proper"
            ]
          },
          {
            "k": "Pinabacdao",
            "v": [
              "Barangay I Pob",
              "Barangay Ii Pob",
              "Dolores",
              "Lale",
              "Laygayon",
              "Madalunot",
              "Mambog",
              "Nabong",
              "Obayan",
              "Pahug",
              "Parasanon"
            ]
          },
          {
            "k": "San Jorge",
            "v": [
              "Aurora",
              "Erenas",
              "Mabuhay",
              "Mancol Pob",
              "Quezon",
              "Rosalim",
              "San Jorge I Pob",
              "San Jorge Ii Pob",
              "Sapinit"
            ]
          },
          {
            "k": "San Sebastian",
            "v": [
              "Balogo",
              "Campiyak",
              "Canduyucan",
              "Inobongan",
              "Poblacion Barangay 1",
              "Poblacion Barangay 2",
              "Poblacion Barangay 3",
              "Poblacion Barangay 4"
            ]
          },
          {
            "k": "Santa Margarita",
            "v": [
              "Balud",
              "Burabod",
              "Cagsumji",
              "Cautod Pob",
              "Ilo",
              "Inoraguiao",
              "Lambao",
              "Monbon Pob",
              "Napuro",
              "Napuro Ii",
              "Palale",
              "Solsogon"
            ]
          },
          { "k": "Tarangnan", "v": ["Balugo", "Palencia"] },
          { "k": "Villareal", "v": ["Central Pob"] }
        ],
        "names": [
          "Basey",
          "Calbayog City",
          "Calbiga",
          "Catbalogan City",
          "Gandara",
          "Hinabangan",
          "Jiabong",
          "Marabut",
          "Motiong",
          "Paranas Wright",
          "Pinabacdao",
          "San Jorge",
          "San Sebastian",
          "Santa Margarita",
          "Tarangnan",
          "Villareal"
        ]
      }
    },
    {
      "k": "Sarangani",
      "v": {
        "lookup": [
          {
            "k": "Alabel",
            "v": ["Kawas", "Ladol", "Maribulan", "Poblacion (Alabel)"]
          },
          {
            "k": "Glan",
            "v": [
              "Baliton",
              "Cablalan",
              "Glan Padidu",
              "Gumasa",
              "Ilaya",
              "Kapatan",
              "Lago",
              "Poblacion",
              "Taluya",
              "Tango",
              "Tapon"
            ]
          },
          {
            "k": "Kiamba",
            "v": [
              "Badtasan",
              "Datu Dani",
              "Katubao",
              "Kayupo",
              "Kling (Lumit)",
              "Lagundi",
              "Lebe",
              "Lomuyon",
              "Poblacion",
              "Salakit",
              "Suli",
              "Tablao",
              "Tambilil"
            ]
          },
          {
            "k": "Maasim",
            "v": [
              "Colon",
              "Daliao",
              "Kabatiol",
              "Kablacan",
              "Kamanga",
              "Kanalo",
              "Lumasal",
              "Lumatil",
              "Malbang",
              "Pananag",
              "Poblacion (Maasim)",
              "Tinoto"
            ]
          },
          {
            "k": "Maitum",
            "v": [
              "Kiambing",
              "Kiayap",
              "Mabay",
              "Old Poblacion (Maitum)",
              "Pangi (Linao)",
              "Sison (Edenton)"
            ]
          },
          { "k": "Malapatan", "v": ["Poblacion (Malapatan)"] },
          {
            "k": "Malungon",
            "v": [
              "Alkikan",
              "Atlae",
              "B'laan",
              "Banate",
              "Datal Bila",
              "Datal Tampal",
              "Kiblat",
              "Malandag",
              "Malungon Gamay",
              "Nagpan",
              "Poblacion",
              "Talus"
            ]
          }
        ],
        "names": [
          "Alabel",
          "Glan",
          "Kiamba",
          "Maasim",
          "Maitum",
          "Malapatan",
          "Malungon"
        ]
      }
    },
    {
      "k": "Sorsogon",
      "v": {
        "lookup": [
          {
            "k": "Bacon",
            "v": [
              "Balete",
              "Caricaran",
              "Del Rosario",
              "Jamislagan",
              "Maricrum",
              "Poblacion",
              "Rawis",
              "San Juan",
              "San Roque",
              "Santo Domingo"
            ]
          },
          {
            "k": "Barcelona",
            "v": [
              "Alegria",
              "Poblacion Central",
              "Poblacion Norte",
              "Poblacion Sur"
            ]
          },
          {
            "k": "Casiguran",
            "v": [
              "Adovis (Pob.)",
              "Boton",
              "Central (Pob.)",
              "Colambis",
              "Ponong",
              "Somal-ot (Pob.)",
              "Timbayog (Pob.)",
              "Tulay"
            ]
          },
          { "k": "Castilla", "v": ["La Union"] },
          {
            "k": "Gubat",
            "v": [
              "Ariman",
              "Balud Del Norte (Pob.)",
              "Balud Del Sur (Pob.)",
              "Carriedo",
              "Cota Na Daco (Pob.)",
              "Luna-candol (Pob.)",
              "Manook (Pob.)",
              "Panganiban (Pob.)",
              "Paradijon (Pob.)",
              "Payawin",
              "Pinontingan (Pob.)",
              "Tiris"
            ]
          },
          {
            "k": "Irosin",
            "v": [
              "Bacolod (Pob.)",
              "Batang",
              "Bolos",
              "Buenavista",
              "Casini",
              "Gulang-gulang",
              "Monbon",
              "Salvacion",
              "San Agustin (Pob.)",
              "San Isidro",
              "San Juan (Pob.)",
              "San Julian (Pob.)",
              "San Pedro",
              "Santo Domingo (Lamboon)",
              "Tabon-tabon",
              "Tinampo"
            ]
          },
          {
            "k": "Pilar",
            "v": [
              "Banuyo (Pob.)",
              "Binanuahan (Pob.)",
              "Calongay",
              "Comapo-capo",
              "Danlog",
              "Dao (Pob.)",
              "Mabanate",
              "Marifosque (Pob.)",
              "Poctol",
              "Putiao"
            ]
          },
          {
            "k": "Sorsogon City",
            "v": [
              "Abuyog",
              "Almendras-cogon (Pob.)",
              "Balete",
              "Balogo",
              "Balogo ( Fr. The Mun. Of Bacon)",
              "Barayong",
              "Basud",
              "Bibincahan",
              "Bitan-o/dalipay (Pob.)",
              "Bucalbucalan",
              "Buhatan",
              "Bulabog",
              "Burabod (Pob.)",
              "Cabarbuhan",
              "Cabid-an",
              "Cambulaga",
              "Capuy",
              "Caricaran",
              "Del Rosario",
              "Guinlajon",
              "Jamislagan",
              "Macabog",
              "Maricrum",
              "Pangpang",
              "PeÃ±afrancia",
              "Piot (Pob.)",
              "Poblacion",
              "Polvorista (Pob.)",
              "Rawis",
              "Rizal",
              "Salog (Pob.)",
              "Sampaloc (Pob.)",
              "San Isidro ( Fr. The Mun. Of Bacon)",
              "San Isidro(west District)",
              "San Juan ( Fr. The Mun. Of Bacon)",
              "San Juan (Roro)",
              "San Ramon",
              "San Roque",
              "Santo Domingo",
              "Sirangan (Pob.)",
              "Sulucan (Pob.)",
              "Talisay (Pob.)",
              "Ticol",
              "Tugos"
            ]
          }
        ],
        "names": [
          "Bacon",
          "Barcelona",
          "Casiguran",
          "Castilla",
          "Gubat",
          "Irosin",
          "Pilar",
          "Sorsogon City"
        ]
      }
    },
    {
      "k": "South Cotabato",
      "v": {
        "lookup": [
          {
            "k": "Banga",
            "v": [
              "Cinco (Barrio 5)",
              "Liwanay (Barrio 1)",
              "Punong Grande (Barrio 2)",
              "Rizal Poblacion"
            ]
          },
          {
            "k": "General Santos City",
            "v": [
              "Apopong",
              "Baluan",
              "Buayan",
              "Bula",
              "Calumpang",
              "City Heights",
              "Conel",
              "Dadiangas East (Pob.)",
              "Dadiangas Hts",
              "Dadiangas North",
              "Dadiangas South",
              "Dadiangas West",
              "Fatima",
              "Katangawan",
              "Labangal",
              "Lagao (1st And 3rd)",
              "Mabuhay",
              "San Isidro (Lagao 2nd)",
              "Siguel",
              "Sinawal",
              "Tambler",
              "Tinagacan"
            ]
          },
          {
            "k": "Koronadal City",
            "v": [
              "AvanceÃ±a (Bo. 3)",
              "Caloocan",
              "Carpenter Hill",
              "Concepcion (Bo. 6)",
              "Esperanza",
              "General Paulino Santos (Bo. 1)",
              "Magsaysay",
              "Mambucal",
              "Morales",
              "Paraiso",
              "Rotonda",
              "San Isidro",
              "San Jose (Bo. 5)",
              "Santa Cruz",
              "Santo NiÃ±o (Bo. 2)",
              "Sarabia (Bo. 8)",
              "Zone I (Pob.)",
              "Zone Ii (Pob.)",
              "Zone Iii (Pob.)",
              "Zone Iv (Pob.)",
              "Zulueta (Bo. 7)"
            ]
          },
          { "k": "Lake Sebu", "v": ["Poblacion"] },
          { "k": "Norala", "v": ["Lapuz", "Poblacion", "San Miguel"] },
          {
            "k": "Polomolok",
            "v": [
              "Bentung",
              "Cannery Site",
              "Crossing Palkan",
              "Glamang",
              "Koronadal Proper",
              "Magsaysay",
              "Pagalungan",
              "Palkan",
              "Poblacion",
              "Rubber",
              "Silway 7",
              "Silway 8",
              "Sulit"
            ]
          },
          { "k": "Santo NiÃ±o", "v": ["Poblacion", "Teresita"] },
          {
            "k": "Surallah",
            "v": [
              "Buenavista",
              "Centrala",
              "Colongulo",
              "Dajay",
              "Lamsugod",
              "Libertad (Pob.)",
              "Tubiala",
              "Veterans"
            ]
          },
          { "k": "T`boli", "v": ["Poblacion"] },
          { "k": "Tampakan", "v": ["Kipalbig", "Maltana"] },
          {
            "k": "Tantangan",
            "v": [
              "Bukay Pait",
              "Dumadalig",
              "Maibo",
              "Mangilala",
              "New Cuyapo",
              "New Iloilo",
              "Poblacion",
              "San Felipe"
            ]
          },
          {
            "k": "Tupi",
            "v": [
              "Bololmala",
              "Crossing Rubber",
              "Kablon",
              "Palian",
              "Poblacion",
              "Polonuling"
            ]
          }
        ],
        "names": [
          "Banga",
          "General Santos City",
          "Koronadal City",
          "Lake Sebu",
          "Norala",
          "Polomolok",
          "Santo NiÃ±o",
          "Surallah",
          "T`boli",
          "Tampakan",
          "Tantangan",
          "Tupi"
        ]
      }
    },
    {
      "k": "Southern Leyte",
      "v": {
        "lookup": [
          {
            "k": "Anahawan",
            "v": [
              "Cogon",
              "Lewing",
              "Lo Ok",
              "Mahalo",
              "Poblacion",
              "San Vicente",
              "Tagup On"
            ]
          },
          {
            "k": "Bontoc",
            "v": [
              "Casao",
              "Divisoria",
              "Poblacion",
              "San Ramon",
              "Santo Nino",
              "Talisay",
              "Union"
            ]
          },
          {
            "k": "Hinunangan",
            "v": [
              "Calag Itan",
              "Canipaan",
              "Ingan",
              "Otama",
              "Panalaron",
              "Poblacion",
              "Pondol",
              "Salvacion",
              "Talisay"
            ]
          },
          {
            "k": "Hinundayan",
            "v": [
              "Ambao",
              "Baculod",
              "Bugho",
              "Cat Iwing",
              "Lungsodaan",
              "Sagbok"
            ]
          },
          {
            "k": "Maasin City",
            "v": [
              "Abgao Pob",
              "Asuncion",
              "Batuan",
              "Bilibol",
              "Bogo",
              "Cabulihan",
              "Canturing",
              "Combado",
              "Guadalupe",
              "Ibarra",
              "Isagani",
              "Lanao",
              "Lonoy",
              "Mahayahay",
              "Mambajao Pob",
              "Manhilo",
              "Mantahan Pob",
              "Maria Clara",
              "Nasaug",
              "Pasay",
              "San Agustin",
              "San Isidro",
              "San Rafael",
              "Santa Rosa",
              "Santo Rosario",
              "Soro Soro",
              "Tagnipa Pob",
              "Tawid",
              "Tunga Tunga Pob"
            ]
          },
          {
            "k": "Macrohon",
            "v": [
              "Aguinaldo",
              "Amparo",
              "Asuncion",
              "Bagong Silang",
              "Buscayan",
              "Canlusay",
              "Flordeliz",
              "Ichon",
              "Lower Villa Jacinta",
              "Mabini",
              "Poblacion",
              "Rizal",
              "San Joaquin",
              "San Roque",
              "Upper Villa Jacinta"
            ]
          },
          {
            "k": "Malitbog",
            "v": [
              "Abgao",
              "Asuncion",
              "Benit",
              "Caaga",
              "Candatag",
              "Cantamuac",
              "Concepcion",
              "Iba",
              "Juangon",
              "Maujo",
              "Sabang",
              "San Jose",
              "San Roque",
              "San Vicente",
              "Taliwa Pob"
            ]
          },
          {
            "k": "Padre Burgos",
            "v": [
              "Buenavista",
              "Cantutang",
              "Dinahugan",
              "Lungsodaan",
              "San Juan",
              "Santa Sofia",
              "Santo Rosario",
              "Tangkaan"
            ]
          },
          {
            "k": "Saint Bernard",
            "v": [
              "Ayahag",
              "Bolodbolod",
              "Guinsaugon",
              "Himatagon Pob",
              "Himos Onan",
              "Hindag An",
              "Lipanto",
              "Magbagacay",
              "Mahayahay",
              "Malibago",
              "Nueva Esperanza Cabac An",
              "Panian",
              "Poblacion",
              "San Isidro",
              "Sug Angon",
              "Tambis I",
              "Tambis Ii"
            ]
          },
          {
            "k": "San Juan Cabalian",
            "v": [
              "Agay Ay",
              "Basak",
              "Bobon A",
              "Bobon B",
              "Garrido",
              "Minoyho",
              "Osao",
              "Poblacion",
              "Pong Oy",
              "San Jose Pob",
              "San Vicente",
              "Santa Cruz Pob",
              "Santo Nino Pob",
              "Sua",
              "Timba"
            ]
          },
          {
            "k": "Silago",
            "v": [
              "Balagawan",
              "Mercedes",
              "Pob District I",
              "Pob District Ii",
              "Poblacion",
              "Sap Ang",
              "Sudmon"
            ]
          },
          {
            "k": "Sogod",
            "v": [
              "Consolacion",
              "Hibod Hibod",
              "Javier",
              "Mac",
              "Mahayahay",
              "Poblacion",
              "Rizal",
              "San Isidro",
              "San Jose",
              "San Miguel",
              "San Pedro",
              "San Roque",
              "Suba",
              "Tampoong",
              "Zone I Pob",
              "Zone Ii Pob",
              "Zone Iii Pob",
              "Zone Iv Pob",
              "Zone V Pob"
            ]
          },
          {
            "k": "Tomas Oppus",
            "v": [
              "Cambite Pob",
              "Canlupao",
              "Higosoan",
              "Looc",
              "Maslog",
              "San Antonio",
              "San Isidro",
              "San Miguel",
              "San Roque",
              "Tinago"
            ]
          }
        ],
        "names": [
          "Anahawan",
          "Bontoc",
          "Hinunangan",
          "Hinundayan",
          "Maasin City",
          "Macrohon",
          "Malitbog",
          "Padre Burgos",
          "Saint Bernard",
          "San Juan Cabalian",
          "Silago",
          "Sogod",
          "Tomas Oppus"
        ]
      }
    },
    {
      "k": "Sultan Kudarat",
      "v": {
        "lookup": [
          {
            "k": "Esperanza",
            "v": ["Ala", "Dukay", "Laguinding", "Poblacion", "Saliao"]
          },
          {
            "k": "Isulan",
            "v": [
              "Bambad",
              "D'lotilla",
              "Dansuli",
              "Impao",
              "Kalawag I (Pob.)",
              "Kalawag Ii (Pob.)",
              "Kalawag Iii (Pob.)",
              "Kenram",
              "Kolambog",
              "Kudanding",
              "Laguilayan",
              "New Pangasinan",
              "Sampao",
              "Tayugo"
            ]
          },
          {
            "k": "Kalamansig",
            "v": [
              "Cadiz",
              "Dumangas Nuevo",
              "Limulan",
              "Nalililidan",
              "Pag-asa",
              "Poblacion",
              "Santa Clara",
              "Santa Maria"
            ]
          },
          {
            "k": "Lambayong (Mariano Marcos)",
            "v": ["Didtaras", "Poblacion (Lambayong)"]
          },
          {
            "k": "Lebak",
            "v": [
              "Bacbacan",
              "Barurao 1",
              "Barurao 2",
              "Cadiz High-way",
              "Kumalawit",
              "Limolan",
              "Macaraeg",
              "Macaraeg High-way",
              "Muling",
              "Nuling",
              "Pansud",
              "Pasandalan",
              "Pilandok",
              "Poblacion",
              "Poblacion 1",
              "Poblacion 2",
              "Poblacion 3",
              "Poloypoloy",
              "Purikay",
              "Salman",
              "Tagisa",
              "Tibpuan",
              "Tipodos"
            ]
          },
          {
            "k": "President Quirino",
            "v": ["Poblacion (Sambulawan)", "Tual (Liguasan)"]
          },
          {
            "k": "Tacurong City",
            "v": [
              "Baras",
              "Buenaflor",
              "Calean",
              "Carmen",
              "D'ledesma",
              "Gansing",
              "Kalandagan",
              "Lancheta",
              "Lower Katungal",
              "New Isabela",
              "New Lagao",
              "New Passi",
              "Poblacion",
              "Rajah Nuda",
              "San Antonio",
              "San Emmanuel",
              "San Pablo",
              "San Rafael",
              "Tina",
              "Upper Katungal"
            ]
          }
        ],
        "names": [
          "Esperanza",
          "Isulan",
          "Kalamansig",
          "Lambayong (Mariano Marcos)",
          "Lebak",
          "President Quirino",
          "Tacurong City"
        ]
      }
    },
    {
      "k": "Sulu",
      "v": {
        "lookup": [
          {
            "k": "Indanan",
            "v": [
              "Buanza",
              "Bunut",
              "Kajatian",
              "Langpas",
              "Licup",
              "Panabuan",
              "Tagbak"
            ]
          },
          {
            "k": "Jolo",
            "v": [
              "Alat",
              "Asturias",
              "Bus-bus",
              "Chinese Pier",
              "San Raymundo",
              "Takut-takut",
              "Tulay",
              "Walled City (Pob.)"
            ]
          },
          {
            "k": "Patikul",
            "v": [
              "Anuling",
              "Bangkal",
              "Buhanginan (Darayan)",
              "Danag",
              "Gandasuli",
              "Igasan",
              "Kadday Mampallam",
              "Kan Ague",
              "Kaunayan",
              "Latih",
              "Liang",
              "Mauboh",
              "Sandah",
              "Taglibi (Pob.)",
              "Tanum"
            ]
          }
        ],
        "names": ["Indanan", "Jolo", "Patikul"]
      }
    },
    {
      "k": "Surigao Del Norte",
      "v": {
        "lookup": [
          {
            "k": "Alegria",
            "v": [
              "Alipao",
              "Anahaw",
              "Poblacion Alegria",
              "Pongtud",
              "San Juan",
              "San Pedro"
            ]
          },
          { "k": "Bacuag", "v": ["Campo", "Poblacion"] },
          { "k": "Burgos", "v": ["Baybay", "Bitaug"] },
          {
            "k": "Claver",
            "v": [
              "Bagakay Pob West",
              "Cabugo",
              "Cagdianao",
              "Daywan",
              "Hayanggabon",
              "Ladgaron Pob",
              "Magallanes",
              "Panatao",
              "Taganito",
              "Tayaga Pob East",
              "Wangke"
            ]
          },
          {
            "k": "Dapa",
            "v": [
              "Barangay 1 Pob",
              "Barangay 10 Pob",
              "Barangay 11 Pob",
              "Barangay 12 Pob",
              "Barangay 13 Pob",
              "Barangay 2 Pob",
              "Barangay 3 Pob",
              "Barangay 4 Pob",
              "Barangay 5 Pob",
              "Barangay 6 Pob",
              "Barangay 7 Pob",
              "Barangay 8 Pob",
              "Barangay 9 Pob",
              "Don Paulino",
              "Osmena",
              "San Carlos",
              "San Miguel",
              "Santa Fe",
              "Santa Felomina",
              "Union"
            ]
          },
          {
            "k": "Del Carmen",
            "v": [
              "Antipolo",
              "Bagakay Alburo",
              "Bitoon",
              "Cabugao",
              "Cancohoy",
              "Caub",
              "Domoyog",
              "Esperanza",
              "Halian",
              "Jamoyaon",
              "Katipunan",
              "Lobogon",
              "Mabuhay",
              "Mahayahay",
              "Quezon",
              "San Fernando",
              "San Jose Pob",
              "Sayak",
              "Tuboran"
            ]
          },
          {
            "k": "General Luna",
            "v": [
              "Anajawan",
              "Cabitoonan",
              "Catangnan",
              "Consuelo",
              "Corazon",
              "Daku",
              "La Januza",
              "Libertad",
              "Magsaysay",
              "Malinao",
              "Poblacion I Purok I",
              "Poblacion Ii Purok Ii",
              "Poblacion Iii Purok Iii",
              "Poblacion Iv Purok Iv",
              "Poblacion V Purok V",
              "Santa Cruz",
              "Santa Fe",
              "Suyangan",
              "Tawin Tawin"
            ]
          },
          {
            "k": "Gigaquit",
            "v": [
              "Alambique Pob",
              "Ipil Pob",
              "Mahanub",
              "Poniente",
              "San Antonio Bonot",
              "San Isidro",
              "Villaflor",
              "Villafranca"
            ]
          },
          {
            "k": "Mainit",
            "v": [
              "Mabini",
              "Magpayang",
              "Mansayao",
              "Matin Ao",
              "Paco",
              "Quezon Pob",
              "Roxas",
              "San Francisco",
              "San Isidro",
              "San Jose",
              "Siana",
              "Silop",
              "Tagbuyawan",
              "Tapi An",
              "Tolingon"
            ]
          },
          { "k": "Malimono", "v": ["Cantapoy", "Doro Binocaran", "Hanagdong"] },
          { "k": "Pilar", "v": ["Caridad", "Maasin", "Mabuhay"] },
          {
            "k": "Placer",
            "v": [
              "Amoslog",
              "Bad As",
              "Bugas Bugas",
              "Ipil Pob",
              "Pananay An",
              "Santa Cruz",
              "Tagbongabong"
            ]
          },
          { "k": "San Benito", "v": ["Nuevo Campo", "San Juan"] },
          {
            "k": "San Francisco Anao Aon",
            "v": [
              "Amontay",
              "Balite",
              "Banbanon",
              "Diaz",
              "Honrado",
              "Jubgan",
              "Linongganan",
              "Oslao"
            ]
          },
          {
            "k": "San Isidro",
            "v": [
              "Del Carmen Pob",
              "Del Pilar",
              "Macapagal",
              "Pacifico",
              "Roxas",
              "Santo Nino",
              "Tigasao"
            ]
          },
          {
            "k": "Santa Monica Sapao",
            "v": [
              "Abad Santos",
              "Alegria",
              "Libertad",
              "Mabini",
              "Magsaysay",
              "Rizal",
              "Tangbo"
            ]
          },
          {
            "k": "Surigao City",
            "v": [
              "Anomar",
              "Bonifacio",
              "Cagniog",
              "Canlanipa",
              "Ipil",
              "Lipata",
              "Luna",
              "Mabua",
              "Punta Bilar",
              "Quezon",
              "Rizal",
              "Sabang",
              "San Isidro",
              "San Juan",
              "San Roque",
              "Taft Pob",
              "Togbongon",
              "Washington Pob"
            ]
          },
          {
            "k": "Tubod",
            "v": [
              "Cawilan",
              "Del Rosario",
              "Marga",
              "Motorpool",
              "Poblacion Tubod",
              "San Isidro",
              "San Pablo",
              "Timamana"
            ]
          }
        ],
        "names": [
          "Alegria",
          "Bacuag",
          "Burgos",
          "Claver",
          "Dapa",
          "Del Carmen",
          "General Luna",
          "Gigaquit",
          "Mainit",
          "Malimono",
          "Pilar",
          "Placer",
          "San Benito",
          "San Francisco Anao Aon",
          "San Isidro",
          "Santa Monica Sapao",
          "Surigao City",
          "Tubod"
        ]
      }
    },
    {
      "k": "Surigao Del Sur",
      "v": {
        "lookup": [
          {
            "k": "Barobo",
            "v": [
              "Amaga",
              "Bahi",
              "Causwagan",
              "Dughan",
              "Poblacion",
              "San Vicente",
              "Wakat"
            ]
          },
          { "k": "Bayabas", "v": ["Amag", "Magobawok"] },
          {
            "k": "Bislig City",
            "v": [
              "Coleto",
              "Cumawas",
              "Kahayag",
              "Maharlika",
              "Mangagoy City Downtown",
              "Mone",
              "Poblacion",
              "San Fernando",
              "San Isidro Bagnan",
              "San Jose",
              "San Roque Cadanglasan",
              "San Vicente",
              "Tabon",
              "Tumanan"
            ]
          },
          { "k": "Cagwait", "v": ["Aras Asan", "Poblacion"] },
          {
            "k": "Cantilan",
            "v": [
              "Calagdaan",
              "Lininti An Pob",
              "Magasang",
              "Magosilom Pob",
              "Pag Antayan",
              "Palasao",
              "Parang",
              "Poblacion",
              "Puyat",
              "San Pedro",
              "Tigabong"
            ]
          },
          {
            "k": "Carmen",
            "v": [
              "Agsam High-way",
              "Antao",
              "Bucaue High-way",
              "Bunga",
              "Cancavan",
              "Carmen Proper",
              "Esperanza",
              "Gamuton Higway",
              "Hinayupan",
              "Puyat",
              "Santa Cruz"
            ]
          },
          { "k": "Carrascal", "v": ["Adlay", "Bon Ot", "Gamuton", "Panikian"] },
          {
            "k": "Cortes",
            "v": ["Balibadon", "Burgos", "Mabahin", "Manlico", "Matho", "Tigao"]
          },
          {
            "k": "Hinatuan",
            "v": [
              "Benigno Aquino Zone I Pob",
              "Bigaan",
              "La Casa Pob",
              "Tagasaka",
              "Zone Ii Pob",
              "Zone Ii Sto Nino Pob",
              "Zone Iii Maharlika Pob"
            ]
          },
          {
            "k": "Lanuza",
            "v": ["Agsam", "Bunga", "Gamuton", "Habag", "Nurcia", "Sibahay"]
          },
          {
            "k": "Lianga",
            "v": [
              "Anibongan",
              "Ban As",
              "Banahao",
              "Baucawe",
              "Diatagon",
              "Ganayon",
              "Liatimco",
              "Payasan",
              "Poblacion",
              "Saint Christine"
            ]
          },
          {
            "k": "Lingig",
            "v": ["Pagtila An", "Poblacion", "Sabang", "San Roque", "Union"]
          },
          {
            "k": "Madrid",
            "v": [
              "Bagsac",
              "Bayogo",
              "Linibonan",
              "Quirino Poblacion",
              "San Juan",
              "San Roque",
              "Union"
            ]
          },
          {
            "k": "Marihatag",
            "v": ["Amontay", "Antipolo", "Arorogan", "Poblacion"]
          },
          { "k": "San Agustin", "v": ["Poblacion"] },
          {
            "k": "Tagbina",
            "v": [
              "Dona Carmen",
              "Maglambing",
              "Poblacion",
              "Quezon",
              "Santa Juana",
              "Santa Maria",
              "Tagongon"
            ]
          },
          { "k": "Tago", "v": ["Victoria"] },
          {
            "k": "Tandag City",
            "v": [
              "Awasian",
              "Bagong Lungsod Pob",
              "Bioto",
              "Bongtod Pob East West",
              "Buenavista",
              "Dagocdoc Pob",
              "Mabua",
              "Pangi",
              "Rosario",
              "Salvacion",
              "San Agustin Norte",
              "San Agustin Sur",
              "Telaje"
            ]
          }
        ],
        "names": [
          "Barobo",
          "Bayabas",
          "Bislig City",
          "Cagwait",
          "Cantilan",
          "Carmen",
          "Carrascal",
          "Cortes",
          "Hinatuan",
          "Lanuza",
          "Lianga",
          "Lingig",
          "Madrid",
          "Marihatag",
          "San Agustin",
          "Tagbina",
          "Tago",
          "Tandag City"
        ]
      }
    },
    {
      "k": "Tarlac",
      "v": {
        "lookup": [
          {
            "k": "Anao",
            "v": [
              "Baguindoc (Baguinloc)",
              "Bantog",
              "Campos",
              "Carmen",
              "Casili",
              "Don Ramon",
              "Hernando",
              "Poblacion",
              "Rizal",
              "San Francisco East",
              "San Francisco West",
              "San Jose North",
              "San Jose South",
              "San Juan",
              "San Roque",
              "Santo Domingo",
              "Sinense",
              "Suaverdez"
            ]
          },
          {
            "k": "Bamban",
            "v": [
              "Anupul",
              "Bamban Dapdap",
              "Banaba",
              "Bangcu",
              "Culubasa",
              "Dela Cruz",
              "La Paz",
              "Lourdes",
              "Magurulgurol",
              "Malonzo",
              "Pag Asa",
              "Panaisan",
              "Pandan",
              "Ph 1 Bamban Banaba",
              "Ph 2 Layak",
              "Ph 2 Macapul",
              "Rofil",
              "Sacot",
              "San Nicolas (Pob.)",
              "San Pedro",
              "San Rafael",
              "San Roque"
            ]
          },
          {
            "k": "Camiling",
            "v": [
              "Anoling 1st",
              "Anoling 2nd",
              "Anoling 3rd",
              "Bacabac",
              "Bancay 1st",
              "Bancay 2nd",
              "Bilad",
              "Bobon 1st",
              "Bobon 2nd",
              "Bobon Caarosipan",
              "Cabanabaan",
              "Cacamilingan Norte",
              "Cacamilingan Sur",
              "Caniag",
              "Carael",
              "Cayaoan",
              "Florida",
              "Lasong",
              "Libueg",
              "Malacampa",
              "Marawi",
              "Matubog",
              "Nagserialan",
              "Palimbo Proper",
              "Palimbo-caarosipan",
              "Pao 1st",
              "Pao 2nd",
              "Pao 3rd",
              "Poblacion 1",
              "Poblacion B",
              "Poblacion C",
              "Poblacion D",
              "Poblacion E",
              "Poblacion F",
              "Poblacion G",
              "Poblacion H",
              "Poblacion I",
              "Poblacion J",
              "Santa Maria",
              "Sawat",
              "Sinilian 1st",
              "Sinilian 2nd",
              "Sinilian 3rd",
              "Sinilian Cacalibosoan",
              "Sinulatan 1st",
              "Sinulatan 2nd",
              "Surgui 1st",
              "Surgui 2nd",
              "Surgui 3rd",
              "Tambugan",
              "Telbang",
              "Tuec"
            ]
          },
          {
            "k": "Capas",
            "v": [
              "Aranguren",
              "Cristo Rey",
              "Cubcub (Pob.)",
              "Cutcut 1st",
              "Cutcut 2nd",
              "Dolores",
              "Estrada (Calingcuan)",
              "Kalangitan",
              "Kawiliwili",
              "Lawy",
              "Manga",
              "Manlapig",
              "O'donnell",
              "Santa Lucia",
              "Santa Rita",
              "Santo Domingo 1st",
              "Santo Domingo 2nd",
              "Santo Rosario",
              "Talaga"
            ]
          },
          {
            "k": "City Of Tarlac",
            "v": [
              "Aguso",
              "Alvindia Segundo",
              "Amucao",
              "Armenia",
              "Atioc",
              "Balete",
              "Balibago I",
              "Balibago Ii",
              "Balingcanaway",
              "Banaba",
              "Baras-baras",
              "Batang-batang",
              "Binauganan",
              "Bora",
              "Buenavista",
              "Buhilit (Bubulit)",
              "Burot",
              "Calingcuan",
              "Capehan",
              "Carangian",
              "Care",
              "Central",
              "Culipat",
              "Cut-cut I",
              "Dalayap",
              "Dela Paz",
              "Dolores",
              "Laoang",
              "Ligtasan",
              "Lourdes",
              "Mabini",
              "Maligaya",
              "Maliwalo",
              "Mapalacsiao",
              "Mapalad",
              "Matadero",
              "Matatalaib",
              "Paraiso",
              "Poblacion",
              "Salapungan",
              "San Carlos",
              "San Francisco",
              "San Isidro",
              "San Jose",
              "San Jose De Urquico",
              "San Juan De Mata",
              "San Luis",
              "San Manuel",
              "San Miguel",
              "San Nicolas",
              "San Pablo",
              "San Pascual",
              "San Rafael",
              "San Roque",
              "San Sebastian",
              "San Vicente",
              "Santa Cruz (Alvindia Primero)",
              "Santa Maria",
              "Santo Cristo",
              "Santo Domingo",
              "Santo NiÃ±o",
              "Sapang Maragul",
              "Sapang Tagalog",
              "Sepung Calzada",
              "Sinait",
              "Suizo",
              "Tariji",
              "Tibag",
              "Tibagan",
              "Trinidad (Trinidad Primero)",
              "Ungot",
              "Villa Bacolor"
            ]
          },
          {
            "k": "Concepcion",
            "v": [
              "Alfonso",
              "Balutu",
              "Cafe",
              "Caluluan",
              "Corazon De Jesus",
              "Culatingan",
              "Dungan",
              "Dutung-a-matas",
              "Green Village",
              "Malupa",
              "Minane",
              "Panalicsian (Panalicsican)",
              "Parulung",
              "Pitabunan",
              "San Agustin (Murcia)",
              "San Antonio",
              "San Francisco",
              "San Jose (Pob.)",
              "San Juan (Castro)",
              "San Nicolas Balas",
              "San Vicente (Caluis/cobra)",
              "Santa Cruz",
              "Santa Maria",
              "Santa Monica",
              "Santa Rita",
              "Santa Rosa",
              "Santiago",
              "Santo Cristo",
              "Santo Rosario (Magunting)",
              "Talimunduc San Miguel",
              "Tinang"
            ]
          },
          {
            "k": "Gerona",
            "v": [
              "Abagon",
              "Amacalan",
              "Apsayan",
              "Ayson",
              "Bawa",
              "Buenlag",
              "Bularit",
              "Calayaan",
              "Carbonel",
              "Cardona",
              "Caturay",
              "Danzo",
              "Dicolor",
              "Don Basilio",
              "Luna",
              "Mabini",
              "Magaspac",
              "Malayep",
              "Matapitap",
              "Matayumcab",
              "New Salem",
              "Oloybuaya",
              "Padapada",
              "Parsolingan",
              "Pinasling (Pinasung)",
              "Plastado",
              "Poblacion 1",
              "Poblacion 2",
              "Poblacion 3",
              "Rizal",
              "Salapungan",
              "San Agustin",
              "San Antonio",
              "Sembrano",
              "Singat",
              "Sulipa",
              "Tagumbao",
              "Villa Paz"
            ]
          },
          {
            "k": "La Paz",
            "v": [
              "Balanoy",
              "Bantog-caricutan",
              "Caramutan",
              "Caut",
              "Comillas",
              "Dumarais",
              "Guevarra",
              "Kapanikian",
              "La Purisima",
              "Lara",
              "Laungcupang",
              "Lomboy",
              "Macalong",
              "Matayumtayum",
              "Mayang",
              "Motrico",
              "Paludpud",
              "Rizal",
              "San Isidro (Pob.)",
              "San Roque (Pob.)",
              "Sierra"
            ]
          },
          {
            "k": "Mayantoc",
            "v": [
              "Ambalingit",
              "Baybayaoas",
              "Binbinaca",
              "Caocaoayan",
              "Carabaoan",
              "Cubcub",
              "Mamonit",
              "Pedro L. Quines",
              "Pitombayog",
              "Poblacion Norte",
              "Poblacion Sur",
              "Rotrottooc",
              "San Bartolome",
              "San Jose",
              "Taldiapan"
            ]
          },
          {
            "k": "Moncada",
            "v": [
              "Ablang-sapang",
              "Aringin",
              "Atencio",
              "Banaoang East",
              "Banaoang West",
              "Baquero Norte",
              "Burgos",
              "Calamay",
              "Calapan",
              "Camangaan East",
              "Camangaan West",
              "Camposanto 1 - Norte",
              "Camposanto 1 - Sur",
              "Camposanto 2",
              "Capaoayan",
              "Lapsing",
              "Mabini",
              "Maluac",
              "Poblacion 1",
              "Poblacion 2",
              "Poblacion 3",
              "Poblacion 4",
              "Rizal",
              "San Juan",
              "San Julian",
              "San Leon",
              "San Pedro",
              "Santa Lucia East",
              "Santa Lucia West",
              "Santa Maria",
              "Tolega Norte",
              "Tolega Sur",
              "Tubectubang",
              "Villa"
            ]
          },
          {
            "k": "Paniqui",
            "v": [
              "Abogado",
              "Acocolao",
              "Aduas",
              "Apulid",
              "Balaoang",
              "Barang (Borang)",
              "Canan",
              "Carino",
              "Cayanga",
              "Colibangbang",
              "Coral",
              "Dapdap",
              "Estacion",
              "Manaois",
              "Matalapitap",
              "Nancamarinan",
              "Nipaco",
              "Patalan",
              "Poblacion Norte",
              "Poblacion Sur",
              "Rang-ayan",
              "Salumague",
              "Samput",
              "San Isidro",
              "San Juan De Milla",
              "Santa Ines",
              "Tablang",
              "Ventenilla"
            ]
          },
          {
            "k": "Pura",
            "v": [
              "Balite",
              "Buenavista",
              "Cadanglaan",
              "Estipona",
              "Linao",
              "Maasin",
              "Matindeg",
              "Maungib",
              "Naya",
              "Nilasin 1st",
              "Nilasin 2nd",
              "Poblacion 1",
              "Poblacion 2",
              "Poblacion 3",
              "Poroc",
              "Singat"
            ]
          },
          {
            "k": "Ramos",
            "v": [
              "Coral-iloco",
              "Guiteb",
              "Pance",
              "Poblacion Center",
              "Poblacion North",
              "Poblacion South",
              "San Juan",
              "San Raymundo",
              "Toledo"
            ]
          },
          {
            "k": "San Clemente",
            "v": [
              "Balloc",
              "Casipo",
              "Catagudingan",
              "Daldalayap",
              "Doclong 1",
              "Doclong 2",
              "Nagsabaran",
              "Pit-ao",
              "Poblacion Norte",
              "Poblacion Sur"
            ]
          },
          {
            "k": "San Jose",
            "v": [
              "Labney",
              "Lawacamulag",
              "San Juan De Valdez",
              "Villa Aglipay"
            ]
          },
          {
            "k": "San Manuel",
            "v": [
              "Colubot",
              "Lanat",
              "Legaspi",
              "Mangandingay",
              "Pacpaco",
              "Poblacion",
              "Salcedo",
              "San Agustin",
              "San Felipe",
              "San Jacinto",
              "San Miguel",
              "San Narciso",
              "San Vicente",
              "Santa Maria"
            ]
          },
          {
            "k": "Santa Ignacia",
            "v": [
              "Baldios",
              "Botbotones",
              "Caanamongan",
              "Cabaruan",
              "Cabugbugan",
              "Caduldulaoan",
              "Macaguing",
              "Nambalan",
              "Padapada",
              "Pilpila",
              "Pinpinas",
              "Poblacion East",
              "Poblacion West",
              "Pugo-cecilio",
              "San Francisco",
              "San Vicente",
              "Santa Ines Centro",
              "Santa Ines East",
              "Santa Ines West",
              "Taguiporo",
              "Timmaguab",
              "Vargas"
            ]
          },
          {
            "k": "Victoria",
            "v": [
              "Baculong",
              "Balayang",
              "Balbaloto",
              "Bangar",
              "Batangbatang",
              "Bulo",
              "Cabuluan",
              "Calibungan",
              "Canarem",
              "Cruz",
              "Maluid",
              "Palacpalac",
              "San Agustin",
              "San Andres",
              "San Fernando (Pob.)",
              "San Francisco",
              "San Gavino (Pob.)",
              "San Jacinto",
              "San Nicolas (Pob.)",
              "San Vicente",
              "Santa Barbara",
              "Santa Lucia (Pob.)"
            ]
          }
        ],
        "names": [
          "Anao",
          "Bamban",
          "Camiling",
          "Capas",
          "City Of Tarlac",
          "Concepcion",
          "Gerona",
          "La Paz",
          "Mayantoc",
          "Moncada",
          "Paniqui",
          "Pura",
          "Ramos",
          "San Clemente",
          "San Jose",
          "San Manuel",
          "Santa Ignacia",
          "Victoria"
        ]
      }
    },
    {
      "k": "Zambales",
      "v": {
        "lookup": [
          {
            "k": "Botolan",
            "v": [
              "Bancal",
              "Bangan",
              "Batonlapoc",
              "Beneg",
              "Binuclutan",
              "Capayawan",
              "Carael",
              "Danacbunga",
              "Mambog",
              "Paco (Pob.)",
              "Panan",
              "Parel",
              "Paudpod",
              "Porac",
              "San Isidro",
              "San Juan",
              "San Miguel",
              "Santiago",
              "Tampo (Pob.)",
              "Taugtog"
            ]
          },
          {
            "k": "Cabangan",
            "v": [
              "Anonang",
              "Apo-apo",
              "Arew",
              "Banuambayo (Pob.)",
              "Cadmang-reserva",
              "Camiling (Camiing)",
              "Casabaan",
              "Del Carmen (Pob.)",
              "Dolores (Pob.)",
              "Felmida-diaz",
              "Laoag",
              "Lomboy",
              "Longos",
              "Mabanglit",
              "New San Juan",
              "San Antonio",
              "San Isidro",
              "San Juan (Pob.)",
              "San Rafael",
              "Santa Rita",
              "Santo NiÃ±o",
              "Tondo"
            ]
          },
          {
            "k": "Candelaria",
            "v": [
              "Babancal",
              "Binabalian",
              "Catol",
              "Lauis",
              "Libertador",
              "Malabon (San Roque)",
              "Malimanga",
              "Pamibian",
              "Panayonan",
              "Pinagrealan",
              "Poblacion",
              "Sinabacan",
              "Uacon",
              "Yamot"
            ]
          },
          {
            "k": "Castillejos",
            "v": [
              "Balaybay",
              "Buenavista",
              "Del Pilar",
              "Looc",
              "Magsaysay",
              "Nagbayan",
              "Nagbunga",
              "San Agustin",
              "San Jose (Pob)",
              "San Juan (Pob.)",
              "San Nicolas",
              "San Pablo (Pob.)",
              "San Roque",
              "Santa Maria"
            ]
          },
          {
            "k": "Iba",
            "v": [
              "Amungan",
              "Bangantalinga",
              "Dirita-baloguen",
              "Lipay-dingin-panibuatan",
              "Palanginan (Palanguinan-tambak)",
              "San Agustin",
              "Santa Barbara",
              "Santo Rosario",
              "Zone 1 Pob. (Libaba)",
              "Zone 2 Pob. (Aypa)",
              "Zone 3 Pob. (Botlay)",
              "Zone 4 Pob. (Sagapan)",
              "Zone 5 Pob. (Bano)",
              "Zone 6 Pob. (Baytan)"
            ]
          },
          {
            "k": "Masinloc",
            "v": [
              "Baloganon",
              "Bamban",
              "Bani",
              "Collat",
              "Inhobol",
              "North Poblacion",
              "San Lorenzo",
              "Santa Rita",
              "Santo Rosario",
              "South Poblacion",
              "Taltal",
              "Tapuac"
            ]
          },
          {
            "k": "Olongapo City",
            "v": [
              "Asinan",
              "Banicain",
              "Barreto",
              "East Bajac-bajac",
              "East Tapinac",
              "Gordon Heights",
              "Kalaklan",
              "Mabayuan",
              "New Cabalan",
              "New Ilalim",
              "New Kababae",
              "New Kalalake",
              "Old Cabalan",
              "Pag-asa",
              "Santa Rita",
              "Subic Bay Freeport Zone(sbma)",
              "West Bajac-bajac",
              "West Tapinac"
            ]
          },
          {
            "k": "Palauig",
            "v": [
              "Alwa",
              "Bato",
              "Bulawen",
              "Cauyan",
              "East Poblacion",
              "Garreta",
              "Libaba",
              "Liozon",
              "Locloc",
              "Macarang",
              "Pangolingan",
              "Salaza",
              "San Juan",
              "Santo NiÃ±o",
              "Santo Tomas",
              "Tition (San Vicente)",
              "West Poblacion"
            ]
          },
          {
            "k": "San Antonio",
            "v": [
              "Angeles",
              "Antipolo (Pob.)",
              "Burgos (Pob.)",
              "East Dirita",
              "Luna (Pob.)",
              "Pundaquit",
              "Rizal",
              "San Esteban",
              "San Gregorio (Pob.)",
              "San Juan (Pob.)",
              "San Miguel",
              "San Nicolas (Pob.)",
              "Santiago",
              "West Dirita"
            ]
          },
          {
            "k": "San Felipe",
            "v": [
              "Amagna (Pob.)",
              "Apostol (Pob.)",
              "Balincaguing",
              "FaraÃ±al (Pob.)",
              "Feria (Pob.)",
              "Maloma",
              "Manglicmot (Pob.)",
              "Rosete (Pob.)",
              "San Rafael",
              "Santo NiÃ±o",
              "Sindol"
            ]
          },
          {
            "k": "San Marcelino",
            "v": [
              "Aglao",
              "Buhawen",
              "Burgos (Pob.)",
              "Central (Pob.)",
              "Consuelo Norte",
              "Consuelo Sur (Pob.)",
              "La Paz (Pob.)",
              "Laoag",
              "Linasin",
              "Linusungan",
              "Lucero (Pob.)",
              "Nagbunga",
              "Rabanes",
              "Rizal (Pob.)",
              "San Guillermo (Pob.)",
              "San Isidro (Pob.)",
              "San Rafael",
              "Santa Fe"
            ]
          },
          {
            "k": "San Narciso",
            "v": [
              "Alusiis",
              "Beddeng",
              "Candelaria (Pob.)",
              "Dallipawen",
              "Grullo",
              "La Paz",
              "Libertad (Pob.)",
              "Natividad (Pob.)",
              "Paite",
              "Patrocinio (Pob.)",
              "San Jose (Pob)",
              "San Juan (Pob.)",
              "San Pascual (Pob.)",
              "San Rafael (Pob.)",
              "Siminublan"
            ]
          },
          {
            "k": "Santa Cruz",
            "v": [
              "Bayto",
              "Biay",
              "Gama",
              "Lipay",
              "Lucapon North",
              "Lucapon South",
              "Malabago",
              "Naulo",
              "Pagatpat",
              "Poblacion North",
              "Poblacion South",
              "San Fernando",
              "Tubotubo North"
            ]
          },
          {
            "k": "Subic",
            "v": [
              "Aningway Sacatihan",
              "Asinan Poblacion",
              "Asinan Proper",
              "Baraca-camachile (Pob.)",
              "Batiawan",
              "Calapacuan",
              "Calapandayan (Pob.)",
              "Cawag",
              "Ilwas (Pob.)",
              "Mangan-vaca",
              "Matain",
              "Naugsol",
              "Pamatawan",
              "San Isidro",
              "Santo Tomas",
              "Wawandue (Pob.)"
            ]
          }
        ],
        "names": [
          "Botolan",
          "Cabangan",
          "Candelaria",
          "Castillejos",
          "Iba",
          "Masinloc",
          "Olongapo City",
          "Palauig",
          "San Antonio",
          "San Felipe",
          "San Marcelino",
          "San Narciso",
          "Santa Cruz",
          "Subic"
        ]
      }
    },
    {
      "k": "Zamboanga Del Norte",
      "v": {
        "lookup": [
          {
            "k": "Baliguian",
            "v": [
              "Alegria",
              "Kauswagan",
              "Mamad",
              "Nonoyan",
              "Poblacion",
              "San Jose",
              "Tamao"
            ]
          },
          {
            "k": "Dapitan City",
            "v": [
              "Bagting Pob",
              "Banonong Pob",
              "Barcelona",
              "Burgos",
              "Cawa Cawa Pob",
              "Dampalan",
              "Dawo Pob",
              "Ilaya",
              "Larayan",
              "Linabo Pob",
              "Liyang",
              "Maria Cristina",
              "Opao",
              "Owaon",
              "Oyan",
              "Polo",
              "Potol Pob",
              "Potungan",
              "San Nicolas",
              "San Pedro",
              "San Vicente",
              "Santa Cruz Pob",
              "Santo Nino",
              "Sicayab Bocana",
              "Sinonoc",
              "Sulangon",
              "Tamion"
            ]
          },
          {
            "k": "Dipolog City",
            "v": [
              "Barra Pob",
              "Biasong Pob",
              "Central Pob",
              "Cogon",
              "Dicayas",
              "Estaca Pob",
              "Galas",
              "Gulayon",
              "Minaog",
              "Miputak Pob",
              "Olingan",
              "Punta",
              "Sangkol",
              "Santa Filomena",
              "Santa Isabel",
              "Sicayab",
              "Sinaman",
              "Turno"
            ]
          },
          { "k": "Kalawit", "v": ["Gatas"] },
          {
            "k": "Katipunan",
            "v": [
              "Barangay Dos Pob",
              "Barangay Uno Pob",
              "Loyuran",
              "Mias",
              "New Tambo",
              "San Antonio Looy",
              "Santo Nino",
              "Sinuyak"
            ]
          },
          {
            "k": "Labason",
            "v": ["Bobongan", "Dansalan", "Malintuboan", "Ubay"]
          },
          {
            "k": "Liloy",
            "v": [
              "Baybay Pob",
              "Candelaria",
              "Causwagan",
              "Communal",
              "Compra",
              "Dela Paz",
              "Fatima",
              "Kayok",
              "Lamao",
              "Maigang",
              "Overview",
              "Patawag",
              "San Francisco",
              "San Miguel",
              "Timan",
              "Villa M Tejero"
            ]
          },
          {
            "k": "Manukan",
            "v": [
              "Don Jose Aguirre",
              "East Poblacion",
              "Linay",
              "Poblacion",
              "Punta Blanca",
              "San Antonio",
              "Villaramos"
            ]
          },
          {
            "k": "Pinan New Pinan",
            "v": ["Del Pilar", "Poblacion North", "Poblacion South", "Segabe"]
          },
          {
            "k": "Polanco",
            "v": [
              "Guinles",
              "Lingasad",
              "Obay",
              "Poblacion North",
              "Poblacion South"
            ]
          },
          {
            "k": "Pres Manuel A Roxas",
            "v": ["Dohinob", "Langatian", "Nabilid", "Piao", "Upper Irasan"]
          },
          {
            "k": "Rizal",
            "v": [
              "East Poblacion",
              "New Dapitan",
              "North Mapang",
              "South Mapang",
              "Tolon",
              "West Poblacion"
            ]
          },
          {
            "k": "Salug",
            "v": [
              "Bacong",
              "Canawan",
              "Lanawan",
              "Mucas",
              "Poblacion East",
              "Ramon Magsaysay"
            ]
          },
          { "k": "Siayan", "v": ["Gunyan", "Suguilon"] },
          {
            "k": "Sindangan",
            "v": [
              "Bago",
              "Balok",
              "Bantayan",
              "Binuangan",
              "Calatunan",
              "Datu Tangkilan",
              "Dicoyong",
              "Disud",
              "Don Ricardo Macias Dinobot",
              "Dona Josefa",
              "Gampis",
              "Goleo",
              "La Concepcion",
              "La Roche San Miguel",
              "Labakid",
              "Magsaysay",
              "Mandih",
              "Maras",
              "Misok",
              "Motibot",
              "Nipaan",
              "Pangalalan",
              "Piao",
              "Poblacion",
              "Santo Rosario",
              "Siare",
              "Talinga",
              "Tigbao",
              "Upper Inuman"
            ]
          },
          {
            "k": "Siocon",
            "v": [
              "Andres Micubo Jr Balili",
              "Jose P Brillantes, Sr Old Lituban",
              "Malipot",
              "Manaol",
              "Mateo Francisco",
              "New Lituban",
              "Pangian",
              "Poblacion",
              "S Cabral",
              "Siay",
              "Tagaytay"
            ]
          },
          {
            "k": "Sirawai",
            "v": [
              "Balubuan",
              "Bitugan",
              "Dona Cecilia",
              "Lubok",
              "Napulan",
              "Piacan",
              "Pina",
              "Pugos",
              "Pula Bato",
              "Saint Mary Pob",
              "San Nicolas Pob",
              "San Roque Pob",
              "San Vicente Pob",
              "Sirawai Proper Pob",
              "Talabiga",
              "Tapanayan"
            ]
          },
          {
            "k": "Tampilisan",
            "v": [
              "Camul",
              "Lumbayao",
              "Molos",
              "Poblacion Tampilisan",
              "Santo Nino",
              "Znac"
            ]
          }
        ],
        "names": [
          "Baliguian",
          "Dapitan City",
          "Dipolog City",
          "Kalawit",
          "Katipunan",
          "Labason",
          "Liloy",
          "Manukan",
          "Pinan New Pinan",
          "Polanco",
          "Pres Manuel A Roxas",
          "Rizal",
          "Salug",
          "Siayan",
          "Sindangan",
          "Siocon",
          "Sirawai",
          "Tampilisan"
        ]
      }
    },
    {
      "k": "Zamboanga Del Sur",
      "v": {
        "lookup": [
          {
            "k": "Aurora",
            "v": [
              "Anonang",
              "Balintawak",
              "Bayabas",
              "Campo Uno",
              "Commonwealth",
              "Kahayagan East Katipunan",
              "Kahayagan West",
              "Kauswagan",
              "La Paz Tinibtiban",
              "La Victoria",
              "Libertad",
              "Lintugop",
              "Maguikay",
              "Mahayahay",
              "Monte Alegre",
              "Montela",
              "Poblacion",
              "Romarate",
              "San Jose",
              "San Juan",
              "Sapa Loboc",
              "Waterfall"
            ]
          },
          {
            "k": "Dimataling",
            "v": [
              "Balanagan",
              "Kagawasan",
              "Poblacion",
              "Tinggabulong",
              "Tiniguangan"
            ]
          },
          {
            "k": "Dinas",
            "v": [
              "Don Jose",
              "Legarda 1",
              "Legarda 2",
              "Poblacion",
              "Proper Dimaya"
            ]
          },
          {
            "k": "Dumalinao",
            "v": ["Camanga", "Pag Asa Pob", "Sibucao", "Sumadat", "Tikwas"]
          },
          {
            "k": "Dumingag",
            "v": [
              "Bagong Kauswagan",
              "Caridad",
              "Guitran",
              "Libertad",
              "Lower Landing",
              "Lower Timonan",
              "San Pablo Pob",
              "San Pedro Pob",
              "Upper Landing",
              "Upper Timonan"
            ]
          },
          {
            "k": "Guipos",
            "v": [
              "Bagong Oroquieta",
              "Balongating",
              "Canunan",
              "Katipunan",
              "Poblacion Guipos"
            ]
          },
          {
            "k": "Josefina",
            "v": [
              "Gumahan Pob",
              "Litapan",
              "Lower Bagong Tudela",
              "Nemeno",
              "Nopulan",
              "Upper Bagong Tudela Pob"
            ]
          },
          { "k": "Kumalarang", "v": ["Poblacion"] },
          {
            "k": "Labangan",
            "v": ["Bulanit", "Dalapang", "Lantian", "Tapodoc", "Tawagan Norte"]
          },
          { "k": "Lakewood", "v": ["Tubod"] },
          {
            "k": "Lapuyan",
            "v": ["Bulawan", "Molum", "Poblacion", "Salambuyan"]
          },
          {
            "k": "Mahayag",
            "v": [
              "Boniao",
              "Kaangayan",
              "Manguiles",
              "Panagaan",
              "San Isidro",
              "San Jose",
              "San Vicente",
              "Santa Cruz",
              "Upper Salug Daku"
            ]
          },
          {
            "k": "Margosatubig",
            "v": ["Kalian", "Limabatong", "Lumbog", "Poblacion", "Sagua"]
          },
          {
            "k": "Midsalip",
            "v": [
              "Bacahan",
              "Canipay Norte",
              "Canipay Sur",
              "Guma",
              "Poblacion A",
              "Poblacion B"
            ]
          },
          {
            "k": "Molave",
            "v": [
              "Bagong Argao",
              "Blancia",
              "Culo",
              "Dipolo",
              "Miligan",
              "Rizal",
              "Sudlon"
            ]
          },
          {
            "k": "Pagadian City",
            "v": [
              "Alegria",
              "Balangasan Pob",
              "Balintawak",
              "Baloyboan",
              "Banale",
              "Bogo",
              "Bomba",
              "Buenavista",
              "Bulatok",
              "Danlugan",
              "Dao",
              "Dumagoc",
              "Gatas Pob",
              "Kagawasan",
              "Kawit",
              "Lenienza",
              "Lumbia",
              "Manga",
              "Muricay",
              "Napolan",
              "San Francisco Pob",
              "San Jose Pob",
              "San Pedro Pob",
              "Santa Lucia Pob",
              "Santa Maria",
              "Santiago Pob",
              "Santo Nino",
              "Tawagan Sur",
              "Tiguma",
              "Tuburan Pob",
              "Tulawas",
              "Upper Sibatang"
            ]
          },
          { "k": "Pitogo", "v": ["Poblacion Pitogo", "Sugbay Dos"] },
          {
            "k": "Ramon Magsaysay Liargo",
            "v": [
              "Bambong Diut",
              "Campo Iv",
              "Campo V",
              "Esperanza",
              "Kauswagan",
              "Malating",
              "Paradise",
              "Pasingkalan",
              "Poblacion",
              "San Fernando",
              "Switch"
            ]
          },
          { "k": "San Miguel", "v": ["Dao An", "Limonan", "Mati"] },
          {
            "k": "Sominot Don Mariano Marcos",
            "v": [
              "Eastern Poblacion",
              "Lantawan",
              "Libertad",
              "New Carmen",
              "Poblacion",
              "San Miguel",
              "Upper Sicpao"
            ]
          },
          { "k": "Tabina", "v": ["Abong Abong", "Culabay", "Poblacion"] },
          {
            "k": "Tambulig",
            "v": [
              "Calolot",
              "Gabunon",
              "Lower Lodiong Pob",
              "Lower Tiparak",
              "New Village Pob",
              "Riverside Pob",
              "Upper Tiparak"
            ]
          },
          {
            "k": "Tigbao",
            "v": ["Begong", "Lacupayan", "Maragang", "Nilo", "Upper Nilo"]
          },
          {
            "k": "Tukuran",
            "v": [
              "Camanga",
              "Curvada",
              "Navalan",
              "Panduma Senior",
              "San Carlos Pob",
              "Santo Nino Pob",
              "Upper Bayao"
            ]
          },
          { "k": "Vincenzo A Sagun", "v": ["Cogon", "Kabatan", "Kapatagan"] },
          {
            "k": "Zamboanga City",
            "v": [
              "Arena Blanco",
              "Bolong",
              "Bunguiao",
              "Cabaluay",
              "Culianan",
              "Dita",
              "Labuan",
              "Limpapa",
              "Lubigan",
              "Mampang",
              "Manicahan",
              "Mercedes",
              "Pamucutan",
              "Panubigan",
              "Pasobolong",
              "Patalon",
              "Quiniput",
              "Rio Hondo",
              "Salaan",
              "Taluksangay",
              "Victoria"
            ]
          }
        ],
        "names": [
          "Aurora",
          "Dimataling",
          "Dinas",
          "Dumalinao",
          "Dumingag",
          "Guipos",
          "Josefina",
          "Kumalarang",
          "Labangan",
          "Lakewood",
          "Lapuyan",
          "Mahayag",
          "Margosatubig",
          "Midsalip",
          "Molave",
          "Pagadian City",
          "Pitogo",
          "Ramon Magsaysay Liargo",
          "San Miguel",
          "Sominot Don Mariano Marcos",
          "Tabina",
          "Tambulig",
          "Tigbao",
          "Tukuran",
          "Vincenzo A Sagun",
          "Zamboanga City"
        ]
      }
    },
    {
      "k": "Zamboanga Sibugay",
      "v": {
        "lookup": [
          {
            "k": "Alicia",
            "v": [
              "Lambuyogan",
              "Lutiman",
              "Naga-naga",
              "Poblacion",
              "Timbang-timbang"
            ]
          },
          {
            "k": "Buug",
            "v": [
              "Basalem",
              "Bawang",
              "Bliss",
              "Bulaan",
              "Datu Panas",
              "Del Monte",
              "Guminta",
              "Mabuhay",
              "Maganay",
              "Manlin",
              "Muyo",
              "Pling",
              "Poblacion"
            ]
          },
          {
            "k": "Diplahan",
            "v": [
              "Butong",
              "Goling",
              "Kauswagan",
              "Mejo",
              "Poblacion (Diplahan)",
              "Santa Cruz"
            ]
          },
          {
            "k": "Imelda",
            "v": [
              "Balugo",
              "Baluyan",
              "Gandiangan",
              "La Victoria",
              "Little Baguio",
              "Lumbog",
              "Santa Barbara"
            ]
          },
          {
            "k": "Ipil",
            "v": [
              "Bulu-an",
              "Caparan",
              "Ipil Heights",
              "Lower Ipil Heights",
              "Lower Taway",
              "Magdaup",
              "Poblacion",
              "Sanito",
              "Taway",
              "Tenan",
              "Tiayon",
              "Upper Pangi",
              "Veteran's Village (Ruiz)"
            ]
          },
          {
            "k": "Kabasalan",
            "v": [
              "Bolo Batallion",
              "Buayan",
              "Calapan",
              "Calubihan",
              "Concepcion (Balungis)",
              "Dipala",
              "Goodyear",
              "Nazareth",
              "Poblacion",
              "Simbol",
              "Timuay Danda (Mangahas)"
            ]
          },
          {
            "k": "Malangas",
            "v": ["Candiis", "Guilawa", "La Dicha", "Mabini", "Poblacion"]
          },
          {
            "k": "Naga",
            "v": [
              "Aguinaldo",
              "Baga",
              "Lower Sulitan",
              "Santa Clara",
              "Tambanan",
              "Upper Sulitan"
            ]
          },
          { "k": "Roseller Lim", "v": ["Balansag"] },
          {
            "k": "Siay",
            "v": [
              "Bagong Silang",
              "Balucanan",
              "Batu",
              "Coloran",
              "Kimos (Kima)",
              "Labasan",
              "Mahayahay",
              "Minsulao",
              "Mirangan",
              "Monching",
              "Paruk",
              "Poblacion",
              "Salinding",
              "Siloh"
            ]
          },
          { "k": "Titay", "v": ["Poblacion (Titay)"] }
        ],
        "names": [
          "Alicia",
          "Buug",
          "Diplahan",
          "Imelda",
          "Ipil",
          "Kabasalan",
          "Malangas",
          "Naga",
          "Roseller Lim",
          "Siay",
          "Titay"
        ]
      }
    }
  ],
  "names": [
    "Metro Manila",
    "Abra",
    "Agusan Del Norte",
    "Agusan Del Sur",
    "Aklan",
    "Albay",
    "Antique",
    "Apayao",
    "Aurora",
    "Basilan",
    "Bataan",
    "Batanes",
    "Batangas",
    "Benguet",
    "Biliran",
    "Bohol",
    "Bukidnon",
    "Bulacan",
    "Cagayan",
    "Camarines Norte",
    "Camarines Sur",
    "Camiguin",
    "Capiz",
    "Catanduanes",
    "Cavite",
    "Cebu",
    "Cotabato",
    "Davao De Oro",
    "Davao Del Norte",
    "Davao Del Sur",
    "Davao Occidental",
    "Davao Oriental",
    "Dinagat Islands",
    "Eastern Samar",
    "Guimaras",
    "Ifugao",
    "Ilocos Norte",
    "Ilocos Sur",
    "Iloilo",
    "Isabela",
    "Kalinga",
    "La Union",
    "Laguna",
    "Lanao Del Norte",
    "Lanao Del Sur",
    "Leyte",
    "Maguindanao",
    "Marinduque",
    "Masbate",
    "Misamis Occidental",
    "Misamis Oriental",
    "Mountain Province",
    "Negros Occidental",
    "Negros Oriental",
    "Northern Samar",
    "Nueva Ecija",
    "Nueva Vizcaya",
    "Occidental Mindoro",
    "Oriental Mindoro",
    "Palawan",
    "Pampanga",
    "Pangasinan",
    "Quezon",
    "Quirino",
    "Rizal",
    "Romblon",
    "Samar",
    "Sarangani",
    "Sorsogon",
    "South Cotabato",
    "Southern Leyte",
    "Sultan Kudarat",
    "Sulu",
    "Surigao Del Norte",
    "Surigao Del Sur",
    "Tarlac",
    "Zambales",
    "Zamboanga Del Norte",
    "Zamboanga Del Sur",
    "Zamboanga Sibugay"
  ]
}
