import { lookup as locationData } from "../data/provinces.json";

type LocationDataElement = {
  k: string;
  v:
    | {
        lookup: {
          k: string;
          v: string[];
        }[];
        names?: string[];
      }
    | string[];
};

export default function getLocationChoices(
  province_key?: string,
  city_key?: string
): { id: string; name: string }[] {
  if (province_key === "") {
    return [];
  } else if (province_key && city_key === "") {
    return [];
  }

  let data = locationData;

  const getLevel = (
    locationData: LocationDataElement[],
    key?: string
  ): any[] => {
    const base = locationData.find((d) => d.k === key)?.v;
    if (base) {
      if ("lookup" in base) {
        return base?.lookup ?? [];
      }
    }

    return base ?? [];
  };

  if (province_key && city_key) {
    province_key = province_key.replace("ñ", "Ã±");
    city_key = city_key.replace("ñ", "Ã±");

    return getLevel(getLevel(data, province_key), city_key)
      .map((name) => name.replace("Ã±", "ñ"))
      .map((name) => ({
        id: name,
        name,
      }));
  } else if (province_key) {
    province_key = province_key.replace("ñ", "Ã±");

    data = getLevel(data, province_key);
  }

  return (
    data
      ?.map((d) => d.k.replace("Ã±", "ñ"))
      .map((name) => ({ id: name, name })) ?? []
  );
}
