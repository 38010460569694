import { ReactElement } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  Create,
  CreateProps,
  Datagrid,
  DateTimeInput,
  Edit,
  EditProps,
  FormDataConsumer,
  FunctionField,
  List,
  NumberInput,
  regex,
  SearchInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  TextInputProps,
  AutocompleteInputProps,
} from "react-admin";
import {
  Discount,
  DiscountAdjustment,
  DiscountValue,
  LineItemCondition,
  LineItemFilter,
} from "../rpc";
import { Typography } from "@mui/material";
import pluralize from "pluralize";
import getLocationChoices from "../utils/location_autocomplete";

const discountFilters = [<SearchInput source="query" alwaysOn />];

export const DiscountList = () => (
  <List filters={discountFilters}>
    <Datagrid rowClick="edit">
      <FunctionField label="Discount" render={DiscountTitle} />
      <FunctionField label="Conditions" render={DiscountConditionField} />
      <FunctionField
        label="Adjustment"
        render={(d: Discount) => <DiscountAdjustmentField {...d.adjustment} />}
      />
      <FunctionField
        label="Sequence"
        render={(d: Discount) => printSequence(d.sequence)}
      />
      <FunctionField label="# Used" render={(d: Discount) => QtyUsed(d)} />
    </Datagrid>
  </List>
);

function DiscountTitle(i: Discount): ReactElement {
  return (
    <div>
      <p>{i.code}</p>
      <p>{i.title}</p>
    </div>
  );
}

function DiscountConditionField(d: Discount): ReactElement {
  let subtotalLi;
  if (d.minCurrentSubtotal) {
    subtotalLi = <li>Min Purchase P{d.minCurrentSubtotal}</li>;
  }

  let lineItemConditionLis =
    d.lineItemConditions &&
    d.lineItemConditions.map((c, ndx) => (
      <LineItemConditionLi key={ndx} {...c} />
    ));

  let customerConditionLi;

  if (d.customerFilter?.kind === "CUSTOMER_IDS") {
    customerConditionLi = d.customerFilter?.ids &&
        d.customerFilter.ids.length > 0 && (
          <li>
            {pluralize("Customer Id", d.customerFilter.ids.length)}
            <ul>
              {d.customerFilter.ids.map((cid) => (
                <li key={cid}>{cid}</li>
              ))}
            </ul>
          </li>
        );
  } else if (d.customerFilter?.kind === "CUSTOMER_TAGS") {
    customerConditionLi = d.customerFilter?.tags &&
        d.customerFilter.tags.length > 0 && (
          <li>
            {pluralize("Customer Tag", d.customerFilter.tags.length)}
            <ul>
              {d.customerFilter.tags.map((cid) => (
                <li key={cid}>{cid}</li>
              ))}
            </ul>
          </li>
        );
  }

  if (!subtotalLi && !lineItemConditionLis && !customerConditionLi) {
    return <p>None</p>;
  }

  return (
    <ul>
      {subtotalLi}
      {lineItemConditionLis}
      {customerConditionLi}
    </ul>
  );
}

function LineItemConditionLi(c: LineItemCondition): ReactElement {
  const filterText = printLineItemFilter(c.filter);

  const minCurrentTotalLi = c.minCurrentTotal && (
    <li>Min purchase P{c.minCurrentTotal}</li>
  );
  const minQuantitylLi = c.minQuantity && <li>Min qty {c.minQuantity}</li>;

  return (
    <li>
      <p>{filterText}</p>
      <ul>
        {minCurrentTotalLi}
        {minQuantitylLi}
      </ul>
    </li>
  );
}

function DiscountAdjustmentField(a: DiscountAdjustment): ReactElement {
  const valueText = printDiscountValue(a.value);

  let description;

  switch (a.kind) {
    case "ORDER":
      description = "Order";
      break;
    case "SHIPPING":
      description = "Shipping";
      break;
    case "LINE_ITEM":
      description = `${printLineItemFilter(a.filter)}`;
      break;
  }

  return (
    <div>
      <p>{valueText}</p>
      <p>{description}</p>
    </div>
  );
}

function printLineItemFilter(f: LineItemFilter | undefined): string {
  switch (f?.kind) {
    case "PRODUCT_IDS":
      return `Product Ids - ${f.ids.join(", ")}`;
    case "PRODUCT_TAGS":
      return `Product Tags - ${f.tags.join(", ")}`;
    default:
      return "All Line Items";
  }
}

function printDiscountValue(v: DiscountValue): string {
  return v.maxFixed
    ? v.maxPct >= 100
      ? `P${v.maxFixed} off`
      : `${v.maxPct}% off, ${v.maxFixed} capped`
    : `${v.maxPct}% off`;
}

function printSequence(seq: number): ReactElement {
  return (
    <div>
      <p>{seq}</p>
      <p style={{ fontSize: "10px" }}>{stageForSequence(seq)}</p>
    </div>
  );
}

function QtyUsed(d: Discount): ReactElement {
  let content = d.usageCount ?? 0;
  let suffix = d.usageMax !== undefined ? "/" + d.usageMax : "";

  return (
    <p>
      {content + suffix}
      {d.usageMaxPerCustomer !== undefined && (
        <>
          <br />
          U: {d.usageMaxPerCustomer}
        </>
      )}
    </p>
  );
}

enum DiscountStage {
  PRE_INFO_FETCH = "PRE_INFO_FETCH",
  PRE_FULFILLMENT_PLAN = "PRE_FULFILLMENT_PLAN",
  PRE_SHIPPING_QUOTATION = "PRE_SHIPPING_QUOTATION",
  PRE_ORDER_CREATION = "PRE_ORDER_CREATION",
}

function stageForSequence(seq: number): DiscountStage {
  if (seq <= 20) {
    return DiscountStage.PRE_INFO_FETCH;
  } else if (seq <= 40) {
    return DiscountStage.PRE_FULFILLMENT_PLAN;
  } else if (seq <= 60) {
    return DiscountStage.PRE_SHIPPING_QUOTATION;
  } else {
    return DiscountStage.PRE_ORDER_CREATION;
  }
}

export const DiscountEdit = (props: EditProps<Discount>) => (
  <Edit {...props}>
    <DiscountForm codeEditable={false} />
  </Edit>
);

export function DiscountCreate(props: CreateProps<Discount>): ReactElement {
  return (
    <Create {...props}>
      <DiscountForm codeEditable={true} />
    </Create>
  );
}

type DiscountFormProps = {
  codeEditable: boolean;
};

function DiscountForm(p: DiscountFormProps): ReactElement {
  return (
    <SimpleForm sanitizeEmptyValues>
      <TextInput
        source="code"
        format={formatCode}
        required={p.codeEditable}
        disabled={!p.codeEditable}
      />
      <TextInput source="title" fullWidth required />
      <SelectInput
        source="sequence"
        label="Discount Sequence"
        choices={sequenceChoices}
        defaultValue={80}
      />
      <DateTimeInput source="validityStart" />
      <DateTimeInput source="validityEnd" />

      <Typography variant="h6">Set as Buy X Get Y</Typography>
      <NumberInput
        source="maxRepeatCount"
        label="Max Repeat (Put 0 for No Limit)"
        min={0}
      />

      <Typography variant="h6">Conditions</Typography>
      <ArrayInput source="lineItemConditions">
        <SimpleFormIterator>
          <SelectInput
            source="filter.kind"
            label="Line Item Filter Kind"
            choices={lineItemFilterChoices}
          />
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => {
              const source = getSource?.("filter.ids") ?? "";
              return (
                scopedFormData?.filter?.kind === "PRODUCT_IDS" && (
                  <ArrayInput source={source}>
                    <SimpleFormIterator>
                      <TextInput source="" />
                    </SimpleFormIterator>
                  </ArrayInput>
                )
              );
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => {
              const source = getSource?.("filter.tags") ?? "";
              return (
                scopedFormData?.filter?.kind === "PRODUCT_TAGS" && (
                  <ArrayInput source={source}>
                    <SimpleFormIterator>
                      <TextInput source="" />
                    </SimpleFormIterator>
                  </ArrayInput>
                )
              );
            }}
          </FormDataConsumer>
          <MoneyInput source="minCurrentTotal" />
          <NumberInput source="minQuantity" defaultValue={0} />
        </SimpleFormIterator>
      </ArrayInput>

      <SelectInput
        source="customerFilter.kind"
        label="Customer"
        choices={customerFilterChoices}
      />
      <FormDataConsumer>
        {({ formData }) => {
          return (
            formData?.customerFilter?.kind === "CUSTOMER_IDS" && (
              <ArrayInput source={"customerFilter.ids"} label="Customer IDs">
                <SimpleFormIterator>
                  <TextInput source="" />
                </SimpleFormIterator>
              </ArrayInput>
            )
          );
        }}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) => {
          return (
            formData?.customerFilter?.kind === "CUSTOMER_TAGS" && (
              <ArrayInput source={"customerFilter.tags"} label="Customer Tags">
                <SimpleFormIterator>
                  <TextInput source="" />
                </SimpleFormIterator>
              </ArrayInput>
            )
          );
        }}
      </FormDataConsumer>

      <MoneyInput source="minCurrentSubtotal" label="Min Subtotal" />

      <ArrayInput source="checkoutConditions">
        <SimpleFormIterator>
          <SelectInput
            source="location.kind"
            label="Checkout Method"
            choices={addressChoices}
          />
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => {
              const sourcePickupLocation = getSource?.("location.pickupLocation.pickupLocationId") ?? "";
              return (
                scopedFormData.location?.kind === "STORE_PICKUP" && (
                  <TextInput source={sourcePickupLocation} label="Location ID" required />
                )
              )
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => {
            const sourceBarangay = getSource?.("location.deliveryAddress.barangay") ?? "";
            const sourceCity = getSource?.("location.deliveryAddress.city") ?? "";
            const sourceProvince = getSource?.("location.deliveryAddress.province") ?? "";
              return (
                scopedFormData.location?.kind === "HOME_DELIVERY" && (
                  <>
                  <LocationDropdowns
                  autoProps={{ isRequired: false }}
                  provinceSource={sourceProvince}
                  citySource={sourceCity}
                  barangaySource={sourceBarangay}
                  />
                  </>
                )
              )
            }}
          </FormDataConsumer>Ï
        </SimpleFormIterator>
      </ArrayInput>

      {/*Adjustment*/}
      <Typography variant="h6">Adjustment</Typography>
      <SelectInput
        source="adjustment.kind"
        choices={adjustmentChoices}
        required
        defaultValue="ORDER"
      />
      <FormDataConsumer>
        {({ formData }) =>
          formData.adjustment?.kind === "LINE_ITEM" && (
            <>
              <SelectInput
                source="adjustment.filter.kind"
                label="Line Item Filter Kind"
                choices={lineItemFilterChoices}
              />
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.adjustment?.filter?.kind === "PRODUCT_IDS" && (
                    <ArrayInput
                      source="adjustment.filter.ids"
                      label="Product Ids"
                      {...rest}
                    >
                      <SimpleFormIterator>
                        <TextInput source="" />
                      </SimpleFormIterator>
                    </ArrayInput>
                  )
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.adjustment?.filter?.kind === "PRODUCT_TAGS" && (
                    <ArrayInput
                      source="adjustment.filter.tags"
                      label="Product Tags"
                      {...rest}
                    >
                      <SimpleFormIterator>
                        <TextInput source="" />
                      </SimpleFormIterator>
                    </ArrayInput>
                  )
                }
              </FormDataConsumer>
              <NumberInput
                source="adjustment.quantity"
                label="Quantity Applied"
                min={1}
              />
            </>
          )
        }
      </FormDataConsumer>
      <NumberInput
        source="adjustment.value.maxPct"
        label="Max Percentage (% value)"
        min={0}
        max={100}
        step={1}
        defaultValue={100}
      />
      <MoneyInput
        source="adjustment.value.maxFixed"
        label="Max Fixed (Peso value)"
      />
      <NumberInput source="usageMax" />
      <NumberInput source="usageMaxPerCustomer" />

      <Typography variant="h6">Modifiers</Typography>
      <SelectInput
        source="checkoutMethod.kind"
        label="Checkout Method"
        choices={addressChoices}
      />
      <FormDataConsumer>
        {({ formData }) =>
          formData.checkoutMethod?.kind === "STORE_PICKUP" && (
            <TextInput
              source={"checkoutMethod.pickupLocation.pickupLocationId"}
              label="Location ID"
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) =>
          formData.checkoutMethod?.kind === "HOME_DELIVERY" && (
            <>
              <TextInput
                source={"checkoutMethod.deliveryAddress.streetAddress"}
                label="Street Address"
                fullWidth
                required
              />
              <LocationDropdowns
                autoProps={{ isRequired: true }}
                provinceSource="checkoutMethod.deliveryAddress.province"
                citySource="checkoutMethod.deliveryAddress.city"
                barangaySource="checkoutMethod.deliveryAddress.barangay"
              />
              <TextInput
                source={"checkoutMethod.deliveryAddress.zip"}
                label="ZIP"
                required
              />
            </>
          )
        }
      </FormDataConsumer>

    </SimpleForm>
  );
}

function MoneyInput(p: TextInputProps): ReactElement {
  const validateMoney = regex(
    /^\d*(\.(\d{1,2}))?$/,
    "Must be a valid monetary value"
  );

  return <TextInput placeholder="0.00" validate={validateMoney} {...p} />;
}

function formatCode(s: string): string {
  return s.toUpperCase().replace(/[^0-9A-Z]/g, "");
}

const adjustmentChoices = [
  { id: "ORDER", name: "Order" },
  { id: "SHIPPING", name: "Shipping" },
  { id: "LINE_ITEM", name: "Line Item" },
];

const lineItemFilterChoices = [
  { id: "PRODUCT_IDS", name: "Product Ids" },
  { id: "PRODUCT_TAGS", name: "Product Tags" },
];

const customerFilterChoices = [
  { id: "CUSTOMER_IDS", name: "Customer Ids" },
  { id: "CUSTOMER_TAGS", name: "Customer Tags" },
];

const sequenceChoices = [
  { id: 30, name: "30 - Pre Fulfillment Plan" },
  { id: 40, name: "40 - Pre Fulfillment Plan" },
  { id: 50, name: "50 - Pre Shipping Quotation" },
  { id: 60, name: "60 - Pre Shipping Quotation" },
  { id: 70, name: "70 - Pre Order Creation" },
  { id: 75, name: "75 - Pre Order Creation" },
  { id: 80, name: "80 - Pre Order Creation" },
  { id: 85, name: "85 - Pre Order Creation" },
  { id: 90, name: "90 - Pre Order Creation" },
  { id: 95, name: "95 - Pre Order Creation" },
  { id: 100, name: "100 - Pre Order Creation" },
];

const addressChoices = [
  { id: "HOME_DELIVERY", name: "Home Delivery" },
  { id: "STORE_PICKUP", name: "Store Pickup" },
];

function LocationDropdowns(props: {
  autoProps: AutocompleteInputProps;
  provinceSource: string;
  citySource: string;
  barangaySource: string;
}): ReactElement {
  const accessSource = (source: string, formData: any) => {
    return source.split('.').reduce((o, i) => o[i], formData);
  }

  return (
    <>
      <LocationAutocompleteInput
        source={props.provinceSource}
        label="Province"
        fullWidth
        isRequired
        choices={getLocationChoices()}
        disabled={!getLocationChoices().length}
      />
      <FormDataConsumer>
        {({ formData }) => {
          const province = accessSource(props.provinceSource, formData)

          return (
            <LocationAutocompleteInput
              source={props.citySource}
              label="City"
              fullWidth
              isRequired={props.autoProps.isRequired}
              choices={getLocationChoices(province)}
              disabled={!getLocationChoices(province).length}
            />
          );
        }}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) => {
          const province = accessSource(props.provinceSource, formData)
          const city = accessSource(props.citySource, formData)

          return (
            <LocationAutocompleteInput
              source={props.barangaySource}
              label="Barangay"
              fullWidth
              isRequired={props.autoProps.isRequired}
              choices={getLocationChoices(province, city)}
              disabled={!getLocationChoices(province, city).length}
            />
          );
        }}
      </FormDataConsumer>
    </>
  );
}

function LocationAutocompleteInput(p: AutocompleteInputProps): ReactElement {
  const validateInput = (v: string) => (v ? undefined || "" : "Fill out this field");

  if (p.isRequired)
    return <AutocompleteInput validate={validateInput} {...p} />;
  return <AutocompleteInput {...p} />;
}
