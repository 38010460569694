import { AuthProvider, UserIdentity } from "react-admin";
import { Auth } from "@aws-amplify/auth";
import { AuthOptions } from "@aws-amplify/auth/lib-esm/types";

type Config = {
  userPoolId: string;
  clientId: string;
  hostedUiDomain: string;
};

class CognitoAuthProvider implements AuthProvider {
  constructor(cfg: Config) {
    const authConfig: AuthOptions = {
      userPoolId: cfg.userPoolId,
      userPoolWebClientId: cfg.clientId,
      oauth: {
        domain: cfg.hostedUiDomain,
        scope: [
          "aws.cognito.signin.user.admin",
          "email",
          "openid",
          "phone",
          "profile",
        ],
        redirectSignIn: location.origin + "/login",
        redirectSignOut: location.origin + "/logout",
        responseType: "code",
      },
    };

    Auth.configure(authConfig);
  }

  login(params: any): Promise<{ redirectTo?: string | boolean } | void | any> {
    return Auth.signIn(params.username, params.password);
  }

  async logout(_params: any): Promise<void | false | string> {
    return Auth.signOut();
  }

  async checkAuth(_params: any): Promise<void> {
    return Auth.currentAuthenticatedUser();
  }

  async checkError(error: any): Promise<void> {}

  async getIdentity(): Promise<UserIdentity> {
    const session = await Auth.currentSession();
    if (!session) {
      throw new Error("Not logged in");
    }

    const profile = session.getIdToken().decodePayload();

    return { id: profile.sub };
  }

  async getPermissions(params: any): Promise<any> {
    return [];
  }
}

export function cognitoAuthProvider(config: Config): AuthProvider {
  return new CognitoAuthProvider(config);
}
