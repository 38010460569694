import { Card } from "@mui/material";
import { ReactElement } from "react";

import {
  ArrayInput,
  Datagrid,
  Edit,
  EditProps,
  FunctionField,
  List,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";

interface DiscountGroup {
  id: string;
  discountIds: string[];
}

export const DiscountGroups = (props: any) => {
  return (
    <List>
      <Datagrid rowClick="edit">
        <FunctionField render={ItemTitle} />
      </Datagrid>
    </List>
  );
};

function ItemTitle(props: DiscountGroup) {
  return (
    <div>
      <p>{props.id}</p>
    </div>
  );
}

export const DiscountGroupEdit = (props: EditProps<DiscountGroup>) => (
  <Edit {...props}>
    <SimpleForm sanitizeEmptyValues>
      <ArrayInput source="discountIds" label={"Discount ids"}>
        <SimpleFormIterator>
          <TextInput source="code" disabled={true} />
          <TextInput source="id" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);
